import { ViewfinderCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export default function EmptyState({
  title,
  description,
  image,
  actionButton,
  bordered = true,
}: {
  title: string;
  description: string;
  image?: React.ReactNode;
  actionButton?: React.ReactNode;
  bordered?: boolean;
}) {
  return (
    <div
      className={classNames('w-full', {
        'border border-gray-200 rounded-xl shadow-sm': bordered,
      })}
    >
      <div className="mx-auto min-h-96 max-w-lg text-center flex flex-col justify-center">
        <div className="mx-auto h-18 w-18 p-4 bg-gray-100 rounded-full flex items-center justify-center">
          {image || <ViewfinderCircleIcon className="w-10 h-10" />}
        </div>
        <h2 className="mt-2 text-lg font-semibold leading-6 text-gray-900">{title}</h2>
        <p className="mt-1 text-md text-gray-500">{description}</p>
        <div className="mt-6">{actionButton}</div>
      </div>
    </div>
  );
}
