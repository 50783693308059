import classNames from 'classnames';

export default function FilterBar({
  title,
  filters,
  currentValue,
  onSelect,
}: {
  title?: string;
  filters: { label: string; value?: string | boolean }[];
  currentValue: string | boolean | undefined | null;
  onSelect: (value?: string | boolean) => void;
}) {
  return (
    <div className="flex items-center gap-2">
      {title && <span className="text-md font-medium text-gray-900">{title}: </span>}
      {filters.map(filter => (
        <div
          className={classNames(
            'cursor-pointer px-2 py-1 rounded-md text-sm bg-gray-50 text-gray-800 hover:bg-gray-100',
            filter.value === currentValue && 'bg-indigo-100 text-indigo-700'
          )}
          key={`${title}-${filter.label}`}
          onClick={() => onSelect(filter.value)}
        >
          {filter.label}
        </div>
      ))}
    </div>
  );
}
