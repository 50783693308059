import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import CreateDealInviteLinkDialogButton from './CreateDealInviteLink';
import UpdateDealInviteLinkStatus from './UpdateInviteLinkStatus';

type DealInviteLink = {
  id: string;
  status: 'ACTIVE' | 'INACTIVE';
  minimumInvestmentAmount: number;
  maximumInvestmentAmount?: number;
  numberOfInvestors?: number;
  note?: string;
  shortUrl: string;
  createdAt: string;
};

const DEAL_INVITE_LINKS_QUERY = gql(`
  query GetDealInviteLinks($dealId: ID!) {
    deal(id: $dealId) {
      id
      status
      inviteLinks {
        id
        status
        minimumInvestmentAmount
        maximumInvestmentAmount
        numberOfInvestors
        note
        shortUrl
        createdAt
      }
      permissions {
        canInviteUsers
      }
    }
  }
`);

const InviteLinks: FC = () => {
  const [inviteLinkOnDisplay, setInviteLinkDisplay] = useState<DealInviteLink | null>(null);
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };

  const { loading, error, data, refetch } = useQuery(DEAL_INVITE_LINKS_QUERY, {
    variables: {
      dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const inviteLinks = data.deal.inviteLinks;

  return (
    <>
      <div className="mt-8 sm:flex sm:items-center sm:justify-between">
        <div className="max-w-xl">
          <h3 className="text-lg font-semibold leading-6 text-gray-700">Invite Links</h3>
          <p className="mt-1 text-md text-gray-500">Create and manage deal invite links.</p>
        </div>
        <CreateDealInviteLinkDialogButton
          dealId={dealId}
          canInviteUsers={data.deal.permissions.canInviteUsers}
        />
      </div>
      {inviteLinkOnDisplay && (
        <UpdateDealInviteLinkStatus
          id={inviteLinkOnDisplay.id}
          status={inviteLinkOnDisplay.status}
          onClose={() => setInviteLinkDisplay(null)}
        />
      )}
      <DataTable
        data={inviteLinks}
        onClick={inviteLink => setInviteLinkDisplay(inviteLink)}
        columns={[
          {
            label: 'Short URL',
            fieldName: 'shortUrl',
            type: 'COPY_STRING',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Minimum Investment Amount',
            fieldName: 'minimumInvestmentAmount',
            type: 'CURRENCY',
          },
          {
            label: 'Maximum Investment Amount',
            fieldName: 'maximumInvestmentAmount',
            type: 'CURRENCY',
          },
          {
            label: 'Number of Investors',
            fieldName: 'numberOfInvestors',
            type: 'NUMBER',
          },
          {
            label: 'Note',
            fieldName: 'note',
          },
          {
            label: 'Created At',
            fieldName: 'createdAt',
            type: 'DATETIME',
          },
        ]}
      />
    </>
  );
};

export default InviteLinks;
