import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';

const CREATE_DEAL_INVITE_LINK_MUTATION = gql(`
  mutation CreateDealInviteLink(
    $dealId: ID!
    $minimumInvestmentAmount: Int!
    $maximumInvestmentAmount: Int
    $numberOfInvestors: Int
    $note: String
  ) {
    createDealInviteLink(
      dealId: $dealId
      minimumInvestmentAmount: $minimumInvestmentAmount
      maximumInvestmentAmount: $maximumInvestmentAmount
      numberOfInvestors: $numberOfInvestors
      note: $note
    ) {
      id
      minimumInvestmentAmount
      maximumInvestmentAmount
      numberOfInvestors
      note
      status
      createdAt
    }
  }
`);

const CreateDealInviteLinkDialogButton: FC<{ dealId: string; canInviteUsers: boolean }> = ({
  dealId,
  canInviteUsers,
}) => {
  const [open, setOpen] = useState(false);

  const [createDealInviteLink, { loading, error }] = useMutation(CREATE_DEAL_INVITE_LINK_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          __typename: 'DealType',
          id: dealId,
        }),
        fields: {
          inviteLinks(existingInviteLinksRef) {
            const newInviteLinkNodeRef = cache.writeFragment({
              data: data.createDealInviteLink,
              fragment: gql(`
                fragment NewInviteLink on DealInviteLinkType {
                  id
                  status
                  minimumInvestmentAmount
                  maximumInvestmentAmount
                  numberOfInvestors
                  note
                  createdAt
                }
              `),
            });
            const updatedInviteLinksRef = [newInviteLinkNodeRef, ...existingInviteLinksRef];
            return updatedInviteLinksRef;
          },
        },
      });
    },
  });

  return (
    <>
      <Button onClick={() => setOpen(true)} disabled={!canInviteUsers}>
        Create Invite Link
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel>
          <DialogTitle>Create Invite Link</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => setOpen(false)}
            onSubmit={data =>
              createDealInviteLink({
                variables: {
                  dealId,
                  minimumInvestmentAmount: data.minimumInvestmentAmount,
                  maximumInvestmentAmount: data.setMaximumInvestmentAmount
                    ? data.maximumInvestmentAmount
                    : undefined,
                  numberOfInvestors: data.setNumberOfInvestors ? data.numberOfInvestors : undefined,
                  note: data.note,
                },
              }).then(() => setOpen(false))
            }
          >
            <FormInput
              type="currency"
              fieldName="minimumInvestmentAmount"
              label="Minimum Investment Amount"
              defaultValue={300000}
            />
            <FormInput
              type="switch"
              fieldName="setMaximumInvestmentAmount"
              label="Set Maximum Investment Amount"
              defaultValue={false}
            />
            <FormInput
              type="currency"
              fieldName="maximumInvestmentAmount"
              label="Maximum Investment Amount"
              defaultValue={1000000}
              conditionsToShow={{
                matches: [
                  {
                    field: 'setMaximumInvestmentAmount',
                    condition: '===',
                    value: 'true',
                  },
                ],
              }}
            />

            <FormInput
              type="switch"
              fieldName="setNumberOfInvestors"
              label="Set Number Of Investors"
              defaultValue={false}
            />
            <FormInput
              type="number"
              fieldName="numberOfInvestors"
              label="Number of Investors"
              defaultValue={199}
              conditionsToShow={{
                matches: [
                  {
                    field: 'setNumberOfInvestors',
                    condition: '===',
                    value: 'true',
                  },
                ],
              }}
            />
            <FormInput type="textarea" fieldName="note" label="Note" defaultValue={''} />
          </FormPanel>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateDealInviteLinkDialogButton;
