import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';
import PostCard from 'components/PostCard';

import Button from 'primitives/Button';
import EmptyState from 'primitives/EmptyState';
import LoadingIndicator from 'primitives/LoadingIndicator';

import CreatePostDialogButton from './CreatePostDialogButton';
import PostSlideOver from './PostSlideOver';

const POSTS_QUERY = gql(`
  query DealPostsQuery($dealId: ID!, $cursor: ID, $limit: Int) {
    deal(id: $dealId) {
      id
      status
      posts(cursor: $cursor, limit: $limit) {
        nodes {
          id
          status
          title
          message
          createdAt
          publishedAt
          deal {
            companyInformation {
              company {
                name
              }
            }
          }
          sender {
            id  
            name
          }
          syndicate {
            id
            name
            image
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const DealPosts: FC = () => {
  const [postOnDisplay, setPostOnDisplay] = useState('');

  const { dealId } = useParams<{ dealId: string }>() as {
    dealId: string;
  };

  const { loading, error, data, refetch, fetchMore } = useQuery(POSTS_QUERY, {
    variables: {
      dealId,
      limit: 15,
    },
  });

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data || !data.deal.posts) return <ErrorMessage error={error} refetch={refetch} />;

  const deal = data.deal;
  const posts = deal.posts.nodes;
  const pageInfo = deal.posts.pageInfo;

  if (posts.length === 0)
    return (
      <>
        <CreatePostDialogButton dealStatus={deal.status} />
        <EmptyState
          title="No posts sent out"
          description="Updates and information to the investors in this deal can be sent out here"
        />
      </>
    );

  return (
    <>
      <CreatePostDialogButton dealStatus={deal.status} />
      <PostSlideOver
        open={!!postOnDisplay}
        postId={postOnDisplay}
        dealStatus={deal.status}
        onClose={() => setPostOnDisplay('')}
      />
      <div className="flex flex-col">
        <div className="space-y-2 overflow-auto">
          {posts.map(post => (
            <PostCard
              key={post.id}
              id={post.id}
              title={post.title}
              message={post.message}
              createdAt={post.createdAt}
              publishedAt={post.publishedAt}
              deal={post.deal}
              sender={post.sender}
              syndicate={post.syndicate}
              onClick={() => setPostOnDisplay(post.id)}
              status={post.status}
            />
          ))}
        </div>
        {pageInfo.hasNextPage ? (
          <div className="flex justify-center p-4">
            <Button
              loading={loading}
              onClick={() =>
                fetchMore({
                  variables: {
                    cursor: pageInfo.cursor,
                  },
                })
              }
            >
              Load more
            </Button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DealPosts;
