import { IndustryTypeEnumType } from '__generated__/graphql';

export default function industryToReadable(industry: IndustryTypeEnumType): {
  label: string;
  color: string;
} {
  let label = '';

  if (!industry) {
    return {
      label: '-',
      color: 'gray',
    };
  }

  function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  switch (industry) {
    case IndustryTypeEnumType.AiMl:
      label = 'AI/ML';
      break;
    case IndustryTypeEnumType.BlockchainCryptocurrency:
      label = 'Crypto';
      break;
    case IndustryTypeEnumType.ECommerce:
      label = 'E-Commerce';
      break;
    case IndustryTypeEnumType.HumanResourcesRecruiting:
      label = 'HR/Recruiting';
      break;
    case IndustryTypeEnumType.IndustrySpecificSaas:
      label = 'Industry Specific SaaS';
      break;
    case IndustryTypeEnumType.Iot:
      label = 'IoT';
      break;
    case IndustryTypeEnumType.FoodBeverages:
      label = 'Food & Beverages';
      break;
    case IndustryTypeEnumType.MediaEntertainment:
      label = 'Media & Entertainment';
      break;
    case IndustryTypeEnumType.VrAr:
      label = 'VR/AR';
      break;
    default:
      label = capitalize(industry.replace(/_/g, ' ').toLowerCase());
      break;
  }

  return {
    label,
    color: 'gray',
  };
}
