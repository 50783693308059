import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ErrorMessage from 'components/ErrorMessage';
import Statistics from 'components/Statistics';

import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import {
  fixToTwoLocalPrice,
  formatCurrencyToShort,
  getInvestedAmountRange,
} from 'utils/format-helper';

import InvestmentStatistics from './InvestmentStatistics';
import InvestmentsOfSyndicateUser from './Investments';
import RemoveUserFromSyndicateDialogButton from './RemoveUserFromSyndicateDialogButton';
import SyndicateUserNote from './SyndicateUserNote';
import SyndicateUserTitle from './SyndicateUserTitle';

const SYNDICATE_USER_QUERY = gql(`
  query GetSyndicateUser($id: ID!) {
    syndicateUser(id: $id) {
      id
      user {
        id
        name
        image {
          url
          name
        }
        email
        linkedInProfileUrl
        createdAt
        statistics {
          numberOfSyndicatesJoined
          totalInvestedAmount
          numberOfInvestments
          investRate
        }
      }
      syndicate {
        id
      }
      conversations {
        nodes {
          id
        }
      }
      privateNote
      createdAt
      statistics {
        totalInvestedAmount
        numberOfInvestments
        investRate
      }
    }
  }
`);

const SyndicateUserSlideOver: FC<{
  open: boolean;
  syndicateUserId: string;
  onClose: () => void;
}> = ({ open = false, syndicateUserId, onClose }) => {
  const { loading, error, data, refetch } = useQuery(SYNDICATE_USER_QUERY, {
    variables: {
      id: syndicateUserId,
    },
    skip: !open,
  });

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const syndicateUser = data.syndicateUser;
    const user = syndicateUser.user;

    return (
      <div>
        <SyndicateUserTitle
          name={user.name}
          createdAt={syndicateUser.createdAt}
          linkedInProfileUrl={user.linkedInProfileUrl}
          conversations={syndicateUser.conversations.nodes}
          syndicateId={syndicateUser.syndicate.id}
          userId={user.id}
          onClose={onClose}
        />
        <div className="my-5">
          <Statistics
            statistics={[
              { label: 'Investor Since', value: user.createdAt, type: 'MONTH' },
              {
                label: 'Number of Syndicates Joined',
                value: user.statistics.numberOfSyndicatesJoined,
                type: 'NUMBER',
              },
            ]}
          />
        </div>
        <div className="grid grid-cols-1 gap-x-2 gap-y-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
          <div className="p-2 bg-gray-400/5 rounded-xl">
            <p className="text-md font-medium text-gray-700">Your Syndicate</p>
            <p className="text-xs text-gray-400">Via SPVs and Funds</p>
            <InvestmentStatistics
              data={[
                {
                  label: 'Total invested',
                  value: formatCurrencyToShort(syndicateUser.statistics.totalInvestedAmount),
                },
                {
                  label: 'Investments',
                  value: syndicateUser.statistics.numberOfInvestments,
                },
                {
                  label: 'Invest Rate',
                  value:
                    `${fixToTwoLocalPrice(syndicateUser.statistics.investRate)}%` || 'No history',
                },
              ]}
            />
          </div>
          <div className="p-2 bg-gray-400/5 rounded-xl">
            <p className="text-md font-medium text-gray-700">Last 12 mo. on AngelList India</p>
            <p className="text-xs text-gray-400">Via SPVs and Funds</p>
            <InvestmentStatistics
              data={[
                {
                  label: 'Total invested',
                  value: getInvestedAmountRange(user.statistics.totalInvestedAmount),
                },
                {
                  label: 'Investments',
                  value: user.statistics.numberOfInvestments,
                },
                {
                  label: 'Invest Rate',
                  value: `${fixToTwoLocalPrice(user.statistics.investRate)}%` || 'No history',
                },
              ]}
            />
          </div>
          <div className="col-span-2 p-2 bg-gray-400/5 rounded-xl">
            <p className="text-md font-medium text-gray-700">Investment Preferences</p>
            <p className="text-xs text-gray-400">Via SPVs and Funds</p>
            <InvestmentStatistics
              data={[
                {
                  label: 'Stages',
                  value: 'All Stages',
                },
                {
                  label: 'Revenues',
                  value: 'All Revenues',
                },
                {
                  label: 'Industries',
                  value: 'All Industries',
                },
              ]}
            />
          </div>
        </div>

        <div className="mt-5">
          <h2 className="text-lg font-semibold text-gray-700 mb-2">
            Past commitments in syndicate
          </h2>
          <InvestmentsOfSyndicateUser id={syndicateUserId} />
        </div>

        <div className="mt-5">
          <h2 className="text-lg font-semibold text-gray-700">Private Note</h2>
          <p className="text-md text-gray-500 mb-2">
            Only you and your team members can see this note
          </p>
          <SyndicateUserNote id={syndicateUser.id} privateNote={syndicateUser.privateNote} />
        </div>

        <div className="mt-6">
          <RemoveUserFromSyndicateDialogButton
            syndicateUserId={syndicateUser.id}
            onClose={onClose}
          />
        </div>
      </div>
    );
  }

  return (
    <SlideOver open={open} onClose={onClose}>
      {renderContent()}
    </SlideOver>
  );
};

export default SyndicateUserSlideOver;
