import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import RecipientsPanel from 'components/Conversation/RecipientsPanel';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import SearchSyndicateForConversation from './SearchSyndicateForConversation';

const INVESTOR_CONVERSATIONS_QUERY = gql(`
  query InvestorConversationsQuery {
    dashboard {
      id
      conversations {
        nodes {
          id
          updatedAt
          syndicate {
            id
            name
          }
        }
      }
    }
  }
`);

const InvestorConversations: FC = () => {
  const navigate = useNavigate();
  const { loading, error, data, refetch } = useQuery(INVESTOR_CONVERSATIONS_QUERY);
  const { conversationId } = useParams() as { conversationId: string };

  useEffect(() => {
    if (!conversationId && data?.dashboard.conversations.nodes.length) {
      const latestConversation = data.dashboard.conversations.nodes[0];
      navigate(`/invest/conversations/${latestConversation.id}`);
    }
  }, [conversationId, data, navigate]);

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const conversations = data.dashboard.conversations.nodes;

    return (
      <div className="grid grid-cols-6 border border-gray-100 rounded-lg">
        <div
          className="col-span-2 overflow-auto"
          style={{
            height: 'calc(100vh - 8rem)',
          }}
        >
          <div className="p-3 sticky">
            <SearchSyndicateForConversation />
          </div>
          <RecipientsPanel conversations={conversations} />
        </div>
        <div
          className="col-span-4"
          style={{
            height: 'calc(100vh - 8rem)',
          }}
        >
          {conversations.length === 0 ? <div className="bg-gray-100 h-full" /> : <Outlet />}
        </div>
      </div>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Your Conversations"
        subTitle="View conversations with syndicates here"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default InvestorConversations;
