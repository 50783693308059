import { useLazyQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import Autocomplete from 'primitives/Autocomplete';
import Button from 'primitives/Button';

import constants from 'utils/constants';
import debounce from 'utils/debounce';

import CreateCompanyDialogButton from './CreateCompanyDialogButton';

type Company = {
  id: string;
  name: string;
  legalName?: string | null;
  image?: string | null;
};

const SEARCH_QUERY = gql(`
  query SearchCompanies($text: String!, $limit: Int) {
    search(text: $text, limit: $limit) {
      companies {
        nodes {
          id
          name
          legalName
          image
        }
      }
    }
  }
`);

const SearchCompanies: FC<{
  onChange: (company: Company) => void;
  value?: Company;
  label?: string;
  editable?: boolean;
}> = ({ value, onChange, label = '', editable = true }) => {
  const [currentValue, setCurrentValue] = useState<Company | null>(value || null);
  const [getSearchResults, { loading, error, data }] = useLazyQuery(SEARCH_QUERY);

  if (currentValue) {
    return (
      <>
        <h2 className="text-base font-semibold mb-1.5">{label}</h2>
        <div className="ring-1 ring-gray-300 rounded-lg p-2">
          <div className="flex gap-x-2 items-start">
            <img
              src={currentValue.image || constants.ALT_AVATAR_URL}
              alt={currentValue.name}
              className="h-11 w-11 object-contain rounded-lg bg-gray-50"
            />
            <div>
              <h3 className="text-md font-medium">{currentValue.name}</h3>
              <p className="text-sm text-gray-500">{currentValue.legalName}</p>
            </div>
          </div>
          {editable && (
            <div className="mt-2 flex gap-x-2 border-t pt-1">
              <Button
                variant="text"
                className="!text-gray-700 hover:!text-gray-500"
                onClick={() => setCurrentValue(null)}
              >
                Edit
              </Button>
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <div>
      <h2 className="text-base font-semibold mb-1.5">{label}</h2>
      <Autocomplete
        options={data?.search?.companies?.nodes || []}
        loading={loading}
        error={error}
        onChange={e => {
          if (e) {
            onChange(e); // Call the onChange prop with the updated value
          }
          setCurrentValue(e);
        }}
        value={currentValue}
        onSearch={debounce(
          e =>
            getSearchResults({
              variables: {
                text: e,
                limit: 10,
              },
            }),
          500
        )}
        placeholder={'Search by name'}
      />
      <CreateCompanyDialogButton
        onSubmit={company => {
          setCurrentValue(company);
          onChange(company);
        }}
      />
    </div>
  );
};

export default SearchCompanies;
