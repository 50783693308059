import constants from 'utils/constants';

export default function SyndicateLeads({
  syndicateLeads,
}: {
  syndicateLeads: {
    id: string;
    user: {
      id: string;
      image?: {
        url: string;
        name: string;
      } | null;
      name: string;
    };
  }[];
}) {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
      {syndicateLeads.map(lead => (
        <div
          key={lead.id}
          className="relative flex items-center space-x-3 rounded-lg border border-gray-100 bg-white px-6 py-5 shadow-sm"
        >
          <div className="flex-shrink-0">
            <img className="h-10 w-10 rounded-full" src={constants.ALT_AVATAR_URL} alt="" />
          </div>
          <div className="min-w-0 flex-1">
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="text-sm font-medium text-gray-900">{lead.user.name}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
