import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { UserStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import FocusLayout from 'layouts/FocusLayout';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import { logout } from 'utils/auth';

import ReportAccountSuspension from './ReportAccountSuspension';

const SUSPENDED_USER_QUERY = gql(`
  query SuspendedUser {
    dashboard {
      id
      user {
        id
        status
      }
    }
  }
`);

const SuspendedUser: FC = () => {
  const { loading, error, data, refetch } = useQuery(SUSPENDED_USER_QUERY);

  if (loading) return <LoadingIndicator />;
  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const user = data.dashboard.user;

  if (user.status !== UserStatusEnumType.Suspended) {
    return <Navigate to="/" />;
  }

  return (
    <FocusLayout>
      <main className="grid min-h-full place-items-center py-32">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">Notice</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Your account is suspended
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            We have detected a breach of our platform guidelines, resulting in the suspension of
            your account. Contact our support team to understand the next steps.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <button
              type="button"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={logout}
            >
              Logout
            </button>
            <ReportAccountSuspension />
          </div>
        </div>
      </main>
    </FocusLayout>
  );
};

export default SuspendedUser;
