import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import CreateStepperLayout from 'layouts/CreateStepperLayout';

import { FormInput, FormPanel } from 'components/FormPanel';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

import { getUser } from 'utils/auth';

const CREATE_FUND_MUTATION = gql(`
  mutation CreateFund(
    $name: String!
    $closingDate: String!
    $image: Upload
    $description: String
  ) {
    createFund(
      name: $name
      closingDate: $closingDate
      image: $image
      description: $description
    ) {
      id
      name
      closingDate
      image
      description
    }
  }
`);

const CreateFund: FC = () => {
  const navigate = useNavigate();
  const currentUser = getUser();

  const [createFund, { loading, error }] = useMutation(CREATE_FUND_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          id: currentUser.id, // The ID of the dashboard is the same as the user ID
          __typename: 'DashboardType',
        }),
        fields: {
          funds(existingFundsRef) {
            const newFundNodeRef = cache.writeFragment({
              data: data.createFund,
              fragment: gql(`
                fragment NewFund on FundType {
                  id
                  name
                  closingDate
                  image
                  description
                }
              `),
            });
            const updatedFundsRef = { ...existingFundsRef };
            updatedFundsRef.nodes = [newFundNodeRef, ...updatedFundsRef.nodes];
            return updatedFundsRef;
          },
        },
      });
    },
  });

  const renderContent = () => {
    return (
      <FormPanel
        loading={loading}
        error={error}
        onSubmit={data => {
          createFund({
            variables: {
              name: data.name,
              description: data.description,
              image: typeof data.image !== 'string' ? data.image : null,
              closingDate: data.closingDate,
            },
          }).then(mutationData => navigate(`/fund-dashboard/${mutationData.data?.createFund?.id}`));
        }}
      >
        <FormInput
          fieldName="name"
          type="text"
          defaultValue=""
          label="Fund Name"
          validators={{
            required: true,
          }}
        />
        <FormInput
          fieldName="description"
          type="custom"
          customInput={EditorFormInput}
          defaultValue=""
          label="Fund Description"
          helperText="Your fund description and thesis"
          fullWidth
          validators={{
            required: true,
          }}
        />
        <FormInput type="upload" fieldName="image" label="Logo" defaultValue={null} />
        <FormInput
          type="date"
          fieldName="closingDate"
          label="Closing Date"
          defaultValue={''}
          validators={{
            required: true,
          }}
        />
      </FormPanel>
    );
  };

  return (
    <CreateStepperLayout onClose={() => navigate('/invest/syndicates/backed')}>
      <h2 className="mt-6 text-3xl font-bold leading-9 tracking-tight text-gray-700">
        Create a Fund
      </h2>
      <p className="text-md mt-1 text-gray-500">
        Create a fund to pool capital from investors and invest in startups.
      </p>
      <div className="lg:-mx-4 my-4 p-4 bg-white ring-1 ring-gray-200 rounded-lg">
        {renderContent()}
      </div>
    </CreateStepperLayout>
  );
};

export default CreateFund;
