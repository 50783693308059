import { FC } from 'react';

import DetailsPanel from 'components/DetailsPanel';

import valuationData from './valuation-policy-data.json';

const Valuation: FC = () => (
  <div>
    <h3 className="text-2xl font-semibold leading-6 text-gray-900 mb-5">Valuation Policy</h3>
    <DetailsPanel data={valuationData} />
  </div>
);

export default Valuation;
