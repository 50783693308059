import { FC } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import CreateLayout from 'layouts/CreateLayout';

import DealTabs from './DealTabs';

const CreateDeal: FC = () => {
  const navigate = useNavigate();
  const { syndicateId } = useParams() as { syndicateId: string };

  return (
    <CreateLayout onClose={() => navigate(`/syndicate-dashboard/${syndicateId}/deals`)}>
      <div className="col-span-2">
        <DealTabs />
      </div>
      <div className="col-span-4">
        <Outlet />
      </div>
    </CreateLayout>
  );
};

export default CreateDeal;
