import { ChevronRightIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';

import Alert from 'primitives/Alert';

import constants from 'utils/constants';

type SyndicateConversationType = {
  id: string;
  updatedAt: string;
  users: {
    id: string;
    name: string;
  }[];
};

type InvestorConversationType = {
  id: string;
  updatedAt: string;
  syndicate?: {
    id: string;
    name: string;
  } | null;
  user?: {
    id: string;
    name: string;
  };
};

const RecipientsPanel = ({
  syndicateId,
  conversations,
}: {
  syndicateId?: string;
  conversations: (SyndicateConversationType | InvestorConversationType)[];
}) => {
  return (
    <>
      {conversations.length === 0 ? (
        <div className="h-full p-4">
          <Alert
            title="No messages found"
            type="info"
            message="Messages shared between your syndicate and investors will be displayed here."
          />
        </div>
      ) : (
        <ul className="divide-y divide-gray-100 ring-1 ring-gray-900/5 bg-gray-100 h-full">
          {conversations.map(conversation => {
            const name =
              'syndicate' in conversation
                ? conversation.syndicate?.name
                : 'users' in conversation
                ? conversation.users[0]?.name || 'No name found'
                : conversation.user?.name;

            return (
              <li key={conversation.id}>
                <NavLink
                  to={
                    syndicateId
                      ? `/syndicate-dashboard/${syndicateId}/conversations/${conversation.id}`
                      : `/invest/conversations/${conversation.id}`
                  }
                  className={({ isActive }) =>
                    classNames(
                      'focus:outline-none relative flex justify-between gap-x-6 px-3 py-2.5',
                      {
                        'hover:bg-gray-50': !isActive,
                        'bg-indigo-50 hover:bg-indigo-50 ': isActive,
                      }
                    )
                  }
                >
                  <div className="flex items-center min-w-0 gap-x-4">
                    <img
                      className="h-8 w-8 flex-none rounded-full bg-gray-50"
                      src={constants.ALT_AVATAR_URL}
                      alt=""
                    />
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        <span className="absolute inset-x-0 -top-px bottom-0" />
                        {name}
                      </p>
                      <p className="flex text-xs leading-4 text-gray-500">
                        {dayjs(conversation.updatedAt).format('MMMM DD, YYYY hh:mm A')}
                      </p>
                    </div>
                  </div>
                  <div className="flex shrink-0 items-center gap-x-4">
                    <ChevronRightIcon
                      className="h-5 w-5 flex-none text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </NavLink>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default RecipientsPanel;
