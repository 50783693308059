import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import HorizontalTabs from 'components/HorizontalTabs';

const SyndicateApplications: FC = () => {
  const { syndicateId } = useParams<{ syndicateId: string }>() as { syndicateId: string };

  const tabs = [
    {
      key: 'pending',
      label: 'Pending',
      description: 'View and manage incoming applications from LPs',
      route: `/syndicate-dashboard/${syndicateId}/network/applications`,
      exact: true,
    },
    {
      key: 'passed',
      label: 'Passed',
      description: 'View and manage outgoing invites to LPs',
      route: `/syndicate-dashboard/${syndicateId}/network/applications/passed`,
      exact: true,
    },
  ];

  return (
    <>
      <HorizontalTabs tabs={tabs} />
      <Outlet />
    </>
  );
};

export default SyndicateApplications;
