import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

const REMOVE_CARRY_RECIPIENT_MUTATION = gql(`
  mutation RemoveDealCarryRecipient($id: ID!) {
    removeDealCarryRecipient(id: $id) {
      id
      deal {
        id
      }
    }
  }
`);

const RemoveCarryRecipientDialogButton: FC<{
  id: string;
}> = ({ id }) => {
  const [showConfirmDialog, toggleConfirmDialog] = useState(false);

  const [removeDealCarryRecipient, { loading, error }] = useMutation(
    REMOVE_CARRY_RECIPIENT_MUTATION,
    {
      variables: {
        id,
      },
      update(cache, { data }) {
        if (!data) return;
        const { removeDealCarryRecipient } = data;
        cache.modify({
          id: `DealType:${removeDealCarryRecipient.deal.id}`,
          fields: {
            carryRecipients(existingCarryRecipientsRefs, { readField }) {
              return existingCarryRecipientsRefs.filter(
                recipientRef => removeDealCarryRecipient.id !== readField('id', recipientRef)
              );
            },
          },
        });
      },
    }
  );

  return (
    <>
      <Button
        variant="text"
        className="!text-red-900 hover:!text-red-700"
        onClick={() => toggleConfirmDialog(true)}
      >
        Remove
      </Button>
      <Dialog open={showConfirmDialog} onClose={() => toggleConfirmDialog(false)}>
        <DialogPanel>
          <DialogTitle>Are you sure?</DialogTitle>
          You can add this person again if needed
          {error && <ErrorMessage error={error} type="alert" />}
          <DialogActions>
            <Button onClick={() => toggleConfirmDialog(false)}>Cancel</Button>
            <Button
              loading={loading}
              onClick={() =>
                removeDealCarryRecipient().then(() => {
                  toggleConfirmDialog(false);
                })
              }
            >
              Remove
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default RemoveCarryRecipientDialogButton;
