import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import AuthLayout from 'layouts/AuthLayout';

import { FormInput, FormPanel } from 'components/FormPanel';

import Alert from 'primitives/Alert';
import Button from 'primitives/Button';
import Divider from 'primitives/Divider';

import { storeLoginCredentials } from 'utils/auth';

const LOGIN_MUTATION = gql(`
  mutation LoginAsUser($email: String!, $password: String!, $targetEmail: String!) {
    loginAsUser(email: $email, password: $password, targetEmail: $targetEmail) {
      token
      user {
        id
        name
        email
        phoneNumber
      }
    }
  }
`);

const LoginAsUser: FC = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const [loginAsUser, { error, loading }] = useMutation(LOGIN_MUTATION);

  return (
    <AuthLayout title="Sign in to your account">
      <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
        <FormPanel
          loading={loading}
          error={error}
          onSubmit={formData => {
            loginAsUser({
              variables: {
                email: formData.email,
                password: formData.password,
                targetEmail: formData.targetEmail,
              },
            }).then(({ data }) => {
              if (data?.loginAsUser?.token) {
                storeLoginCredentials(
                  data.loginAsUser.user,
                  `Bearer ${data.loginAsUser.token}`,
                  true
                );
                navigate('/');
              }
            });
          }}
          submitButtonLabel="Login"
          buttonRef={buttonRef}
        >
          <FormInput
            fieldName="email"
            type="text"
            defaultValue=""
            label="Email *"
            fullWidth
            validators={{ required: true }}
          />
          <FormInput
            fieldName="password"
            type="password"
            defaultValue=""
            label="Password *"
            fullWidth
            validators={{
              required: true,
            }}
          />
          <FormInput
            fieldName="targetEmail"
            type="text"
            defaultValue=""
            label="Target Email *"
            fullWidth
            validators={{
              required: true,
            }}
          />
        </FormPanel>
        <div className="mt-6">
          <Button
            loading={loading}
            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              // @ts-ignore
              buttonRef.current?.submit();
            }}
          >
            Sign in
          </Button>
          <div className="mt-6">
            <Divider />
            <Alert
              type="info"
              title="How is AngelList India different from AngelList?"
              message="We are a separate platform focused on the Indian startup ecosystem but part of the same AngelList family."
              buttonText="Read more"
              onClick={() => window.open('https://angellistindia.com', '_blank')}
            />
          </div>
        </div>
      </div>

      <p className="mt-10 text-center text-sm text-gray-500">
        New to AngelList India?{' '}
        <NavLink
          to="/signup"
          className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
        >
          Create an account
        </NavLink>
      </p>
    </AuthLayout>
  );
};

export default LoginAsUser;
