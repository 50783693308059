import { useMutation } from '@apollo/client';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__';
import {
  SyndicateInviteResponseTypeEnumType,
  SyndicateInviteTypeEnumType,
} from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';

const RESOLVE_INVITATION_MUTATION = gql(`
    mutation ResolveSyndicateInvite(
      $id: ID!
      $inviteResponseType: SyndicateInviteResponseTypeEnumType!
    ) {
      resolveSyndicateInvite(id: $id, inviteResponseType: $inviteResponseType) {
        id
        type
        status
      }
    }
  `);

const ResolveSyndicateInvite: FC<{
  syndicateInviteId: string;
  syndicateId: string;
  note?: string | null;
}> = ({ syndicateInviteId, syndicateId, note }) => {
  const [resolveInvite, { loading, error }] = useMutation(RESOLVE_INVITATION_MUTATION, {
    refetchQueries: ['SuggestedSyndicates', 'BackedSyndicates'],
  });

  const navigate = useNavigate();

  return (
    <div className="mt-4 px-4 py-6 overflow-hidden bg-white shadow sm:rounded-lg">
      {note && (
        <>
          <p className="text-sm font-medium text-gray-900">Note from Syndicate: </p>
          <blockquote className="mt-2 rounded-md py-2 px-4 bg-gray-50">{note}</blockquote>
        </>
      )}
      {error && (
        <div className="mt-2">
          <ErrorMessage type="alert" error={error} />
        </div>
      )}
      <div className="flex flex-row min-w-full space-x-2 mt-4">
        <Button
          variant="secondary"
          loading={loading}
          className="w-full"
          onClick={() =>
            resolveInvite({
              variables: {
                id: syndicateInviteId,
                inviteResponseType: SyndicateInviteResponseTypeEnumType.Reject,
              },
            }).then(() => navigate('/invest/syndicates'))
          }
          leadingIcon={<XCircleIcon />}
        >
          Reject
        </Button>
        <Button
          loading={loading}
          className="w-full"
          onClick={() =>
            resolveInvite({
              variables: {
                id: syndicateInviteId,
                inviteResponseType: SyndicateInviteResponseTypeEnumType.Accept,
              },
            }).then(data => {
              if (
                data.data?.resolveSyndicateInvite?.type ===
                SyndicateInviteTypeEnumType.FromSyndicateToLead
              ) {
                navigate(`/syndicate-dashboard/${syndicateId}`);
                return;
              }
              navigate('/invest/syndicates/backed');
            })
          }
          leadingIcon={<CheckCircleIcon />}
        >
          Accept
        </Button>
      </div>
    </div>
  );
};

export default ResolveSyndicateInvite;
