import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import FocusLayout from 'layouts/FocusLayout';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ReportAccountSuspension from './ReportAccountSuspension';

const MAINTENANCE_QUERY = gql(`
  query MaintenanceQuery {
    maintenanceMode
  }
`);

const UnderMaintenance: FC = () => {
  const { loading, error, data, refetch } = useQuery(MAINTENANCE_QUERY);

  if (loading) return <LoadingIndicator />;
  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  if (!data.maintenanceMode) {
    return <Navigate to="/" />;
  }

  return (
    <FocusLayout>
      <main className="grid min-h-full place-items-center py-32">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">AngelList India</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Enhancing your experience
          </h1>
          <p className="mt-6 text-xl leading-7 text-gray-600">
            We’re upgrading your dashboard for a faster, smoother experience. During this brief
            maintenance, things may be down for a bit, but we’ll be back up shortly. Thank you!
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <ReportAccountSuspension />
          </div>
        </div>
      </main>
    </FocusLayout>
  );
};

export default UnderMaintenance;
