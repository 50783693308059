export default function HorizontalBarChart({
  percentage,
  size = 'sm',
}: {
  percentage: number;
  size?: 'sm' | 'md' | 'lg';
}) {
  const height = {
    sm: 'h-4',
    md: 'h-8',
    lg: 'h-12',
  }[size];

  return (
    <div className="w-full">
      <div className="overflow-hidden rounded-sm bg-gray-200">
        <div
          className={`${height}
         rounded-sm bg-indigo-600`}
          style={{ width: `${percentage}%` }}
        />
      </div>
    </div>
  );
}
