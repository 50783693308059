import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import useParentRoute from 'hooks/useParentRoute';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import Alert from 'primitives/Alert';
import LoadingIndicator from 'primitives/LoadingIndicator';

import {
  getEligibilityResponseValue,
  getInvestingExperienceValue,
  getTargetAllocationValue,
} from '../utils/options';

const INVESTOR_APPLICATION_QUERY = gql(`
  query InvestorApplication($id: ID!) {
    investmentEntity(id: $id) {
      id
      investorApplication {
        id
        eligibilityResponse
        eligibilityProofDocument {
          url
          name
        }
        targetAllocation
        investingExperience
      }
    }
  }
`);

const UPDATE_INVESTOR_APPLICATION_MUTATION = gql(`
  mutation UpsertInvestorApplication(
    $investmentEntityId: ID!
    $eligibilityResponse: String!
    $eligibilityProofDocument: Upload
    $targetAllocation: String!
    $investingExperience: String!
  ) {
    upsertInvestorApplication(
      investmentEntityId: $investmentEntityId
      eligibilityResponse: $eligibilityResponse
      eligibilityProofDocument: $eligibilityProofDocument
      targetAllocation: $targetAllocation
      investingExperience: $investingExperience
    ) {
      id
      investorApplication {
        id
        eligibilityResponse
        eligibilityProofDocument {
          url
          name
        }
        targetAllocation
        investingExperience
      }
    }
  }
`);

const InvestorApplication: FC = () => {
  const navigate = useNavigate();
  const parentRoute = useParentRoute();

  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const { loading, error, data, refetch } = useQuery(INVESTOR_APPLICATION_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });

  const [upsertInvestorApplication, { loading: upsertLoading, error: upsertError }] = useMutation(
    UPDATE_INVESTOR_APPLICATION_MUTATION
  );

  if (loading) return <LoadingIndicator />;

  if (error || !data || !investmentEntityId)
    return <ErrorMessage error={error} refetch={refetch} />;
  return (
    <FormPanel
      loading={upsertLoading}
      error={upsertError}
      onSubmit={formData => {
        let eligibilityProofDocument;
        if (formData.eligibilityResponse === 'netWorth2Cr') {
          eligibilityProofDocument = formData.eligibilityProofDocumentForIndividual;
        } else {
          eligibilityProofDocument = formData.eligibilityProofDocumentForCorporate;
        }
        upsertInvestorApplication({
          variables: {
            investmentEntityId,
            eligibilityResponse: formData.eligibilityResponse,
            eligibilityProofDocument:
              data.investmentEntity.investorApplication?.eligibilityProofDocument !==
              eligibilityProofDocument
                ? eligibilityProofDocument
                : undefined,
            targetAllocation: formData.targetAllocation,
            investingExperience: formData.investingExperience,
          },
        }).then(() => {
          navigate(`${parentRoute}/submit-for-review`);
        });
      }}
      onCancel={() => {
        navigate(`${parentRoute}/kyc`);
      }}
      submitButtonLabel="Save and continue"
      cancelButtonLabel="Back"
    >
      <FormInput
        fieldName="eligibilityResponse"
        label="How are you accredited? *"
        type="radio-group"
        defaultValue={
          getEligibilityResponseValue(
            data.investmentEntity.investorApplication?.eligibilityResponse
          ) || ''
        }
        options={[
          {
            label:
              'I am an individual investor with net tangible assets of INR 2 crores or more (excluding value of my principal residence)',
            value: 'netWorth2Cr',
          },
          {
            label: 'I am a body corporate with a net worth of INR 10 crores or more',
            value: 'corporateNetWorth10Cr',
          },
          {
            label: 'I am a SEBI registered Alternative Investment Fund/ Venture Capital Fund',
            value: 'ventureCapitalFund',
          },
          {
            label:
              'I qualify as an “Accredited Investor” as per Securities and Exchange Board Of India (Alternative Investment Funds) Regulations, 2012',
            value: 'accreditedInvestorAsPerSebi',
          },
        ]}
        validators={{
          required: true,
        }}
      />
      <FormInput
        type="checkbox-group"
        fieldName="eligibilityDeclaration"
        label=""
        options={[
          {
            label:
              'I hereby declare that my net tangible assets, excluding the value of my primary residence, exceed INR 2 Crores as of the end of the previous financial year.',
            value: 'agree',
          },
        ]}
        defaultValue={
          getEligibilityResponseValue(
            data.investmentEntity.investorApplication?.eligibilityResponse
          ) === 'netWorth2Cr'
            ? ['agree']
            : []
        }
        validators={{
          required: true,
        }}
        compact={false}
        conditionsToShow={{
          matches: [
            {
              field: 'eligibilityResponse',
              condition: '===',
              value: 'netWorth2Cr',
            },
          ],
        }}
      />
      <FormInput
        fieldName="eligibilityProofDocumentForCorporate"
        label="Eligibility Proof Document *"
        helperText="Eligibility Proof: Please provide a Net Worth / Accreditation / AIF Registration certificate"
        type="upload"
        defaultValue={data.investmentEntity.investorApplication?.eligibilityProofDocument}
        validators={{
          required: true,
        }}
        conditionsToShow={{
          type: 'some',
          matches: [
            {
              field: 'eligibilityResponse',
              condition: '===',
              value: 'corporateNetWorth10Cr',
            },
            {
              field: 'eligibilityResponse',
              condition: '===',
              value: 'ventureCapitalFund',
            },
            {
              field: 'eligibilityResponse',
              condition: '===',
              value: 'accreditedInvestorAsPerSebi',
            },
          ],
        }}
      />

      <FormInput
        fieldName="eligibilityProofDocumentForIndividual"
        label="Eligibility Proof Document *"
        helperText="Eligibility Proof: Please provide a document evidencing tangible net assets of INR 2 cr or more (e.g., demat statement, tax returns, audited financials, and bank statements)"
        type="upload"
        defaultValue={data.investmentEntity.investorApplication?.eligibilityProofDocument}
        validators={{
          required: true,
        }}
        conditionsToShow={{
          matches: [
            {
              field: 'eligibilityResponse',
              condition: '===',
              value: 'netWorth2Cr',
            },
          ],
        }}
      />
      <FormInput
        fieldName="targetAllocation"
        label="How much capital do you plan to invest using Angellist India over the next twelve months? *"
        type="radio-group"
        defaultValue={
          getTargetAllocationValue(data.investmentEntity.investorApplication?.targetAllocation) ||
          ''
        }
        options={[
          { label: 'Less than 25 lakhs', value: 'lessThan25Lakhs' },
          { label: '25-50 lakhs', value: '25To50Lakhs' },
          { label: '50 lakhs - 1 crore', value: '50LakhsTo1Crore' },
          { label: '1 crore +', value: '1CrorePlus' },
        ]}
        validators={{
          required: true,
        }}
      />
      <Alert
        type="info"
        title="Minimum Investment"
        message="SEBI mandates that you invest a minimum of ₹25 lakhs over the next 5 years"
      />
      <FormInput
        fieldName="investingExperience"
        label="What is your experience investing in venture-backed tech startups or capital funds? *"
        type="radio-group"
        defaultValue={
          getInvestingExperienceValue(
            data.investmentEntity.investorApplication?.investingExperience
          ) || ''
        }
        options={[
          {
            label:
              'I have invested in startups or emerging or early-stage ventures directly/ indirectly',
            value: 'invested',
          },
          {
            label: 'I am a senior management professional with at least 10 years of experience',
            value: 'seniorManagement',
          },
          {
            label: 'I represent/ have represented a family office',
            value: 'familyOffice',
          },
          { label: 'I have experience as a serial entrepreneur', value: 'entrepreneur' },
        ]}
        validators={{
          required: true,
        }}
      />
    </FormPanel>
  );
};

export default InvestorApplication;
