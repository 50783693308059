import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { SyndicateTypeEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CreateStepperLayout from 'layouts/CreateStepperLayout';

import { FormInput, FormPanel } from 'components/FormPanel';

import { getUser } from 'utils/auth';

const CREATE_SYNDICATE_MUTATION = gql(`
  mutation CreateSyndicate(
    $name: String!
    $tagline: String!
    $isPrivate: Boolean!
    $applicationInformation: ApplicationInformationInputType!
    $type: SyndicateTypeEnumType!
  ) {
    createSyndicate(
      name: $name
      tagline: $tagline
      isPrivate: $isPrivate
      applicationInformation: $applicationInformation
      type: $type
    ) {
      id
      name
      isPrivate
      status
      createdAt
      updatedAt
    }
  }
`);

const CreateSyndicate: FC = () => {
  const navigate = useNavigate();
  const currentUser = getUser();
  const { type } = useParams<{ type: string }>();
  const isRuvSyndicate = type === 'ruv';
  const [createSyndicate, { loading, error }] = useMutation(CREATE_SYNDICATE_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          id: currentUser.id, // The ID of the dashboard is the same as the user ID
          __typename: 'DashboardType',
        }),
        fields: {
          syndicates(existingSyndicatesRef) {
            const newSyndicateNodeRef = cache.writeFragment({
              data: data.createSyndicate,
              fragment: gql(`
                fragment NewSyndicate on SyndicateType {
                  id
                  name
                  isPrivate
                  status
                  createdAt
                  updatedAt
                }
              `),
            });
            const updatedSyndicatesRef = { ...existingSyndicatesRef };
            updatedSyndicatesRef.nodes = [newSyndicateNodeRef, ...updatedSyndicatesRef.nodes];
            return updatedSyndicatesRef;
          },
        },
      });
    },
  });

  const renderContent = () => {
    return (
      <FormPanel
        loading={loading}
        error={error}
        onSubmit={data => {
          createSyndicate({
            variables: {
              name: data.name,
              tagline: data.tagline,
              isPrivate: data.isPrivate === 'true',
              type: isRuvSyndicate ? SyndicateTypeEnumType.Ruv : SyndicateTypeEnumType.Default,
              applicationInformation: {
                expectedDealsPerYear: data.expectedDealsPerYear,
                previousFundraisingExperience: data.previousFundraisingExperience,
                existingInvestorNetwork: data.existingInvestorNetwork,
              },
            },
          }).then(data => navigate(`/syndicate-dashboard/${data.data?.createSyndicate?.id}`));
        }}
      >
        <FormInput
          fieldName="name"
          type="text"
          defaultValue=""
          label={isRuvSyndicate ? 'Startup Name *' : 'Syndicate Name *'}
          placeholder={
            isRuvSyndicate ? 'Example: AngelList India' : 'Example: "AngelList India Syndicate'
          }
          validators={{ required: true, minLength: 2 }}
        />
        <FormInput
          fieldName="tagline"
          type="text"
          defaultValue=""
          placeholder='Example: "Market access and infrastructure for investing in top-tier, venture-backed companies in India."'
          label={isRuvSyndicate ? 'Tagline *' : 'Syndicate Tagline *'}
          helperText={
            isRuvSyndicate
              ? 'One-liner describing your startup'
              : 'One-liner describing your syndicate to LPs.'
          }
          fullWidth
          validators={{
            required: true,
            maxLength: 255,
            minLength: 10,
          }}
        />
        <FormInput
          fullWidth
          type="radio-group"
          fieldName="isPrivate"
          label="Syndicate Profile Privacy *"
          helperText="Do you want your syndicate profile to be public or private on AngelList India?"
          defaultValue={isRuvSyndicate ? 'true' : ''}
          options={[
            {
              label: 'Public: Investors can discover and apply to back your syndicate.',
              value: 'false',
            },
            {
              label: 'Private: Only people with a direct link can view your profile.',
              value: 'true',
            },
          ]}
          validators={{
            required: true,
          }}
          hidden={isRuvSyndicate}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="expectedDealsPerYear"
          label="Expected Dealflow *"
          helperText="How many deals do you plan to run per year?"
          defaultValue={isRuvSyndicate ? 'I only want to run 1 deal now' : ''}
          options={[
            { label: 'More than 20 deals per year', value: 'More than 20 deals per year' },
            { label: 'Between 5 and 20 deals per year', value: 'Between 5 and 20 deals per year' },
            { label: 'Fewer than 5 deals per year', value: 'Fewer than 5 deals per year' },
            { label: 'I only want to run 1 deal now', value: 'I only want to run 1 deal now' },
          ]}
          validators={{
            required: true,
          }}
          hidden={isRuvSyndicate}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="previousFundraisingExperience"
          label="Fundraising Experience *"
          helperText="How much have you raised from LPs previously?"
          defaultValue={isRuvSyndicate ? 'I haven’t raised money from investors' : ''}
          options={[
            { label: 'I raised more than ₹10 Crore', value: 'I raised more than ₹10 Crore' },
            {
              label: 'I raised between ₹1 Crore and ₹10 Crore',
              value: 'I raised between ₹1 Crore and ₹10 Crore',
            },
            { label: 'I raised less than ₹1 Crore', value: 'I raised less than ₹1 Crore' },
            {
              label: 'I haven’t raised money from investors',
              value: 'I haven’t raised money from investors',
            },
          ]}
          validators={{
            required: true,
          }}
          hidden={isRuvSyndicate}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="existingInvestorNetwork"
          label="Existing LP network *"
          helperText="How many LPs are part of your current network for deals?"
          defaultValue={isRuvSyndicate ? 'I have enough LPs to fill my deals' : ''}
          options={[
            {
              label: 'I have enough LPs to fill my deals',
              value: 'I have enough LPs to fill my deals',
            },
            {
              label: 'I have some LPs, not enough to fill my deals',
              value: 'I have some LPs, not enough to fill my deals',
            },
            { label: 'I don’t have any LPs', value: 'I don’t have any LPs' },
          ]}
          validators={{
            required: true,
          }}
          hidden={isRuvSyndicate}
        />
      </FormPanel>
    );
  };

  return (
    <CreateStepperLayout onClose={() => navigate('/invest/syndicates/backed')}>
      <h2 className="mt-6 text-3xl font-bold leading-9 tracking-tight text-gray-700">
        {isRuvSyndicate ? 'Tell us little bit about your startup' : 'Create a Syndicate'}
      </h2>
      <p className="text-md mt-1 text-gray-500">
        {isRuvSyndicate
          ? 'Launch your round, close investors, and collect capital - all in one place'
          : 'Start investing in startups alongside other investors by creating your own syndicate.'}
      </p>
      <div className="lg:-mx-4 my-4 p-4 bg-white ring-1 ring-gray-200 rounded-lg">
        {renderContent()}
      </div>
    </CreateStepperLayout>
  );
};

export default CreateSyndicate;
