import { InvestmentEntityTypeEnumType } from '__generated__/graphql';

export default function investmentEntityTypeToReadable(type: InvestmentEntityTypeEnumType): {
  label: string;
  color: string;
} {
  switch (type) {
    case InvestmentEntityTypeEnumType.Individual:
      return {
        label: 'Individual',
        color: 'green',
      };
    case InvestmentEntityTypeEnumType.PrivateLimited:
      return {
        label: 'Private Limited',
        color: 'amber',
      };
    case InvestmentEntityTypeEnumType.LimitedLiabilityPartnership:
      return {
        label: 'LLP',
        color: 'blue',
      };
    case InvestmentEntityTypeEnumType.Unknown:
    case InvestmentEntityTypeEnumType.UnknownFirm:
      return {
        label: 'Unspecified',
        color: 'gray',
      };
    case InvestmentEntityTypeEnumType.Trust:
      return {
        label: 'Trust',
        color: 'indigo',
      };
    case InvestmentEntityTypeEnumType.Fund:
      return {
        label: 'Fund',
        color: 'indigo',
      };
    case InvestmentEntityTypeEnumType.Huf:
      return {
        label: 'HUF',
        color: 'indigo',
      };
    default:
      return {
        label: type,
        color: 'gray',
      };
  }
}
