import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import Alert from 'primitives/Alert';

import commitmentsIcons from 'media/icons/commitments.svg';
import invitesIcons from 'media/icons/invites.svg';
import passedInvitesIcon from 'media/icons/passed-invites.svg';

const DealInvites: FC = () => {
  const tabs = [
    {
      key: 'active',
      label: 'Invited',
      description: 'Deals from your syndicates',
      route: `/invest/deal-invites`,
      icon: invitesIcons,
      exact: true,
    },
    {
      key: 'committed',
      label: 'Committed',
      description: 'Deals you have committed to',
      route: `/invest/deal-invites/committed`,
      icon: commitmentsIcons,
    },
    {
      key: 'passed',
      label: 'Passed',
      description: 'Passed but still active deals',
      route: `/invest/deal-invites/passed`,
      icon: passedInvitesIcon,
    },
  ];

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Deal Invites"
        subTitle="Investment opportunities delivered directly to your inbox."
      />
      <SidebarLayoutContent secondaryTabs={tabs}>
        <Alert
          type="warning"
          title="Confidential: Disclosing deal information will result in removal from Angellist India"
        />
        <Outlet />
      </SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default DealInvites;
