import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';
import TextField from 'primitives/TextField';

import debounce from 'utils/debounce';

import SyndicateUserSlideOver from './SyndicateUserSlideOver';

const SYNDICATE_USERS_QUERY = gql(`
  query SyndicateUsers($id: ID!, $cursor: ID, $limit: Int, $filters: SyndicateUsersFilterType) {
    syndicate(id: $id) {
      id
      syndicateUsers(cursor: $cursor, limit: $limit, filters: $filters) {
        nodes {
          id
          user {
            id
            name
            email
          }
          createdAt
          statistics {
            totalInvestedAmount
            numberOfInvestments
            numberOfDealInvites
            investRate
          }
        }
        pageInfo {
          hasNextPage
          totalCount
          cursor
        }
      }
    }
  }
`);

const SyndicateUsers: FC = () => {
  const { syndicateId } = useParams<{ syndicateId: string }>() as { syndicateId: string };

  const [syndicateUserOnDisplay, setSyndicateUserOnDisplay] = useState<string>('');

  const { loading, error, data, refetch, fetchMore, variables, networkStatus } = useQuery(
    SYNDICATE_USERS_QUERY,
    {
      variables: {
        id: syndicateId,
        limit: 20,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const { nodes: syndicateUsers, pageInfo } = data.syndicate.syndicateUsers;

  return (
    <>
      <SyndicateUserSlideOver
        open={!!syndicateUserOnDisplay}
        syndicateUserId={syndicateUserOnDisplay}
        onClose={() => setSyndicateUserOnDisplay('')}
      />
      <div className="flex justify-between items-end">
        <TextField
          placeholder="Search for a user"
          onChange={debounce(e => {
            refetch({
              filters: {
                ...variables?.filters,
                text: e.target.value,
              },
            });
          }, 500)}
        />
        <span className="text-sm text-gray-500">
          {syndicateUsers.length} of {pageInfo.totalCount} members
        </span>
      </div>
      <DataTable
        data={syndicateUsers}
        onClick={syndicateUser => setSyndicateUserOnDisplay(syndicateUser.id)}
        columns={[
          {
            label: 'Name',
            fieldName: 'user.name',
          },
          {
            label: 'Joined Syndicate',
            fieldName: 'createdAt',
            type: 'DATE',
          },
          {
            label: 'Amount Invested',
            fieldName: 'statistics.totalInvestedAmount',
            type: 'CURRENCY',
          },
          {
            label: '# Deal Invites Sent',
            fieldName: 'statistics.numberOfDealInvites',
            type: 'NUMBER',
          },
          {
            label: '# Investments',
            fieldName: 'statistics.numberOfInvestments',
            type: 'NUMBER',
          },
          {
            label: 'Invest Rate',
            fieldName: 'statistics.investRate',
            type: 'PERCENTAGE',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        paginationLoading={loading}
        filterLoading={networkStatus === NetworkStatus.setVariables}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </>
  );
};

export default SyndicateUsers;
