import { FC } from 'react';
import { useParams } from 'react-router-dom';

import DealProgressChart from './DealProgressChart';
import GetStartedBannerForDealDashboard from './GetStartedBannerForDealDashboard';

const ManageDeal: FC = () => {
  const { syndicateId, dealId } = useParams<{ syndicateId: string; dealId: string }>() as {
    syndicateId: string;
    dealId: string;
  };

  return (
    <>
      <DealProgressChart dealId={dealId} />
      <GetStartedBannerForDealDashboard syndicateId={syndicateId} dealId={dealId} />
    </>
  );
};

export default ManageDeal;
