import { useLazyQuery } from '@apollo/client';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { ClickAwayListener, Popper, TextField } from '@mui/material';
import { gql } from '__generated__/gql';
import { DashboardSyndicatesEnumType } from '__generated__/graphql';
import { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import constants from 'utils/constants';
import debounce from 'utils/debounce';

import MessageSyndicateDialogButton from '../Syndicates/SyndicateSlideOver/MessageSyndicateDialogButton';

type Syndicate = {
  id: string;
  name: string;
};

const SEARCH_SYNDICATE_USERS_FOR_CONVERSATION_QUERY = gql(`
  query SearchSyndicateForConversation($filters: DashboardSyndicatesFilterType, $cursor: ID, $limit: Int) {
    dashboard {
      id
      syndicates(filters: $filters, cursor: $cursor, limit: $limit) {
        nodes {
          id
          name
          tagline
          image
          userConnections {
            conversation {
              id
            }
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const SearchSyndicateForConversation: FC<{
  value?: Syndicate;
  label?: string;
}> = ({ value, label = '' }) => {
  const [currentValue, setCurrentValue] = useState<Syndicate | null>(value ?? null);
  const [showMessageSyndicateDialog, setShowMessageSyndicateDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const [getSearchResults, { loading, error, data, refetch, variables }] = useLazyQuery(
    SEARCH_SYNDICATE_USERS_FOR_CONVERSATION_QUERY
  );

  const ref = useRef<HTMLDivElement | null>(null);

  const performSearch = searchTerm =>
    getSearchResults({
      variables: {
        limit: 10,
        filters: {
          text: searchTerm.trim(),
          type: DashboardSyndicatesEnumType.Backed,
        },
      },
    });

  function results() {
    if (loading) return <LoadingIndicator />;

    if (error) return <ErrorMessage error={error} refetch={refetch} />;

    if (!data || !data.dashboard || variables?.filters?.text === '') return null;

    const searchResults = data.dashboard.syndicates.nodes;

    if (searchResults.length === 0)
      return (
        <p className="bg-white p-4 text-sm cursor-pointer border select-none hover:bg-gray-50">
          No result found.
        </p>
      );

    return (
      <>
        {searchResults.map(syndicate => {
          return !showMessageSyndicateDialog ? (
            <div
              key={syndicate.id}
              className="flex flex-row justify-between align-middle bg-white p-4 cursor-pointer border border-b-0 hover:bg-gray-50"
              onClick={() => {
                setCurrentValue(syndicate);
                if (syndicate.userConnections.conversation)
                  navigate(`/invest/conversations/${syndicate.userConnections.conversation?.id}`);
                else setShowMessageSyndicateDialog(true);
                performSearch('');
              }}
            >
              <div className="flex flex-row space-x-3 align-middle">
                <img
                  className="rounded-full w-8 h-8 object-cover border self-center"
                  src={syndicate.image ?? constants.ALT_AVATAR_URL}
                  alt="Syndicate logo"
                />
                <div className="flex flex-col justify-evenly">
                  <p className="text-sm select-none">{syndicate.name}</p>
                  <p className="text-xs select-none">
                    {syndicate.tagline.length > 30
                      ? syndicate.tagline.substring(0, 28) + '...'
                      : syndicate.tagline}
                  </p>
                </div>
              </div>
              <PaperAirplaneIcon className="w-5 h-5 self-center" />
            </div>
          ) : (
            <div key={syndicate.id} />
          );
        })}
      </>
    );
  }

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          performSearch('');
          setCurrentValue(null);
          setShowMessageSyndicateDialog(false);
        }}
      >
        <div>
          <TextField
            ref={ref}
            fullWidth
            size="small"
            label={label}
            onBlur={e => (e.target.value = '')}
            onChange={debounce(e => performSearch(e.target.value), 500)}
            placeholder="Search by name"
          />
          <p className="text-[0.7rem] mt-1 text-gray-400">
            Search for syndicates you have joined to start or view your conversation.
          </p>
          <Popper
            open={true}
            style={{ width: ref.current?.offsetWidth, zIndex: 10000 }}
            anchorEl={ref.current}
            placement="bottom-start"
            onClick={e => e.preventDefault()}
          >
            <div>{results()}</div>
          </Popper>
        </div>
      </ClickAwayListener>
      {showMessageSyndicateDialog && currentValue && (
        <MessageSyndicateDialogButton
          syndicateId={currentValue.id}
          onClose={() => setCurrentValue(null)}
          showButton={false}
        />
      )}
    </>
  );
};

export default SearchSyndicateForConversation;
