import { FC, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import joinedSyndicatesIcon from 'media/icons/joined-syndicates.svg';
import pendingSyndicatesIcon from 'media/icons/pending-syndicates.svg';

// import suggestedSyndicatesIcon from 'media/icons/suggested-syndicates.svg';
import SyndicateSlideOver from './SyndicateSlideOver';

const SyndicatesPage: FC = () => {
  const [syndicateOnDisplay, setSyndicateOnDisplay] = useState('');

  const tabs = [
    // {
    //   key: 'SUGGESTED',
    //   label: 'All Syndicates',
    //   description: 'Explore to get more deals',
    //   route: '/invest/syndicates',
    //   icon: suggestedSyndicatesIcon,
    //   exact: true,
    // },
    {
      key: 'BACKED',
      label: 'Joined Syndicates',
      description: "See what you're backing",
      route: '/invest/syndicates/backed',
      icon: joinedSyndicatesIcon,
    },
    {
      key: 'PENDING',
      label: 'Applications Pending',
      description: 'What you have applied for',
      route: '/invest/syndicates/pending',
      icon: pendingSyndicatesIcon,
    },
  ];

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Syndicates"
        subTitle="Explore and join new syndicates to start investing"
      />
      <SidebarLayoutContent secondaryTabs={tabs}>
        <SyndicateSlideOver
          open={!!syndicateOnDisplay}
          syndicateId={syndicateOnDisplay}
          onClose={() => setSyndicateOnDisplay('')}
        />
        <Outlet context={{ setSyndicateOnDisplay }} />
      </SidebarLayoutContent>
    </SidebarLayout>
  );
};

type ContextType = { setSyndicateOnDisplay: (syndicateId: string) => void };

export function useSyndicateOnDisplay() {
  return useOutletContext<ContextType>();
}

export default SyndicatesPage;
