import { useLazyQuery } from '@apollo/client';
import { ClickAwayListener, Popper, TextField } from '@mui/material';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';

import debounce from 'utils/debounce';

import theme from './theme.module.scss';

type SyndicateUser = {
  id: string;
  user: {
    id: string;
    fullName: string;
  };
};

const SEARCH_SYNDICATE_USERS_QUERY = gql(`
  query SearchSyndicateUsers($text: String!, $syndicateId: String!, $cursor: ID, $limit: Int) {
    search(text: $text, cursor: $cursor, limit: $limit) {
      syndicateUsers(syndicateId: $syndicateId) {
        nodes {
          id
          user {
            id
            fullName
          }
          conversations {
            nodes {
              id
            }
          }
        }
      }
    }
  }
`);

const SearchSyndicateUsers: FC<{
  onSelect: (syndicateUser: SyndicateUser) => void;
  syndicateId: string;
  value?: SyndicateUser;
  label?: string;
}> = ({ value, syndicateId, onSelect, label = '' }) => {
  const [currentValue, setCurrentValue] = useState<SyndicateUser | null>(value ?? null);
  const [getSearchResults, { loading, error, data, refetch, variables }] = useLazyQuery(
    SEARCH_SYNDICATE_USERS_QUERY
  );

  const ref = useRef<HTMLDivElement | null>(null);

  const performSearch = searchTerm =>
    getSearchResults({
      variables: {
        text: searchTerm.trim(),
        limit: 10,
        syndicateId,
      },
    });

  function results() {
    if (loading) return <LoadingIndicator />;

    if (error) return <ErrorMessage error={error} refetch={refetch} />;

    if (!data || !data.search || variables?.text === '') return null;

    const searchResults = data.search.syndicateUsers.nodes;

    if (searchResults.length === 0) return <p style={{ paddingLeft: '1rem' }}>No result found.</p>;

    return (
      <>
        {searchResults.map(syndicateUser => (
          <div
            key={syndicateUser.id}
            className={theme.result}
            onClick={() => {
              setCurrentValue(syndicateUser);
              onSelect(syndicateUser);
            }}
          >
            <p className={theme.name}>{syndicateUser.user.fullName}</p>
          </div>
        ))}
      </>
    );
  }

  if (currentValue) {
    return (
      <>
        <p className="text-sm mb-2">{currentValue.user.fullName}</p>
        <Button onClick={() => setCurrentValue(null)}>Clear</Button>
      </>
    );
  }

  return (
    <div className={theme.container}>
      <ClickAwayListener onClickAway={() => performSearch('')}>
        <div className={theme.inputContainer}>
          <TextField
            ref={ref}
            className={theme.input}
            fullWidth
            size="small"
            label={label}
            onBlur={e => (e.target.value = '')}
            onChange={debounce(e => performSearch(e.target.value), 500)}
            placeholder="Search by name"
          />
          <Popper
            open={true}
            style={{ width: ref.current?.offsetWidth, zIndex: 10000 }}
            anchorEl={ref.current}
            placement="bottom-start"
            onClick={e => e.preventDefault()}
          >
            <div className={theme.resultsContainer}>{results()}</div>
          </Popper>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default SearchSyndicateUsers;
