const statusOptions = [
  { label: 'New', value: 'NEW' },
  { label: 'Has active deal', value: 'ACTIVE_DEAL' },
  { label: 'Not joined/applied', value: 'NOT_JOINED_APPLIED' },
  { label: 'Raising Venture Fund', value: 'RAISING_VENTURE_FUND' },
];

const dealFlowOptions = [
  { label: 'High', value: 'HIGH' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Low', value: 'LOW' },
];

const stageOptions = [
  { label: 'Seed', value: 'SEED' },
  { label: 'Early', value: 'EARLY' },
  { label: 'Growth', value: 'GROWTH' },
  { label: 'Secondaries', value: 'SECONDARIES' },
];

const geographyOptions = [
  { label: 'India', value: 'IN' },
  { label: 'United States', value: 'US' },
  { label: 'Singapore', value: 'SG' },
];

const industryOptions = [
  { label: 'Advertising Technology', value: 'ADTECH' },
  { label: 'Artificial Intelligence/Machine Learning', value: 'AI_ML' },
  { label: 'Aerospace', value: 'AEROSPACE' },
  { label: 'Analytics', value: 'ANALYTICS' },
  { label: 'Biotechnology', value: 'BIOTECH' },
  { label: 'Blockchain/Cryptocurrency', value: 'BLOCKCHAIN_CRYPTOCURRENCY' },
  { label: 'Cannabis', value: 'CANNABIS' },
  { label: 'Cleantech', value: 'CLEANTECH' },
  { label: 'Collaboration Tools', value: 'COLLABORATION_TOOLS' },
  { label: 'Consumer', value: 'CONSUMER' },
  { label: 'Consumer Products', value: 'CONSUMER_PRODUCT' },
  { label: 'Dating', value: 'DATING' },
  { label: 'Developer Tools', value: 'DEVELOPER_TOOLS' },
  { label: 'Drones', value: 'DRONES' },
  { label: 'E-Commerce', value: 'E_COMMERCE' },
  { label: 'Education', value: 'EDUCATION' },
  { label: 'Enterprise', value: 'ENTERPRISE' },
  { label: 'Finance', value: 'FINANCE' },
  { label: 'Fitness', value: 'FITNESS' },
  { label: 'Food & Beverages', value: 'FOOD_BEVERAGES' },
  { label: 'Gaming', value: 'GAMING' },
  { label: 'Hardware', value: 'HARDWARE' },
  { label: 'Health', value: 'HEALTH' },
  { label: 'Healthcare', value: 'HEALTHCARE' },
  { label: 'Home Services', value: 'HOME_SERVICES' },
  { label: 'Human Resources/Recruiting', value: 'HUMAN_RESOURCES_RECRUITING' },
  { label: 'Industry-Specific SaaS', value: 'INDUSTRY_SPECIFIC_SAAS' },
  { label: 'Insurance', value: 'INSURANCE' },
  { label: 'Investment Platforms', value: 'INVESTMENT_PLATFORMS' },
  { label: 'Internet of Things (IoT)', value: 'IOT' },
  { label: 'Logistics', value: 'LOGISTICS' },
  { label: 'Media & Entertainment', value: 'MEDIA_ENTERTAINMENT' },
  { label: 'Other', value: 'OTHER' },
  { label: 'Payments', value: 'PAYMENTS' },
  { label: 'Productivity', value: 'PRODUCTIVITY' },
  { label: 'Real Estate', value: 'REAL_ESTATE' },
  { label: 'Retail', value: 'RETAIL' },
  { label: 'Robotics', value: 'ROBOTICS' },
  { label: 'Sales Tools', value: 'SALES_TOOLS' },
  { label: 'Security', value: 'SECURITY' },
  { label: 'Social', value: 'SOCIAL' },
  { label: 'Social Impact', value: 'SOCIAL_IMPACT' },
  { label: 'Transportation', value: 'TRANSPORTATION' },
  { label: 'Travel', value: 'TRAVEL' },
  { label: 'Virtual Reality/Augmented Reality (VR/AR)', value: 'VR_AR' },
];

const getActiveFilters = (
  currentFilters,
  filtersFromData: Array<{ label: string; value: Array<string> }>,
  hasFiltersBeenChanged: boolean
) => {
  if (hasFiltersBeenChanged) return { ...currentFilters };

  let result;

  filtersFromData.map(filter => {
    if (filter.value.length > 0) result = { ...result, [filter.label]: filter.value };
    return null;
  });

  // Don't show the type filter in the active filters list. It's meant to be hidden.
  return { ...currentFilters, ...result, type: undefined };
};

const syndicateStatusEnumToReadable = status => {
  switch (status) {
    case 'NEW':
      return {
        label: 'New',
        color: 'green',
      };
    case 'ACTIVE_DEAL':
      return {
        label: 'Has active deal',
        color: 'amber',
      };
    case 'NOT_JOINED_APPLIED':
      return {
        label: 'Not joined/applied',
        color: 'amber',
      };
    case 'RAISING_VENTURE_FUND':
      return {
        label: 'Raising fund',
        color: 'amber',
      };
    default:
      return {
        label: status,
        color: 'gray',
      };
  }
};

const syndicateDealFlowEnumToReadable = flow => {
  switch (flow) {
    case 'HIGH':
      return {
        label: 'High',
        color: 'green',
      };
    case 'MEDIUM':
      return {
        label: 'Medium',
        color: 'amber',
      };
    case 'LOW':
      return {
        label: 'Low',
        color: 'red',
      };
    default:
      return {
        label: flow,
        color: 'gray',
      };
  }
};

const syndicateStageEnumToReadable = stage => {
  switch (stage) {
    case 'SEED':
      return {
        label: 'Seed',
        color: 'green',
      };
    case 'EARLY':
      return {
        label: 'Early',
        color: 'amber',
      };
    case 'GROWTH':
      return {
        label: 'Growth',
        color: 'red',
      };
    case 'SECONDARIES':
      return {
        label: 'Secondaries',
        color: 'gray',
      };
    default:
      return {
        label: stage,
        color: 'gray',
      };
  }
};

const syndicateRevenueEnumToReadable = revenue => {
  switch (revenue) {
    case 'PRE_LAUNCH':
      return {
        label: 'Pre-launch',
        color: 'green',
      };
    case 'PRE_REVENUE':
      return {
        label: 'Pre-revenue',
        color: 'amber',
      };
    case 'POST_REVENUE':
      return {
        label: 'Post-revenue',
        color: 'red',
      };
    case 'PROFITABLE':
      return {
        label: 'Profitable',
        color: 'green',
      };
    default:
      return {
        label: revenue,
        color: 'gray',
      };
  }
};

const syndicateIndustryEnumToReadable = industry => {
  switch (industry) {
    case 'ADTECH':
      return {
        label: 'Advertising Technology',
        color: 'green',
      };
    case 'AI_ML':
      return {
        label: 'Artificial Intelligence/Machine Learning',
        color: 'green',
      };
    case 'AEROSPACE':
      return {
        label: 'Aerospace',
        color: 'green',
      };
    case 'ANALYTICS':
      return {
        label: 'Analytics',
        color: 'green',
      };
    case 'BIOTECH':
      return {
        label: 'Biotechnology',
        color: 'green',
      };
    case 'BLOCKCHAIN_CRYPTOCURRENCY':
      return {
        label: 'Blockchain/Cryptocurrency',
        color: 'green',
      };
    case 'CANNABIS':
      return {
        label: 'Cannabis',
        color: 'green',
      };
    case 'CLEANTECH':
      return {
        label: 'Cleantech',
        color: 'green',
      };
    case 'COLLABORATION_TOOLS':
      return {
        label: 'Collaboration Tools',
        color: 'green',
      };
    case 'CONSUMER':
      return {
        label: 'Consumer',
        color: 'green',
      };
    case 'CONSUMER_PRODUCT':
      return {
        label: 'Consumer Products',
        color: 'green',
      };
    case 'DATING':
      return {
        label: 'Dating',
        color: 'green',
      };
    case 'DEVELOPER_TOOLS':
      return {
        label: 'Developer Tools',
        color: 'green',
      };
    case 'DRONES':
      return {
        label: 'Drones',
        color: 'green',
      };
    case 'E_COMMERCE':
      return {
        label: 'E-Commerce',
        color: 'green',
      };
    case 'EDUCATION':
      return {
        label: 'Education',
        color: 'green',
      };
    case 'ENTERPRISE':
      return {
        label: 'Enterprise',
        color: 'green',
      };
    case 'FINANCE':
      return {
        label: 'Finance',
        color: 'green',
      };
    case 'FITNESS':
      return {
        label: 'Fitness',
        color: 'green',
      };
    case 'FOOD_BEVERAGES':
      return {
        label: 'Food & Beverages',
        color: 'green',
      };
    case 'GAMING':
      return {
        label: 'Gaming',
        color: 'green',
      };
    case 'HARDWARE':
      return {
        label: 'Hardware',
        color: 'green',
      };
    case 'HEALTH':
      return {
        label: 'Health',
        color: 'green',
      };
    case 'HEALTHCARE':
      return {
        label: 'Healthcare',
        color: 'green',
      };
    case 'HOME_SERVICES':
      return {
        label: 'Home Services',
        color: 'green',
      };
    case 'HUMAN_RESOURCES_RECRUITING':
      return {
        label: 'Human Resources/Recruiting',
        color: 'green',
      };
    case 'INDUSTRY_SPECIFIC_SAAS':
      return {
        label: 'Industry-Specific SaaS',
        color: 'green',
      };
    case 'INSURANCE':
      return {
        label: 'Insurance',
        color: 'green',
      };
    case 'INVESTMENT_PLATFORMS':
      return {
        label: 'Investment Platforms',
        color: 'green',
      };
    case 'IOT':
      return {
        label: 'Internet of Things (IoT)',
        color: 'green',
      };
    case 'LOGISTICS':
      return {
        label: 'Logistics',
        color: 'green',
      };
    case 'MEDIA_ENTERTAINMENT':
      return {
        label: 'Media & Entertainment',
        color: 'green',
      };
    case 'OTHER':
      return {
        label: 'Other',
        color: 'green',
      };
    case 'PAYMENTS':
      return {
        label: 'Payments',
        color: 'green',
      };
    case 'PRODUCTIVITY':
      return {
        label: 'Productivity',
        color: 'green',
      };
    case 'REAL_ESTATE':
      return {
        label: 'Real Estate',
        color: 'green',
      };
    case 'RETAIL':
      return {
        label: 'Retail',
        color: 'green',
      };
    case 'ROBOTICS':
      return {
        label: 'Robotics',
        color: 'green',
      };
    case 'SALES_TOOLS':
      return {
        label: 'Sales Tools',
        color: 'green',
      };
    case 'SECURITY':
      return {
        label: 'Security',
        color: 'green',
      };
    case 'SOCIAL':
      return {
        label: 'Social',
        color: 'green',
      };
    case 'SOCIAL_IMPACT':
      return {
        label: 'Social Impact',
        color: 'green',
      };
    case 'TRANSPORTATION':
      return {
        label: 'Transportation',
        color: 'green',
      };
    case 'TRAVEL':
      return {
        label: 'Travel',
        color: 'green',
      };
    case 'VR_AR':
      return {
        label: 'Virtual Reality/Augmented Reality (VR/AR)',
        color: 'green',
      };
    default:
      return {
        label: industry,
        color: 'gray',
      };
  }
};

export {
  statusOptions,
  dealFlowOptions,
  stageOptions,
  industryOptions,
  geographyOptions,
  getActiveFilters,
  syndicateStatusEnumToReadable,
  syndicateDealFlowEnumToReadable,
  syndicateIndustryEnumToReadable,
  syndicateRevenueEnumToReadable,
  syndicateStageEnumToReadable,
};
