import { useMutation } from '@apollo/client';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__';
import { SyndicateInviteResponseTypeEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';

const RESOLVE_INVITATION_MUTATION = gql(`
    mutation ResolveSyndicateApplication(
      $id: ID!
      $inviteResponseType: SyndicateInviteResponseTypeEnumType!
    ) {
      resolveSyndicateApplication(id: $id, inviteResponseType: $inviteResponseType) {
        id
        type
        status
      }
    }
  `);

const ResolveSyndicateApplication: FC<{
  syndicateInviteId: string;
  syndicateId: string;
  note?: string | null;
  onClose: () => void;
}> = ({ syndicateInviteId, syndicateId, note, onClose }) => {
  const [resolveInvite, { loading, error }] = useMutation(RESOLVE_INVITATION_MUTATION, {
    refetchQueries: [
      'PassedSyndicateApplications',
      'PendingSyndicateApplications',
      'SyndicateUsers',
    ],
  });

  const navigate = useNavigate();

  return (
    <div className="mt-4 px-4 py-6 overflow-hidden bg-white shadow sm:rounded-lg">
      {note && (
        <>
          <p className="text-sm font-medium text-gray-900">Note from Syndicate: </p>
          <blockquote className="mt-2 rounded-md py-2 px-4 bg-gray-50">{note}</blockquote>
        </>
      )}
      {error && (
        <div className="mt-2">
          <ErrorMessage type="alert" error={error} />
        </div>
      )}
      <div className="flex flex-row min-w-full space-x-2 mt-4">
        <Button
          variant="secondary"
          loading={loading}
          className="w-full"
          onClick={() =>
            resolveInvite({
              variables: {
                id: syndicateInviteId,
                inviteResponseType: SyndicateInviteResponseTypeEnumType.Reject,
              },
            }).then(() =>
              navigate(`/syndicate-dashboard/${syndicateId}/network/applications/passed`)
            )
          }
          leadingIcon={<XCircleIcon />}
        >
          Reject
        </Button>
        <Button
          loading={loading}
          className="w-full"
          onClick={() =>
            resolveInvite({
              variables: {
                id: syndicateInviteId,
                inviteResponseType: SyndicateInviteResponseTypeEnumType.Accept,
              },
            }).then(onClose)
          }
          leadingIcon={<CheckCircleIcon />}
        >
          Accept
        </Button>
      </div>
    </div>
  );
};

export default ResolveSyndicateApplication;
