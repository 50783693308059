import { CompanyEventEnumType } from '__generated__/graphql';

export default function companyEventTypeToReadable(type: CompanyEventEnumType): {
  label: string;
  color: string;
} {
  switch (type) {
    case CompanyEventEnumType.ConvertibleFinancingRound:
      return {
        label: 'Convertible Financing Round',
        color: 'indigo',
      };
    case CompanyEventEnumType.CorporateAction:
      return {
        label: 'Corporate Action',
        color: 'green',
      };
    case CompanyEventEnumType.EquityFinancingRound:
      return {
        label: 'Equity Financing Round',
        color: 'blue',
      };
    case CompanyEventEnumType.ValuationUpdate:
      return {
        label: 'Valuation Update',
        color: 'purple',
      };
    case CompanyEventEnumType.Others:
      return {
        label: 'Others',
        color: 'amber',
      };
    default:
      return {
        label: type,
        color: 'gray',
      };
  }
}
