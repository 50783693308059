import { useQuery } from '@apollo/client';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { gql } from '__generated__/gql';
import { SyndicateInviteTypeEnumType, SyndicateLeadRoleEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import ErrorMessage from 'components/ErrorMessage';

import Badge from 'primitives/Badge';
import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import constants from 'utils/constants';
import syndicateLeadRoleToReadable from 'utils/enums/syndicate-lead-role-to-readable';

import InviteLeadToSyndicateDialogButton from './InviteLeadToSyndicateDialogButton';
import RescindSyndicateLeadInviteDialog from './RescindSyndicateLeadInviteDialog';
import SyndicateLeadSlideOver from './SyndicateLeadSlideOver';

const SYNDICATE_TEAM_MEMBERS_QUERY = gql(`
  query SyndicateTeamMembers($id: ID!, $cursor: ID, $filters: SyndicateInvitesFilterType) {
    syndicate(id: $id) {
      id
      syndicateLeads {
        id
        role
        createdAt
        user {
          id
          name
          email
          image {
            url
          }
        }
      }
      invites(cursor: $cursor, filters: $filters) {
        nodes {
          id
          name
          email
          status
          createdAt
          user {
            id
            name
            email
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const TeamMember: FC<{
  id: string;
  name: string;
  email: string;
  image?: string;
  role: SyndicateLeadRoleEnumType | 'Invite Sent';
  createdAt: string;
  onClick: (id: string) => void;
}> = ({ id, name, email, image, role, createdAt, onClick }) => {
  const [teamMemberOnDisplay, setTeamMemberOnDisplay] = useState<any>(null);

  return (
    <>
      {teamMemberOnDisplay && (
        <RescindSyndicateLeadInviteDialog
          id={teamMemberOnDisplay.id}
          onClose={() => setTeamMemberOnDisplay(null)}
        />
      )}
      <li
        key={id}
        className="relative flex justify-between gap-x-6 px-4 py-5 sm:px-6 hover:bg-gray-50 cursor-pointer"
        onClick={() => (role === 'Invite Sent' ? setTeamMemberOnDisplay({ id }) : onClick(id))}
      >
        <div className="flex min-w-0 gap-x-4">
          <img
            className="h-12 w-12 flex-none rounded-full bg-gray-50"
            src={image || constants.ALT_AVATAR_URL}
            alt="Avatar"
          />
          <div className="min-w-0 flex-auto">
            <p className="font-semibold leading-6 text-gray-700">
              <span className="absolute inset-x-0 -top-px bottom-0" />
              {name}
            </p>
            <p className="flex text-sm leading-5 text-gray-500">{email}</p>
          </div>
        </div>
        <div className="flex shrink-0 items-center gap-x-4">
          <div className="hidden sm:flex sm:flex-col sm:items-end">
            <p className="text-sm leading-6 text-gray-900">
              {role === 'Invite Sent' ? (
                <Badge label={role} color="amber" />
              ) : (
                <Badge
                  label={syndicateLeadRoleToReadable(role).label}
                  color={syndicateLeadRoleToReadable(role).color}
                />
              )}
            </p>
            <p className="mt-1 text-xs leading-5 text-gray-500">
              {role === 'Invite Sent' ? 'Invited ' : 'Joined '}
              <time dateTime={dayjs(createdAt).format('DD MMMM YYYY')}>
                {dayjs(createdAt).format('DD MMMM YYYY')}
              </time>
            </p>
          </div>
          {role !== 'Invite Sent' && (
            <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
          )}
        </div>
      </li>
    </>
  );
};

const SyndicateTeam: FC = () => {
  const { syndicateId } = useParams<{ syndicateId: string }>() as { syndicateId: string };

  const [syndicateLeadOnDisplay, setSyndicateLeadOnDisplay] = useState<string | null>(null);

  const { loading, error, data, refetch } = useQuery(SYNDICATE_TEAM_MEMBERS_QUERY, {
    variables: {
      id: syndicateId,
      filters: {
        type: SyndicateInviteTypeEnumType.FromSyndicateToLead,
      },
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data || !syndicateId) return <ErrorMessage error={error} refetch={refetch} />;

    const syndicateLeads = data.syndicate.syndicateLeads;

    const invites = data.syndicate.invites.nodes;

    const combinedInvites = invites.map(invite => ({
      ...invite,
      name: invite.user?.name || invite.name,
      email: invite.user?.email || invite.email,
    }));

    return (
      <>
        <SlideOver open={!!syndicateLeadOnDisplay} onClose={() => setSyndicateLeadOnDisplay('')}>
          {!!syndicateLeadOnDisplay && (
            <SyndicateLeadSlideOver
              syndicateLeadId={syndicateLeadOnDisplay}
              onClose={() => setSyndicateLeadOnDisplay('')}
            />
          )}
        </SlideOver>
        <ul className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
          {syndicateLeads.map(lead => (
            <TeamMember
              key={lead.id}
              id={lead.id}
              name={lead.user.name}
              email={lead.user.email}
              image={lead.user.image?.url}
              role={lead.role}
              createdAt={lead.createdAt}
              onClick={setSyndicateLeadOnDisplay}
            />
          ))}
          {combinedInvites.map(invite => (
            <TeamMember
              key={invite.id}
              id={invite.id}
              name={invite.name!}
              email={invite.email!}
              role={'Invite Sent'}
              createdAt={invite.createdAt}
              onClick={setSyndicateLeadOnDisplay}
            />
          ))}
        </ul>
        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-xl font-semibold leading-6 text-gray-700">
              Add a team member or carry recipient
            </h3>
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
              <div className="max-w-xl text-sm text-gray-500">
                <p>
                  Add people to your syndicate to help you manage your syndicate or share your carry
                  with them.
                </p>
              </div>
              <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                <InviteLeadToSyndicateDialogButton syndicateId={syndicateId} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation title="Team" subTitle="Manage your syndicate team members" />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default SyndicateTeam;
