import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import DetailsPanel from 'components/DetailsPanel';
import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';

import { logout } from 'utils/auth';

import UpdateAvatar from './UpdateAvatar';

const USER_QUERY = gql(`
  query getUserGeneralSettings {
    dashboard {
      id
      user {
        id
        name
        phoneNumber
        email
        image {
          url
          name
        }
      }
    }
  }
`);

const GeneralSettings: FC = () => {
  const { loading, error, data, refetch } = useQuery(USER_QUERY);

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const user = data.dashboard.user;

  return (
    <>
      <DetailsPanel
        title="Profile"
        data={[
          {
            label: 'Name',
            value: user.name,
          },
          {
            label: 'Phone Number',
            value: user.phoneNumber,
          },
          {
            label: 'Email',
            value: user.email,
          },
        ]}
      />
      <UpdateAvatar id={user.id} avatar={user.image?.url} />
      <div>
        <Button onClick={logout}>Logout</Button>
      </div>
    </>
  );
};

export default GeneralSettings;
