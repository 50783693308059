import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';

import Alert from 'primitives/Alert';
import LoadingIndicator from 'primitives/LoadingIndicator';

import UpdateKyc from './UpdateKyc';
import UpdateKycAddress from './UpdateKycAddress';

const INVESTMENT_ENTITY_QUERY = gql(`
  query InvestmentEntityKyc($id: ID!) {
    investmentEntity(id: $id) {
      id
      kyc {
        id
        pan
        identityProofDocument {
          url
          name
        }
        addressProofDocument {
          url
          name
        }
        dateOfBirth
        address {
          lineOne
          lineTwo
          city
          state
          country
          pincode
        }
      }
    }
  }
`);

const InvestmentEntityKyc: FC = () => {
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const { loading, error, data, refetch } = useQuery(INVESTMENT_ENTITY_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const investmentEntity = data.investmentEntity;

  if (!investmentEntity.kyc) return <Alert type="error" title="No KYC found for this entity" />;

  return (
    <>
      <UpdateKyc
        id={investmentEntity.kyc.id}
        pan={investmentEntity.kyc.pan ?? ''}
        identityProofDocument={investmentEntity.kyc.identityProofDocument ?? { url: '', name: '' }}
        addressProofDocument={investmentEntity.kyc.addressProofDocument ?? { url: '', name: '' }}
        dateOfBirth={investmentEntity.kyc.dateOfBirth ?? ''}
      />
      <UpdateKycAddress
        id={investmentEntity.kyc.id}
        lineOne={investmentEntity.kyc.address?.lineOne ?? ''}
        lineTwo={investmentEntity.kyc.address?.lineTwo ?? ''}
        city={investmentEntity.kyc.address?.city ?? ''}
        state={investmentEntity.kyc.address?.state ?? ''}
        country={investmentEntity.kyc.address?.country ?? ''}
        pincode={investmentEntity.kyc.address?.pincode ?? ''}
      />
    </>
  );
};

export default InvestmentEntityKyc;
