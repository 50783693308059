import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { BankAccountType } from '__generated__/graphql';
import { FC, useRef } from 'react';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

const UPDATE_BANK_ACCOUNT_MUTATION = gql(`
  mutation UpdateBankAccount(
    $id: ID!
    $accountNumber: String!
    $accountHolderName: String!
    $ifscCode: String!
    $bankName: String!
    $accountType: BankAccountTypeEnumType!
  ) {
    updateBankAccount(
      id: $id
      accountNumber: $accountNumber
      accountHolderName: $accountHolderName
      ifscCode: $ifscCode
      bankName: $bankName
      accountType: $accountType
    ) {
      id
      accountNumber
      accountHolderName
      ifscCode
      bankName
      accountType
    }
  }
`);

const REMOVE_BANK_ACCOUNT_MUTATION = gql(`
  mutation RemoveBankAccountFromInvestorApp($bankAccountId: ID!) {
    removeBankAccount(bankAccountId: $bankAccountId) {
      id
    }
  }
`);

const UpdateBankAccountDialog: FC<{
  investmentEntityId: string;
  bankAccount: BankAccountType;
  onClose: () => void;
}> = ({ investmentEntityId, bankAccount, onClose }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [updateBankAccount, { loading, error }] = useMutation(UPDATE_BANK_ACCOUNT_MUTATION);
  const [removeBankAccount, { loading: removeAccountLoading, error: removeAccountError }] =
    useMutation(REMOVE_BANK_ACCOUNT_MUTATION, {
      variables: {
        bankAccountId: bankAccount.id,
      },
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: `InvestmentEntityType:${investmentEntityId}`,
          fields: {
            bankAccounts(existingAccountRefs, { readField }) {
              return existingAccountRefs.filter(
                accountRef => bankAccount.id !== readField('id', accountRef)
              );
            },
          },
        });
      },
      onCompleted: () => {
        onClose();
      },
    });

  return (
    <>
      <Dialog open={true} onClose={onClose}>
        <DialogPanel>
          <DialogTitle>Update Bank Account</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onSubmit={data => {
              updateBankAccount({
                variables: {
                  id: bankAccount.id,
                  accountHolderName: data.accountHolderName,
                  accountNumber: data.accountNumber,
                  ifscCode: data.ifscCode,
                  bankName: data.bankName,
                  accountType: data.accountType,
                },
              }).then(() => {
                onClose();
              });
            }}
            onCancel={onClose}
            buttonRef={buttonRef}
          >
            <FormInput
              type="text"
              fieldName="accountHolderName"
              label="Account Holder Name"
              defaultValue={bankAccount.accountHolderName}
              validators={{ required: true }}
            />
            <FormInput
              type="text"
              fieldName="accountNumber"
              label="Account Number"
              defaultValue={bankAccount.accountNumber}
              validators={{ required: true }}
            />
            <FormInput
              type="text"
              fieldName="ifscCode"
              label="IFSC Code"
              defaultValue={bankAccount.ifscCode}
              validators={{ required: true }}
            />
            <FormInput
              type="text"
              fieldName="bankName"
              label="Bank Name"
              defaultValue={bankAccount.bankName}
              validators={{ required: true }}
            />
            <FormInput
              type="radio-group"
              fieldName="accountType"
              label="Account Type"
              defaultValue={bankAccount.accountType}
              options={[
                { label: 'Savings', value: 'SAVINGS' },
                { label: 'Current', value: 'CURRENT' },
              ]}
              validators={{ required: true }}
            />
          </FormPanel>
          {removeAccountError && <ErrorMessage error={removeAccountError} />}
          <DialogActions className="sm:grid-cols-3">
            <Button
              variant="secondary"
              loading={removeAccountLoading}
              onClick={() => removeBankAccount()}
            >
              Delete
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Apply
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default UpdateBankAccountDialog;
