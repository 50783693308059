import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FocusLayout from 'layouts/FocusLayout';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

const VERIFY_OLD_PLATFORM_DEAL_INVITE_LINK_MUTATION = gql(`
  mutation VerifyOldPlatformDealInviteLink($id: String!) {
    verifyOldPlatformDealInviteLink(id: $id) {
      id
    }
  }
`);

const OldPlatformDealInviteLinkPage: FC = () => {
  const navigate = useNavigate();
  const { dealInviteLinkId } = useParams<{ dealInviteLinkId: string }>() as {
    dealInviteLinkId: string;
  };

  const [verifyOldPlatformDealInviteLink, { loading, error, data }] = useMutation(
    VERIFY_OLD_PLATFORM_DEAL_INVITE_LINK_MUTATION,
    {
      variables: {
        id: dealInviteLinkId,
      },
    }
  );

  useEffect(() => {
    verifyOldPlatformDealInviteLink().then(data => {
      if (!data.data?.verifyOldPlatformDealInviteLink?.id) return;
      navigate(`/invest/deal-invites/${data.data?.verifyOldPlatformDealInviteLink.id}`);
    });
  }, [navigate, verifyOldPlatformDealInviteLink]);

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} />;

    return <div>{data && <p>Redirecting ...</p>}</div>;
  };

  return <FocusLayout>{renderContent()}</FocusLayout>;
};

export default OldPlatformDealInviteLinkPage;
