import classNames from 'classnames';
import { FC, useState } from 'react';

import DetailsPanel from 'components/DetailsPanel';

import privacyPolicyData from './privacy-policy-data.json';
import termsData from './terms-data.json';

const Terms: FC = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const tabs = [
    { name: 'Terms of Service', data: termsData },
    { name: 'Privacy Policy', data: privacyPolicyData },
  ];

  return (
    <>
      <div className="mb-2 pb-5 sm:pb-0">
        <h3 className="text-2xl font-semibold leading-6 text-gray-900">
          Terms of Service and Privacy
        </h3>
        <div className="mt-3 sm:mt-4">
          <div className="sm:hidden">
            <select
              id="current-tab"
              name="current-tab"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue={tabs[currentTab].name}
            >
              {tabs.map((tab, tIdx) => (
                <option key={tab.name} value={tIdx}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab, tIdx) => (
                <button
                  key={tab.name}
                  onClick={() => setCurrentTab(tIdx)}
                  className={classNames(
                    tIdx === currentTab
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 px-1 pb-2 text-sm font-medium'
                  )}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <DetailsPanel data={tabs[currentTab].data} />
    </>
  );
};

export default Terms;
