import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_USER_AVATAR_MUTATION = gql(`
  mutation UpdateUserAvatar($id: ID!, $image: Upload!) {
    updateUserAvatar(id: $id, image: $image) {
      id
      image{
        url
        name
      }
    }
  }
`);

const UpdateAvatar: FC<{ id: string; avatar?: string | null }> = ({ id, avatar }) => {
  const [updateAvatar, { loading, error }] = useMutation(UPDATE_USER_AVATAR_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateAvatar({
          variables: {
            id,
            image: data.avatar,
          },
        });
      }}
      title="Avatar"
    >
      <FormInput type="avatar" fieldName="avatar" label="Avatar" defaultValue={avatar || null} />
    </FormPanelWithReadMode>
  );
};

export default UpdateAvatar;
