const industryOptions = [
  { label: 'Ad Tech', value: 'ADTECH' },
  { label: 'AI/ML', value: 'AI_ML' },
  { label: 'Aerospace', value: 'AEROSPACE' },
  { label: 'Analytics', value: 'ANALYTICS' },
  { label: 'BioTech', value: 'BIOTECH' },
  { label: 'Blockchain / Cryptocurrency', value: 'BLOCKCHAIN_CRYPTOCURRENCY' },
  { label: 'Cannabis', value: 'CANNABIS' },
  { label: 'CleanTech', value: 'CLEANTECH' },
  { label: 'Collaboration Tools', value: 'COLLABORATION_TOOLS' },
  { label: 'Consumer', value: 'CONSUMER' },
  { label: 'Consumer Product', value: 'CONSUMER_PRODUCT' },
  { label: 'Dating', value: 'DATING' },
  { label: 'Developer Tools', value: 'DEVELOPER_TOOLS' },
  { label: 'Drones', value: 'DRONES' },
  { label: 'E-commerce', value: 'E_COMMERCE' },
  { label: 'Education', value: 'EDUCATION' },
  { label: 'Enterprise', value: 'ENTERPRISE' },
  { label: 'Finance', value: 'FINANCE' },
  { label: 'Fitness', value: 'FITNESS' },
  { label: 'Food & Beverages', value: 'FOOD_BEVERAGES' },
  { label: 'Gaming', value: 'GAMING' },
  { label: 'Hardware', value: 'HARDWARE' },
  { label: 'Health', value: 'HEALTH' },
  { label: 'Healthcare', value: 'HEALTHCARE' },
  { label: 'Home Services', value: 'HOME_SERVICES' },
  { label: 'Human Resources & Recruiting', value: 'HUMAN_RESOURCES_RECRUITING' },
  { label: 'Industry Specific SAAS', value: 'INDUSTRY_SPECIFIC_SAAS' },
  { label: 'Insurance', value: 'INSURANCE' },
  { label: 'Investment Platforms', value: 'INVESTMENT_PLATFORMS' },
  { label: 'Internet of Things (IOT)', value: 'IOT' },
  { label: 'Logistics', value: 'LOGISTICS' },
  { label: 'Media/Entertainment', value: 'MEDIA_ENTERTAINMENT' },
  { label: 'Payments', value: 'PAYMENTS' },
  { label: 'Productivity', value: 'PRODUCTIVITY' },
  { label: 'NonTech', value: 'NONTECH' },
  { label: 'Real Estate', value: 'REAL_ESTATE' },
  { label: 'Retail', value: 'RETAIL' },
  { label: 'Robotics', value: 'ROBOTICS' },
  { label: 'Sales Tools', value: 'SALES_TOOLS' },
  { label: 'Social', value: 'SOCIAL' },
  { label: 'Social Impact', value: 'SOCIAL_IMPACT' },
  { label: 'Transportation', value: 'TRANSPORTATION' },
  { label: 'Travel', value: 'TRAVEL' },
  { label: 'VR/AR', value: 'VR_AR' },
  { label: 'Other', value: 'OTHER' },
];

export { industryOptions };
