const InvestmentStatistics = ({
  data,
}: {
  data: {
    label: string;
    value?: number | string | null;
  }[];
}) => {
  return (
    <div className="flex flex-col mt-4 divide-y divide-gray-100">
      {data.map(item => {
        return (
          <div className="py-4 grid grid-cols-3" key={item.label}>
            <span className="col-span-2 text-sm font-medium text-gray-600">{item.label}</span>
            <span className="col-span-1 text-sm text-gray-500 mr-2">{item.value}</span>
          </div>
        );
      })}
    </div>
  );
};

export default InvestmentStatistics;
