import Cookies from 'js-cookie';

type User = {
  id: string;
  name: string;
  email: string;
  phoneNumber?: string | null;
};

export const isAuthenticated = () => !!Cookies.get('token');

export const storeLoginCredentials = (
  user: User,
  tokenId: string,
  adminLoggingInAsUser?: boolean
) => {
  Cookies.set('token', tokenId, { expires: 365 });
  Cookies.set('user', JSON.stringify(user), { expires: 365 });
  Cookies.set('adminLoggingInAsUser', adminLoggingInAsUser ? 'true' : 'false', { expires: 365 });
};

export const getToken = () => (Cookies.get('token') ? Cookies.get('token') : '');
export const getUser = () => (Cookies.get('user') ? JSON.parse(Cookies.get('user')) : '');

export const isAdminLoggingInAsUser = () => Cookies.get('adminLoggingInAsUser') === 'true';

export const logout = () => {
  Cookies.remove('token');
  Cookies.remove('user');
  Cookies.remove('adminLoggingInAsUser');
  refresh();
};

const refresh = () => window.location.reload();
