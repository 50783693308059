import { useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { InvestmentEntityTypeEnumType } from '__generated__/graphql';
import useParentRoute from 'hooks/useParentRoute';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import LoadingIndicator from 'primitives/LoadingIndicator';

import { getUser } from 'utils/auth';

const GET_INVESTMENT_ENTITY_QUERY = gql(`
  query InvestmentEntity($id: ID!) {
    investmentEntity(id: $id) {
      id
      type
      name
    }
  }
`);

const UPSERT_INVESTMENT_ENTITY_MUTATION = gql(`
  mutation UpsertInvestmentEntity($id: ID, $type: InvestmentEntityTypeEnumType!, $name: String!) {
    upsertInvestmentEntity(id: $id, type: $type, name: $name) {
      id
      type
      name
    }
  }
`);

const BasicInfo: FC = () => {
  const navigate = useNavigate();
  const parentRoute = useParentRoute();

  const { investmentEntityId } = useParams<{ investmentEntityId: string }>();
  const user = getUser();

  const [getInvestmentEntity, { data, loading, error, refetch }] = useLazyQuery(
    GET_INVESTMENT_ENTITY_QUERY
  );

  const [upsertInvestmentEntity, { loading: upsertLoading, error: upsertError }] = useMutation(
    UPSERT_INVESTMENT_ENTITY_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: cache.identify({
            __typename: 'DashboardType',
            id: user.id,
          }),
          fields: {
            investmentEntities(existingInvestmentEntitiesRef, { readField }) {
              if (
                existingInvestmentEntitiesRef.some(
                  ref => readField('id', ref) === data.upsertInvestmentEntity.id
                )
              ) {
                return [
                  cache.writeFragment({
                    data: data.upsertInvestmentEntity,
                    fragment: gql(`
                      fragment NewInvestmentEntity on InvestmentEntityType {
                        id
                        type
                      }
                    `),
                  }),
                  ...existingInvestmentEntitiesRef.filter(
                    investmentEntityRef =>
                      readField('id', investmentEntityRef) !== data.upsertInvestmentEntity.id
                  ),
                ];
              }

              const newInvestmentEntityNodeRef = cache.writeFragment({
                data: data.upsertInvestmentEntity,
                fragment: gql(`
                  fragment NewInvestmentEntity on InvestmentEntityType {
                    id
                    type
                  }
                `),
              });
              const updatedInvestmentEntitiesRef = [
                newInvestmentEntityNodeRef,
                ...existingInvestmentEntitiesRef,
              ];
              return updatedInvestmentEntitiesRef;
            },
          },
        });
      },
    }
  );

  useEffect(() => {
    if (!investmentEntityId) return;

    getInvestmentEntity({ variables: { id: investmentEntityId } });
  }, [investmentEntityId, getInvestmentEntity]);

  if (loading) return <LoadingIndicator />;

  if (error) return <ErrorMessage error={error} refetch={refetch} />;

  if (investmentEntityId && !data) return <LoadingIndicator />;

  return (
    <FormPanel
      loading={upsertLoading}
      error={upsertError}
      onSubmit={data => {
        upsertInvestmentEntity({
          variables: {
            id: investmentEntityId ? investmentEntityId : '',
            type: data.type,
            name: data.name,
          },
        }).then(data => {
          investmentEntityId
            ? navigate(`${parentRoute}/kyc`)
            : navigate(
                `${
                  parentRoute.includes('onboarding') && !parentRoute.includes('basic-information')
                    ? `${parentRoute}/investment-entity`
                    : `${parentRoute}/create`
                }/${data.data?.upsertInvestmentEntity.id}/kyc`
              );
        });
      }}
      onCancel={() => {
        navigate(`/onboarding/complete-profile/social-profile`);
      }}
      submitButtonLabel="Save and continue"
      cancelButtonLabel="Back"
    >
      <FormInput
        fullWidth
        type="select"
        fieldName="type"
        label="Entity Type *"
        defaultValue={data?.investmentEntity?.type || ''}
        options={[
          { label: 'Individual', value: InvestmentEntityTypeEnumType.Individual },
          { label: 'Private Limited', value: InvestmentEntityTypeEnumType.PrivateLimited },
          {
            label: 'Limited Liability Partnership',
            value: InvestmentEntityTypeEnumType.LimitedLiabilityPartnership,
          },
          { label: 'HUF', value: InvestmentEntityTypeEnumType.Huf },
          { label: 'Trust', value: InvestmentEntityTypeEnumType.Trust },
        ]}
        validators={{
          required: true,
        }}
      />
      <FormInput
        fullWidth
        type="text"
        fieldName="name"
        label="Legal name of the entity *"
        helperText="As per legal records"
        defaultValue={data?.investmentEntity?.name || ''}
        validators={{
          required: true,
          minLength: 8,
          maxLength: 255,
          isAlphaNumeric: true,
        }}
      />
    </FormPanel>
  );
};

export default BasicInfo;
