import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ClosingStatusEnumType, DealTypeEnumType } from '__generated__/graphql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import SyndicateUserSlideOver from 'routes/SyndicateDashboard/SyndicateNetwork/Users/SyndicateUserSlideOver';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';
import Toast from 'primitives/Toast';
import useToast from 'primitives/Toast/useToast';

const CLOSINGS_QUERY = gql(`
  query getDealClosings($id: ID!, $cursor: ID, $limit: Int) {
    deal(id: $id) {
      id
      type
      closings(cursor: $cursor, limit: $limit) {
        nodes {
          id
          investmentEntity {
            id
            name
            user {
              id
              name
            }
          }
          amount
          status
          createdAt
          updatedAt
          wiredAt
          finalisedAt
          syndicateUser {
            id
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const Commitments: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };
  const [syndicateUserOnDisplay, setSyndicateUserOnDisplay] = useState<string>('');

  const { showToast, handleToastOpen, handleToastClose } = useToast();

  const { loading, error, data, refetch, fetchMore } = useQuery(CLOSINGS_QUERY, {
    variables: {
      id: dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const closings = data.deal.closings.nodes;
  const pageInfo = data.deal.closings.pageInfo;

  return (
    <>
      <SyndicateUserSlideOver
        open={!!syndicateUserOnDisplay}
        syndicateUserId={syndicateUserOnDisplay}
        onClose={() => setSyndicateUserOnDisplay('')}
      />
      <DataTable
        data={closings}
        onClick={closing =>
          data.deal.type === DealTypeEnumType.Syndicated
            ? closing.syndicateUser
              ? setSyndicateUserOnDisplay(closing.syndicateUser.id)
              : handleToastOpen()
            : null
        }
        columns={[
          {
            label: 'Name',
            fieldName: 'investmentEntity.user.name',
          },
          {
            label: 'Investment Entity',
            fieldName: 'investmentEntity.name',
          },
          {
            label: 'Amount',
            fieldName: 'amount',
            type: 'CURRENCY',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: closings.some(closing => closing.status === ClosingStatusEnumType.Committed)
              ? 'Committed At'
              : closings.some(closing => closing.status === ClosingStatusEnumType.Transferred)
              ? 'Transferred At'
              : closings.some(closing => closing.status === ClosingStatusEnumType.Active)
              ? 'Finalised At'
              : 'Created At',
            fieldName: closings.some(closing => closing.status === ClosingStatusEnumType.Committed)
              ? 'createdAt'
              : closings.some(closing => closing.status === ClosingStatusEnumType.Transferred)
              ? 'wiredAt'
              : closings.some(closing => closing.status === ClosingStatusEnumType.Active)
              ? 'finalisedAt'
              : 'createdAt',
            type: 'DATETIME',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
      <Toast
        title="User not in your network"
        type="error"
        message={
          'This user is not a part of your network. Please send them an invite to begin viewing statistics.'
        }
        show={showToast}
        handleClose={handleToastClose}
      />
    </>
  );
};

export default Commitments;
