import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

const LEAVE_SYNDICATE_MUTATION = gql(`
  mutation LeaveSyndicateAsLead($syndicateId: ID!) {
    leaveSyndicateAsLead(syndicateId: $syndicateId) {
      id
    }
  }
`);

const LeaveSyndicateButton: FC<{ id: string }> = ({ id: syndicateId }) => {
  const navigate = useNavigate();
  const [showConfirmDialog, toggleConfirmDialog] = useState(false);

  const [leaveSyndicate, { loading, error }] = useMutation(LEAVE_SYNDICATE_MUTATION);

  return (
    <>
      <Button onClick={() => toggleConfirmDialog(true)}>Leave</Button>
      <Dialog open={showConfirmDialog} onClose={() => toggleConfirmDialog(false)}>
        <DialogPanel>
          <DialogTitle>Are you sure?</DialogTitle>
          You will have to be invited to the syndicate again in order to become a lead
          {error && <ErrorMessage error={error} type="alert" />}
          <DialogActions>
            <Button variant="secondary" onClick={() => toggleConfirmDialog(false)}>
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() =>
                leaveSyndicate({ variables: { syndicateId } }).then(() => navigate('/'))
              }
            >
              Leave
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default LeaveSyndicateButton;
