import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import {
  dealFlowOptions,
  geographyOptions,
  stageOptions,
  statusOptions,
} from 'routes/Invest/Syndicates/Suggested/options';
import { industryOptions } from 'routes/SyndicateDashboard/SyndicateDeals/CreateDeal/Memo/constants';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

import LoadingIndicator from 'primitives/LoadingIndicator';

import { getUser } from 'utils/auth';

const INVESTMENT_PREFERENCES_QUERY = gql(`
  query getUserInvestmentPreferencesSettings {
    dashboard {
      id
      user {
        id
        investmentPreferences {
          statuses
          dealFlows
          stages
          revenues
          geographies
          industries
        }
      }
    }
  }
`);

const UPDATE_INVESTMENT_PREFERENCES_MUTATION = gql(`
  mutation UpdateUserInvestmentPreferences($id: ID!, $statuses: [SuggestedSyndicatesStatusEnumType], $dealFlows: [DealFlowTypeEnumType], $stages: [SuggestedSyndicatesStageEnumType], $revenues: [DealCompanyStageEnumType], $geographies: [CountryEnumType], $industries: [IndustryTypeEnumType]) {
    updateUserInvestmentPreferences(id: $id, statuses: $statuses, dealFlows: $dealFlows, stages: $stages, revenues: $revenues, geographies: $geographies, industries: $industries) {
      id
    }
  }
`);

const InvestmentPreferencesSettingsPage: FC = () => {
  const { loading, error, data, refetch } = useQuery(INVESTMENT_PREFERENCES_QUERY);

  const [updateUserInvestmentPreferences] = useMutation(UPDATE_INVESTMENT_PREFERENCES_MUTATION);

  const { id } = getUser();

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const user = data.dashboard.user;

  const { statuses, dealFlows, stages, revenues, geographies, industries } =
    user.investmentPreferences || {};

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateUserInvestmentPreferences({
          variables: {
            id,
            statuses: data.statuses,
            dealFlows: data.dealFlows,
            stages: data.stages,
            revenues: data.revenues,
            geographies: data.geographies,
            industries: data.industries,
          },
        });
      }}
      title="Investment Preferences"
    >
      <FormInput
        fullWidth
        type="checkbox-group"
        fieldName="statuses"
        label="Deal statuses"
        defaultValue={statuses}
        options={statusOptions}
        compact={true}
      />
      <FormInput
        fullWidth
        type="checkbox-group"
        fieldName="dealFlows"
        label="Dealflows"
        defaultValue={dealFlows}
        options={dealFlowOptions}
        compact={true}
      />
      <FormInput
        fullWidth
        type="checkbox-group"
        fieldName="stages"
        label="Stages"
        defaultValue={stages}
        options={stageOptions}
        compact={true}
      />
      <FormInput
        fullWidth
        type="checkbox-group"
        fieldName="revenues"
        label="Revenues"
        defaultValue={revenues}
        options={[
          { label: 'Pre Launch', value: 'PRE_LAUNCH' },
          { label: 'Pre Revenue', value: 'PRE_REVENUE' },
          { label: 'Post Revenue', value: 'POST_REVENUE' },
          { label: 'Profitable', value: 'PROFITABLE' },
        ]}
        compact={true}
      />
      <FormInput
        fullWidth
        type="checkbox-group"
        fieldName="geographies"
        label="Geographies"
        defaultValue={geographies}
        options={geographyOptions}
        compact={true}
      />
      <FormInput
        fullWidth
        type="checkbox-group"
        fieldName="industries"
        label="Industries"
        defaultValue={industries}
        options={industryOptions}
        compact={true}
      />
    </FormPanelWithReadMode>
  );
};

export default InvestmentPreferencesSettingsPage;
