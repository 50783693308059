import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';

export default function CopyToClipboard({
  value,
  className,
}: {
  value: string;
  className?: string;
}) {
  return (
    <Popover className={className}>
      <PopoverButton
        className="outline-none p-2 bg-indigo-600 hover:bg-indigo-500 rounded-full"
        onClick={e => {
          navigator.clipboard.writeText(value);
          e.stopPropagation();
        }}
      >
        <ClipboardDocumentCheckIcon className="size-5 text-white" />
      </PopoverButton>
      <PopoverPanel anchor="bottom" className="flex flex-col">
        {({ close }) => {
          setTimeout(() => {
            close();
          }, 2000);
          return (
            <div className="bg-indigo-600 text-white mt-2 py-1 px-2 rounded-lg text-sm">Copied</div>
          );
        }}
      </PopoverPanel>
    </Popover>
  );
}
