import { useQuery } from '@apollo/client';
import { gql } from '__generated__';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';

import Alert from 'primitives/Alert';
import LoadingIndicator from 'primitives/LoadingIndicator';

import BasicInformation from './BasicInformation';
import CarryRecipients from './CarryRecipients';
import CompanyInformation from './CompanyInformation';
import DealTabsForInformation from './DealTabsForInformation';
import ExternalInvestors from './ExternalInvestors';
import Memo from './Memo';
import RisksDisclosures from './RiskDisclosures';

const DEAL_STATUS_QUERY = gql(`
  query DealStatus($id: ID!) {
    deal(id: $id) {
      id
      status
    }
  }
`);

const DealInformation: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };
  const [activeTab, setActiveTab] = useState('basic-information');

  const { loading, error, data, refetch } = useQuery(DEAL_STATUS_QUERY, {
    variables: {
      id: dealId,
    },
  });

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    switch (activeTab) {
      case 'basic-information':
        return <BasicInformation dealStatus={data.deal.status} />;
      case 'external-investors':
        return <ExternalInvestors dealStatus={data.deal.status} />;
      case 'company-information':
        return <CompanyInformation dealStatus={data.deal.status} />;
      case 'memo':
        return <Memo dealStatus={data.deal.status} />;
      case 'risks-and-disclosures':
        return <RisksDisclosures dealStatus={data.deal.status} />;
      case 'carry-recipients':
        return <CarryRecipients dealStatus={data.deal.status} />;
    }
  }

  return (
    <>
      <Alert
        type="warning"
        title="Any material changes require us to provide LPs with an opportunity to opt-out of the deal"
      />
      <div className="w-full grid grid-cols-6 gap-x-8">
        <div className="col-span-2 relative">
          <DealTabsForInformation activeTab={activeTab} onSelect={setActiveTab} />
        </div>
        <div className="col-span-4">{renderContent()}</div>
      </div>
    </>
  );
};

export default DealInformation;
