import { useQuery } from '@apollo/client';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { gql } from '__generated__';
import { ClosingStatusEnumType, FundingSourceEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';
import { FC } from 'react';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import fundingSourceToReadable from 'utils/enums/funding-source-to-readable';
import { formatCurrency } from 'utils/format-helper';

import ReportIssueDealPage from './ReportIssueDealPage';

const CLOSINGS_QUERY = gql(`
  query getCommitmentsOfUserForDeal(
    $cursor: ID
    $limit: Int
    $filters: DashboardClosingsFilterType
  ) {
    dashboard {
      id
      closings(cursor: $cursor, limit: $limit, filters: $filters) {
        nodes {
          id
          investmentEntity {
            id
            name
          }
          amount
          status
          fundingSource
          createdAt
          wiredAt
          closingDocuments {
            id
            type
            name
            url
          }
          deal {
            companyInformation {
              company {
                name
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const CommitmentStatus: FC<{
  id: string;
  amount: number;
  investmentEntity: {
    id: string;
    name: string;
  };
  fundingSource: FundingSourceEnumType;
  createdAt: string;
  wiredAt?: string;
  companyName: string;
  status: ClosingStatusEnumType;
}> = ({ id, amount, investmentEntity, fundingSource, createdAt, wiredAt, companyName, status }) => {
  return (
    <div className="custom-bounce">
      <div
        key={id}
        className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border p-4"
      >
        <div className="flex items-center sm:items-start">
          <CheckCircleIcon aria-hidden="true" className="h-16 w-16 text-green-500" />
          <div className="ml-6 flex-1">
            <div className="text-2xl font-medium text-gray-900">
              <h5>
                {formatCurrency(amount)} committed to {companyName}
              </h5>
            </div>
            {status === ClosingStatusEnumType.Committed && (
              <p className="text-gray-500">
                We've notified the Syndicate lead of your commitment. Wiring instructions will be
                sent to you once the syndicate lead has confirmed your investment.
              </p>
            )}

            {status === ClosingStatusEnumType.Transferred && (
              <p className="text-gray-500">
                We've received your investment. The Syndicate lead has been notified, and your
                commitment has been confirmed. Thank you for your participation.
              </p>
            )}

            {status === ClosingStatusEnumType.Active && (
              <p className="text-gray-500">
                Your investment is now active. You can view the closing documents below.
              </p>
            )}

            <dl className="mt-4 pt-4 flex flex-row gap-x-6 text-sm border-t border-gray-100">
              <div className="hidden sm:block">
                <dt className="font-medium text-gray-900">Investment Entity</dt>
                <dd className="mt-1 text-gray-500">{investmentEntity.name}</dd>
              </div>
              <div>
                <dt className="font-medium text-gray-900">Funding Source</dt>
                <dd className="mt-1 font-medium text-gray-500">
                  {fundingSourceToReadable(fundingSource).label}
                </dd>
              </div>
              <div>
                <dt className="font-medium text-gray-900">
                  {status === ClosingStatusEnumType.Committed
                    ? 'Committed at'
                    : status === ClosingStatusEnumType.Transferred
                    ? 'Wired at'
                    : 'Commited at'}
                </dt>
                <dd className="mt-1 text-gray-500">
                  {(status === ClosingStatusEnumType.Committed ||
                    status === ClosingStatusEnumType.Active) && (
                    <time dateTime={createdAt}>{dayjs(createdAt).format('MMMM DD, YYYY')}</time>
                  )}
                  {status === ClosingStatusEnumType.Transferred && wiredAt && (
                    <time dateTime={createdAt}>{dayjs(wiredAt).format('MMMM DD, YYYY')}</time>
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="flex justify-end">
          <ReportIssueDealPage />
        </div>
      </div>
    </div>
  );
};

const Commitments: FC<{ dealId: string }> = ({ dealId }) => {
  const { loading, error, data, refetch } = useQuery(CLOSINGS_QUERY, {
    variables: {
      filters: {
        statuses: [
          ClosingStatusEnumType.Created,
          ClosingStatusEnumType.Committed,
          ClosingStatusEnumType.Transferred,
          ClosingStatusEnumType.Active,
          ClosingStatusEnumType.Exited,
        ],
        dealId,
      },
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const commitments = data.dashboard.closings.nodes;

  return (
    <>
      <div className="custom-bounce">
        <div className="mt-8">
          <div className="mx-auto">
            <div className="mx-auto max-w-2xl space-y-8 sm:px-4 lg:max-w-4xl lg:px-0">
              {commitments.map(commitment => (
                <>
                  <CommitmentStatus
                    key={commitment.id}
                    id={commitment.id}
                    amount={commitment.amount}
                    investmentEntity={commitment.investmentEntity}
                    fundingSource={commitment.fundingSource}
                    createdAt={commitment.createdAt}
                    companyName={commitment.deal.companyInformation.company.name}
                    status={commitment.status}
                    wiredAt={commitment.wiredAt || undefined}
                  />
                  {/* TODO: Add closing documents later when investor statement + scheme doc is ready */}
                  {/* <p className="text-md font-bold mt-4 mb-2">Documents related to this closing</p>
                  <DataTable
                    data={commitment.closingDocuments}
                    columns={[
                      {
                        label: 'Name',
                        fieldName: 'name',
                      },
                      {
                        label: 'Type',
                        fieldName: 'type',
                        type: 'INVESTMENT_ENTITY_DOCUMENT_TYPE',
                      },
                      {
                        label: 'Url',
                        fieldName: 'url',
                        type: 'LINK',
                      },
                    ]}
                  /> */}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Commitments;
