import { ReportIssueTypeEnumType } from '__generated__/graphql';
import { useState } from 'react';
import ReportIssue from 'routes/Help/ReportIssue';

import SlideOver from 'primitives/SlideOver';

export default function ReportAccountInactivity() {
  const [showReportIssueSlideOver, toggleReportIssueSlideOver] = useState(false);
  return (
    <>
      <SlideOver
        open={!!showReportIssueSlideOver}
        onClose={() => toggleReportIssueSlideOver(false)}
        title=""
      >
        <ReportIssue
          type={ReportIssueTypeEnumType.AccountInactivity}
          onClose={() => toggleReportIssueSlideOver(false)}
        />
      </SlideOver>

      <button
        onClick={() => toggleReportIssueSlideOver(true)}
        className="text-sm font-semibold text-gray-900"
      >
        Contact support <span aria-hidden="true">&rarr;</span>
      </button>
    </>
  );
}
