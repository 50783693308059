import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import alIndiaLogo from 'media/alIndiaLogo.png';

import theme from './theme.module.scss';

const RESET_PASSWORD_MUTATION = gql(`
  mutation ResetPassword($resetToken: String!, $userId: ID!, $password: String!) {
    resetPassword(resetToken: $resetToken, userId: $userId, password: $password)
  }
`);

const ResetPassword: FC = () => {
  const [params] = useSearchParams();
  const token = params.get('token');
  const userId = params.get('userId');

  const [resetPassword, { error, loading }] = useMutation(RESET_PASSWORD_MUTATION);

  const [showMessage, setShowMessage] = useState<boolean>(false);

  if (!token || !userId) {
    return <ErrorMessage title={'Invalid reset link'} message="The reset link is invalid" />;
  }

  return (
    <div className={theme.container}>
      <NavLink to="/" className={theme.logoContainer}>
        <img className={theme.logo} src={alIndiaLogo} alt="AL India logo" height={24} />
      </NavLink>
      <div className={theme.inner}>
        <h2>Reset your password</h2>
        <FormPanel
          loading={loading}
          error={error}
          onSubmit={formData => {
            resetPassword({
              variables: {
                password: formData.password,
                resetToken: token,
                userId: userId,
              },
            }).then(() => setShowMessage(true));
          }}
          submitButtonLabel="Reset Password"
        >
          <FormInput
            fieldName="password"
            type="password"
            defaultValue=""
            label="Enter new password"
            fullWidth
          />
          <FormInput
            fieldName="passwordConfirmation"
            type="password"
            validators={{
              equalTo: {
                fieldName: 'password',
                error: 'Passwords do not match',
              },
            }}
            defaultValue=""
            label="Re-enter new password"
            fullWidth
          />
        </FormPanel>
        {showMessage ? (
          <p className={theme.information}>
            You can now sign in to your account <Link to="/login">Sign in</Link>
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default ResetPassword;
