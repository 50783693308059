import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FocusLayout from 'layouts/FocusLayout';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DealInformation from './DealInformation';
import PassDealDialogButton from './PassDealDialogButton';

const DEAL_QUERY = gql(`
  query GetDealInvite($id: ID!) {
    dealInvite(id: $id) {
      id
      status
      minimumInvestmentAmount
      maximumInvestmentAmount
      note
      deal {
        id
        companyInformation {
          id
          company {
            id
            name
            image
          }
          industries
          memo
        }
        syndicate {
          id
          name
          type
        }
        dealDisclosures {
          id
          risksNote
          otherDisclosuresNote
          hasLeadInvestedInCompanyBefore
          doesLeadHaveAdvisoryShares    
          isLeadEmployeeAtCompany       
          doesLeadHaveRelativesInCompany
          hasSameTermsForCoInvestors    
          isLeadInvestingOnSameTerms    
          isLeadInvestingFromFund       
        }
        dealTerms {
          id
          fundingRoundSize
          fundingRoundName
          financialInstrument
          valuationOrCap
          discount
          pastFinancingNote
          prorataRights
          minimumInvestment
          availableCarryPercentage
        }
        type
        allocation
        closingDate
        gpCommitment
        externalInvestors {
          id
          name
          description
          amount
          isLead
        }
      }
    }
  }
`);

const DealInvitePage: FC = () => {
  const navigate = useNavigate();
  const { dealInviteId } = useParams<{ dealInviteId: string }>() as { dealInviteId: string };

  const { loading, error, data, refetch } = useQuery(DEAL_QUERY, {
    variables: {
      id: dealInviteId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const dealInvite = data.dealInvite;
  const deal = dealInvite.deal;

  if (!deal || !deal.dealTerms) {
    return (
      <ErrorMessage
        title="Invalid Deal Invite"
        message="There is an issue with this deal invite. Please contact the syndicate lead for a new invite."
      />
    );
  }

  if (dealInvite.status === 'COMMITTED') {
    navigate(`/invest/deal/${deal.id}`);
  }

  return (
    <FocusLayout>
      <DealInformation deal={deal} />
      <div className="fixed bottom-4 left-0 right-0">
        <div className="mx-auto max-w-3xl bg-white shadow-2xl p-4 grid grid-cols-6 gap-x-3 border-2 border-indigo-100 rounded-lg">
          <div className="col-span-2">
            <h2 className="text-2xl font-medium">Invest</h2>
            <p className="text-sm text-gray-400 my-1">
              Deadline: {dayjs(deal.closingDate).format('MMMM DD, YYYY')}
            </p>
          </div>
          <div className="col-span-2 h-full">
            <PassDealDialogButton dealId={deal.id} />
          </div>
          <button
            onClick={() => navigate(`/invest/deal-invites/${dealInviteId}/confirm`)}
            className="col-span-2 w-full rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
          >
            Invest
          </button>
        </div>
      </div>
    </FocusLayout>
  );
};

export default DealInvitePage;
