import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';

import HorizontalTabs from 'components/HorizontalTabs';
import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';
import ProfileSwitchBar from 'components/Sidebar/ProfileSwitchBar';

export default function SidebarLayout({ children }: { children: any }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <Transition show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-9 lg:hidden" onClose={setSidebarOpen}>
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </TransitionChild>

          <div className="fixed inset-0 flex">
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <div className="flex w-full">
                  <ProfileSwitchBar />
                  <Sidebar mobile />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-9 lg:flex lg:w-64 lg:flex-col">
        <div className="flex w-full h-full">
          <Sidebar />
        </div>
      </div>

      <div className="lg:pl-64">
        <div className="sticky top-0 z-10 lg:mx-auto lg:max-w-8xl lg:px-8 lg:hidden bg-white">
          <div className="flex h-16 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-gray-200" aria-hidden="true" />
          </div>
        </div>

        {children}
      </div>
    </div>
  );
}

export function SidebarLayoutNavigation({
  title,
  subTitle,
  onBackButtonClick,
}: {
  title: string;
  subTitle: string;
  onBackButtonClick?: () => void;
}) {
  return (
    <nav className="sticky top-0 z-10 mx-auto max-w-5xl bg-white pl-4 pt-4 pb-2 lg:-pl-3">
      <Navbar title={title || 'title'} subTitle={subTitle} onBackButtonClick={onBackButtonClick} />
    </nav>
  );
}

export function SidebarLayoutContent({
  children,
  secondaryTabs,
}: {
  children: any;
  secondaryTabs?: {
    key: string;
    label: string;
    description: string;
    route: string;
    icon: any;
    exact?: boolean;
  }[];
}) {
  return (
    <main className="mx-auto max-w-5xl">
      <div>
        <div className="px-4 py-0 lg:py-3 flex flex-col gap-4">
          {secondaryTabs && (
            <nav>
              <HorizontalTabs tabs={secondaryTabs} />
            </nav>
          )}
          {children}
        </div>
      </div>
    </main>
  );
}
