export default function Badge({
  label,
  color,
  showIndicator = false,
  size = 'sm',
}: {
  label: string;
  color: string;
  showIndicator?: boolean;
  size?: 'sm' | 'lg';
}) {
  let style;

  switch (color) {
    case 'green':
      style = {
        backgroundColor: 'bg-green-100',
        textColor: 'text-green-700',
        fillColor: 'fill-green-500',
      };
      break;
    case 'amber':
      style = {
        backgroundColor: 'bg-amber-100',
        textColor: 'text-amber-700',
        fillColor: 'fill-amber-500',
      };
      break;
    case 'gray':
      style = {
        backgroundColor: 'bg-gray-100',
        textColor: 'text-gray-700',
        fillColor: 'fill-gray-500',
      };
      break;
    case 'stone':
      style = {
        backgroundColor: 'bg-stone-100',
        textColor: 'text-stone-700',
        fillColor: 'fill-stone-500',
      };
      break;
    case 'yellow':
      style = {
        backgroundColor: 'bg-yellow-100',
        textColor: 'text-yellow-700',
        fillColor: 'fill-yellow-500',
      };
      break;
    case 'sky':
      style = {
        backgroundColor: 'bg-sky-100',
        textColor: 'text-sky-700',
        fillColor: 'fill-sky-500',
      };
      break;
    case 'indigo':
      style = {
        backgroundColor: 'bg-indigo-100',
        textColor: 'text-indigo-700',
        fillColor: 'fill-indigo-500',
      };
      break;
    case 'purple':
      style = {
        backgroundColor: 'bg-purple-100',
        textColor: 'text-purple-700',
        fillColor: 'fill-purple-500',
      };
      break;
    case 'lime':
      style = {
        backgroundColor: 'bg-lime-100',
        textColor: 'text-lime-700',
        fillColor: 'fill-lime-500',
      };
      break;
    case 'red':
      style = {
        backgroundColor: 'bg-red-100',
        textColor: 'text-red-700',
        fillColor: 'fill-red-500',
      };
      break;
    default:
      style = {
        backgroundColor: `bg-gray-100`,
        textColor: `text-gray-700`,
        fillColor: `fill-gray-500`,
      };
  }

  return (
    <>
      <span
        className={`inline-flex items-center gap-x-1.5 rounded-md ${
          style.backgroundColor
        } px-2 py-1 ${size === 'sm' ? 'text-xs font-medium' : 'text-lg uppercase'} ${
          style.textColor
        }`}
      >
        {showIndicator && (
          <svg
            className={`${size === 'sm' ? 'h-1.5 w-1.5' : 'h-3 w-3'} ${style.fillColor}`}
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
        )}
        {label}
      </span>
    </>
  );
}
