import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import FocusLayout from 'layouts/FocusLayout';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import Commitments from './Commitments';
import DealInformation from './DealInformation';

const DEAL_QUERY = gql(`
  query GetDeal($id: ID!) {
    deal(id: $id) {
      id
      companyInformation {
        id
        company {
          id
          name
          image
        }
        industries
        memo
      }
      syndicate {
        id
        name
        type
      }
      dealDisclosures {
        id
        risksNote
        otherDisclosuresNote
        hasLeadInvestedInCompanyBefore
        doesLeadHaveAdvisoryShares    
        isLeadEmployeeAtCompany       
        doesLeadHaveRelativesInCompany
        hasSameTermsForCoInvestors    
        isLeadInvestingOnSameTerms    
        isLeadInvestingFromFund       
      }
      dealTerms {
        id
        fundingRoundSize
        fundingRoundName
        financialInstrument
        valuationOrCap
        discount
        pastFinancingNote
        prorataRights
        minimumInvestment
        availableCarryPercentage
      }
      type
      allocation
      closingDate
      gpCommitment
      externalInvestors {
        id
        name
        description
        amount
        isLead
      }
    }
  }
`);

const DealPage: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };

  const { loading, error, data, refetch } = useQuery(DEAL_QUERY, {
    variables: {
      id: dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const deal = data.deal;

  if (!deal || !deal.dealTerms) {
    return (
      <ErrorMessage
        title="Invalid Deal Invite"
        message="There is an issue with this deal invite. Please contact the syndicate lead for a new invite."
      />
    );
  }

  return (
    <FocusLayout>
      <Commitments dealId={deal.id} />
      <DealInformation deal={deal} />
    </FocusLayout>
  );
};

export default DealPage;
