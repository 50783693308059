import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { SyndicateInviteStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import ErrorMessage from 'components/ErrorMessage';
import Statistics from 'components/Statistics';

import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import { fixToTwoLocalPrice, formatCurrencyToShort } from 'utils/format-helper';

import InvestmentStatistics from './InvestmentStatistics';
import ResolveSyndicateApplication from './ResolveSyndicateApplication';
import UserTitle from './UserTitle';

const GET_SYNDICATE_INVITE_QUERY = gql(`
  query GetSyndicateApplication($id: ID!) {
    syndicateInvite(id: $id) {
      id
      name
      type
      status
      note
      syndicate {
        id
      }
      user {
        id
        createdAt
        name
        image{
          url
          name
        }
        linkedInProfileUrl
        statistics {
          totalInvestedAmountRange
          numberOfSyndicatesJoined
          totalInvestedAmount
          numberOfInvestments
          investRate
        }
      }
      createdAt
    }
  }
`);

const SyndicateApplicationSlideOver: FC<{
  open: boolean;
  syndicateInviteId: string;
  onClose: () => void;
}> = ({ open = false, syndicateInviteId, onClose }) => {
  const { loading, error, data, refetch } = useQuery(GET_SYNDICATE_INVITE_QUERY, {
    variables: {
      id: syndicateInviteId,
    },
    skip: !open,
  });

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const syndicateInvite = data.syndicateInvite;
    const user = syndicateInvite.user;

    if (!user) return <ErrorMessage title="User not found" />;

    return (
      <div>
        <UserTitle
          name={user.name}
          createdAt={syndicateInvite.createdAt}
          linkedInProfileUrl={user.linkedInProfileUrl}
        />
        <div className="my-5">
          <Statistics
            statistics={[
              { label: 'Investor Since', value: user.createdAt, type: 'MONTH' },
              {
                label: 'Number of Syndicates Joined',
                value: user.statistics.numberOfSyndicatesJoined,
                type: 'NUMBER',
              },
            ]}
          />
        </div>
        <div className="grid grid-cols-1 gap-x-2 gap-y-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
          <div className="p-2 bg-gray-400/5 rounded-xl">
            <p className="text-md font-medium text-gray-700">Last 12 mo. on AngelList India</p>
            <p className="text-xs text-gray-400">Via SPVs and Funds</p>
            <InvestmentStatistics
              data={[
                {
                  label: 'Total invested',
                  value: formatCurrencyToShort(user.statistics.totalInvestedAmount),
                },
                {
                  label: 'Investments',
                  value: user.statistics.numberOfInvestments,
                },
                {
                  label: 'Invest Rate',
                  value: `${fixToTwoLocalPrice(user.statistics.investRate)}%` || 'No history',
                },
              ]}
            />
          </div>
          <div className="p-2 bg-gray-400/5 rounded-xl">
            <p className="text-md font-medium text-gray-700">Investment Preferences</p>
            <p className="text-xs text-gray-400">Via SPVs and Funds</p>
            <InvestmentStatistics
              data={[
                {
                  label: 'Stages',
                  value: 'All Stages',
                },
                {
                  label: 'Revenues',
                  value: 'All Revenues',
                },
                {
                  label: 'Industries',
                  value: 'All Industries',
                },
              ]}
            />
          </div>
        </div>
        {syndicateInvite.status === SyndicateInviteStatusEnumType.Active && (
          <ResolveSyndicateApplication
            syndicateInviteId={syndicateInvite.id}
            syndicateId={syndicateInvite.syndicate.id}
            note={syndicateInvite.note}
            onClose={onClose}
          />
        )}
      </div>
    );
  }

  return (
    <SlideOver open={open} onClose={onClose}>
      {renderContent()}
    </SlideOver>
  );
};

export default SyndicateApplicationSlideOver;
