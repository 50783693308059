import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import dealIcon from 'media/icons/deal.svg';
import informationIcon from 'media/icons/information.svg';
import moneyIcon from 'media/icons/investment.svg';
import manageIcon from 'media/icons/manage.svg';

const DEAL_QUERY = gql(`
  query GetDealForSyndicate($id: ID!) {
    deal(id: $id) {
      id
      status
      companyInformation {
        id
        company {
          id
          name
        }
      }
      type
    }
  }
`);

const DealPage: FC = () => {
  const navigate = useNavigate();
  const { syndicateId, dealId } = useParams<{ syndicateId: string; dealId: string }>() as {
    syndicateId: string;
    dealId: string;
  };

  const { loading, error, data, refetch } = useQuery(DEAL_QUERY, {
    variables: {
      id: dealId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const deal = data.deal;

    // Draft state should take user to deal create page instead of deal manage page
    if (deal.status === 'DRAFT') {
      navigate(`/syndicate-dashboard/${syndicateId}/deals/create/${dealId}/basic-information`, {
        replace: true,
      });
    }

    const tabs = [
      {
        key: 'manage',
        label: 'Manage',
        description: 'Manage your deal',
        route: `/syndicate-dashboard/${syndicateId}/deals/${dealId}/manage`,
        icon: manageIcon,
      },
      {
        key: 'commitments',
        label: 'Commitments',
        description: 'View commitments for this deal',
        route: `/syndicate-dashboard/${syndicateId}/deals/${dealId}/commitments`,
        icon: moneyIcon,
      },
      {
        key: 'invites',
        label: 'Invites',
        description: 'View and manage invites',
        route: `/syndicate-dashboard/${syndicateId}/deals/${dealId}/invites`,
        icon: dealIcon,
      },
      {
        key: 'information',
        label: 'Information',
        description: 'View and manage information',
        route: `/syndicate-dashboard/${syndicateId}/deals/${dealId}/information/basic-information`,
        icon: informationIcon,
      },
      {
        key: 'posts',
        label: 'Posts',
        description: 'View and manage posts',
        route: `/syndicate-dashboard/${syndicateId}/deals/${dealId}/posts`,
        icon: informationIcon,
      },
    ];

    return (
      <>
        <SidebarLayoutNavigation
          onBackButtonClick={() => navigate(-1)}
          title={deal.companyInformation.company.name}
          subTitle={deal.type === 'SYNDICATED' ? 'Syndicated Deal' : 'Roll Up Vehicle'}
        />
        <SidebarLayoutContent secondaryTabs={tabs}>
          <Outlet />
        </SidebarLayoutContent>
      </>
    );
  };

  return <SidebarLayout>{renderContent()}</SidebarLayout>;
};

export default DealPage;
