import { useState } from 'react';

const useToast = () => {
  const [show, setShow] = useState(false);
  const handleToastOpen = () => setShow(true);
  const handleToastClose = () => setShow(false);

  return { showToast: show, handleToastOpen, handleToastClose };
};

export default useToast;
