import { gql, useQuery } from '@apollo/client';
import { SyndicateTypeEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import incomingApplicationsIcon from 'media/icons/incoming-applications.svg';
import linkIcon from 'media/icons/link-icon.svg';
import manageIcon from 'media/icons/manage.svg';
import outgoingInvitesIcon from 'media/icons/outgoing-invites.svg';

const SYNDICATE_DASHBOARD_TABS_QUERY = gql(`
  query SyndicateDashboardTabsQuery($syndicateId: ID!) {
    syndicate(id: $syndicateId) {
      id
      name
      type
    }
  }
`);

const SyndicateNetwork: FC = () => {
  const { syndicateId } = useParams<{ syndicateId: string }>();
  const { data, loading, error, refetch } = useQuery(SYNDICATE_DASHBOARD_TABS_QUERY, {
    variables: {
      syndicateId,
    },
  });

  if (loading) return <LoadingIndicator />;
  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const syndicate = data?.syndicate;

  const tabs = [
    {
      key: 'manage',
      label: 'Manage',
      description: 'Manage the LPs who can invest in your deals',
      route: `/syndicate-dashboard/${syndicateId}/network`,
      icon: manageIcon,
      exact: true,
    },
    {
      key: 'applications',
      label: 'Incoming Applications',
      description: 'View and manage incoming applications from LPs',
      route: `/syndicate-dashboard/${syndicateId}/network/applications`,
      icon: incomingApplicationsIcon,
    },
  ];

  const inviteTabs = [
    {
      key: 'invites',
      label: 'Email Invites',
      description: 'View and manage outgoing invites to LPs',
      route: `/syndicate-dashboard/${syndicateId}/network/invites`,
      icon: outgoingInvitesIcon,
      exact: true,
    },
    {
      key: 'invite-links',
      label: 'Invite Links',
      description: 'Shareable invite links',
      route: `/syndicate-dashboard/${syndicateId}/network/invite-links`,
      icon: linkIcon,
      exact: true,
    },
  ];

  if (syndicate.type === SyndicateTypeEnumType.Default) {
    tabs.push(...inviteTabs);
  }

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="LP Network"
        subTitle="Manage the LPs who can invest in your deals"
      />
      <SidebarLayoutContent secondaryTabs={tabs}>
        <Outlet />
      </SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default SyndicateNetwork;
