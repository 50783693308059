import { ReportIssueTypeEnumType } from '__generated__/graphql';
import { useState } from 'react';
import ReportIssue from 'routes/Help/ReportIssue';

import SlideOver from 'primitives/SlideOver';

export default function ReportDataDiscrepancy() {
  const [showReportIssueSlideOver, toggleReportIssueSlideOver] = useState(false);
  return (
    <>
      <SlideOver
        open={!!showReportIssueSlideOver}
        onClose={() => toggleReportIssueSlideOver(false)}
        title=""
      >
        <ReportIssue
          type={ReportIssueTypeEnumType.DataDiscrepancy}
          onClose={() => toggleReportIssueSlideOver(false)}
        />
      </SlideOver>
      <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-50 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
        <p className="text-sm leading-6 text-indigo-600">
          <button
            onClick={() => toggleReportIssueSlideOver(true)}
            className="flex items-center gap-x-1.5"
          >
            <strong className="font-semibold">Notice any issues?</strong>
            <svg
              viewBox="0 0 2 2"
              className="mx-2 inline h-0.5 w-0.5 fill-current"
              aria-hidden="true"
            >
              <circle cx={1} cy={1} r={1} />
            </svg>
            Help us improve by reporting any data discrepancies or missing information&nbsp;
            <span aria-hidden="true">&rarr;</span>
          </button>
        </p>
      </div>
    </>
  );
}
