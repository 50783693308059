import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';

const UPDATE_DEAL_INVITE_LINK_STATUS = gql(`
  mutation UpdateFundInviteLink($id: ID!, $status: FundInviteLinkStatusEnumType!) {
    updateFundInviteLinkStatus(id: $id, status: $status) {
      id
      status
    }
  }
`);

const UpdateFundInviteLinkStatusDialogButton: FC<{
  id: string;
  status: string;
  onClose: () => void;
}> = ({ id, status, onClose }) => {
  const [updateFundInviteLinkStatus, { loading, error }] = useMutation(
    UPDATE_DEAL_INVITE_LINK_STATUS
  );

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogPanel>
        <DialogTitle>Update Fund Invite Link Status</DialogTitle>
        <FormPanelWithReadMode
          loading={loading}
          error={error}
          onSubmit={data => {
            updateFundInviteLinkStatus({
              variables: {
                id,
                status: data.status,
              },
            }).then(() => {
              onClose();
            });
          }}
        >
          <FormInput
            type="select"
            fieldName="status"
            label="Status"
            defaultValue={status}
            options={[
              { value: 'ACTIVE', label: 'Active', color: 'bg-green-500' },
              { value: 'INACTIVE', label: 'Inactive', color: 'bg-red-500' },
            ]}
          />
        </FormPanelWithReadMode>
      </DialogPanel>
    </Dialog>
  );
};

export default UpdateFundInviteLinkStatusDialogButton;
