import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import FocusLayout from 'layouts/FocusLayout';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

const VERIFY_EMAIL_MUTATION = gql(`
  mutation VerifyEmail($verificationToken: String!, $userId: String!) {
    verifyEmail(verificationToken: $verificationToken, userId: $userId)
  }
`);

const VerifyEmailPage: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const userId = searchParams.get('userId');

  const [verifyEmail, { loading, error, data }] = useMutation(VERIFY_EMAIL_MUTATION, {
    variables: {
      verificationToken: token ?? '',
      userId: userId ?? '',
    },
  });

  useEffect(() => {
    verifyEmail().then(({ data }) => {
      if (!data?.verifyEmail) return;
      navigate(`/login`);
    });
  }, [navigate, verifyEmail]);

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data || !token) return <ErrorMessage error={error} />;

    return <div>{data && <p>Redirecting to home page ...</p>}</div>;
  };

  return <FocusLayout>{renderContent()}</FocusLayout>;
};

export default VerifyEmailPage;
