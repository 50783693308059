import { Switch as HeadlessSwitch } from '@headlessui/react';
import classNames from 'classnames';

export default function Switch({
  checked,
  onChange,
  label,
  helperText,
  disabled = false,
}: {
  checked: boolean;
  onChange?: (checked: boolean) => void;
  label: string;
  helperText?: string;
  disabled?: boolean;
}) {
  return (
    <HeadlessSwitch.Group as="div" className="flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <HeadlessSwitch.Label
          as="span"
          className="text-sm font-medium leading-6 text-gray-900"
          passive
        >
          {label}
        </HeadlessSwitch.Label>
        <HeadlessSwitch.Description as="span" className="text-sm text-gray-500">
          {helperText}
        </HeadlessSwitch.Description>
      </span>
      <HeadlessSwitch
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        className={classNames(
          checked ? 'bg-indigo-600 disabled:bg-indigo-300' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 disabled:cursor-default disabled:bg-gray-300'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </HeadlessSwitch>
    </HeadlessSwitch.Group>
  );
}
