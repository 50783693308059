import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

// import bellIcon from 'media/icons/bell.svg';
// import investmentPreferencesIcon from 'media/icons/coins-document-chart.svg';
import informationIcon from 'media/icons/information.svg';

const SettingsPage: FC = () => {
  const renderContent = () => {
    const tabs = [
      {
        key: 'general',
        label: 'General',
        description: 'Manage your account information',
        route: `/invest/settings`,
        icon: informationIcon,
        exact: true,
      },
      // {
      //   key: 'notifications',
      //   label: 'Notifications',
      //   description: 'Manage your notification preferences',
      //   route: `/invest/settings/notifications`,
      //   icon: bellIcon,
      // },
      // {
      //   key: 'investment-preferences',
      //   label: 'Investment Preferences',
      //   description: 'Manage your investment preferences',
      //   route: `/invest/settings/investment-preferences`,
      //   icon: investmentPreferencesIcon,
      // },
    ];

    return (
      <>
        <SidebarLayoutNavigation
          title="Settings"
          subTitle="Manage your account, security and notification preferences"
        />
        <SidebarLayoutContent secondaryTabs={tabs}>
          <Outlet />
        </SidebarLayoutContent>
      </>
    );
  };

  return <SidebarLayout>{renderContent()}</SidebarLayout>;
};

export default SettingsPage;
