import dayjs from 'dayjs';
import { FC } from 'react';

import constants from 'utils/constants';

const UserTitle: FC<{
  name: string;
  createdAt: string;
  image?: string | null;
  linkedInProfileUrl?: string | null;
}> = ({ name, createdAt, image, linkedInProfileUrl }) => {
  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-5">
      <div className="flex items-start space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <img
              className="h-12 w-12 rounded-full"
              src={image ?? constants.ALT_AVATAR_URL}
              alt=""
            />
            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
          </div>
        </div>
        <div>
          <h1 className="text-2xl font-bold text-gray-900">{name}</h1>
          <p className="text-sm font-medium text-gray-500">
            Accepted on{' '}
            <time dateTime={dayjs(createdAt).format('MMMM YYYY')}>
              {dayjs(createdAt).format('MMMM YYYY')}
            </time>
          </p>
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4">
        <span className="sm:block">
          {linkedInProfileUrl && (
            <a
              className="inline-flex items-center px-4 py-1.5 border border-transparent text-sm font-semibold rounded-md text-indigo-700 bg-white hover:bg-indigo-50 mr-2"
              target="_blank"
              href={linkedInProfileUrl}
              rel="noreferrer"
            >
              LinkedIn Profile
            </a>
          )}
        </span>
      </div>
    </div>
  );
};

export default UserTitle;
