import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { CurrencyRupeeIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { cloneElement } from 'react';

function formatValue(
  type: 'email' | 'password' | 'number' | 'text' | 'currency' | 'image_url',
  value?: string
) {
  if (!value) return '-';

  if (type === 'currency') {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
    }).format(Number(value));
  }

  if (type === 'password') return '•'.repeat(value.length);

  if (type === 'number') return new Intl.NumberFormat('en-IN').format(Number(value));

  if (type === 'image_url') return <img src={value} alt={value} height={50} />;

  return value;
}

export default function TextField({
  onChange,
  value,
  error,
  label,
  placeholder,
  type = 'text',
  fullWidth = false,
  helperText,
  leadingIcon,
  readOnly,
  uppercase,
}: {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  label?: string;
  error?: string;
  placeholder?: string;
  type?: 'email' | 'password' | 'number' | 'text' | 'currency' | 'image_url';
  fullWidth?: boolean;
  helperText?: string;
  leadingIcon?: any;
  readOnly?: boolean;
  uppercase?: boolean;
}) {
  const icon = type === 'currency' ? <CurrencyRupeeIcon /> : undefined;

  function content() {
    if (readOnly) {
      return (
        <div className="block py-2.5 text-gray-900 sm:text-sm">{formatValue(type, value)}</div>
      );
    }

    return (
      <div className="relative mt-1 rounded-md">
        {type === 'currency' && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {cloneElement(icon as React.ReactElement, {
              className: classNames('h-5 w-5', {
                'text-gray-400': !error,
                'text-red-500': error,
              }),
            })}
          </div>
        )}
        <input
          type={type}
          name={label}
          id={label}
          className={classNames(
            'block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6',
            {
              'w-full': fullWidth,
              'pr-10 ring-inset ring-red-300 focus:ring-red-600': error,
              'pl-10': leadingIcon,
              uppercase: uppercase,
            }
          )}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      {label && (
        <label htmlFor={label} className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      )}
      {content()}
      {helperText && !error && <p className="mt-2 text-sm text-gray-500">{helperText}</p>}
      {error && (
        <p className="mt-2 text-sm text-red-600" id="error">
          {error}
        </p>
      )}
    </div>
  );
}
