import { FundingRoundNameEnumType } from '__generated__/graphql';

export default function fundingRoundToReadable(round: FundingRoundNameEnumType): {
  label: string;
  color: string;
} {
  let label = '';

  if (!round) {
    return {
      label: 'Unknown',
      color: 'gray',
    };
  }

  function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  switch (round) {
    case FundingRoundNameEnumType.PreSeed:
      label = 'Pre-Seed';
      break;
    case FundingRoundNameEnumType.SeedPlus:
      label = 'Seed+';
      break;
    case FundingRoundNameEnumType.SeriesA:
      label = 'Series A';
      break;
    case FundingRoundNameEnumType.SeriesAPlus:
      label = 'Series A+';
      break;
    case FundingRoundNameEnumType.SeriesB:
      label = 'Seried B';
      break;
    case FundingRoundNameEnumType.SeriesBPlus:
      label = 'Series B+';
      break;
    case FundingRoundNameEnumType.SeriesC:
      label = 'Series C';
      break;
    case FundingRoundNameEnumType.SeriesCPlus:
      label = 'Series C+';
      break;
    case FundingRoundNameEnumType.SeriesD:
      label = 'Series D';
      break;
    case FundingRoundNameEnumType.SeriesDPlus:
      label = 'Series D+';
      break;
    case FundingRoundNameEnumType.SeriesE:
      label = 'Series E';
      break;
    case FundingRoundNameEnumType.SeriesEPlus:
      label = 'Series E+';
      break;
    case FundingRoundNameEnumType.SeriesF:
      label = 'Series F';
      break;
    case FundingRoundNameEnumType.SeriesFPlus:
      label = 'Series F+';
      break;
    default:
      label = capitalize(round.toLowerCase());
      break;
  }

  return {
    label,
    color: 'gray',
  };
}
