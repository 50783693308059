import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

type CreateConversationMutationResponse = {
  createConversation: {
    id: string;
  };
};

type CreateConversationMutationVariables = {
  syndicateId: string;
  message: string;
  receiverId?: string;
};

const CREATE_CONVERSATION_MUTATION = gql(`
  mutation CreateConversationFromSyndicateToUser($syndicateId: ID!, $message: String!, $receiverId: ID) {
    createConversation(syndicateId: $syndicateId, message: $message, receiverId: $receiverId) {
      id
    }
  }
`);

const MessageUserDialogButton: FC<{
  syndicateId: string;
  userId: string;
  onClose: () => void;
  showButton?: boolean;
}> = ({ syndicateId, onClose, userId, showButton = true }) => {
  const [open, setOpen] = useState<boolean>(!showButton ?? false);
  const navigate = useNavigate();

  const [createConversation, { loading, error }] = useMutation<
    CreateConversationMutationResponse,
    CreateConversationMutationVariables
  >(CREATE_CONVERSATION_MUTATION, {
    refetchQueries: ['SyndicateConversations'],
  });

  return (
    <>
      {showButton && <Button onClick={() => setOpen(true)}>Message Investor</Button>}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel>
          <DialogTitle>Message Investor</DialogTitle>
          <DialogSubTitle>
            This will create a private conversation between your syndicate and the investor.
          </DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onSubmit={data =>
              createConversation({
                variables: {
                  syndicateId: syndicateId,
                  receiverId: userId,
                  message: data.message,
                },
              }).then(response => {
                navigate(
                  `/syndicate-dashboard/${syndicateId}/conversations/${response.data?.createConversation.id}`
                );
                onClose();
                setOpen(false);
              })
            }
          >
            <FormInput fullWidth type="textarea" fieldName="message" label="" defaultValue="" />
          </FormPanel>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default MessageUserDialogButton;
