import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const PASS_DEAL_MUTATION = gql(`
  mutation PassDeal($dealId: ID!) {
    passDeal(id: $dealId) {
      id
    }
  }
`);

const PassDealDialogButton: FC<{
  dealId: string;
}> = ({ dealId }) => {
  const [passDeal, { loading, error }] = useMutation(PASS_DEAL_MUTATION, {
    variables: {
      dealId,
    },
    refetchQueries: ['getCommitmentsOfUserForDeal', 'GetDeal'],
  });

  return (
    <ConfirmButton
      className="w-full h-full"
      onConfirm={passDeal}
      loading={loading}
      error={error}
      title="Pass Deal"
      description="Are you sure you want to pass on this deal? You will have to contact the syndicate lead if you want to invest later."
      variant="secondary"
    >
      Pass Deal
    </ConfirmButton>
  );
};

export default PassDealDialogButton;
