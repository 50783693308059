import { ApolloError } from '@apollo/client';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { FC } from 'react';

import Alert from 'primitives/Alert';
import Button from 'primitives/Button';

const ErrorMessage: FC<{
  error?: ApolloError;
  title?: string;
  message?: string;
  refetch?: () => void;
  type?: 'standard' | 'alert';
}> = ({ error, title, message, refetch, type = 'standard' }) => {
  const checkIfInvalidOperation = () => {
    if (error && typeof error === 'object' && error.graphQLErrors) {
      const graphQLError = error.graphQLErrors[0];
      const code =
        graphQLError && graphQLError.extensions
          ? graphQLError.extensions.code
          : 'INTERNAL_SERVER_ERROR';
      return code === 'INVALID_OPERATION';
    }
    return false;
  };

  const getTitleFromGraphQLError = (): string | null => {
    if (error && typeof error === 'object' && error.graphQLErrors) {
      const graphQLError = error.graphQLErrors[0];
      return graphQLError && graphQLError.extensions
        ? (graphQLError.extensions.title as string | null)
        : null;
    }
    return null;
  };

  function getErrorMessage() {
    if (error && typeof error === 'object' && error.graphQLErrors) {
      const graphQLError = error.graphQLErrors[0];
      const code =
        graphQLError && graphQLError.extensions
          ? graphQLError.extensions.code
          : 'INTERNAL_SERVER_ERROR';
      switch (code) {
        case 'INTERNAL_SERVER_ERROR':
          return 'We are experiencing some technical difficulties. Please try again later.';
        case 'RESOURCE_NOT_FOUND':
          return 'The requested resource could not be found. Please try a different one.';
        case 'INVALID_OPERATION':
          return graphQLError.message ? graphQLError.message : 'An invalid operation was attempted';
        case 'BAD_USER_INPUT':
          return graphQLError.message
            ? graphQLError.message
            : 'An incorrect value was provided in this action';
        case 'UNAUTHENTICATED':
          return 'You need to be authenticated to perform this action. Please log in and try again.';
        case 'FORBIDDEN':
          return "You don't have the necessary permissions to perform this action";
        case 'GRAPHQL_PARSE_FAILED':
          return 'You seem to have encountered a bug. Please report this issue and our team will fix this for you right away.';
        case 'GRAPHQL_VALIDATION_FAILED':
          return 'You seem to have encountered a bug. Please report this issue and our team will fix this for you right away.';
        default:
          return 'There was an issue processing this request. Please try again later.';
      }
    }

    if (typeof error === 'string') {
      return error;
    }

    return 'An unknown error occurred. Please try again later.';
  }

  if (type === 'alert') {
    return (
      <Alert
        type="error"
        title={title || 'Uh oh! Something went wrong'}
        message={message || getErrorMessage()}
      />
    );
  }

  return (
    <div
      className={classNames(
        'my-2 min-h-96 w-full text-center flex flex-col justify-center rounded-lg p-4',
        checkIfInvalidOperation() ? 'bg-orange-50' : 'bg-red-50 '
      )}
    >
      <div
        className={classNames(
          'mx-auto h-24 w-24 p-4 rounded-full flex items-center justify-center',
          checkIfInvalidOperation() ? 'bg-orange-100' : 'bg-red-100'
        )}
      >
        <ExclamationTriangleIcon
          className={classNames(
            'h-16 w-16',
            checkIfInvalidOperation() ? 'text-orange-500' : 'text-red-500'
          )}
        />
      </div>
      <h2 className="mt-2 text-md lg:text-lg font-semibold leading-6 text-gray-900">
        {title || getTitleFromGraphQLError() || 'Uh oh! Something went wrong'}
      </h2>
      <p className="mt-1 text-sm lg:text-md text-gray-800">{message || getErrorMessage()}</p>
      <div className="mx-auto mt-6">
        {refetch && (
          <Button variant="secondary" onClick={refetch}>
            Retry
          </Button>
        )}
      </div>
    </div>
  );
};

export default ErrorMessage;
