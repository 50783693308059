import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';

const ADD_BANK_ACCOUNT_MUTATION = gql(`
  mutation AddBankAccount(
    $investmentEntityId: ID!
    $accountNumber: String!
    $accountHolderName: String!
    $bankName: String!
    $ifscCode: String!
    $accountType: BankAccountTypeEnumType!
  ) {
    addBankAccount(
      investmentEntityId: $investmentEntityId
      accountNumber: $accountNumber
      accountHolderName: $accountHolderName
      bankName: $bankName
      ifscCode: $ifscCode
      accountType: $accountType
    ) {
      id
      accountNumber
      accountHolderName
      bankName
      ifscCode
      accountType
      isVerified
    }
  }
`);

const AddBankAccountDialogButton: FC<{
  investmentEntityId: string;
}> = ({ investmentEntityId }) => {
  const [open, setOpen] = useState<boolean>(false);

  const [addBankAccount, { loading, error }] = useMutation(ADD_BANK_ACCOUNT_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: `InvestmentEntityType:${investmentEntityId}`,
        fields: {
          bankAccounts(existingBankAccounts) {
            const newAccountRef = cache.writeFragment({
              data: data.addBankAccount,
              fragment: gql(`
                fragment NewAccount on BankAccountType {
                  id
                  accountNumber
                  accountHolderName
                  bankName
                  ifscCode
                  accountType
                  isVerified
                }
              `),
            });

            return [...existingBankAccounts, newAccountRef];
          },
        },
      });
    },
  });

  return (
    <>
      <Button onClick={() => setOpen(true)}>Add bank account</Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogPanel>
          <DialogTitle>Add bank account</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onSubmit={data =>
              addBankAccount({
                variables: {
                  investmentEntityId,
                  accountNumber: data.accountNumber,
                  accountHolderName: data.accountHolderName,
                  bankName: data.bankName,
                  ifscCode: data.ifscCode,
                  accountType: data.accountType,
                },
              }).then(() => {
                setOpen(false);
              })
            }
          >
            <FormInput
              type="text"
              fieldName="accountHolderName"
              label="Account Holder Name"
              defaultValue={''}
              validators={{ required: true }}
            />
            <FormInput
              type="text"
              fieldName="accountNumber"
              label="Account Number"
              defaultValue={''}
              validators={{ required: true }}
            />
            <FormInput
              type="text"
              fieldName="ifscCode"
              label="IFSC Code"
              defaultValue={''}
              validators={{ required: true }}
            />
            <FormInput
              type="text"
              fieldName="bankName"
              label="Bank Name"
              defaultValue={''}
              validators={{ required: true }}
            />
            <FormInput
              type="radio-group"
              fieldName="accountType"
              label="Account Type"
              defaultValue={'SAVINGS'}
              options={[
                { label: 'Savings', value: 'SAVINGS' },
                { label: 'Current', value: 'CURRENT' },
              ]}
            />
          </FormPanel>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default AddBankAccountDialogButton;
