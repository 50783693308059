import { useState } from 'react';

const useTag = (maxTags = 10) => {
  const [tags, setTags] = useState<string[]>([]);

  const handleAddTag = (newTag: string) => {
    if (newTag && !tags.includes(newTag) && tags.length < maxTags) {
      setTags([...tags, newTag]);
    }
  };

  const handleRemoveTag = (tag: string) => setTags(tags.filter(t => t !== tag));

  const clearTags = () => setTags([]);

  return { tags, handleAddTag, handleRemoveTag, clearTags };
};

export default useTag;
