import { SyndicateTypeEnumType } from '__generated__/graphql';

export default function syndicateTypeToReadable(type: SyndicateTypeEnumType): {
  label: string;
  color: string;
} {
  switch (type) {
    case SyndicateTypeEnumType.Default:
      return {
        label: 'Created by User',
        color: 'gray',
      };
    case SyndicateTypeEnumType.Ruv:
      return {
        label: 'Created for RUV',
        color: 'gray',
      };
    default:
      return {
        label: type,
        color: 'gray',
      };
  }
}
