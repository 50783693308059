import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';

const CREATE_SYNDICATE_INVITE_LINK_MUTATION = gql(`
  mutation CreateSyndicateInviteLink(
    $syndicateId: ID!
    $numberOfInvestors: Int
    $note: String
  ) {
    createSyndicateInviteLink(
      syndicateId: $syndicateId
      numberOfInvestors: $numberOfInvestors
      note: $note
    ) {
      id
      numberOfInvestors
      note
      status
      createdAt
    }
  }
`);

const CreateSyndicateInviteLinkDialogButton: FC<{ syndicateId: string }> = ({ syndicateId }) => {
  const [open, setOpen] = useState(false);

  const [createSyndicateInviteLink, { loading, error }] = useMutation(
    CREATE_SYNDICATE_INVITE_LINK_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: cache.identify({
            __typename: 'SyndicateType',
            id: syndicateId,
          }),
          fields: {
            inviteLinks(existingInviteLinksRef) {
              const newInviteLinkNodeRef = cache.writeFragment({
                data: data.createSyndicateInviteLink,
                fragment: gql(`
                fragment NewInviteLink1 on SyndicateInviteLinkType {
                  id
                  status
                  numberOfInvestors
                  note
                  createdAt
                }
              `),
              });
              const updatedInviteLinksRef = [newInviteLinkNodeRef, ...existingInviteLinksRef];
              return updatedInviteLinksRef;
            },
          },
        });
      },
    }
  );

  return (
    <>
      <Button onClick={() => setOpen(true)}>Create Invite Link</Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel>
          <DialogTitle>Create Syndicate Invite Link</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => setOpen(false)}
            onSubmit={data =>
              createSyndicateInviteLink({
                variables: {
                  syndicateId,
                  numberOfInvestors: data.setNumberOfInvestors ? data.numberOfInvestors : undefined,
                  note: data.note,
                },
              }).then(() => setOpen(false))
            }
          >
            <FormInput
              type="switch"
              fieldName="setNumberOfInvestors"
              label="Set Number Of Investors"
              defaultValue={false}
            />
            <FormInput
              type="number"
              fieldName="numberOfInvestors"
              label="Number of Investors"
              defaultValue={1000}
              conditionsToShow={{
                matches: [
                  {
                    field: 'setNumberOfInvestors',
                    condition: '===',
                    value: 'true',
                  },
                ],
              }}
            />
            <FormInput type="textarea" fieldName="note" label="Note" defaultValue={''} />
          </FormPanel>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateSyndicateInviteLinkDialogButton;
