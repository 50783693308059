import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import useParentRoute from 'hooks/useParentRoute';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import Divider from 'primitives/Divider';
import LoadingIndicator from 'primitives/LoadingIndicator';

import countries from 'utils/countries.json';
import {
  GERMAN_STATES,
  INDIAN_STATES,
  SINGAPORE_STATES,
  UNITED_KINGDOM_STATES,
  US_STATES,
} from 'utils/states';

const KYC_QUERY = gql(`
  query Kyc($id: ID!) {
    investmentEntity(id: $id) {
      id
      kyc {
        id
        pan
        identityProofDocument {
          url
          name
        }
        addressProofDocument {
          url
          name
        }
        dateOfBirth
        address {
          id
          lineOne
          lineTwo
          city
          state
          pincode
          country
        }
      }
    }
  }
`);

const UPSERT_KYC_MUTATION = gql(`
  mutation UpsertKyc(
    $investmentEntityId: ID!
    $pan: String!
    $identityProofDocument: Upload
    $addressProofDocument: Upload
    $dateOfBirth: String!
    $lineOne: String!
    $lineTwo: String
    $city: String!
    $pincode: String!
    $state: String!
    $country: String!
  ) {
    upsertKyc(
      investmentEntityId: $investmentEntityId
      pan: $pan
      identityProofDocument: $identityProofDocument
      addressProofDocument: $addressProofDocument
      dateOfBirth: $dateOfBirth
      lineOne: $lineOne
      lineTwo: $lineTwo
      city: $city
      pincode: $pincode
      state: $state
      country: $country
    ) {
      id
      kyc {
        id
        pan
        identityProofDocument {
          url
          name
        }
        addressProofDocument {
          url
          name
        }
        dateOfBirth
        address {
          id
          lineOne
          lineTwo
          city
          state
          pincode
          country
        }
      }
    }
  }
`);

const Kyc: FC = () => {
  const navigate = useNavigate();
  const parentRoute = useParentRoute();

  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const { loading, error, data, refetch } = useQuery(KYC_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });

  const [upsertKyc, { loading: updateLoading, error: updateError }] =
    useMutation(UPSERT_KYC_MUTATION);

  if (loading) return <LoadingIndicator />;

  if (error || !data || !investmentEntityId)
    return <ErrorMessage error={error} refetch={refetch} />;
  const eighteenYearsAgo = dayjs().subtract(18, 'year');
  return (
    <FormPanel
      loading={updateLoading}
      error={error || updateError}
      onSubmit={formData => {
        if (!formData.IN && !formData.US && !formData.SG && !formData.GE && !formData.UK) return;
        upsertKyc({
          variables: {
            investmentEntityId,
            pan: formData.pan.toUpperCase(),
            identityProofDocument:
              data.investmentEntity.kyc?.identityProofDocument !== formData.identityProofDocument
                ? formData.identityProofDocument
                : undefined,
            addressProofDocument:
              data.investmentEntity.kyc?.addressProofDocument !== formData.addressProofDocument
                ? formData.addressProofDocument
                : undefined,
            dateOfBirth: formData.dateOfBirth,
            lineOne: formData.lineOne,
            lineTwo: formData.lineTwo,
            city: formData.city,
            state: formData[formData.country],
            pincode: formData.pincode,
            country: formData.country,
          },
        }).then(() => {
          navigate(`${parentRoute}/investor-application`);
        });
      }}
      onCancel={() => {
        navigate(`${parentRoute}/basic-information`);
      }}
      submitButtonLabel="Save and continue"
      cancelButtonLabel="Back"
    >
      <FormInput
        fieldName="pan"
        type="text"
        fullWidth
        defaultValue={(data?.investmentEntity?.kyc?.pan || '').toUpperCase()}
        label="PAN *"
        validators={{
          required: true,
          isPAN: true,
        }}
        span="3"
      />

      <FormInput
        fieldName="dateOfBirth"
        type="date"
        fullWidth
        defaultValue={
          data.investmentEntity.kyc?.dateOfBirth
            ? dayjs(data.investmentEntity.kyc?.dateOfBirth)
            : ''
        }
        label="Date of Birth *"
        maxDate={eighteenYearsAgo}
        validators={{
          required: true,
        }}
        span="3"
      />
      <Divider className="col-span-full" />
      <h2 className="font-bold">Address</h2>
      <FormInput
        fieldName="country"
        type="select"
        fullWidth
        defaultValue={data.investmentEntity.kyc?.address?.country || ''}
        label="Country"
        options={Object.keys(countries).map(country => ({
          label: countries[country],
          value: country,
        }))}
        validators={{
          required: true,
        }}
      />
      <FormInput
        fieldName="lineOne"
        type="text"
        fullWidth
        defaultValue={data.investmentEntity.kyc?.address?.lineOne || ''}
        label="Line One *"
        validators={{
          required: true,
          minLength: 1,
        }}
      />
      <FormInput
        fieldName="lineTwo"
        type="text"
        fullWidth
        defaultValue={data.investmentEntity.kyc?.address?.lineTwo || ''}
        label="Line Two"
      />
      <FormInput
        fieldName="city"
        type="text"
        fullWidth
        defaultValue={data.investmentEntity.kyc?.address?.city || ''}
        label="City *"
        validators={{
          required: true,
          isCity: true,
        }}
      />
      <FormInput
        fieldName="IN"
        type="select"
        fullWidth
        defaultValue={data.investmentEntity.kyc?.address?.state || ''}
        label="State *"
        validators={{
          required: true,
        }}
        options={Object.keys(INDIAN_STATES).map(state => ({
          label: INDIAN_STATES[state],
          value: state,
        }))}
        conditionsToShow={{
          matches: [
            {
              field: 'country',
              condition: '===',
              value: 'IN',
            },
          ],
        }}
      />
      <FormInput
        fieldName="US"
        type="select"
        fullWidth
        defaultValue={data.investmentEntity.kyc?.address?.state || ''}
        label="State *"
        validators={{
          required: true,
        }}
        options={Object.keys(US_STATES).map(state => ({
          label: US_STATES[state],
          value: state,
        }))}
        conditionsToShow={{
          matches: [
            {
              field: 'country',
              condition: '===',
              value: 'US',
            },
          ],
        }}
      />
      <FormInput
        fieldName="GE"
        type="select"
        fullWidth
        defaultValue={data.investmentEntity.kyc?.address?.state || ''}
        label="State *"
        validators={{
          required: true,
        }}
        options={Object.keys(GERMAN_STATES).map(state => ({
          label: GERMAN_STATES[state],
          value: state,
        }))}
        conditionsToShow={{
          matches: [
            {
              field: 'country',
              condition: '===',
              value: 'GE',
            },
          ],
        }}
      />
      <FormInput
        fieldName="SG"
        type="select"
        fullWidth
        defaultValue={data.investmentEntity.kyc?.address?.state || ''}
        label="State *"
        validators={{
          required: true,
        }}
        options={Object.keys(SINGAPORE_STATES).map(state => ({
          label: SINGAPORE_STATES[state],
          value: state,
        }))}
        conditionsToShow={{
          matches: [
            {
              field: 'country',
              condition: '===',
              value: 'SG',
            },
          ],
        }}
      />
      <FormInput
        fieldName="UK"
        type="select"
        fullWidth
        defaultValue={data.investmentEntity.kyc?.address?.state || ''}
        label="State *"
        validators={{
          required: true,
        }}
        options={Object.keys(UNITED_KINGDOM_STATES).map(state => ({
          label: UNITED_KINGDOM_STATES[state],
          value: state,
        }))}
        conditionsToShow={{
          matches: [
            {
              field: 'country',
              condition: '===',
              value: 'UK',
            },
          ],
        }}
      />
      <FormInput
        fieldName="pincode"
        type="text"
        fullWidth
        defaultValue={data.investmentEntity.kyc?.address?.pincode || ''}
        label="Pincode *"
        validators={{
          required: true,
          isPincode: true,
        }}
      />
      <Divider className="col-span-full" />
      <FormInput
        type="upload"
        fieldName="identityProofDocument"
        label="Upload Aadhaar Card *"
        defaultValue={data.investmentEntity.kyc?.identityProofDocument || ''}
        validators={{
          required: true,
        }}
        span="3"
        maxFileSize={10}
      />
      <FormInput
        type="upload"
        fieldName="addressProofDocument"
        label="Upload PAN Card *"
        defaultValue={data.investmentEntity.kyc?.addressProofDocument || ''}
        validators={{
          required: true,
        }}
        span="3"
        maxFileSize={10}
      />
    </FormPanel>
  );
};

export default Kyc;
