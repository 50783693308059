import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import RecipientsPanel from 'components/Conversation/RecipientsPanel';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import SearchSyndicateUsersForConversation from './SearchSyndicateUserForConversation';

const SYNDICATE_CONVERSATIONS_QUERY = gql(`
  query SyndicateConversations($id: ID!) {
    syndicate(id: $id) {
      id
      conversations {
        nodes {
          id
          updatedAt
          users {
            id
            name
          }
        }
      }
    }
  }
`);

const SyndicateConversations: FC = () => {
  const navigate = useNavigate();
  const { syndicateId } = useParams<{ syndicateId: string }>() as { syndicateId: string };

  const { loading, error, data, refetch } = useQuery(SYNDICATE_CONVERSATIONS_QUERY, {
    variables: {
      id: syndicateId,
    },
  });

  const { conversationId } = useParams() as { conversationId: string };

  useEffect(() => {
    if (!conversationId && data?.syndicate.conversations.nodes.length) {
      const latestConversation = data.syndicate.conversations.nodes[0];
      navigate(`/syndicate-dashboard/${syndicateId}/conversations/${latestConversation.id}`);
    }
  }, [conversationId, data, navigate, syndicateId]);

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const conversations = data.syndicate.conversations.nodes;

    return (
      <div className="grid grid-cols-6 border border-gray-100 rounded-lg">
        <div
          className="col-span-2 overflow-auto"
          style={{
            height: 'calc(100vh - 8rem)',
          }}
        >
          <div className="p-3 sticky">
            <SearchSyndicateUsersForConversation syndicateId={syndicateId} />
          </div>
          <RecipientsPanel syndicateId={syndicateId} conversations={conversations} />
        </div>
        <div
          className="col-span-4"
          style={{
            height: 'calc(100vh - 8rem)',
          }}
        >
          {conversations.length === 0 ? <div className="bg-gray-100 h-full" /> : <Outlet />}
        </div>
      </div>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Syndicate Conversations"
        subTitle="View conversations with LPs here"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default SyndicateConversations;
