import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef } from 'react';

import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

const UPDATE_EXTERNAL_INVESTOR_MUTATION = gql(`
  mutation UpdateDealExternalInvestor(
    $id: ID!
    $name: String!
    $description: String
    $amount: Float!
    $isLead: Boolean!
  ) {
    updateDealExternalInvestor(
      id: $id
      name: $name
      description: $description
      amount: $amount
      isLead: $isLead
    ) {
      id
      name
      description
      amount
      isLead
    }
  }
`);

const UpdateDealExternalInvestor: FC<{
  id: string;
  name: string;
  description?: string | null;
  amount?: number | null;
  isLead: boolean;
  onClose: () => void;
}> = ({ id, name, description, amount, isLead, onClose }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [updateDealExternalInvestor, { loading, error }] = useMutation(
    UPDATE_EXTERNAL_INVESTOR_MUTATION
  );

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogPanel>
        <DialogTitle>Update external investor</DialogTitle>
        <FormPanel
          loading={loading}
          error={error}
          onCancel={onClose}
          onSubmit={data => {
            updateDealExternalInvestor({
              variables: {
                id,
                name: data.name,
                description: data.description,
                amount: data.amount,
                isLead: data.isLead === 'true',
              },
            }).then(onClose);
          }}
          buttonRef={buttonRef}
        >
          <FormInput fullWidth type="text" fieldName="name" label="Name" defaultValue={name} />
          <FormInput
            fullWidth
            type="text"
            fieldName="description"
            label="Description"
            defaultValue={description}
          />
          <FormInput
            fullWidth
            type="currency"
            fieldName="amount"
            label="Amount"
            defaultValue={amount}
          />
          <FormInput
            fullWidth
            type="radio-group"
            fieldName="isLead"
            label="Is Lead"
            defaultValue={isLead.toString()}
            options={[
              { value: 'true', label: 'Yes' },
              { value: 'false', label: 'No' },
            ]}
          />
        </FormPanel>
        <DialogActions>
          <Button
            variant="secondary"
            onClick={() => {
              // @ts-ignore
              buttonRef.current?.cancel();
            }}
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              // @ts-ignore
              buttonRef.current?.submit();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </DialogPanel>
    </Dialog>
  );
};

export default UpdateDealExternalInvestor;
