import ProtectedRoute from 'components/ProtectedRoute';

import AuthenticateOTP from './AuthenticateOTP';
import InactiveUser from './InactiveUser';
import Login from './Login';
import LoginAsUser from './LoginAsUser';
import LoginWithPassword from './LoginWithPassword';
import ResetPassword from './ResetPassword';
import Signup from './Signup';
import VerifyEmailPage from './Signup/VerifyEmail';
import SuspendedUser from './SuspendedUser';
import UnderReviewUser from './UnderReviewUser';

const routes = [
  {
    path: '/login-with-password',
    element: <LoginWithPassword />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/login-as',
    element: <LoginAsUser />,
  },
  {
    path: '/authenticate-otp',
    element: <AuthenticateOTP />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/verify-email',
    element: <VerifyEmailPage />,
  },
  {
    path: '/suspended-user',
    element: <SuspendedUser />,
  },
  {
    path: '/inactive-user',
    element: <InactiveUser />,
  },
  {
    path: 'under-review-user',
    element: (
      <ProtectedRoute>
        <UnderReviewUser />
      </ProtectedRoute>
    ),
  },
];

export default routes;
