import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';

const MonthlyDealsChart = ({ data }) => {
  const dataWithMonthAbbreviated = data.map(stat => ({
    ...stat,
    month: stat.month[0],
  }));

  const renderCustomizedLabel = props => {
    const { x, y, width, value } = props;
    // Adjust these offsets as needed to position the label correctly
    const offsetX = width / 2;
    const offsetY = 8;

    return value > 0 ? (
      <text
        x={x + offsetX}
        y={y - offsetY}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={12}
      >
        {value}
      </text>
    ) : null;
  };

  return (
    <ResponsiveContainer width="100%" height={160}>
      <BarChart
        data={dataWithMonthAbbreviated}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <XAxis axisLine={false} tickLine={false} dataKey="month" fontSize={10} />
        <YAxis hide={true} domain={[0, 'dataMax + 0.2']} />
        <Bar dataKey="count" fill="#4f46e5" minPointSize={2}>
          <LabelList content={renderCustomizedLabel} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MonthlyDealsChart;
