import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

export default function Select({
  options,
  onChange,
  value,
  error,
  label,
  placeholder,
  fullWidth = false,
  helperText,
  readOnly,
}: {
  options: { value: string; label: string; color?: string }[];
  onChange: (e: string) => void;
  value?: string;
  label?: string;
  error?: string;
  placeholder?: string;
  fullWidth?: boolean;
  helperText?: string;
  readOnly?: boolean;
}) {
  const [selected, setSelected] = useState(options.find(o => o.value === value));

  useEffect(() => {
    setSelected(options.find(o => o.value === value));
  }, [value, options]);

  if (readOnly) {
    const option = options?.find(o => o.value === value);
    return (
      <div>
        <span className="block text-sm font-medium leading-6 text-gray-900 pt-1.5 pb-1">
          {label}
        </span>
        <span>{option ? option.label : '-'}</span>
        {helperText && !error && <p className="mt-2 text-sm text-gray-500">{helperText}</p>}
      </div>
    );
  }

  return (
    <>
      <span className="block text-sm font-medium leading-6 text-gray-900 mb-1">{label}</span>
      <Listbox
        value={selected?.value}
        onChange={value => {
          const option = options.find(o => o.value === value);
          onChange(value);
          setSelected(option);
        }}
      >
        <ListboxButton
          className={classNames(
            'relative block rounded-lg border border-gray-300 bg-white py-1.5 pr-8 pl-3 text-left text-sm/6 text-black',
            {
              'w-full': fullWidth,
              'ring-1 ring-red-300 focus:ring-red-600': error,
            }
          )}
        >
          <span className="flex items-center">
            {selected?.color && (
              <span
                aria-label={selected.color ? 'Online' : 'Offline'}
                className={classNames(
                  selected.color,
                  'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                )}
              />
            )}
            {selected?.label ? (
              <span className={classNames('block truncate', { 'ml-3': selected.color })}>
                {selected.label}
              </span>
            ) : (
              <span className="block text-gray-400">
                {placeholder ? placeholder : `Select ${label}`}
              </span>
            )}
          </span>
          <ChevronDownIcon
            className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-black"
            aria-hidden="true"
          />
        </ListboxButton>
        {helperText && !error && <p className="mt-1 text-sm text-gray-500">{helperText}</p>}
        {error && (
          <p className="mt-2 text-sm text-red-600" id="error">
            {error}
          </p>
        )}
        <ListboxOptions
          anchor="bottom"
          transition
          className={classNames(
            'w-[var(--button-width)] rounded-lg border border-gray-300 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
            'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0 z-50 mt-2'
          )}
        >
          {options.map((option, idx) => (
            <ListboxOption
              key={option.label + idx}
              value={option.value}
              className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-gray-50"
            >
              <CheckIcon className="invisible size-4 fill-black group-data-[selected]:visible" />
              <div className="flex items-center">
                {option.color && (
                  <span
                    className={classNames(
                      option.color,
                      'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                    )}
                  />
                )}
                <span
                  className={classNames(
                    selected?.value === option.value ? 'font-semibold' : 'font-normal',
                    option.color ? 'ml-3' : null,
                    'block truncate text-md'
                  )}
                >
                  {option.label}
                </span>
              </div>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Listbox>
    </>
  );
}
