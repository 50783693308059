import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import AuthLayout from 'layouts/AuthLayout';

import AuthFooter from 'components/AuthFooter';
import { FormInput, FormPanel } from 'components/FormPanel';

import Alert from 'primitives/Alert';
import Button from 'primitives/Button';
import Divider from 'primitives/Divider';

const SIGNUP_MUTATION = gql(`
  mutation SignUp($email: String!, $name: String!) {
    signup(email: $email, name: $name)
  }
`);

const Signup: FC = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const [signup, { error, loading }] = useMutation(SIGNUP_MUTATION);

  return (
    <AuthLayout title={'Create an account'}>
      <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
        <FormPanel
          loading={loading}
          error={error}
          onSubmit={formData => {
            signup({
              variables: {
                email: formData.email,
                name: formData.name,
              },
            }).then(({ data }) => {
              if (data?.signup) {
                navigate(`/authenticate-otp?email=${encodeURIComponent(formData.email)}`);
              }
            });
          }}
          buttonRef={buttonRef}
        >
          <FormInput
            fieldName="name"
            type="text"
            defaultValue=""
            label="Full Name *"
            fullWidth
            validators={{
              required: true,
              isAlphaNumeric: true,
              maxLength: 255,
              minLength: 2,
            }}
          />
          <FormInput
            fieldName="email"
            type="text"
            defaultValue=""
            label="Email *"
            fullWidth
            validators={{
              required: true,
              isEmail: true,
              maxLength: 255,
            }}
          />
        </FormPanel>
        <div className="mt-6">
          <Button
            loading={loading}
            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              // @ts-ignore
              buttonRef.current?.submit();
            }}
          >
            Create
          </Button>
          <div className="mt-6">
            <Divider />
            <Alert
              type="info"
              title="How is AngelList India different from AngelList?"
              message="We are a separate platform focused on the Indian startup ecosystem but part of the same AngelList family."
              buttonText="Read more"
              onClick={() =>
                window.open(
                  'https://medium.com/@AngelListIndia/introducing-a-new-platform-for-india-854cc8b909a4',
                  '_blank'
                )
              }
            />
          </div>
        </div>
      </div>

      <p className="mt-10 text-center text-sm text-gray-500">
        Already have an account?{' '}
        <NavLink
          to="/login"
          className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
        >
          Login
        </NavLink>
      </p>
      <AuthFooter styles="mt-4" />
    </AuthLayout>
  );
};

export default Signup;
