import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { PostStatusEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';
import htmlTruncate from 'html-truncate';

import Badge from 'primitives/Badge';

import constants from 'utils/constants';

const PostCard = ({
  id,
  title,
  message,
  createdAt,
  publishedAt,
  deal,
  sender,
  syndicate,
  onClick,
  status,
}: {
  id: string;
  title: string;
  message: string;
  createdAt: string;
  publishedAt?: string | null;
  deal?: {
    companyInformation: {
      company: {
        name: string;
      };
    };
  } | null;
  sender: {
    name: string;
  };
  syndicate: {
    name: string;
    image?: string | null;
  };
  onClick: (id: string) => void;
  status?: PostStatusEnumType;
}) => {
  return (
    <div
      className="focus:outline-none relative flex justify-between gap-x-6 border border-gray-200 shadow-sm rounded-lg hover:bg-gray-50 cursor-pointer"
      onClick={() => onClick(id)}
    >
      <div className="p-4 flex-1">
        <div className="flex-1">
          <div className="flex gap-x-2">
            {status === PostStatusEnumType.Draft && <Badge label="Draft" color="gray" />}
            {deal && <Badge label={`Deal: ${deal.companyInformation.company.name}`} color="sky" />}
          </div>
          <div className="text-md font-semibold text-gray-900 sm:flex sm:justify-between mt-1">
            <h5>{title}</h5>
          </div>
          <div
            className="text-gray-600 text-sm sm:mt-2"
            dangerouslySetInnerHTML={{ __html: htmlTruncate(message, 200) }}
          />
        </div>

        <div className="mt-6 sm:flex sm:justify-between border-t border-gray-100 pt-2">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img
                alt=""
                src={syndicate.image || constants.ALT_AVATAR_URL}
                className="h-8 w-8 rounded-full"
              />
            </div>
            <div className="ml-2">
              <h3 className="text-sm font-semibold leading-5 text-gray-900">{syndicate.name}</h3>
              <p className="text-xs text-gray-500">{sender.name}</p>
            </div>
          </div>
          <div className="flex items-center">
            {publishedAt ? (
              <>
                <CheckCircleIcon aria-hidden="true" className="h-4 w-4 mr-1 text-green-500" />
                <p className="text-xs text-gray-500">
                  Sent at {dayjs(publishedAt).format('MMMM DD, YYYY hh:mm A')}
                </p>
              </>
            ) : (
              <p className="text-xs text-gray-500">
                {dayjs(createdAt).format('MMMM DD, YYYY hh:mm A')}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-x-4">
        <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
      </div>
    </div>
  );
};

export default PostCard;
