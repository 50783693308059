import { FinancialInstrumentEnumType } from '__generated__/graphql';

export default function financialInstrumentToReadable(instrument: FinancialInstrumentEnumType): {
  label: string;
  color: string;
} {
  switch (instrument) {
    case FinancialInstrumentEnumType.ConvertibleNote:
      return {
        label: 'Convertible Note',
        color: 'purple',
      };
    case FinancialInstrumentEnumType.Safe:
      return {
        label: 'SAFE',
        color: 'green',
      };
    case FinancialInstrumentEnumType.Equity:
      return {
        label: 'Equity',
        color: 'amber',
      };
    case FinancialInstrumentEnumType.Debt:
      return {
        label: 'Debt',
        color: 'blue',
      };
    default:
      return {
        label: instrument,
        color: 'gray',
      };
  }
}
