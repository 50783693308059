import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';

const UNBLOCK_USER_FROM_DEAL_MUTATION = gql(`
  mutation UnblockUserFromDeal($id: ID!, $userId: ID!) {
    unblockUserFromDeal(id: $id, userId: $userId) {
      id
    }
  }
`);

const UnblockUserDialogButton: FC<{
  id: string;
  userId: string;
  open: boolean;
  setOpen: (boolean) => void;
}> = ({ id, userId, open, setOpen }) => {
  const { dealId } = useParams<{ dealId: string }>() as {
    dealId: string;
  };

  const [unblockUser] = useMutation(UNBLOCK_USER_FROM_DEAL_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          __typename: 'DealType',
          id: dealId,
        }),
        fields: {
          blockedUserList(existingBlockedUsersRef, { readField }) {
            return existingBlockedUsersRef.filter(
              existingBlockedUserRef => id !== readField('id', existingBlockedUserRef)
            );
          },
        },
      });
    },
  });

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel>
          <DialogTitle>Are you sure you want to unblock this user?</DialogTitle>
          <Button
            onClick={() =>
              unblockUser({
                variables: {
                  id: dealId,
                  userId,
                },
              }).then(() => setOpen(false))
            }
          >
            Unblock user
          </Button>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default UnblockUserDialogButton;
