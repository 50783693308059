import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

const REMOVE_LEAD_FROM_SYNDICATE_MUTATION = gql(`
  mutation RemoveLeadFromSyndicate($syndicateLeadId: ID!) {
    removeLeadFromSyndicate(syndicateLeadId: $syndicateLeadId) {
      id
      syndicate {
        id
      }
    }
  }
`);

const RemoveLeadFromSyndicateDialogButton: FC<{
  syndicateLeadId: string;
  onClose: () => void;
}> = ({ syndicateLeadId, onClose }) => {
  const [showConfirmDialog, toggleConfirmDialog] = useState(false);

  const [removeLead, { loading, error }] = useMutation(REMOVE_LEAD_FROM_SYNDICATE_MUTATION, {
    variables: {
      syndicateLeadId,
    },
    update(cache, { data }) {
      if (!data) return;
      const { removeLeadFromSyndicate } = data;
      cache.modify({
        id: `SyndicateType:${removeLeadFromSyndicate.syndicate.id}`,
        fields: {
          syndicateLeads(existingLeadsRefs, { readField }) {
            return existingLeadsRefs.filter(
              leadRef => removeLeadFromSyndicate.id !== readField('id', leadRef)
            );
          },
        },
      });
    },
  });

  return (
    <>
      <Button onClick={() => toggleConfirmDialog(true)}>Remove Lead</Button>
      <Dialog open={showConfirmDialog} onClose={() => toggleConfirmDialog(false)}>
        <DialogPanel>
          <DialogTitle>Are you sure?</DialogTitle>
          You will have to send the lead another invite for them to rejoin
          {error && <ErrorMessage error={error} type="alert" />}
          <DialogActions>
            <Button onClick={() => toggleConfirmDialog(false)}>Cancel</Button>
            <Button
              loading={loading}
              onClick={() =>
                removeLead().then(() => {
                  toggleConfirmDialog(false);
                  onClose();
                })
              }
            >
              Remove Lead
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default RemoveLeadFromSyndicateDialogButton;
