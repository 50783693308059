import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_SYNDICATE_LEAD_ROLE_MUTATION = gql(`
  mutation UpdateSyndicateLeadRole($syndicateLeadId: ID!, $role: SyndicateLeadRoleEnumType!) {
    updateSyndicateLeadRole(id: $syndicateLeadId, role: $role) {
      id
      role
    }
  }
`);

const UpdateSyndicateLeadRole: FC<{
  syndicateLeadId: string;
  role: string;
}> = ({ syndicateLeadId, role }) => {
  const [updateSyndicateLeadRole, { loading, error }] = useMutation(
    UPDATE_SYNDICATE_LEAD_ROLE_MUTATION
  );

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      title="Update Syndicate Lead Role"
      onSubmit={data =>
        updateSyndicateLeadRole({
          variables: {
            syndicateLeadId,
            role: data.role,
          },
        })
      }
    >
      <FormInput
        label="Role"
        fieldName="role"
        type="radio-group"
        defaultValue={role}
        options={[
          { label: 'Owner', value: 'OWNER' },
          { label: 'Admin', value: 'ADMIN' },
          { label: 'Carry Recipient', value: 'CARRY_RECIPIENT' },
        ]}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateSyndicateLeadRole;
