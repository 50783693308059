import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FocusLayout from 'layouts/FocusLayout';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

const VERIFY_OLD_PLATFORM_SYNDICATE_INVITE_LINK_MUTATION = gql(`
  mutation VerifyOldPlatformSyndicateInviteLink($id: String!) {
    verifyOldPlatformSyndicateInviteLink(id: $id) {
      id
    }
  }
`);

const OldPlatformSyndicateInviteLinkPage: FC = () => {
  const navigate = useNavigate();
  const { syndicateInviteLinkId } = useParams<{ syndicateInviteLinkId: string }>() as {
    syndicateInviteLinkId: string;
  };

  const [verifyOldPlatformSyndicateInviteLink, { loading, error, data }] = useMutation(
    VERIFY_OLD_PLATFORM_SYNDICATE_INVITE_LINK_MUTATION,
    {
      variables: {
        id: syndicateInviteLinkId,
      },
    }
  );

  useEffect(() => {
    verifyOldPlatformSyndicateInviteLink().then(data => {
      if (!data.data?.verifyOldPlatformSyndicateInviteLink?.id) return;
      navigate(`/syndicate-invites/${data.data.verifyOldPlatformSyndicateInviteLink.id}`);
    });
  }, [navigate, verifyOldPlatformSyndicateInviteLink]);

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} />;

    return <div>{data && <p>Redirecting to syndicate ...</p>}</div>;
  };

  return <FocusLayout>{renderContent()}</FocusLayout>;
};

export default OldPlatformSyndicateInviteLinkPage;
