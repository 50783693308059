import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ClosingStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';
import TextField from 'primitives/TextField';

import debounce from 'utils/debounce';

const CLOSINGS_QUERY = gql(`
  query getCompletedCommitmentsOfUser(
    $id: ID!
    $cursor: ID
    $limit: Int
    $filters: DashboardClosingsFilterType
  ) {
    investmentEntity(id: $id) {
      id
      statistics {
        totalInvestedAmount
        totalRealizedValue
        totalUnrealizedValue
      }
    }
    dashboard {
      id
      closings(cursor: $cursor, limit: $limit, filters: $filters) {
        nodes {
          id
          deal {
            id
            companyInformation {
              id
              company {
                id
                image
                name
              }
            }
          }
          investmentEntity {
            id
            name
          }
          amount
          status
          wiredAt
          statistics {
            id
            netValue
            multipleOnInvestment
          }
        }
        pageInfo {
          hasNextPage
          cursor
          totalCount
        }
      }
    }
  }
`);

const CommitmentsOfUser: FC<{ investmentEntityId: string }> = ({ investmentEntityId }) => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore, variables, networkStatus } = useQuery(
    CLOSINGS_QUERY,
    {
      variables: {
        filters: {
          statuses: [ClosingStatusEnumType.Active],
          investmentEntityId,
        },
        limit: 20,
        id: investmentEntityId,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const closings = data.dashboard.closings.nodes;
  const pageInfo = data.dashboard.closings.pageInfo;

  return (
    <div>
      <TextField
        placeholder="Search for a company"
        onChange={debounce(e => {
          refetch({
            filters: {
              ...variables?.filters,
              text: e.target.value,
            },
          });
        }, 500)}
      />

      <DataTable
        aggregatedData={{
          'deal.companyInformation.company.name': `${
            data.dashboard.closings.pageInfo.totalCount
          } Investment${data.dashboard.closings.pageInfo.totalCount > 1 ? 's' : ''}`,
          amount: data.investmentEntity.statistics.totalInvestedAmount,
          'statistics.netValue': data.investmentEntity.statistics.totalUnrealizedValue,
        }}
        data={closings}
        onClick={closing => navigate(`/invest/deal/${closing.deal.id}`)}
        emptyListTitle="Your haven't made any investments yet"
        columns={[
          {
            label: 'Company',
            fieldName: 'deal.companyInformation.company',
            type: 'COMPANY',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Investment Entity',
            fieldName: 'investmentEntity.name',
          },
          {
            label: 'Invested',
            fieldName: 'amount',
            type: 'CURRENCY',
          },
          {
            label: 'Invest Date',
            fieldName: 'wiredAt',
            type: 'DATE',
          },
          {
            label: 'Net Value',
            fieldName: 'statistics.netValue',
            type: 'CURRENCY',
          },
          {
            label: 'Multiple',
            fieldName: 'statistics.multipleOnInvestment',
            type: 'MULTIPLE',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        filterLoading={networkStatus === NetworkStatus.setVariables}
        paginationLoading={loading}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </div>
  );
};

export default CommitmentsOfUser;
