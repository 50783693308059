import { useLazyQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { StepperLayoutTabs } from 'layouts/CreateStepperLayout';

const GET_INVESTMENT_ENTITY_QUERY = gql(`
  query GetInvestmentEntityTabs($id: ID!) {
    investmentEntity(id: $id) {
      id
      kyc {
        id
      }
      investorApplication {
        id
      }
    }
  }
`);

const InvestmentEntityTabs: FC = () => {
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>();

  const [getInvestmentEntity, { data }] = useLazyQuery(GET_INVESTMENT_ENTITY_QUERY);

  useEffect(() => {
    if (!investmentEntityId) return;

    getInvestmentEntity({ variables: { id: investmentEntityId } });
  }, [investmentEntityId, getInvestmentEntity]);

  const baseUrl = '/invest/investment-entities/create';

  const tabs = [
    {
      name: 'Create Entity',
      matchingUrls: [baseUrl, `${baseUrl}/${investmentEntityId}/basic-information`],
      defaultUrl: `${baseUrl}/${investmentEntityId}/basic-information`,
      title: 'Create an investment entity',
      subTitle: 'An investment entity is a legal entity that can invest in deals and funds',
      conditionForComplete: !!data?.investmentEntity,
      index: true,
    },
    {
      name: 'KYC',
      matchingUrls: [`${baseUrl}/${investmentEntityId}/kyc`],
      defaultUrl: `${baseUrl}/${investmentEntityId}/kyc`,
      title: 'KYC',
      subTitle: 'Regulations require us to verify your identity',
      conditionForComplete: !!data?.investmentEntity?.kyc,
    },
    {
      name: 'Experience',
      matchingUrls: [`${baseUrl}/${investmentEntityId}/investor-application`],
      defaultUrl: `${baseUrl}/${investmentEntityId}/investor-application`,
      title: 'Investing Experience',
      subTitle: 'Angel investing in India requires a certain level of experience',
      conditionForComplete: !!data?.investmentEntity?.investorApplication,
    },
    {
      name: 'Review',
      matchingUrls: [`${baseUrl}/${investmentEntityId}/submit-for-review`],
      defaultUrl: `${baseUrl}/${investmentEntityId}/submit-for-review`,
      title: 'Submit for review',
      subTitle: 'Review your details before submitting for review',
      conditionForComplete: undefined,
    },
  ];

  return <StepperLayoutTabs tabs={tabs} />;
};

export default InvestmentEntityTabs;
