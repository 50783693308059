import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

import SelectSyndicateLead from './SelectSyndicateLead';

const UPDATE_CARRY_RECIPIENT_MUTATION = gql(`
  mutation UpdateDealCarryRecipient($id: ID!, $investmentEntityId: ID!, $percentage: Float!) {
    updateDealCarryRecipient(
      id: $id
      investmentEntityId: $investmentEntityId
      percentage: $percentage
    ) {
      id
      percentage
      investmentEntity {
        id
        user {
          id
          name
        }
      }
    }
  }
`);

const UpdateDealCarryRecipient: FC<{
  id: string;
  percentage: number;
  investmentEntityId?: string | null;
  totalLeadCarryPercentage: number;
  syndicateLeads: {
    id: string;
    user: {
      id: string;
      name: string;
      investmentEntities?:
        | {
            id: string;
            name: string;
          }[]
        | null;
    };
  }[];
  onClose: () => void;
}> = ({
  id,
  percentage,
  investmentEntityId,
  syndicateLeads,
  totalLeadCarryPercentage,
  onClose,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [updateDealCarryRecipient, { loading, error }] = useMutation(
    UPDATE_CARRY_RECIPIENT_MUTATION
  );

  const syndicateLeadIdOfInvestmentEntity = syndicateLeads.find(syndicateLead =>
    syndicateLead.user.investmentEntities?.find(entity => entity.id === investmentEntityId)
  )?.id;

  const [syndicateLeadId, setSyndicateLeadId] = useState<string | null>(
    syndicateLeadIdOfInvestmentEntity || null
  );

  function renderForm() {
    if (!syndicateLeadId) return <div className="h-40 w-96" />;

    const syndicateLead = syndicateLeads.find(
      syndicateLead => syndicateLead.id === syndicateLeadId
    );

    if (!syndicateLead) {
      // This should never happen because syndicateLeadId is always set from the list of syndicateLeads
      throw new Error('Syndicate lead not found');
    }

    const user = syndicateLead.user;
    const investmentEntities = user.investmentEntities;

    if (!investmentEntities || investmentEntities.length === 0)
      return (
        <ErrorMessage
          title="No investment entities found"
          message="Carry can only be assigned to Syndicate Leads with active investment entities. Please contact the Syndicate Lead to add an investment entity."
        />
      );

    return (
      <FormPanel
        loading={loading}
        error={error}
        onCancel={onClose}
        onSubmit={data => {
          updateDealCarryRecipient({
            variables: {
              id,
              investmentEntityId: data.investmentEntityId,
              percentage: data.percentage,
            },
          }).then(onClose);
        }}
        buttonRef={buttonRef}
      >
        <FormInput
          fullWidth
          type="number"
          fieldName="percentage"
          label="Carry Points"
          defaultValue={percentage}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="investmentEntityId"
          label="Investment Entity"
          defaultValue={investmentEntityId}
          options={investmentEntities.map(investmentEntity => ({
            label: investmentEntity.name,
            value: investmentEntity.id,
          }))}
        />
      </FormPanel>
    );
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogPanel>
        <DialogTitle>Update Deal Carry Recipient</DialogTitle>
        <SelectSyndicateLead
          syndicateLeads={syndicateLeads}
          onSelect={syndicateLeadId => {
            setSyndicateLeadId(syndicateLeadId);
            // @ts-ignore
            buttonRef.current?.reset();
          }}
          value={syndicateLeadIdOfInvestmentEntity}
          readOnly={!!syndicateLeadIdOfInvestmentEntity}
        />
        {renderForm()}
        <DialogActions>
          <Button
            variant="secondary"
            onClick={() => {
              // @ts-ignore
              buttonRef.current?.cancel();
            }}
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              // @ts-ignore
              buttonRef.current?.submit();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </DialogPanel>
    </Dialog>
  );
};

export default UpdateDealCarryRecipient;
