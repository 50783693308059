import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';

import SearchSyndicateUsers from './SearchSyndicateUsers';

type SyndicateUser = {
  id: string;
  user: {
    id: string;
    fullName: string;
  };
};

const BLOCK_USER_FROM_DEAL_MUTATION = gql(`
  mutation BlockUserFromDeal($id: ID!, $userId: ID!, $note: String) {
    blockUserFromDeal(id: $id, userId: $userId, note: $note) {
      id
      note
      user {
        id
        fullName
      }
      createdAt
    }
  }
`);

const BlockUserDialogButton: FC = () => {
  const { syndicateId, dealId } = useParams<{ syndicateId: string; dealId: string }>() as {
    syndicateId: string;
    dealId: string;
  };

  const [open, setOpen] = useState(false);
  const [syndicateUser, setSyndicateUser] = useState<SyndicateUser>();

  const [blockUser, { loading, error }] = useMutation(BLOCK_USER_FROM_DEAL_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          __typename: 'DealType',
          id: dealId,
        }),
        fields: {
          blockedUserList(existingBlockedUsersRef) {
            const newBlockedUserRef = cache.writeFragment({
              data: data.blockUserFromDeal,
              fragment: gql(`
                fragment NewBlockedUser on DealBlockingType {
                  id
                  note
                  user {
                    id
                    fullName
                  }
                  createdAt
                }
              `),
            });
            const updatedBlockedUsersRef = [newBlockedUserRef, ...existingBlockedUsersRef];
            return updatedBlockedUsersRef;
          },
        },
      });
    },
  });

  return (
    <>
      <Button onClick={() => setOpen(true)}>Block User</Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel>
          <DialogTitle>Block a user from this deal</DialogTitle>
          <SearchSyndicateUsers
            syndicateId={syndicateId}
            onSelect={syndicateUser => setSyndicateUser(syndicateUser)}
          />
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => setOpen(false)}
            onSubmit={data => {
              if (!syndicateUser) return;
              blockUser({
                variables: {
                  id: dealId,
                  userId: syndicateUser.user.id,
                  note: data.note,
                },
              }).then(() => setOpen(false));
            }}
          >
            <FormInput
              fullWidth
              type="text"
              fieldName="note"
              label="Note"
              defaultValue=""
              helperText="Optional note"
            />
          </FormPanel>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default BlockUserDialogButton;
