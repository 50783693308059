import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

const REMOVE_USER_FROM_SYNDICATE_MUTATION = gql(`
  mutation RemoveUserFromSyndicate($syndicateUserId: ID!) {
    removeUserFromSyndicate(syndicateUserId: $syndicateUserId) {
      id
      syndicate {
        id
      }
    }
  }
`);

const RemoveUserFromSyndicateDialogButton: FC<{
  syndicateUserId: string;
  onClose: () => void;
}> = ({ syndicateUserId, onClose }) => {
  const [showConfirmDialog, toggleConfirmDialog] = useState(false);

  const [removeUser, { loading, error }] = useMutation(REMOVE_USER_FROM_SYNDICATE_MUTATION, {
    variables: {
      syndicateUserId,
    },
    update(cache, { data }) {
      if (!data) return;
      const { removeUserFromSyndicate } = data;
      cache.modify({
        id: `SyndicateType:${removeUserFromSyndicate.syndicate.id}`,
        fields: {
          syndicateUsers(existingUsersRefs, { readField }) {
            const updatedUsersRef = { ...existingUsersRefs };
            updatedUsersRef.nodes = existingUsersRefs.nodes.filter(
              syndicateUserRef => removeUserFromSyndicate.id !== readField('id', syndicateUserRef)
            );
            return updatedUsersRef;
          },
        },
      });
    },
  });

  return (
    <>
      <Button onClick={() => toggleConfirmDialog(true)}>Remove Investor From Syndicate</Button>
      <Dialog open={showConfirmDialog} onClose={() => toggleConfirmDialog(false)}>
        <DialogPanel>
          <DialogTitle>Are you sure you want to remove this Investor?</DialogTitle>
          You will have to send the user another invite for them to rejoin
          {error && <ErrorMessage error={error} type="alert" />}
          <DialogActions>
            <Button variant="secondary" onClick={() => toggleConfirmDialog(false)}>
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() =>
                removeUser().then(() => {
                  toggleConfirmDialog(false);
                  onClose();
                })
              }
            >
              Remove User
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default RemoveUserFromSyndicateDialogButton;
