import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DashboardSyndicatesEnumType } from '__generated__/graphql';
import { FC } from 'react';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import { useSyndicateOnDisplay } from '../index';

const PENDING_SYNDICATES_QUERY = gql(`
  query PendingSyndicates($filters: DashboardSyndicatesFilterType, $cursor: ID, $limit: Int) {
    dashboard {
      id
      syndicates(filters: $filters, cursor: $cursor, limit: $limit) {
        nodes {
          id
          name
          image
          statistics {
            id
            topStage
            topIndustry
            dealFlow
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const OutgoingSyndicateApplications: FC = () => {
  const { setSyndicateOnDisplay } = useSyndicateOnDisplay();

  const { loading, error, data, refetch, fetchMore } = useQuery(PENDING_SYNDICATES_QUERY, {
    variables: {
      limit: 50,
      filters: {
        type: DashboardSyndicatesEnumType.PendingApplications,
      },
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const dashboard = data.dashboard;
  const syndicates = dashboard.syndicates.nodes;
  const pageInfo = dashboard.syndicates.pageInfo;

  return (
    <DataTable
      data={syndicates}
      onClick={syndicate => setSyndicateOnDisplay(syndicate.id)}
      columns={[
        {
          label: '',
          fieldName: 'image',
          type: 'IMAGE',
        },
        {
          label: 'Name',
          fieldName: 'name',
        },
        {
          label: 'Top Stage',
          fieldName: 'statistics.topStage',
          type: 'STAGE',
        },
        {
          label: 'Top Industry',
          fieldName: 'statistics.topIndustry',
          type: 'INDUSTRY',
        },
        {
          label: 'Deal Flow',
          fieldName: 'dealFlow',
        },
      ]}
      hasNextPage={pageInfo.hasNextPage}
      onLoadMore={() =>
        fetchMore({
          variables: {
            cursor: pageInfo.cursor,
          },
        })
      }
    />
  );
};

export default OutgoingSyndicateApplications;
