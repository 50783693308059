import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

const CANCEL_SYNDICATE_INVITE_MUTATION = gql(`
  mutation CancelSyndicateInvite($syndicateId: ID!) {
    cancelSyndicateInvite(syndicateId: $syndicateId) {
      id
      email
      status
      type
      syndicate {
        id
        permissions {
          canApplyToSyndicate
          canCancelSyndicateInvite
          canLeaveSyndicateAsUser
        }
      }
    }
  }
`);

const CancelSyndicateInviteDialogButton: FC<{
  syndicateId: string;
  onClose: () => void;
}> = ({ syndicateId, onClose }) => {
  const [open, setOpen] = useState<boolean>(false);

  const [cancelSyndicateInvite, { loading, error }] = useMutation(
    CANCEL_SYNDICATE_INVITE_MUTATION,
    {
      refetchQueries: ['SuggestedSyndicates', 'PendingSyndicates'],
    }
  );

  return (
    <>
      <Button onClick={() => setOpen(true)}>Cancel Syndicate Application</Button>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
          setOpen(false);
        }}
      >
        <DialogPanel>
          <DialogTitle>Cancel Syndicate Application</DialogTitle>
          Are you sure you want to withdraw your application to join this syndicate?
          {error ? <ErrorMessage error={error} /> : null}
          <DialogActions>
            <Button
              loading={loading}
              onClick={() => {
                cancelSyndicateInvite({
                  variables: {
                    syndicateId,
                  },
                }).then(() => {
                  onClose();
                  setOpen(false);
                });
              }}
            >
              Withdraw Application
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                onClose();
                setOpen(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CancelSyndicateInviteDialogButton;
