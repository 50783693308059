import { useLocation } from 'react-router-dom';

const useParentRoute = () => {
  const location = useLocation();

  // Split the pathname into an array of segments
  const pathSegments = location.pathname.split('/').filter(Boolean);

  // Remove the last segment to get the parent path
  const parentPath = `/${pathSegments.slice(0, -1).join('/')}`;

  return parentPath;
};

export default useParentRoute;
