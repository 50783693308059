import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ErrorMessage from 'components/ErrorMessage';
import Statistics from 'components/Statistics';

import LoadingIndicator from 'primitives/LoadingIndicator';

const STATISTICS_QUERY = gql(`
  query getStatisticsOfInvestmentEntities($id: ID!) {
    investmentEntity(id: $id) {
      id
      statistics {
        totalInvestedAmount
        totalRealizedValue
        totalUnrealizedValue
      }
    }
  }
`);

const StatisticsOfInvestmentEntities: FC<{ investmentEntityId: string }> = ({
  investmentEntityId,
}) => {
  const { loading, error, data, refetch } = useQuery(STATISTICS_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const { totalInvestedAmount, totalUnrealizedValue } = data.investmentEntity.statistics;

  return (
    <Statistics
      statistics={[
        {
          label: 'Total Amount Invested',
          value: totalInvestedAmount,
          type: 'CURRENCY',
        },
        {
          label: 'Total Unrealized Value',
          value: totalUnrealizedValue,
          type: 'CURRENCY',
        },
        // TODO: Add this back when exits are taken care of
        // {
        //   label: 'Total Realized Value',
        //   value: '-',
        //   // type: 'CURRENCY',
        // },
      ]}
    />
  );
};

export default StatisticsOfInvestmentEntities;
