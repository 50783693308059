import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';

import { logout } from 'utils/auth';

import LeaveSyndicateButton from './LeaveSyndicateAsLeadButton';
import UpdateSyndicate from './UpdateSyndicate';

const SYNDICATE_QUERY = gql(`
  query GetSyndicateSettings($id: ID!) {
    syndicate(id: $id) {
      id
      name
      image
      tagline
      description
      isPrivate
      status
      createdAt
      updatedAt
      permissions {
        canLeaveSyndicateAsLead
      }
    }
  }
`);

const SyndicateSettings: FC = () => {
  const { syndicateId } = useParams<{ syndicateId: string }>() as { syndicateId: string };

  const { loading, error, data, refetch } = useQuery(SYNDICATE_QUERY, {
    variables: {
      id: syndicateId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const syndicate = data.syndicate;

    return (
      <>
        <UpdateSyndicate
          id={syndicate.id}
          name={syndicate.name}
          image={syndicate.image}
          tagline={syndicate.tagline}
          description={syndicate.description}
          isPrivate={syndicate.isPrivate}
        />
        <div className="flex flex-row gap-x-2">
          {syndicate.permissions.canLeaveSyndicateAsLead ? (
            <LeaveSyndicateButton id={syndicate.id} />
          ) : null}
          <Button onClick={logout}>Logout</Button>
        </div>
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Syndicate Settings"
        subTitle="Manage your syndicate information and configuration"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default SyndicateSettings;
