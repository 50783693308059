import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import BlockUserDialogButton from './BlockUserDialogButton';
import UnblockUserDialogButton from './UnblockUserDialogButton';

type BlockedUser = {
  id: string;
  note?: string;
  user: {
    id: string;
    fullName: string;
  };
  createdAt: string;
};

const BLOCKED_USERS_QUERY = gql(`
  query getBlockedUserListForDeal($id: ID!) {
    deal(id: $id) {
      id
      blockedUserList {
        id
        note
        user {
          id
          fullName
        }
        createdAt
      }
    }
  }
`);

const BlockedUsers: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };
  const [openUnblockUserDialog, setOpenUnblockUserDialog] = useState(false);
  const [userToUnblock, setUserToUnblock] = useState<BlockedUser>();

  const { loading, error, data, refetch } = useQuery(BLOCKED_USERS_QUERY, {
    variables: {
      id: dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const blockedUsers = data.deal.blockedUserList;

  return (
    <>
      <BlockUserDialogButton />
      <UnblockUserDialogButton
        id={userToUnblock?.id || ''}
        userId={userToUnblock?.user.id || ''}
        open={openUnblockUserDialog}
        setOpen={setOpenUnblockUserDialog}
      />
      <DataTable
        data={blockedUsers}
        onClick={blockedUser => {
          setUserToUnblock(blockedUser);
          setOpenUnblockUserDialog(true);
        }}
        columns={[
          {
            label: 'Name',
            fieldName: 'user.fullName',
          },
          {
            label: 'Note',
            fieldName: 'note',
          },
          {
            label: 'Blocked On',
            fieldName: 'createdAt',
            type: 'DATETIME',
          },
        ]}
      />
    </>
  );
};

export default BlockedUsers;
