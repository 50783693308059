import { useLazyQuery } from '@apollo/client';
import { gql } from '__generated__';
import {
  FundStatusEnumType,
  SyndicateStatusEnumType,
  SyndicateTypeEnumType,
} from '__generated__/graphql';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import alIndiaLogo from 'media/alIndiaLogo.png';

import ProfileSwitchBar from './ProfileSwitchBar';
import {
  getFundDashboardTabs,
  getInvestorTabs,
  getRuvDashboardTabs,
  getSyndicateDashboardTabs,
} from './sidebar-tabs';

const SYNDICATE_STATUS_QUERY = gql(`
  query SyndicateStatus($id: ID!) {
    syndicate(id: $id) {
      id
      status
      type
    }
  }
`);

const FUND_STATUS_QUERY = gql(`
  query FundStatus($id: ID!) {
    fund(id: $id) {
      id
      status
    }
  }
`);

const Sidebar = ({ mobile = false }: { mobile?: boolean }) => {
  const [tabs, setTabs] = useState<
    {
      key: string;
      label: string;
      icon: string;
      route: string;
      exact?: boolean;
      disabled?: boolean;
    }[]
  >([]);
  const { syndicateId, fundId } = useParams<{ syndicateId: string; fundId: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const [getSyndicateStatus, { loading: syndicateLoading, error: syndicateError }] =
    useLazyQuery(SYNDICATE_STATUS_QUERY);

  const [getFundStatus, { loading: fundLoading, error: fundError }] =
    useLazyQuery(FUND_STATUS_QUERY);

  useEffect(() => {
    const lowercaseURL = location.pathname.toLowerCase();

    const containsFund = lowercaseURL.includes('fund-dashboard');
    const containsSyndicate = lowercaseURL.includes('syndicate-dashboard');

    if (!containsFund && !containsSyndicate) {
      setTabs(getInvestorTabs());
      return;
    }
    if (containsSyndicate && syndicateId) {
      (async () => {
        const syndicate = await getSyndicateStatus({ variables: { id: syndicateId } });
        if (
          syndicate.data?.syndicate.status === SyndicateStatusEnumType.Inactive ||
          syndicate.data?.syndicate.status === SyndicateStatusEnumType.Deleted
        ) {
          navigate(`/syndicate-dashboard/${syndicateId}/suspended`);
          return;
        }
        if (syndicate.data?.syndicate.type === SyndicateTypeEnumType.Ruv) {
          setTabs(getRuvDashboardTabs(syndicateId, syndicate.data?.syndicate.status));
        } else {
          setTabs(
            getSyndicateDashboardTabs(
              syndicateId,
              syndicate.data?.syndicate.status,
              syndicate.data?.syndicate.type
            )
          );
        }
      })();
      return;
    }
    if (containsFund && fundId) {
      (async () => {
        const fund = await getFundStatus({ variables: { id: fundId } });
        if (fund.data?.fund.status === FundStatusEnumType.Inactive) {
          navigate(`/fund-dashboard/${fundId}/suspended`);
          return;
        }
        setTabs(getFundDashboardTabs(fundId, fund.data?.fund.status));
      })();
      setTabs(getFundDashboardTabs(fundId));
      return;
    }
  }, [syndicateId, fundId, getSyndicateStatus, getFundStatus, location.pathname, navigate]);

  function renderContent() {
    if (syndicateLoading || fundLoading) return <LoadingIndicator />;

    if (syndicateError || fundError) {
      return <ErrorMessage error={syndicateError || fundError} />;
    }

    return (
      <>
        <ProfileSwitchBar />
        <nav className="mt-1 flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-1">
            {tabs.map(tab => {
              function isActive() {
                if (tab?.exact) {
                  return location.pathname === tab.route;
                }
                return (
                  location.pathname === tab.route ||
                  (location.pathname.startsWith(tab.route) && tab.route !== '/')
                );
              }

              return (
                <li key={tab.key}>
                  <NavLink
                    to={tab.route}
                    className={classNames(
                      isActive()
                        ? 'bg-gray-200 text-indigo-600'
                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-100',
                      tab.disabled ? 'cursor-default opacity-50 pointer-events-none' : null,
                      'group flex gap-x-3 rounded-md p-1 text-sm leading-6 font-medium'
                    )}
                  >
                    <img
                      src={tab.icon}
                      alt="icon"
                      className={classNames(
                        isActive()
                          ? 'text-indigo-600'
                          : 'text-gray-400 group-hover:text-indigo-600',
                        'h-5 w-5 shrink-0'
                      )}
                      aria-hidden="true"
                    />
                    {tab.label}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
      </>
    );
  }

  return (
    <div
      className={classNames(
        'flex grow flex-col gap-y-3 overflow-y-auto bg-gray-50 px-3.5 pb-4',
        mobile ? 'border-none' : null
      )}
    >
      <NavLink to="/">
        <div className="flex h-16 shrink-0 items-center ml-1.5">
          <img className="h-6 w-auto" src={alIndiaLogo} alt="Your Company" />
        </div>
      </NavLink>
      {renderContent()}
    </div>
  );
};

export default Sidebar;
