import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ReportIssuePriorityEnumType } from '__generated__/graphql';
import { FC, useState } from 'react';

import { FormInput, FormPanel } from 'components/FormPanel';

const CONTACT_SUPPORT_MUTATION = gql(`
  mutation ContactSupport(
    $subject: String!
    $message: String!
    $priority: ReportIssuePriorityEnumType!
  ) {
    contactSupport(
      subject: $subject
      message: $message
      priority: $priority
    )
  }
`);

const ContactSupport: FC<{ onClose: () => void }> = ({ onClose }) => {
  const [contactSupport, { loading, error }] = useMutation(CONTACT_SUPPORT_MUTATION);
  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <>
      <h3 className="text-2xl font-semibold leading-6 text-gray-900 mb-1">Contact Support</h3>
      <p className="text-lg tracking-tight text-gray-700 mb-5">
        Data discrepancies, bug report, feature request or general feedback. We're here to help you.
      </p>
      {!showSuccess ? (
        <FormPanel
          loading={loading}
          error={error}
          onCancel={onClose}
          onSubmit={data => {
            contactSupport({
              variables: {
                subject: data.subject,
                message: data.dealName
                  ? `${data.message}. Investment Name(s): ${data.dealName}`
                  : data.message,
                priority: data.priority,
              },
            }).then(data => {
              setShowSuccess(true);
            });
          }}
        >
          <FormInput
            fullWidth
            type="textarea"
            fieldName="subject"
            label="Subject"
            helperText="Please provide as much detail as possible. Include steps to reproduce the issue if applicable."
            defaultValue=""
            validators={{
              required: true,
            }}
          />
          <FormInput
            fullWidth
            type="textarea"
            fieldName="message"
            label="Describe the issue"
            helperText="Please provide as much detail as possible. Include steps to reproduce the issue if applicable."
            defaultValue=""
            validators={{
              required: true,
            }}
          />
          <FormInput
            fullWidth
            type="text"
            helperText='Optional. E.g. "ACME company, Test Inc."'
            fieldName="dealName"
            label="Investment Name(s) if applicable"
            defaultValue=""
          />
          <FormInput
            fullWidth
            type="select"
            fieldName="priority"
            label="Priority"
            defaultValue={ReportIssuePriorityEnumType.Medium}
            options={[
              { value: ReportIssuePriorityEnumType.Low, label: 'Low' },
              { value: ReportIssuePriorityEnumType.Medium, label: 'Medium' },
              { value: ReportIssuePriorityEnumType.High, label: 'High' },
            ]}
            validators={{
              required: true,
            }}
          />
        </FormPanel>
      ) : (
        <div className="bg-green-50 sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg font-semibold leading-6 text-gray-900">
              Thank you for reporting the issue
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-700">
              <p>
                Our team will review the issue and get back to you as soon as possible. We
                appreciate your patience.
              </p>
            </div>
            <div className="mt-5">
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={() => {
                  setShowSuccess(false);
                  onClose();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactSupport;
