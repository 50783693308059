import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

const UPDATE_SYNDICATE_MUTATION = gql(`
  mutation UpdateSyndicate(
    $id: ID!
    $name: String!
    $image: Upload
    $isPrivate: Boolean!
    $tagline: String!
    $description: String
  ) {
    updateSyndicate(
      id: $id
      name: $name
      image: $image
      isPrivate: $isPrivate
      tagline: $tagline
      description: $description
    ) {
      id
      name
      image
      tagline
      isPrivate
      description
      updatedAt
    }
  }
`);

const UpdateSyndicate: FC<{
  id: string;
  name: string;
  image?: string | null;
  tagline: string;
  description?: string | null;
  isPrivate: boolean;
}> = ({ id, name, image, tagline, description, isPrivate }) => {
  const [updateSyndicate, { loading, error }] = useMutation(UPDATE_SYNDICATE_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateSyndicate({
          variables: {
            id,
            name: data.name,
            image: typeof data.image !== 'string' ? data.image : null,
            tagline: data.tagline,
            isPrivate: data.isPrivate,
            description: data.description,
          },
        });
      }}
      title="Information"
    >
      <FormInput type="text" fieldName="name" label="Name" defaultValue={name} />
      <FormInput type="avatar" fieldName="image" label="Logo" defaultValue={image || null} />
      <FormInput fullWidth type="text" fieldName="tagline" label="Tagline" defaultValue={tagline} />
      <FormInput
        fullWidth
        type="custom"
        fieldName="description"
        label="Description"
        defaultValue={description}
        customInput={EditorFormInput}
        validators={{
          required: true,
        }}
      />
      <FormInput
        type="switch"
        fieldName="isPrivate"
        label="Syndicate Profile Privacy"
        defaultValue={isPrivate}
        helperText="Would you like your syndicate profile to be public on the Angellist India Platform?"
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateSyndicate;
