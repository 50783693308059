import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DealStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

import LoadingIndicator from 'primitives/LoadingIndicator';

const COMPANY_INFORMATION_QUERY = gql(`
  query CompanyInformation($id: ID!) {
    deal(id: $id) {
      id
      status
      companyInformation {
        id
        stage
        monthsOfRunway
      }
    }
  }
`);

const UPDATE_COMPANY_INFORMATION_MUTATION = gql(`
  mutation UpdateCompanyInformation(
    $dealId: ID!
    $stage: DealCompanyStageEnumType
    $monthsOfRunway: MonthsOfRunwayEnumType
  ) {
    updateCompanyInformation(
      id: $dealId
      stage: $stage
      monthsOfRunway: $monthsOfRunway
    ) {
      id
      companyInformation {
        id
        stage
        monthsOfRunway
      }
    }
  }
`);

const CompanyInformation: FC<{ dealStatus: DealStatusEnumType }> = ({ dealStatus }) => {
  const { dealId } = useParams<{ dealId: string }>() as {
    dealId: string;
  };

  const { loading, error, data, refetch } = useQuery(COMPANY_INFORMATION_QUERY, {
    variables: {
      id: dealId,
    },
  });

  const [updateCompanyInformation, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_COMPANY_INFORMATION_MUTATION
  );

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  function disableEdit() {
    return [
      DealStatusEnumType.Cancelled,
      DealStatusEnumType.Closed,
      DealStatusEnumType.Deleted,
      DealStatusEnumType.Exited,
      DealStatusEnumType.Wired,
    ].includes(dealStatus);
  }

  return (
    <FormPanelWithReadMode
      loading={updateLoading}
      error={updateError}
      onSubmit={data => {
        updateCompanyInformation({
          variables: {
            dealId,
            stage: data.stage,
            monthsOfRunway: data.monthsOfRunway,
          },
        });
      }}
      disableEdit={disableEdit()}
    >
      <FormInput
        fullWidth
        type="select"
        fieldName="stage"
        label="Company Stage"
        defaultValue={data.deal.companyInformation.stage || ''}
        options={[
          { label: 'Pre Launch', value: 'PRE_LAUNCH' },
          { label: 'Pre Revenue', value: 'PRE_REVENUE' },
          { label: 'Post Revenue', value: 'POST_REVENUE' },
          { label: 'Profitable', value: 'PROFITABLE' },
        ]}
        validators={{
          required: true,
        }}
      />
      <FormInput
        fullWidth
        type="select"
        fieldName="monthsOfRunway"
        label="Months of runway after this round"
        defaultValue={data.deal.companyInformation.monthsOfRunway || ''}
        options={[
          { label: 'Fewer than 12 months', value: 'FEWER_THAN_12' },
          { label: 'More than 12 months', value: 'MORE_THAN_12' },
        ]}
        validators={{
          required: true,
        }}
      />
    </FormPanelWithReadMode>
  );
};

export default CompanyInformation;
