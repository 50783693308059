import { ApolloClient, ApolloProvider, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { createUploadLink } from 'apollo-upload-client';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { getToken, logout } from 'utils/auth';
import paginationHelper from 'utils/pagination-helper';

import './index.scss';
import reportWebVitals from './reportWebVitals';
import App from './routes';
import './tailwind.css';

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_INDIA_BACKEND,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions && extensions.code === 'UNAUTHENTICATED') logout();
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: getToken(),
      'Apollo-Require-Preflight': 'true',
    },
  };
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          companies: paginationHelper(),
          deals: paginationHelper(),
          syndicates: paginationHelper(true),
        },
      },
      SyndicateType: {
        fields: {
          invites: paginationHelper(['filters']),
          deals: paginationHelper(),
          posts: paginationHelper(),
          syndicateUsers: paginationHelper(['filters']),
        },
      },
      SyndicateUserType: {
        fields: {
          closings: paginationHelper(),
        },
      },
      DealType: {
        fields: {
          invites: paginationHelper(['filters']),
          closings: paginationHelper(),
        },
      },
      DashboardType: {
        fields: {
          syndicates: paginationHelper(['filters', 'fundFilters']),
          dealInvites: paginationHelper(['filters']),
          closings: paginationHelper(['filters']),
          fundClosings: paginationHelper(),
          posts: paginationHelper(),
        },
      },
    },
  }),
});

Sentry.init({
  dsn: 'https://8f2d4bfdff7e370c1df0d92f53d6cec6@o4507808313180160.ingest.us.sentry.io/4507951989129216',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://app.angellistindia.com'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
