import {
  Dialog as HeadlessDialog,
  DialogPanel as HeadlessDialogPanel,
  DialogTitle as HeadlessDialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';

function Dialog({ open, onClose, children }) {
  return (
    <Transition show={open} as={Fragment}>
      <HeadlessDialog as="div" className="relative z-10" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div>{children}</div>
            </TransitionChild>
          </div>
        </div>
      </HeadlessDialog>
    </Transition>
  );
}

function DialogTitle({ children }) {
  return (
    <HeadlessDialog.Title as="h2" className="text-xl font-semibold leading-6 text-gray-700 mb-1">
      {children}
    </HeadlessDialog.Title>
  );
}

function DialogSubTitle({ children }) {
  return (
    <HeadlessDialogTitle as="h3" className="text-md leading-6 text-gray-600 mb-5">
      {children}
    </HeadlessDialogTitle>
  );
}

function DialogPanel({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <HeadlessDialogPanel
      className={classNames(
        'relative transform overflow-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-8',
        className
      )}
    >
      {children}
    </HeadlessDialogPanel>
  );
}

function DialogActions({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <div
      className={classNames(
        'mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3',
        className
      )}
    >
      {children}
    </div>
  );
}

export { Dialog, DialogTitle, DialogSubTitle, DialogPanel, DialogActions };
