import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ClosingStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import ReportDataDiscrepancy from 'components/ReportDataDiscrepancy';

import LoadingIndicator from 'primitives/LoadingIndicator';

const CLOSINGS_QUERY = gql(`
  query getCompletedCommitmentsOfFund(
    $id: ID!
    $cursor: ID
    $limit: Int
    $filters: FundInvestmentsFilterType
  ) {
    fund(id: $id) {
      id
      investments(cursor: $cursor, limit: $limit, filters: $filters) {
        nodes {
          id
          deal {
            id
            companyInformation {
              id
              company {
                id
                name
                image
              }
            }
          }
          investmentEntity {
            id
            name
          }
          amount
          status
          wiredAt
          statistics {
            id
            netValue
            multipleOnInvestment
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const FundInvestments: FC = () => {
  const navigate = useNavigate();
  const { fundId } = useParams<{ fundId: string }>() as { fundId: string };

  const { loading, error, data, refetch, fetchMore } = useQuery(CLOSINGS_QUERY, {
    variables: {
      id: fundId,
      filters: {
        statuses: [ClosingStatusEnumType.Active],
      },
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const closings = data.fund.investments.nodes;
    const pageInfo = data.fund.investments.pageInfo;

    return (
      <>
        <DataTable
          data={closings}
          onClick={closing => navigate(`/invest/deal/${closing.deal.id}`)}
          emptyListTitle="Your haven't made any investments yet"
          columns={[
            {
              label: 'Company',
              fieldName: 'deal.companyInformation.company',
              type: 'COMPANY',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Investment Entity',
              fieldName: 'investmentEntity.name',
            },
            {
              label: 'Invested',
              fieldName: 'amount',
              type: 'CURRENCY',
            },
            {
              label: 'Invest Date',
              fieldName: 'wiredAt',
              type: 'DATE',
            },
            {
              label: 'Net Value',
              fieldName: 'statistics.netValue',
              type: 'CURRENCY',
            },
            {
              label: 'Multiple',
              fieldName: 'statistics.multipleOnInvestment',
              type: 'MULTIPLE',
            },
          ]}
          hasNextPage={pageInfo.hasNextPage}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: pageInfo.cursor,
              },
            })
          }
        />
        <ReportDataDiscrepancy />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation title="Investments" subTitle="View your fund commitments here" />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default FundInvestments;
