import { useQuery } from '@apollo/client';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__/gql';
import { SyndicateLeadRoleEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';
import { FC } from 'react';

import ErrorMessage from 'components/ErrorMessage';

import Badge from 'primitives/Badge';
import LoadingIndicator from 'primitives/LoadingIndicator';

import constants from 'utils/constants';
import syndicateLeadRoleToReadable from 'utils/enums/syndicate-lead-role-to-readable';

import RemoveLeadFromSyndicateDialogButton from './RemoveLeadFromSyndicateDialogButton';
import UpdateSyndicateLeadRole from './UpdateSyndicateLeadRole';

const SYNDICATE_LEAD_QUERY = gql(`
  query SyndicateLead($id: ID!) {
    syndicateLead(id: $id) {
      id
      user {
        id
        name
        email
        image {
          url
        }
      }
      role
      createdAt
      permissions {
        canUpdateLeadRole
        canRemoveLead
      }
    }
  }
`);

const SyndicateLeadSlideOver: FC<{ syndicateLeadId: string; onClose: () => void }> = ({
  syndicateLeadId,
  onClose,
}) => {
  const { loading, error, data, refetch } = useQuery(SYNDICATE_LEAD_QUERY, {
    variables: {
      id: syndicateLeadId,
    },
  });

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const lead = data.syndicateLead;
    const user = lead.user;

    const { label, color } = syndicateLeadRoleToReadable(lead.role);

    function getLeadPermissions() {
      const owner = SyndicateLeadRoleEnumType.Owner;
      const admin = SyndicateLeadRoleEnumType.Admin;
      const carryRecipient = SyndicateLeadRoleEnumType.CarryRecipient;

      return [
        {
          description: 'Receive carry from specific syndicate investments (if added).',
          enabled: [owner, admin, carryRecipient].includes(lead.role),
        },
        {
          description: 'View all areas of your syndicate account.',
          enabled: [owner, admin].includes(lead.role),
        },
        {
          description: 'Manage team, carry recipients, and admin roles.',
          enabled: [owner, admin].includes(lead.role),
        },
        {
          description: 'Run deals, invite and message investors',
          enabled: [owner, admin].includes(lead.role),
        },
        {
          description: 'Receive emails regarding your syndicate and deals.',
          enabled: [owner, admin].includes(lead.role),
        },
        {
          description: 'Remove owners or delete the syndicate.',
          enabled: lead.role === owner,
        },
      ];
    }

    return (
      <>
        <div className="md:flex md:items-center md:justify-between md:space-x-5 mb-5">
          <div className="flex items-start space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <img
                  className="h-12 w-12 rounded-full"
                  src={user.image?.url || constants.ALT_AVATAR_URL}
                  alt=""
                />
                <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">{user.name}</h1>
              <p className="text-sm font-medium text-gray-500">
                Accepted on{' '}
                <time dateTime={dayjs(lead.createdAt).format('MMMM YYYY')}>
                  {dayjs(lead.createdAt).format('MMMM YYYY')}
                </time>
              </p>
            </div>
          </div>
          <div className="mt-5 flex lg:mt-0 lg:ml-4">
            <span className="sm:block">
              <Badge label={label} color={color} />
            </span>
          </div>
        </div>
        <div className="lg:flex-auto pb-6 lg:pr-12">
          <div className="flex items-center gap-x-4">
            <h4 className="flex-none text-md font-semibold leading-6 text-gray-900">Permissions</h4>
          </div>
          <ul className="mt-4 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2">
            {getLeadPermissions().map(permission => (
              <li key={permission.description} className="flex gap-x-3">
                {permission.enabled ? (
                  <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600" />
                ) : (
                  <XMarkIcon aria-hidden="true" className="h-6 w-5 flex-none text-red-600" />
                )}
                {permission.description}
              </li>
            ))}
          </ul>
        </div>
        {lead.permissions.canUpdateLeadRole ? (
          <UpdateSyndicateLeadRole syndicateLeadId={syndicateLeadId} role={lead.role} />
        ) : null}
        {lead.permissions.canRemoveLead ? (
          <>
            <div className="mt-5" />
            <RemoveLeadFromSyndicateDialogButton
              syndicateLeadId={syndicateLeadId}
              onClose={onClose}
            />
          </>
        ) : null}
      </>
    );
  }

  return renderContent();
};

export default SyndicateLeadSlideOver;
