import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  SyndicateInviteQueryTypeEnumType,
  SyndicateInviteTypeEnumType,
} from '__generated__/graphql';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import SyndicateApplicationSlideOver from './SyndicateApplicationSlideOver';

const SYNDICATE_APPLICATIONS_QUERY = gql(`
  query PendingSyndicateApplications($id: ID!, $cursor: ID, $limit: Int, $filters: SyndicateInvitesFilterType) {
    syndicate(id: $id) {
      id
      invites(cursor: $cursor, limit: $limit, filters: $filters) {
        nodes {
          id
          user {
            id
            name
            createdAt
            statistics {
              totalInvestedAmountRange
            }
          }
          createdAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const PendingApplications = () => {
  const { syndicateId } = useParams<{ syndicateId: string }>() as { syndicateId: string };
  const [syndicateApplicationOnDisplay, setSyndicateApplicationOnDisplay] = useState<string>('');

  const { loading, error, data, refetch, fetchMore } = useQuery(SYNDICATE_APPLICATIONS_QUERY, {
    variables: {
      id: syndicateId,
      filters: {
        type: SyndicateInviteTypeEnumType.FromUserToSyndicate,
        status: SyndicateInviteQueryTypeEnumType.Pending,
      },
      limit: 30,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading || !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const { nodes: invites, pageInfo } = data.syndicate.invites;

  return (
    <>
      <SyndicateApplicationSlideOver
        open={!!syndicateApplicationOnDisplay}
        syndicateInviteId={syndicateApplicationOnDisplay}
        onClose={() => setSyndicateApplicationOnDisplay('')}
      />
      <DataTable
        data={invites}
        columns={[
          {
            label: 'Name',
            fieldName: 'user.name',
          },
          {
            label: 'Joined AL',
            fieldName: 'user.createdAt',
            type: 'MONTH',
          },
          {
            label: 'Applied',
            fieldName: 'createdAt',
            type: 'DATE',
          },
          {
            label: 'Total Invested Amount On AL',
            fieldName: 'user.statistics.totalInvestedAmountRange',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        paginationLoading={loading}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
        onClick={invite => setSyndicateApplicationOnDisplay(invite.id)}
      />
    </>
  );
};

export default PendingApplications;
