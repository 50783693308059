import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';

const UPDATE_PASSWORD_MUTATION = gql(`
  mutation UpdatePassword($currentPassword: String!, $newPassword: String!) {
    updatePassword(currentPassword: $currentPassword, newPassword: $newPassword)
  }
`);

const UpdatePasswordDialogButton: FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const [updatePassword, { loading, error }] = useMutation(UPDATE_PASSWORD_MUTATION);

  return (
    <>
      <Button onClick={() => setOpen(true)}>Update password</Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogPanel>
          <DialogTitle>Update password</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onSubmit={data => {
              if (data.newPassword !== data.newPasswordRepeated) return;
              updatePassword({
                variables: {
                  currentPassword: data.currentPassword,
                  newPassword: data.newPassword,
                },
              }).then(() => setOpen(false));
            }}
          >
            <FormInput
              type="password"
              fieldName="currentPassword"
              label="Current Password"
              defaultValue={''}
            />
            <FormInput
              type="password"
              fieldName="newPassword"
              label="New Password"
              defaultValue={''}
            />
            <FormInput
              type="password"
              fieldName="newPasswordRepeated"
              label="Repeat New Password"
              defaultValue={''}
            />
          </FormPanel>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default UpdatePasswordDialogButton;
