import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ManageDealInvitesEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

const DEAL_INVITES_QUERY = gql(`
  query GetViewedInvites($id: ID!, $cursor: ID, $filters: DealInvitesFilterType) {
    deal(id: $id) {
      id
      invites(cursor: $cursor, filters: $filters) {
        nodes {
          id
          user {
            id
            name
          }
          status
          createdAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const ViewedInvites: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };

  const { loading, error, data, refetch, fetchMore } = useQuery(DEAL_INVITES_QUERY, {
    variables: {
      id: dealId,
      filters: {
        type: ManageDealInvitesEnumType.Viewed,
      },
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const invites = data.deal.invites.nodes;
  const pageInfo = data.deal.invites.pageInfo;

  return (
    <DataTable
      data={invites}
      columns={[
        {
          label: 'Name',
          fieldName: 'user.name',
        },
        {
          label: 'Status',
          fieldName: 'status',
          type: 'STATUS',
        },
        {
          label: 'Created At',
          fieldName: 'createdAt',
          type: 'DATETIME',
        },
      ]}
      hasNextPage={pageInfo.hasNextPage}
      onLoadMore={() =>
        fetchMore({
          variables: {
            cursor: pageInfo.cursor,
          },
        })
      }
    />
  );
};

export default ViewedInvites;
