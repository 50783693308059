import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateLayoutContent } from 'layouts/CreateLayout';

import { FormInput, FormPanel } from 'components/FormPanel';

const SUBMIT_DEAL_FOR_REVIEW_MUTATION = gql(`
  mutation SubmitDealForReview($dealId: ID!) {
    submitDealForReview(id: $dealId) {
      id
    }
  }
`);

const ESignSubmit: FC = () => {
  const navigate = useNavigate();

  const { syndicateId, dealId } = useParams<{ syndicateId: string; dealId: string }>() as {
    syndicateId: string;
    dealId: string;
  };

  const [submitDealForReview, { loading, error }] = useMutation(SUBMIT_DEAL_FOR_REVIEW_MUTATION);

  return (
    <CreateLayoutContent title="Submit for Review" subTitle="We'll get back to you right away">
      <div className="p-5">
        <h3 className="block text-md font-semibold leading-6 text-gray-900 mb-4">Closing Terms</h3>
        <ul className="list-disc space-y-4">
          <li className="text-base sm:text-sm">
            I/ We attest that I/ We qualify as an eligible ‘Angel Investor’ as per the SEBI (AIF)
            Regulations, 2012, and am/ are competent to invest in the securities market as per
            applicable laws.
          </li>
          <li className="text-base sm:text-sm">
            I/ We agree to the terms of the documents of AL Trust, a SEBI-registered category I
            Angel Fund (“Fund”) and to act in the capacity of the ‘Lead Angel Investor’ for the
            relevant scheme of the Fund investing in this investee company.
          </li>
          <li className="text-base sm:text-sm">
            I/ We (as Lead Angel Investor) will be responsible for conducting due diligence and
            sourcing all the capital from the eligible angel investors for this investment.
          </li>
          <li className="text-base sm:text-sm">
            I/ We understand that a standalone scheme of the Fund will represent the investment to
            be made in this investee company.
          </li>
          <li className="text-base sm:text-sm">
            In case of a company incorporated in India, I/ We declare that the investee company:
            <ul className="list-disc ml-8 space-y-2 mt-2">
              <li>
                Qualifies as a ‘startup’* as per Regulation 2 (wa) of the SEBI (AIF) Regulations,
                2012.
              </li>
              <li>
                Is not promoted or sponsored by or related to any Industrial Group whose Group
                turnover exceeds INR 300 crores.
              </li>
              <li>
                Is not a company with a family connection with me or any of LPs willing to
                participate in this investment.
              </li>
              <li>
                Is engaged in a business in which up to 100% foreign investment is allowed under the
                automatic route as per the current foreign exchange laws of India.
              </li>
            </ul>
          </li>
          <li className="text-base sm:text-sm">
            I/ We understand that the investment by the Fund will qualify as ‘Indirect Foreign
            Investment’ (as per the Foreign Exchange Management Act, 1999) for the investee company
            as the investment manager of the Fund is a foreign-owned and controlled entity.
          </li>
          <li className="text-base sm:text-sm">
            In case of an investment in a company incorporated outside India, I/ We understand that
            the investment will be subject to Securities and Exchange Board of India (“SEBI”)’s
            approval, in accordance with the SEBI (AIF) Regulations, 2012.
          </li>
          <li className="text-base sm:text-sm">
            I/ We understand that the AngelList India team will review and finalize the investment
            agreements before execution, and I/founder/investee company’s legal counsels shall
            extend maximum support in this regard.
          </li>
          <li className="text-base sm:text-sm">
            I/ We understand that the investment in this investee company (collectively via all
            schemes that are/ may be launched by the Fund) shall not exceed INR 10 Crore as per the
            SEBI (AIF) Regulations, 2012.
          </li>
          <li className="text-base sm:text-sm">
            I/ We understand that AngelList India LLP, in its capacity as the investment manager of
            the Fund, will invest a nominal amount in this investment.
          </li>
          <li className="text-base sm:text-sm">
            I/ We understand that this investment shall be subject to a mandatory lock-in period of
            1 year as per the SEBI (AIF) Regulations, 2012.
          </li>
          <li className="text-base sm:text-sm">
            I/ We understand that any reference to the applicable law includes reference to
            subsequent amendments, rules, regulations, and notifications under such law.
          </li>
          <li className="text-base sm:text-sm">
            I/ We fully understand the above declarations and if needed, have sought guidance from
            advisors or legal counsel to clarify their meaning and intent. I/We have made the
            necessary declarations (as above) based on this understanding.
          </li>
        </ul>
        <h3 className="block text-md font-semibold leading-6 text-gray-900 mt-5 mb-4">
          GP Community Guidelines
        </h3>
        <ul className="list-disc space-y-4">
          <li className="text-base sm:text-sm">
            I/ We understand that violating the terms of the AngelList India Community Guidelines
            may result in AngelList India restricting my ability to invite AngelList India Limited
            Partners (“LPs”) to my Special Purpose Vehicle (“SPV”) or accepting capital on the
            platform from such LPs, removing AngelList India LPs from my SPV, cancelling my SPV, or,
            in extreme cases, terminating my use of the AngelList India Platform.
          </li>
          <li className="text-base sm:text-sm">
            I confirm that I have sought permission from the investee company to run an SPV for this
            investment.
          </li>
        </ul>
      </div>
      <FormPanel
        loading={loading}
        error={error}
        onSubmit={data => {
          submitDealForReview({
            variables: {
              dealId,
            },
          }).then(() => {
            navigate(`/syndicate-dashboard/${syndicateId}/deals`);
          });
        }}
        onCancel={() => {
          navigate(`/syndicate-dashboard/${syndicateId}/deals/create/${dealId}/carry-recipients`);
        }}
        submitButtonLabel="Submit"
        cancelButtonLabel="Back"
      >
        <FormInput
          type="switch"
          fieldName="disclaimer"
          label="I confirm that the information provided is accurate to the best of my knowledge, and I have read and agree to the above disclosures."
          defaultValue=""
          validators={{
            required: true,
          }}
        />
      </FormPanel>
    </CreateLayoutContent>
  );
};

export default ESignSubmit;
