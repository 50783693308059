import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

export default function TextArea({
  onChange,
  value,
  minRows = 3,
  error,
  label,
  placeholder,
  fullWidth = false,
  helperText,
  readOnly,
}: {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  minRows?: number;
  label?: string;
  error?: string;
  placeholder?: string;
  fullWidth?: boolean;
  helperText?: string;
  readOnly?: boolean;
}) {
  function content() {
    if (readOnly) {
      return <div className="block py-2.5 text-gray-900 sm:text-sm">{value ? value : '-'}</div>;
    }

    return (
      <div className="relative mt-1 rounded-md">
        <textarea
          rows={minRows}
          name={label}
          id={label}
          className={classNames(
            'block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6',
            {
              'w-full': fullWidth,
              'pr-10 ring-inset ring-red-300 focus:ring-red-600': error,
            }
          )}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      {label && (
        <label htmlFor={label} className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      )}
      {content()}
      {helperText && !error && <p className="mt-2 text-sm text-gray-500">{helperText}</p>}
      {error && (
        <p className="mt-2 text-sm text-red-600" id="error">
          {error}
        </p>
      )}
    </div>
  );
}
