import { FC, useState } from 'react';

import Select from 'primitives/Select';

type SyndicateLead = {
  id: string;
  user: {
    id: string;
    name: string;
  };
};

const SelectSyndicateLead: FC<{
  syndicateLeads: SyndicateLead[];
  onSelect: (syndicateLead?: any) => void;
  value?: string;
  readOnly?: boolean;
}> = ({ syndicateLeads, value, onSelect, readOnly }) => {
  const [currentValue, setCurrentValue] = useState<string | undefined>(value);
  return (
    <Select
      options={syndicateLeads.map(syndicateLead => ({
        value: syndicateLead.id,
        label: syndicateLead.user.name,
      }))}
      onChange={syndicateLeadId => {
        setCurrentValue(
          syndicateLeads.find(syndicateLead => syndicateLead.id === syndicateLeadId)?.id
        );
        onSelect(syndicateLeads.find(syndicateLead => syndicateLead.id === syndicateLeadId)?.id);
      }}
      fullWidth
      value={currentValue}
      label="Syndicate Lead"
      placeholder="Select Syndicate Lead"
      readOnly={readOnly}
    />
  );
};

export default SelectSyndicateLead;
