import { useLazyQuery, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { StepperLayoutTabs } from 'layouts/CreateStepperLayout';

import { getUser } from 'utils/auth';

const GET_USER_PROFILE_QUERY = gql(`
  query GetUserOnboardingUser($id: ID!) {
    user(id: $id) {
      id
      bio
      investmentEntities {
        id
        status
      }
    }
  }
`);

const GET_INVESTMENT_ENTITY_QUERY = gql(`
  query GetUserOnboardingInvestmentEntity($id: ID!) {
    investmentEntity(id: $id) {
      id
      kyc {
        id
      }
      investorApplication {
        id
      }
    }
  }
`);

const ProfileCompletionTabs: FC<{
  isUserWithActiveEntity: boolean;
}> = ({ isUserWithActiveEntity }) => {
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>();

  const user = getUser();

  const { data: userData } = useQuery(GET_USER_PROFILE_QUERY, {
    variables: { id: user.id },
  });

  const [getInvestmentEntity, { data: entityData }] = useLazyQuery(GET_INVESTMENT_ENTITY_QUERY);

  useEffect(() => {
    if (!investmentEntityId) return;

    getInvestmentEntity({ variables: { id: investmentEntityId } });
  }, [investmentEntityId, getInvestmentEntity]);

  const baseUrl = '/onboarding/complete-profile';

  const tabsForExistingUsers = [
    {
      name: 'Social Profile',
      matchingUrls: [
        baseUrl,
        `${baseUrl}/social-profile`,
        `${baseUrl}/social-profile/${investmentEntityId}`,
      ],
      defaultUrl: investmentEntityId
        ? `${baseUrl}/social-profile/${investmentEntityId}`
        : `${baseUrl}/social-profile`,
      title: 'Tell us a little more about yourself',
      subTitle: 'This will help other investors, funds and startups know more about you',
      conditionForComplete: !!userData?.user?.bio,
      index: true,
    },
  ];

  const tabsForNewUsers = [
    {
      name: 'Social Profile',
      matchingUrls: [
        baseUrl,
        `${baseUrl}/social-profile`,
        `${baseUrl}/social-profile/${investmentEntityId}`,
      ],
      defaultUrl: investmentEntityId
        ? `${baseUrl}/social-profile/${investmentEntityId}`
        : `${baseUrl}/social-profile`,
      title: 'Tell us a little more about yourself',
      subTitle: 'This will help other investors, funds and startups know more about you',
      conditionForComplete: !!userData?.user?.bio,
      index: true,
    },
    {
      name: 'Create Entity',
      matchingUrls: [
        `${baseUrl}/investment-entity`,
        `${baseUrl}/investment-entity/${investmentEntityId}/basic-information`,
      ],
      defaultUrl: `${baseUrl}/investment-entity/${investmentEntityId}/basic-information`,
      title: 'Create an investment entity',
      subTitle: 'An investment entity is a legal entity that can invest in deals and funds',
      conditionForComplete: !!entityData?.investmentEntity,
    },
    {
      name: 'KYC',
      matchingUrls: [`${baseUrl}/investment-entity/${investmentEntityId}/kyc`],
      defaultUrl: `${baseUrl}/investment-entity/${investmentEntityId}/kyc`,
      title: 'KYC',
      subTitle: 'Regulations require us to verify your identity',
      conditionForComplete: !!entityData?.investmentEntity?.kyc,
    },
    {
      name: 'Experience',
      matchingUrls: [`${baseUrl}/investment-entity/${investmentEntityId}/investor-application`],
      defaultUrl: `${baseUrl}/investment-entity/${investmentEntityId}/investor-application`,
      title: 'Investing Experience',
      subTitle: 'Angel investing in India requires a certain level of experience',
      conditionForComplete: !!entityData?.investmentEntity?.investorApplication,
    },
    {
      name: 'Review',
      matchingUrls: [`${baseUrl}/investment-entity/${investmentEntityId}/submit-for-review`],
      defaultUrl: `${baseUrl}/investment-entity/${investmentEntityId}/submit-for-review`,
      title: 'Submit for review',
      subTitle: 'Review your details before submitting for review',
      conditionForComplete: undefined,
    },
  ];

  return (
    <StepperLayoutTabs tabs={isUserWithActiveEntity ? tabsForExistingUsers : tabsForNewUsers} />
  );
};

export default ProfileCompletionTabs;
