import { FC } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import CreateStepperLayout, { StepperLayoutContent } from 'layouts/CreateStepperLayout';

import InvestmentEntityTabs from './InvestmentEntityTabs';

const CreateInvestmentEntity: FC = () => {
  const navigate = useNavigate();

  function onClose() {
    navigate('/invest/investment-entities');
  }

  return (
    <CreateStepperLayout onClose={onClose}>
      <InvestmentEntityTabs />
      <StepperLayoutContent>
        <Outlet />
      </StepperLayoutContent>
    </CreateStepperLayout>
  );
};

export default CreateInvestmentEntity;
