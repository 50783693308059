import { useLazyQuery } from '@apollo/client';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { ClickAwayListener, Popper, TextField } from '@mui/material';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import constants from 'utils/constants';
import debounce from 'utils/debounce';

import MessageUserDialogButton from './MessageUserDialogButton';

type SyndicateUser = {
  id: string;
  user: {
    id: string;
    fullName: string;
  };
};

const SEARCH_SYNDICATE_USERS_FOR_CONVERSATION_QUERY = gql(`
  query SearchSyndicateUsersForConversation($text: String!, $syndicateId: String!, $cursor: ID, $limit: Int) {
    search(text: $text, cursor: $cursor, limit: $limit) {
      syndicateUsers(syndicateId: $syndicateId) {
        nodes {
          id
          user {
            id
            fullName
            image{
              url
              name
            }
          }
          conversations {
            nodes {
              id
            }
          }
        }
      }
    }
  }
`);

const SearchSyndicateUsersForConversation: FC<{
  syndicateId: string;
  value?: SyndicateUser;
  label?: string;
}> = ({ value, syndicateId, label = '' }) => {
  const [currentValue, setCurrentValue] = useState<SyndicateUser | null>(value ?? null);
  const [showMessageUserDialog, setShowMessageUserDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const [getSearchResults, { loading, error, data, refetch, variables }] = useLazyQuery(
    SEARCH_SYNDICATE_USERS_FOR_CONVERSATION_QUERY
  );

  const ref = useRef<HTMLDivElement | null>(null);

  const performSearch = searchTerm =>
    getSearchResults({
      variables: {
        text: searchTerm.trim(),
        limit: 10,
        syndicateId,
      },
    });

  function results() {
    if (loading) return <LoadingIndicator />;

    if (error) return <ErrorMessage error={error} refetch={refetch} />;

    if (!data || !data.search || variables?.text === '') return null;

    const searchResults = data.search.syndicateUsers.nodes;

    if (searchResults.length === 0)
      return (
        <p className="bg-white p-4 text-sm cursor-pointer border select-none hover:bg-gray-50">
          No result found.
        </p>
      );

    return (
      <>
        {searchResults.map(syndicateUser => {
          return !showMessageUserDialog ? (
            <div
              key={syndicateUser.id}
              className="flex flex-row justify-between align-middle bg-white p-4 cursor-pointer border hover:bg-gray-50"
              onClick={() => {
                setCurrentValue(syndicateUser);
                if (syndicateUser.conversations?.nodes.length)
                  navigate(
                    `/syndicate-dashboard/${syndicateId}/conversations/${syndicateUser.conversations?.nodes[0].id}`
                  );
                else setShowMessageUserDialog(true);
                performSearch('');
              }}
            >
              <div className="flex flex-row space-x-3 align-middle">
                <img
                  className="rounded-full w-8 h-8 object-cover border self-center"
                  src={syndicateUser.user.image?.url ?? constants.ALT_AVATAR_URL}
                  alt="Syndicate logo"
                />
                <p className="text-sm select-none self-center">{syndicateUser.user.fullName}</p>
              </div>
              <PaperAirplaneIcon className="w-5 h-5 self-center" />
            </div>
          ) : (
            <div key={syndicateUser.id} />
          );
        })}
      </>
    );
  }

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          performSearch('');
          setCurrentValue(null);
          setShowMessageUserDialog(false);
        }}
      >
        <div>
          <TextField
            ref={ref}
            fullWidth
            size="small"
            label={label}
            onBlur={e => (e.target.value = '')}
            onChange={debounce(e => performSearch(e.target.value), 500)}
            placeholder="Search by name"
          />
          <p className="text-[0.7rem] mt-1 text-gray-400">
            Search for users in your network to start or view your conversation.
          </p>
          <Popper
            open={true}
            style={{ width: ref.current?.offsetWidth, zIndex: 10000 }}
            anchorEl={ref.current}
            placement="bottom-start"
            onClick={e => e.preventDefault()}
          >
            <div>{results()}</div>
          </Popper>
        </div>
      </ClickAwayListener>
      {showMessageUserDialog && currentValue && (
        <MessageUserDialogButton
          userId={currentValue.user.id}
          syndicateId={syndicateId}
          onClose={() => setCurrentValue(null)}
          showButton={false}
        />
      )}
    </>
  );
};

export default SearchSyndicateUsersForConversation;
