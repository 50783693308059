import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  SyndicateInviteStatusEnumType,
  SyndicateInviteTypeEnumType,
  SyndicateLeadRoleEnumType,
  SyndicateStatusEnumType,
} from '__generated__/graphql';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import SyndicateLeads from 'routes/Invest/Syndicates/SyndicateSlideOver/SyndicateLeads';

import FocusLayout from 'layouts/FocusLayout';

import ErrorMessage from 'components/ErrorMessage';

import Badge from 'primitives/Badge';
import LoadingIndicator from 'primitives/LoadingIndicator';

import constants from 'utils/constants';
import industryToReadable from 'utils/enums/industry-to-readable';

import ResolveSyndicateInvite from './ResolveSyndicateInvite';

const GET_SYNDICATE_INVITE_QUERY = gql(`
  query GetSyndicateInvite($id: ID!) {
    syndicateInvite(id: $id) {
      id
      type
      status
      note
      leadRole
      syndicate {
        id
        name
        image
        tagline
        description
        createdAt
        statistics {
          industries
        }
        status
        syndicateLeads {
          id
          user {
            id
            name
            image{
              url
              name
            }
          }
        }
      }
    }
  }
`);

const InvitePage: FC = () => {
  const { id } = useParams<{ id: string }>() as { id: string };

  const { data, error, loading, refetch } = useQuery(GET_SYNDICATE_INVITE_QUERY, {
    variables: {
      id,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;
    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const invite = data.syndicateInvite;
    const syndicate = invite.syndicate;

    if (invite.status !== SyndicateInviteStatusEnumType.Active)
      return (
        <ErrorMessage
          title="Inactive invite"
          message="This invite link is no longer active. Please request a new one from the syndicate lead."
        />
      );

    if (
      invite.type !== SyndicateInviteTypeEnumType.FromSyndicateToUser &&
      invite.type !== SyndicateInviteTypeEnumType.FromSyndicateToLead
    ) {
      return (
        <ErrorMessage
          title="Invalid invite"
          message="This invite link is invalid. Please request a new one from the syndicate lead."
          refetch={() => {}}
        />
      );
    }

    if (syndicate.status !== SyndicateStatusEnumType.Active)
      return (
        <ErrorMessage title="Inactive Syndicate" message="This syndicate is no longer active." />
      );

    return (
      <>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="flex justify-center mt-4">
            <img
              className="h-14 w-14 rounded-full object-cover bg-gray-100"
              src={syndicate.image || constants.ALT_AVATAR_URL}
              alt=""
            />
          </div>
          <div className="mx-auto text-center px-4 py-6 sm:px-6 max-w-xl">
            <h3 className="text-3xl font-semibold text-gray-900">
              You have been invited to{' '}
              {invite.type === SyndicateInviteTypeEnumType.FromSyndicateToLead
                ? invite.leadRole === SyndicateLeadRoleEnumType.CarryRecipient
                  ? 'receive carry from specific deals of'
                  : 'lead'
                : 'invest with'}
              <span className="ml-2 text-3xl font-semibold leading-7 text-indigo-600">
                {invite.syndicate.name}.
              </span>
            </h3>
          </div>
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Overview</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {syndicate.tagline}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">
                  {syndicate.statistics.industries.length > 0 ? 'Investing since' : 'Started on'}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <time dateTime={dayjs(syndicate.createdAt).format('MMMM YYYY')}>
                    {dayjs(syndicate.createdAt).format('MMMM YYYY')}
                  </time>
                </dd>
              </div>
              {syndicate.statistics.industries.length > 0 && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Expertise</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <div className="inline-flex flex-wrap items-center gap-2">
                      {syndicate.statistics.industries.map(industry => {
                        const { label, color } = industryToReadable(industry!);
                        return <Badge key={industry} color={color} label={label} />;
                      })}
                    </div>
                  </dd>
                </div>
              )}
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium leading-6 text-gray-900">Team</dt>
                <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <SyndicateLeads syndicateLeads={syndicate.syndicateLeads} />
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">About</dt>
                <dd
                  className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                  dangerouslySetInnerHTML={{ __html: syndicate.description || '' }}
                />
              </div>
            </dl>
          </div>
        </div>

        <ResolveSyndicateInvite
          syndicateInviteId={invite.id}
          syndicateId={syndicate.id}
          note={invite.note}
        />
      </>
    );
  };

  return <FocusLayout>{renderContent()}</FocusLayout>;
};

export default InvitePage;
