import classNames from 'classnames';
import { FC, useState } from 'react';

const MultiSelect: FC<{
  onChange?: (data: any) => void;
  prevData: any;
  readOnly?: boolean;
  options?: {
    label: string;
    value: string;
    color?: string;
  }[];
  displaySmall?: boolean;
}> = ({ onChange, readOnly, prevData, options, displaySmall }) => {
  const [selectedOptions, setSelectedOptions] = useState(prevData);

  return (
    <>
      <div className="py-2 flex flex-row flex-wrap gap-2">
        {selectedOptions.map(selectedOption => {
          return (
            <span
              className={classNames(
                'py-1 px-2 rounded-lg bg-gray-100 cursor-pointer font-medium',
                displaySmall ? 'text-xs' : 'text-sm'
              )}
              key={selectedOption}
              onClick={() => {
                if (readOnly) return;
                setSelectedOptions(prev => prev.filter(option => option !== selectedOption));
                if (onChange) onChange(selectedOptions.filter(option => option !== selectedOption));
              }}
            >
              {options?.find(option => option.value === selectedOption)?.label}
            </span>
          );
        })}
      </div>
      {!readOnly && (
        <div
          className="p-4 border-gray-500 border-[0.5px] rounded-lg 
        grid grid-flow-row grid-cols-3 gap-2"
        >
          {options
            ?.filter(option => !selectedOptions.includes(option.value))
            .map(option => {
              return (
                <span
                  className="py-1 px-2 bg-gray-200 text-sm cursor-pointer text-center h-min rounded-full"
                  key={option.value}
                  onClick={() => {
                    if (readOnly) return;
                    setSelectedOptions(prev => [...prev, option.value]);
                    if (onChange) onChange([...selectedOptions, option.value]);
                  }}
                >
                  {option.label}
                </span>
              );
            })}
        </div>
      )}
    </>
  );
};

export default MultiSelect;
