import { FinancialInstrumentEnumType, SyndicateTypeEnumType } from '__generated__/graphql';
import { industryOptions } from 'routes/SyndicateDashboard/SyndicateDeals/CreateDeal/Memo/constants';

import MultiSelect from 'components/MultiSelect';

import Alert from 'primitives/Alert';
import Badge from 'primitives/Badge';

import constants from 'utils/constants';
import financialInstrumentToReadable from 'utils/enums/financial-instrument-to-readable';
import fundingRoundToReadable from 'utils/enums/funding-round-to-readable';
import prorataRightsToReadable from 'utils/enums/prorata-rights-to-readable';
import { formatCurrency, formatCurrencyToShort } from 'utils/format-helper';

import PitchDeck from './PitchDeck';
import ReadMoreWithDialog from './ReadMoreWithDialog';

export default function DealInformation({ deal }: { deal: any }) {
  function Stat({ name, value }: { name: string; value: string | number }) {
    return (
      <div className="flex flex-col gap-y-1 border-l-2 border-indigo-200 pl-4">
        <dt className="text-sm leading-6 text-gray-600">{name}</dt>
        <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900">{value}</dd>
      </div>
    );
  }

  if (!deal.dealTerms) {
    return <p>Error</p>;
  }

  return (
    <>
      <Alert
        type="warning"
        title="Confidential: Disclosing deal information will result in removal from Angellist India"
      />
      <div className="divide-y divide-gray-200 space-y-9">
        <div>
          <div className="flex items-start space-x-5 mt-8">
            <div className="flex-shrink-0">
              <div className="relative">
                <img
                  className="h-16 w-16 rounded-lg object-contain bg-white"
                  src={deal.companyInformation.company.image || constants.ALT_AVATAR_URL}
                  alt=""
                />
                <span className="absolute inset-0 rounded-lg shadow-inner" aria-hidden="true" />
              </div>
            </div>
            <div>
              <h1 className="text-3xl font-bold text-gray-900">
                {deal.companyInformation.company.name}
              </h1>
              <p className="text-md font-medium text-gray-500">Invited by {deal.syndicate.name}</p>
            </div>
          </div>
          <MultiSelect
            readOnly={true}
            prevData={deal.companyInformation.industries}
            options={industryOptions}
          />
          <div className="mt-8">
            <dl className="grid max-w-2xl grid-cols-1 gap-8 sm:grid-cols-4">
              <Stat name="Allocation" value={formatCurrencyToShort(deal.allocation)} />
              <Stat
                name="Raising"
                value={formatCurrencyToShort(deal.dealTerms.fundingRoundSize!)}
              />
              <Stat
                name="Round"
                value={fundingRoundToReadable(deal.dealTerms.fundingRoundName).label}
              />
              <Stat
                name={
                  deal.dealTerms.financialInstrument === FinancialInstrumentEnumType.ConvertibleNote
                    ? 'Cap'
                    : 'Valuation'
                }
                value={formatCurrencyToShort(deal.dealTerms.valuationOrCap)}
              />
              {/* {deal.dealTerms.discount && (
                <Stat name="Discount" value={`${deal.dealTerms.discount}%`} />
              )} */}
              <Stat
                name="Instrument"
                value={financialInstrumentToReadable(deal.dealTerms.financialInstrument).label}
              />
            </dl>
          </div>
        </div>
        <div className="mt-8">
          <h2 className="text-lg font-semibold text-gray-900 mt-8">Memo</h2>
          <ReadMoreWithDialog content={deal.companyInformation.memo || ''} />
        </div>

        <div className="mt-8">
          <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">PitchDeck</h2>
          <PitchDeck dealId={deal.id} />
        </div>

        <div className="mt-8">
          <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">Co-Investors</h2>
          {deal.externalInvestors.length === 0 && (
            <Alert
              type="info"
              title="Not specified"
              message="No co-investors have been specified for this deal"
            />
          )}
          <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {deal.externalInvestors.map(investor => (
              <div
                key={investor.id}
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
              >
                <div className="flex-shrink-0">
                  <img className="h-10 w-10 rounded-full" src={constants.ALT_AVATAR_URL} alt="" />
                </div>
                <div className="min-w-0 flex-1">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-900">{investor.name}</p>
                  <p className="truncate text-sm text-gray-500">{investor.description}</p>
                  <Badge
                    color={investor.isLead ? 'green' : 'indigo'}
                    label={investor.isLead ? 'Lead Investor' : 'Investor'}
                  />
                </div>
                <div className="flex-shrink-0">
                  <span className="text-sm font-medium text-indigo-600">
                    {investor.amount ? `${formatCurrency(investor.amount)}` : 'Undisclosed Amount'}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-8">
          <h2 className="text-lg font-semibold text-gray-900 mt-8">Past Financing</h2>
          <div className="prose prose-md mt-4 text-gray-500">
            {deal.dealTerms?.pastFinancingNote}
          </div>
        </div>

        <div className="mt-8">
          <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">Additional Information</h2>
          <div className="space-y-6">
            <dl className="mt-2 divide-y divide-gray-200 last:border-b-0">
              <div className="flex justify-between py-3 text-sm font-normal">
                <dt className="text-gray-500">Pro Rata Rights</dt>
                <dd className="text-gray-900">
                  {prorataRightsToReadable(deal.dealTerms?.prorataRights!).label}
                </dd>
              </div>
              <div className="flex justify-between py-3 text-sm font-normal">
                <dt className="text-gray-500">Estimated Setup Costs</dt>
                <dd className="text-gray-900">2%</dd>
              </div>
              {deal.syndicate.type === SyndicateTypeEnumType.Default ? (
                <div className="flex justify-between py-3 text-sm font-normal">
                  <dt className="text-gray-500">Lead's Investment</dt>
                  <dd className="text-gray-900">{formatCurrencyToShort(deal.gpCommitment)}</dd>
                </div>
              ) : null}
              {deal.syndicate.type === SyndicateTypeEnumType.Default ? (
                <div className="flex justify-between py-3 text-sm font-normal">
                  <dt className="text-gray-500">Total Carry</dt>
                  <dd className="text-gray-900">{deal.dealTerms?.availableCarryPercentage}%</dd>
                </div>
              ) : null}
              <div className="flex justify-between py-3 text-sm font-normal">
                <dt className="text-gray-500">Min. Investment</dt>
                <dd className="text-gray-900">
                  {formatCurrencyToShort(deal.dealTerms?.minimumInvestment)}
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div className="mt-8">
          <h2 className="text-lg font-semibold text-gray-900 mt-8">Risks and Disclaimers</h2>
          <div
            className="prose prose-md mt-4 text-gray-500"
            dangerouslySetInnerHTML={{ __html: deal.dealDisclosures?.risksNote || '' }}
          />
          <div className="prose prose-md mt-4 text-gray-500">
            {deal.dealDisclosures?.otherDisclosuresNote}
          </div>
          <div>
            {deal.dealDisclosures?.doesLeadHaveAdvisoryShares && (
              <Alert
                type="warning"
                title="Advisory Shares"
                message="The lead investor has advisory shares in this company"
              />
            )}
            {deal.dealDisclosures?.doesLeadHaveRelativesInCompany && (
              <Alert
                type="warning"
                title="Relatives in Company"
                message="The lead investor has relatives in this company"
              />
            )}
            {deal.dealDisclosures?.hasLeadInvestedInCompanyBefore && (
              <Alert
                type="warning"
                title="Previous Investment"
                message="The lead investor has previously invested in this company"
              />
            )}
            {deal.dealDisclosures?.hasSameTermsForCoInvestors === false && (
              <Alert
                type="warning"
                title="Different Terms for Co-Investors"
                message="You may not be investing on the same terms as the co-investors"
              />
            )}
            {deal.dealDisclosures?.isLeadEmployeeAtCompany && (
              <Alert
                type="warning"
                title="Employee"
                message="The lead investor is an employee at this company"
              />
            )}
            {deal.dealDisclosures?.isLeadInvestingFromFund && (
              <Alert
                type="warning"
                title="Fund Investment"
                message="The lead investor is investing from a fund"
              />
            )}
            {deal.dealDisclosures?.isLeadInvestingOnSameTerms === false && (
              <Alert
                type="warning"
                title="Different Terms for Lead"
                message="The lead investor is not investing on the same terms as co-investors"
              />
            )}
          </div>
        </div>

        <div className="pb-32 pt-8">
          <div className="space-y-4">
            <p className="text-xs font-light text-gray-500">
              AngelList India and its affiliates do not provide investment advice to investors, and
              the information on this page should not be relied upon as research, investment advice
              or a recommendation of any kind.
            </p>
            <p className="text-xs font-light text-gray-500">
              Past performance is not indicative of future returns. Examples of past investments by
              syndicates are purely for illustrative purposes. There is no guarantee that any
              syndicate will achieve the same exposure to or quality of portfolio companies held by
              any existing AngelList India syndicate funds.
            </p>
            <p className="text-xs font-light text-gray-500">
              Some institutional and professional investors may have additional material information
              about this deal. These investors may also have access to a broader set of deals than
              is available to other backers on the AngelList India platform, may be able to view
              deals before other backers, and have certain other preferential deal access and
              allocation rights. The existence of these additional rights and privileges may be
              material to your investment decision.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
