import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ClosingStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

const CLOSINGS_QUERY = gql(`
  query getSignedCommitmentsOfUser(
    $cursor: ID
    $limit: Int
    $filters: DashboardClosingsFilterType
  ) {
    dashboard {
      id
      closings(cursor: $cursor, limit: $limit, filters: $filters) {
        nodes {
          id
          deal {
            id
            closingDate
            companyInformation {
              id
              company {
                id
                image
                name
              }
            }
            syndicate {
              id
              name
            }
          }
          investmentEntity {
            id
            name
          }
          amount
          status
          createdAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const CommitmentsOfUser: FC = () => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore } = useQuery(CLOSINGS_QUERY, {
    variables: {
      filters: {
        statuses: [
          ClosingStatusEnumType.Created,
          ClosingStatusEnumType.Committed,
          ClosingStatusEnumType.Transferred,
        ],
      },
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  if (!data.dashboard.closings) return <p>No commitments found</p>;

  const closings = data.dashboard.closings.nodes;
  const pageInfo = data.dashboard.closings.pageInfo;

  return (
    <DataTable
      data={closings}
      onClick={closing => navigate(`/invest/deal/${closing.deal.id}`)}
      emptyListTitle="Your committed investments will appear here"
      emptyListDescription="When your investment is funded and approved, it will move to your Portfolio."
      columns={[
        {
          label: 'Deal',
          fieldName: 'deal.companyInformation.company',
          type: 'COMPANY',
        },
        {
          label: 'Syndicate',
          fieldName: 'deal.syndicate.name',
        },
        {
          label: 'Investment Entity',
          fieldName: 'investmentEntity.name',
        },
        {
          label: 'Amount',
          fieldName: 'amount',
          type: 'CURRENCY',
        },
        {
          label: 'Status',
          fieldName: 'status',
          type: 'STATUS',
        },
        {
          label: 'Committed At',
          fieldName: 'createdAt',
          type: 'DATETIME',
        },
      ]}
      hasNextPage={pageInfo.hasNextPage}
      onLoadMore={() =>
        fetchMore({
          variables: {
            cursor: pageInfo.cursor,
          },
        })
      }
    />
  );
};

export default CommitmentsOfUser;
