import { useQuery } from '@apollo/client';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { CheckCircleIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { CurrencyRupeeIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__/gql';
import {
  DashboardFundsEnumType,
  DashboardSyndicatesEnumType,
  SyndicateTypeEnumType,
} from '__generated__/graphql';
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import fundIcon from 'media/icons/fund.svg';
import syndicateIcon from 'media/icons/syndicate.svg';

const PROFILE_SWITCH_BAR_QUERY = gql(`
  query ProfileSwitchBarQuery(
    $filters: DashboardSyndicatesFilterType
    $fundFilters: DashboardFundsFilterType
    $cursor: ID
    $limit: Int
  ) {
    dashboard {
      id
      user {
        id
        name
      }
      syndicates(filters: $filters, cursor: $cursor, limit: $limit) {
        nodes {
          id
          name
          image
          status
          type
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
      funds(filters: $fundFilters) {
        nodes {
          id
          name
          image
          status
        }
      }
    }
  }
`);

function ProfileIcon({
  image,
  name,
  type,
}: {
  image?: string | null;
  name: string;
  type: 'Syndicate' | 'Fund' | 'Investor' | 'Founder';
}) {
  const handleError = event => {
    event.target.src = type === 'Syndicate' ? syndicateIcon : fundIcon;
  };

  if (type === 'Investor') {
    return (
      <CurrencyRupeeIcon
        className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
        aria-hidden="true"
      />
    );
  }

  return (
    <img
      src={image || (type === 'Syndicate' ? syndicateIcon : fundIcon)}
      alt={name}
      onError={handleError}
      className="h-9 w-9 text-gray-600 group-hover:text-indigo-600 rounded-lg object-cover"
    />
  );
}

function ProfileTab({
  id,
  name,
  type,
  image,
  isActive,
}: {
  id?: string;
  name: string;
  type: 'Syndicate' | 'Fund' | 'Investor' | 'Founder';
  image?: string | null;
  isActive: boolean;
}) {
  return (
    <div
      key={name}
      className={classNames(
        'group relative flex items-center gap-x-4 rounded-lg p-1 hover:bg-gray-50',
        type === 'Investor' && 'border-b border-gray-100'
      )}
    >
      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
        <ProfileIcon image={image} name={name} type={type} />
      </div>
      <div className="p-1">
        <PopoverButton
          as={NavLink}
          to={
            type === 'Syndicate'
              ? `/syndicate-dashboard/${id}`
              : type === 'Founder'
              ? `/syndicate-dashboard/${id}/deals`
              : type === 'Fund'
              ? `/fund-dashboard/${id}`
              : '/invest'
          }
          key={name}
          className="font-semibold mt-0 text-gray-900"
        >
          {name}
          <span className="absolute inset-0" />
        </PopoverButton>
        <p className="text-gray-600 mt-0">{type}</p>
      </div>
      <span className="flex-1" />
      {isActive && <CheckCircleIcon className="mr-2 h-6 w-6 text-indigo-600" />}
    </div>
  );
}

export default function ProfileSwitchBar() {
  const { syndicateId, fundId } = useParams();

  const [currentProfile, setCurrentProfile] = useState<{
    id: string;
    name: string;
    type: 'Syndicate' | 'Fund' | 'Investor' | 'Founder';
    image?: string | null;
  }>();

  const { data, error, loading } = useQuery(PROFILE_SWITCH_BAR_QUERY, {
    variables: {
      filters: {
        type: DashboardSyndicatesEnumType.Lead,
      },
      fundFilters: {
        type: DashboardFundsEnumType.Lead,
      },
    },
  });

  useEffect(() => {
    if (!data) return;

    const syndicates = data.dashboard.syndicates.nodes;
    const funds = data.dashboard.funds.nodes;

    if (syndicateId) {
      const activeSyndicate = syndicates.find(syndicate => syndicate.id === syndicateId);
      if (!activeSyndicate) {
        return;
      }
      const isRuvSyndicate = activeSyndicate.type === SyndicateTypeEnumType.Ruv;
      setCurrentProfile({
        id: activeSyndicate.id,
        name: activeSyndicate.name,
        type: isRuvSyndicate ? 'Founder' : 'Syndicate',
        image: activeSyndicate.image,
      });
    }

    if (fundId) {
      const activeFund = funds.find(fund => fund.id === fundId);
      if (!activeFund) {
        return;
      }
      setCurrentProfile({
        id: activeFund.id,
        name: activeFund.name,
        type: 'Fund',
        image: activeFund.image,
      });
    }

    if (!syndicateId && !fundId) {
      setCurrentProfile({
        id: data.dashboard.user.id,
        name: data.dashboard.user.name,
        type: 'Investor',
      });
    }
  }, [data, fundId, syndicateId]);

  if (loading || error || !data || !currentProfile) return null;

  const syndicates = data.dashboard.syndicates.nodes;
  const funds = data.dashboard.funds.nodes;

  return (
    <Popover className="relative -mt-2 pb-3 border-b border-gray-200">
      <PopoverButton className="w-full focus:outline-none">
        <div
          key={'invest'}
          className="group relative flex items-center gap-x-2 rounded-xl p-2 hover:bg-indigo-200 bg-indigo-100"
        >
          <div className="flex h-9 w-9 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
            <ProfileIcon
              image={currentProfile.image}
              name={currentProfile.name}
              type={currentProfile.type}
            />
          </div>
          <div className="">
            <p className="text-sm text-left font-medium text-gray-900 w-32 whitespace-nowrap truncate">
              {currentProfile.name}
            </p>
            <p className="text-xs text-left text-gray-600">{currentProfile.type}</p>
          </div>
          <span className="flex-1" />
          <ChevronDownIcon className="mr-2 h-5 w-5 text-gray-600" />
        </div>
        <p className="text-xs underline text-indigo-400 text-left mt-0.5 ml-2">Switch Profile</p>
      </PopoverButton>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel className="fixed left-0 z-20 mt-2 flex w-screen max-w-max px-4">
          <div className="w-screen max-w-sm flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4 max-h-96 overflow-auto">
              <ProfileTab
                name={data.dashboard.user.name}
                type="Investor"
                isActive={currentProfile.type === 'Investor'}
              />
              {syndicates.map(syndicate => (
                <ProfileTab
                  key={syndicate.id}
                  id={syndicate.id}
                  name={syndicate.name}
                  type={syndicate.type === SyndicateTypeEnumType.Ruv ? 'Founder' : 'Syndicate'}
                  image={syndicate.image}
                  isActive={syndicate.id === currentProfile.id}
                />
              ))}
              {funds.map(fund => (
                <ProfileTab
                  key={fund.id}
                  id={fund.id}
                  name={fund.name}
                  type="Fund"
                  image={fund.image}
                  isActive={fund.id === currentProfile.id}
                />
              ))}
            </div>
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
}
