import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC } from 'react';

import Badge from 'primitives/Badge';

import constants from 'utils/constants';

const Message: FC<{
  message: string;
  type: 'SELF' | 'OTHER';
  isLead: boolean;
  sender: string;
  createdAt: string;
}> = ({ message, type, sender, createdAt, isLead }) => {
  return (
    <>
      <div
        className={classNames(
          'flex items-end gap-2.5',
          type === 'SELF' ? 'justify-end' : 'justify-start'
        )}
      >
        {type === 'OTHER' && (
          <img className="w-8 h-8 rounded-full" src={constants.ALT_AVATAR_URL} alt="Avatar" />
        )}
        <div
          className={classNames(
            'flex flex-col w-full max-w-[320px] leading-1.5 p-4 border-gray-200 bg-gray-100',
            type === 'SELF' ? 'rounded-xl' : 'rounded-e-xl rounded-ss-xl'
          )}
        >
          <div className="flex items-center justify-between">
            <span className="text-sm font-semibold text-gray-900">{sender}</span>
            <span className="text-xs font-normal text-gray-500">
              {isLead && <Badge label="Lead" color="indigo" />}
            </span>
          </div>
          <p className="text-sm font-normal py-2.5 text-gray-900">{message}</p>
          <span className="text-xs font-normal text-gray-500">
            {dayjs(createdAt).format('hh:mm A MMMM DD, YYYY')}
          </span>
        </div>
      </div>
    </>
  );
};

export default Message;
