import { useQuery } from '@apollo/client';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__/gql';
import { SyndicateTypeEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FocusLayout from 'layouts/FocusLayout';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import InvestInDealDialogButton from './InvestInDealDialogButton';

const DEAL_QUERY = gql(`
  query GetDealInviteForConfirmation($id: ID!) {
    dealInvite(id: $id) {
      id
      status
      minimumInvestmentAmount
      deal {
        id
        companyInformation {
          id
          company {
            id
            name
          }
        }
        syndicate {
          id
          name
          type
          userConnections {
            isUserPartOfSyndicate
          }
        }
        dealTerms {
          id
          minimumInvestment
        }
      }
    }
  }
`);

const DealConfirmPage: FC = () => {
  const navigate = useNavigate();
  const { dealInviteId } = useParams<{ dealInviteId: string }>() as { dealInviteId: string };

  const { loading, error, data, refetch } = useQuery(DEAL_QUERY, {
    variables: {
      id: dealInviteId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const dealInvite = data.dealInvite;
  const inviteMinimumInvestment = dealInvite.minimumInvestmentAmount;
  const deal = dealInvite.deal;
  const isUserPartOfSyndicate = deal.syndicate.userConnections.isUserPartOfSyndicate;
  const isRuv = deal.syndicate.type === SyndicateTypeEnumType.Ruv;
  const showSyndicateApplicationOption = !isUserPartOfSyndicate && !isRuv;

  if (!deal || !deal.dealTerms) {
    return (
      <ErrorMessage
        title="Invalid Deal Invite"
        message="There is an issue with this deal invite. Please contact the syndicate lead for a new invite."
      />
    );
  }

  if (dealInvite.status === 'COMMITTED') {
    navigate(`/invest/deal/${deal.id}`);
  }

  return (
    <FocusLayout>
      <>
        <button type="button" onClick={() => navigate(-1)}>
          <div className="flex items-center flex-nowrap">
            <ArrowLeftIcon className="h-6 w-6 mr-3" aria-hidden="true" />
          </div>
        </button>
        <div className="divide-y divide-gray-200 space-y-9">
          <div className="my-8">
            <h2 className="text-3xl font-bold text-gray-900">
              Confirm your investment into{' '}
              <span className="text-indigo-600"> {deal.companyInformation.company.name}</span>
            </h2>
            <h3 className="text-lg font-base text-gray-700 mt-1 max-w-2xl">
              We'll notify the syndicate of your interest in this deal. Wiring instructions will be
              sent to you once the syndicate lead has confirmed your investment.
            </h3>
          </div>
        </div>
      </>
      <div className="grid grid-cols-5 gap-x-2">
        <div className="col-span-5 bg-white p-4 rounded-lg">
          <InvestInDealDialogButton
            dealId={deal.id}
            minimumInvestment={inviteMinimumInvestment || deal.dealTerms.minimumInvestment}
            syndicateName={deal.syndicate.name}
            showSyndicateApplicationOption={showSyndicateApplicationOption}
          />
        </div>
      </div>
    </FocusLayout>
  );
};

export default DealConfirmPage;
