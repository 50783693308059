import { DealCompanyStageEnumType } from '__generated__/graphql';

export default function stageToReadable(stage: DealCompanyStageEnumType): {
  label: string;
  color: string;
} {
  switch (stage) {
    case DealCompanyStageEnumType.PreLaunch:
      return {
        label: 'Pre-Launch',
        color: 'blue',
      };
    case DealCompanyStageEnumType.PreRevenue:
      return {
        label: 'Pre-Revenue',
        color: 'amber',
      };
    case DealCompanyStageEnumType.PostRevenue:
      return {
        label: 'Post-Revenue',
        color: 'purple',
      };
    case DealCompanyStageEnumType.Profitable:
      return {
        label: 'Profitable',
        color: 'green',
      };
    default:
      return {
        label: '-',
        color: 'gray',
      };
  }
}
