import { FC } from 'react';

import DetailsPanel from 'components/DetailsPanel';

import disclosuresData from './disclosures-data.json';

const Disclosures: FC = () => (
  <div>
    <h3 className="text-2xl font-semibold leading-6 text-gray-900 mb-5">Disclosures</h3>
    <DetailsPanel data={disclosuresData} />
  </div>
);

export default Disclosures;
