import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { DateRangePicker } from '@mui/lab';
import { TextField as MuiTextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import React, { Dispatch, FC, useContext } from 'react';

import CheckboxGroup from 'primitives/CheckboxGroup';
import RadioGroup from 'primitives/RadioGroup';
import Select from 'primitives/Select';
import Switch from 'primitives/Switch';
import TextArea from 'primitives/TextArea';
import TextField from 'primitives/TextField';
import UploadInput from 'primitives/UploadInput';

import { Validators } from 'utils/text-validators';

import FormPanelContext from './formPanelContext';
import { ActionType, UpdateStateActionType } from './formPanelReducer';

type FormInputProps = {
  fieldName: string;
  defaultValue: any;
  label: string;
  helperText?: string;
  editable?: boolean;
  readOnlyMode?: boolean;
  uppercase?: boolean;
  options?: {
    label: string;
    value: string;
    color?: string;
  }[];
  validators?: Validators;
  fullWidth?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  maxFileSize?: number;
  placeholder?: string;
  hidden?: boolean;
  onlyAcceptsPDF?: boolean;
  conditionsToShow?: {
    matches: {
      field: string;
      condition: '===' | '!==' | '>' | '<' | '>=' | '<=';
      value: string;
    }[];
    type?: 'every' | 'some';
  };
  customInput?: React.ElementType;
  span?: 'full' | '1' | '2' | '3';
  compact?: boolean;
} & (
  | {
      type:
        | 'text'
        | 'number'
        | 'password'
        | 'select'
        | 'select_with_search'
        | 'textarea'
        | 'currency'
        | 'upload'
        | 'avatar'
        | 'date'
        | 'switch'
        | 'image_url'
        | 'date-range'
        | 'radio-group'
        | 'checkbox-group'
        | 'custom';
      defaultValue: any;
    }
  | {
      type: 'multi_select';
      defaultValue: any[];
    }
);

const FormInput: FC<FormInputProps> = ({
  fieldName,
  type,
  defaultValue, // Though it's not used here, we need it to be passed when declaring inside FormPanel
  label,
  helperText,
  editable = true,
  readOnlyMode = false,
  options,
  fullWidth = true,
  validators,
  minDate,
  maxDate,
  maxFileSize,
  placeholder,
  conditionsToShow,
  customInput,
  span = 'full',
  compact = false,
  uppercase = false,
  hidden,
  onlyAcceptsPDF,
}) => {
  const { formState, formDispatch } = useContext(FormPanelContext);
  const updateStateDispatch: Dispatch<ActionType & UpdateStateActionType> = formDispatch;

  function hideFormInput() {
    if (hidden) return true;
    if (!conditionsToShow) return false;
    if (!conditionsToShow.matches.length) return false;

    if (conditionsToShow.type === 'some')
      return !conditionsToShow.matches.some(c => {
        // eslint-disable-next-line
        return eval(`"${formState.data[c.field]}" ${c.condition} "${c.value}"`);
      });
    else
      return !conditionsToShow.matches.every(c => {
        // eslint-disable-next-line
        return eval(`"${formState.data[c.field]}" ${c.condition} "${c.value}"`);
      });
  }

  if (validators) {
    if (validators.required !== undefined) {
      validators.required = !hideFormInput();
    }
    if (validators.isPhoneNumber) {
      validators.isPhoneNumber = !hideFormInput();
    }
  }

  const handleChange = value =>
    updateStateDispatch({
      type: 'UPDATE_STATE',
      payload: {
        fieldName,
        value,
      },
    });

  if (hideFormInput()) {
    return null;
  }

  if (validators?.dependsOn && !formState.data[validators.dependsOn]) {
    return null;
  }

  const inputField = () => {
    switch (type) {
      case 'text':
        return (
          <TextField
            label={label}
            placeholder={placeholder}
            fullWidth={fullWidth}
            value={formState.data[fieldName]}
            onChange={e => handleChange(e.target.value)}
            error={formState.errors ? formState.errors[fieldName] : ''}
            helperText={helperText}
            readOnly={readOnlyMode || !editable}
            uppercase={uppercase}
          />
        );
      case 'number':
        return (
          <TextField
            type="number"
            label={label}
            fullWidth={fullWidth}
            placeholder={placeholder}
            value={formState.data[fieldName]}
            onChange={e => handleChange(e.target.value ? parseFloat(e.target.value) : '')}
            error={formState.errors ? formState.errors[fieldName] : ''}
            helperText={helperText}
            readOnly={readOnlyMode || !editable}
          />
        );
      case 'password':
        return (
          <TextField
            type="password"
            label={label}
            placeholder={placeholder}
            fullWidth={fullWidth}
            value={formState.data[fieldName]}
            onChange={e => handleChange(e.target.value)}
            error={formState.errors ? formState.errors[fieldName] : ''}
            helperText={helperText}
            readOnly={readOnlyMode || !editable}
          />
        );
      case 'currency':
        return (
          <TextField
            type="currency"
            label={label}
            fullWidth={fullWidth}
            placeholder={placeholder}
            value={formState.data[fieldName]}
            leadingIcon={true}
            onChange={e =>
              handleChange(!isNaN(Number(e.target.value)) ? Number(e.target.value) : '')
            }
            error={formState.errors ? formState.errors[fieldName] : ''}
            helperText={helperText}
            readOnly={readOnlyMode || !editable}
          />
        );
      case 'select':
        return (
          <Select
            label={label}
            fullWidth={fullWidth}
            placeholder={placeholder}
            value={formState.data[fieldName]}
            error={formState.errors ? formState.errors[fieldName] : ''}
            onChange={value => handleChange(value)}
            helperText={helperText}
            options={options!}
            readOnly={readOnlyMode || !editable}
          />
        );
      case 'switch':
        if (readOnlyMode || !editable) {
          return (
            <Switch
              checked={formState.data[fieldName]}
              label={label}
              helperText={helperText}
              disabled={true}
            />
          );
        }

        return (
          <Switch
            checked={formState.data[fieldName]}
            onChange={checked => handleChange(checked)}
            label={label}
            helperText={helperText}
            disabled={readOnlyMode || !editable}
          />
        );
      case 'textarea':
        return (
          <TextArea
            minRows={3}
            fullWidth={fullWidth}
            label={label}
            placeholder={placeholder}
            value={formState.data[fieldName]}
            onChange={e => handleChange(e.target.value)}
            error={formState.errors ? formState.errors[fieldName] : ''}
            helperText={helperText}
            readOnly={readOnlyMode || !editable}
          />
        );
      case 'upload':
        return (
          <UploadInput
            label={label}
            value={formState.data[fieldName]}
            onChange={([file]) => handleChange(file)}
            helperText={helperText}
            error={formState.errors ? formState.errors[fieldName] : ''}
            readOnly={readOnlyMode || !editable}
            maxFileSize={maxFileSize}
            isPdf={onlyAcceptsPDF}
          />
        );
      case 'avatar':
        return (
          <UploadInput
            label={label}
            value={formState.data[fieldName]}
            onChange={([file]) => handleChange(file)}
            helperText={formState.errors ? formState.errors[fieldName] : ''}
            readOnly={readOnlyMode || !editable}
            isAvatar={true}
            maxFileSize={maxFileSize}
          />
        );
      case 'image_url':
        return (
          <TextField
            type="image_url"
            label={label}
            fullWidth={fullWidth}
            value={formState.data[fieldName]}
            onChange={e => handleChange(e.target.value)}
            error={formState.errors ? formState.errors[fieldName] : ''}
            helperText={helperText}
            readOnly={readOnlyMode || !editable}
          />
        );
      case 'date':
        if (readOnlyMode || !editable) {
          return (
            <div>
              <p className="block text-sm font-medium leading-6 text-gray-900">{label}</p>
              {helperText ? <p className="text-sm text-gray-500">{helperText}</p> : null}
              <div className="mt-2 text-sm">
                {formState.data[fieldName]
                  ? dayjs(formState.data[fieldName]).format('DD/MM/YYYY')
                  : '-'}
              </div>
            </div>
          );
        }

        return (
          <>
            <p className="block text-sm mb-1 font-medium leading-6 text-gray-900">{label}</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                components={{
                  OpenPickerIcon: () => <CalendarDaysIcon className="w-5 h-5" />,
                }}
                value={formState.data[fieldName] ? formState.data[fieldName] : null}
                onChange={newValue => {
                  handleChange(newValue);
                }}
                minDate={minDate}
                maxDate={maxDate}
                // @ts-ignore
                renderInput={params => <MuiTextField size="small" fullWidth {...params} />}
                inputFormat="DD/MM/YYYY"
              />
              <p className="mt-2 text-sm text-red-600" id="error">
                {formState.errors && formState.errors[fieldName]}
              </p>
            </LocalizationProvider>
            {helperText ? <p className="text-sm text-gray-500">{helperText}</p> : null}
          </>
        );
      case 'date-range':
        if (readOnlyMode || !editable) {
          const startDate = formState.data[fieldName][0].format('DD/MM/YYYY');
          const endDate = formState.data[fieldName][1].format('DD/MM/YYYY');

          return (
            <div>
              <p className="block text-sm font-medium leading-6 text-gray-900">{label}</p>
              <div className="mt-2 text-sm">{`${startDate} ${endDate}`}</div>
              {helperText ? <p className="text-sm text-gray-500">{helperText}</p> : null}
            </div>
          );
        }

        return (
          <>
            <p className="block text-sm font-medium leading-6 text-gray-900">{label}</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                components={{
                  OpenPickerIcon: () => <CalendarDaysIcon className="w-5 h-5" />,
                }}
                value={formState.data[fieldName]}
                onChange={newValue => handleChange(newValue)}
                PopperProps={{
                  placement: 'bottom-start',
                }}
                minDate={minDate}
                maxDate={maxDate}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <MuiTextField size="small" {...startProps} fullWidth label={undefined} />
                    <div style={{ margin: '0 1rem' }}> to </div>
                    <MuiTextField size="small" {...endProps} fullWidth label={undefined} />
                  </React.Fragment>
                )}
                inputFormat="DD/MM/YYYY"
              />
              <p>{formState.errors && formState.errors[fieldName]}</p>
            </LocalizationProvider>
            {helperText ? <p className="text-sm text-gray-500">{helperText}</p> : null}
          </>
        );
      case 'radio-group':
        return (
          <RadioGroup
            value={formState.data[fieldName]}
            onChange={value => handleChange(value)}
            options={options!}
            label={label}
            helperText={helperText}
            disabled={readOnlyMode || !editable}
            error={formState.errors ? formState.errors[fieldName] : ''}
          />
        );
      case 'checkbox-group':
        return (
          <CheckboxGroup
            value={formState.data[fieldName]}
            onChange={value => handleChange(value)}
            options={options!}
            label={label}
            helperText={helperText}
            disabled={readOnlyMode || !editable}
            displayCompact={compact}
            error={formState.errors ? formState.errors[fieldName] : ''}
          />
        );
      case 'custom':
        const CustomInput = customInput ? customInput : () => <></>;

        return (
          <>
            <CustomInput
              value={formState.data[fieldName]}
              onChange={newValue => handleChange(newValue)}
              readOnly={readOnlyMode}
              label={label}
              helperText={helperText}
              options={options}
              editable={editable}
            />
            <p>{formState.errors && formState.errors[fieldName]}</p>
          </>
        );
      default:
        return (
          <TextField
            label={label}
            fullWidth={fullWidth}
            value={formState.data[fieldName]}
            onChange={e => handleChange(e.target.value)}
            error={formState.errors ? formState.errors[fieldName] : ''}
            helperText={helperText}
            readOnly={readOnlyMode || !editable}
          />
        );
    }
  };

  return <div className={`col-span-${span}`}>{inputField()}</div>;
};

export default FormInput;
