import { SyndicateLeadRoleEnumType } from '__generated__/graphql';

export default function syndicateLeadRoleToReadable(role: SyndicateLeadRoleEnumType): {
  label: string;
  color: string;
} {
  switch (role) {
    case SyndicateLeadRoleEnumType.Owner:
      return {
        label: 'Owner',
        color: 'green',
      };
    case SyndicateLeadRoleEnumType.Admin:
      return {
        label: 'Admin',
        color: 'amber',
      };
    case SyndicateLeadRoleEnumType.CarryRecipient:
      return {
        label: 'Carry Recipient',
        color: 'red',
      };
    case SyndicateLeadRoleEnumType.AngellistIndia:
      return {
        label: 'AngelList India',
        color: 'indigo',
      };
    default:
      return {
        label: role,
        color: 'gray',
      };
  }
}
