import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_KYC_ADDRESS_MUTATION = gql(`
  mutation UpdateKycAddress(
    $id: ID!
    $lineOne: String!
    $lineTwo: String
    $city: String!
    $state: String!
    $country: String!
    $pincode: String!
  ) {
    updateKycAddress(
      id: $id
      lineOne: $lineOne
      lineTwo: $lineTwo
      city: $city
      state: $state
      country: $country
      pincode: $pincode
    ) {
      id
      address {
        lineOne
        lineTwo
        city
        state
        country
        pincode
      }
    }
  }
`);

const UpdateKycAddress: FC<{
  id: string;
  lineOne: string;
  lineTwo?: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
}> = ({ id, lineOne, lineTwo, city, state, country, pincode }) => {
  const [updateKycAddress, { loading, error }] = useMutation(UPDATE_KYC_ADDRESS_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateKycAddress({
          variables: {
            id,
            lineOne: data.lineOne,
            lineTwo: data.lineTwo,
            city: data.city,
            state: data.state,
            country: data.country,
            pincode: data.pincode,
          },
        });
      }}
      disableEdit={true}
      title="Address"
    >
      <FormInput type="text" fieldName="lineOne" label="Line One" defaultValue={lineOne} />
      <FormInput type="text" fieldName="lineTwo" label="Line Two" defaultValue={lineTwo} />
      <FormInput type="text" fieldName="city" label="City" defaultValue={city} />
      <FormInput type="text" fieldName="state" label="State" defaultValue={state} />
      <FormInput type="text" fieldName="country" label="Country" defaultValue={country} />
      <FormInput type="text" fieldName="pincode" label="Pincode" defaultValue={pincode} />
    </FormPanelWithReadMode>
  );
};

export default UpdateKycAddress;
