import { FC } from 'react';

import UpdatePasswordDialogButton from './UpdatePasswordDialogButton';

const SecuritySettingsPage: FC = () => {
  return (
    <div className="flex flex-row border rounded-md justify-between align-middle p-4">
      <div className="flex flex-row space-x-4 align-middle">
        <span className="text-sm text-gray-500 self-center">Password</span>
        <span className="text-base self-end">************</span>
      </div>
      <UpdatePasswordDialogButton />
    </div>
  );
};

export default SecuritySettingsPage;
