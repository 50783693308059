import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { UserStatusEnumType } from '__generated__/graphql';
import { Navigate, useLocation } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import { isAuthenticated } from 'utils/auth';

import ErrorMessage from './ErrorMessage';

const USER_ACCESS_QUERY = gql(`
  query UserAccess {
    dashboard {
      id
      user {
        id
        status
      }
    }
  }
`);

export default function ProtectedRoute({ children }: { children: JSX.Element }) {
  let location = useLocation();
  const { loading, error, data } = useQuery(USER_ACCESS_QUERY);

  if (!isAuthenticated()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (loading) return <LoadingIndicator />;
  if (error || !data) return <ErrorMessage error={error} />;

  const user = data.dashboard.user;

  if (user.status === UserStatusEnumType.Suspended && location.pathname !== '/suspended-user') {
    return <Navigate to="/suspended-user" />;
  }

  if (
    user.status === UserStatusEnumType.UnderReview &&
    location.pathname !== '/under-review-user'
  ) {
    return <Navigate to="/under-review-user" />;
  }

  if (
    user.status === UserStatusEnumType.PendingOnboarding &&
    !location.pathname.includes('/onboarding')
  ) {
    return <Navigate to="/onboarding" />;
  }

  if (
    user.status === UserStatusEnumType.PendingEmailVerification ||
    user.status === UserStatusEnumType.Inactive
  ) {
    return <Navigate to="/inactive-user" />;
  }

  return children;
}
