import classNames from 'classnames';
import { FC } from 'react';

const AuthFooter: FC<{ styles?: string }> = ({ styles }) => {
  return (
    <div className={classNames('w-full flex flex-row justify-center space-x-2 text-sm', styles)}>
      <a
        href="https://angellistindia.notion.site/AngelList-India-s-Privacy-Policy-104177cec5db4f449bb10ceb14936945"
        target="__blank"
        className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
      >
        Privacy Policy
      </a>
      <p className="text-gray-500">•</p>
      <a
        href="https://angellistindia.notion.site/AngelList-India-s-Terms-of-Service-344fff7dfc2a405d98c247428c699709"
        target="__blank"
        className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
      >
        Terms of Service
      </a>
      <p className="text-gray-500">•</p>
      <a
        href="https://medium.com/@AngelListIndia/introducing-a-new-platform-for-india-854cc8b909a4"
        target="__blank"
        className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
      >
        Why a new platform?
      </a>
    </div>
  );
};

export default AuthFooter;
