import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import DealFileTypeOptions from '../constants';

const GET_INVESTMENT_ENTITY_DOCUMENT_QUERY = gql(`
  query InvestmentEntityDocument($id: ID!) {
    investmentEntityDocument(id: $id) {
      id
      type
      name
      description
      url
    }
  }
`);

const InvestmentEntityDocumentSlideOver: FC<{
  open: boolean;
  documentId: string;
  onClose: () => void;
}> = ({ open = false, documentId, onClose }) => {
  const { loading, error, data, refetch } = useQuery(GET_INVESTMENT_ENTITY_DOCUMENT_QUERY, {
    variables: {
      id: documentId,
    },
    skip: !open,
  });

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const investmentEntityDocument = data.investmentEntityDocument;

    return (
      <>
        <FormPanelWithReadMode
          loading={loading}
          error={error}
          onSubmit={data => {}}
          title="Investment Entity Document"
        >
          <FormInput
            type="text"
            fieldName="name"
            label="Name"
            defaultValue={investmentEntityDocument.name}
          />
          <FormInput
            type="text"
            fieldName="description"
            label="Description"
            defaultValue={investmentEntityDocument.description}
          />
          <FormInput
            type="select"
            fieldName="type"
            label="Type"
            defaultValue={investmentEntityDocument.type}
            options={DealFileTypeOptions}
          />
        </FormPanelWithReadMode>
        <Button onClick={() => window.open(investmentEntityDocument.url, '_blank')}>
          View Document
        </Button>
      </>
    );
  }

  return (
    <SlideOver
      open={open}
      onClose={onClose}
      title="Investment Entity Document"
      subTitle="Manage and audit deal document details"
    >
      {renderContent()}
    </SlideOver>
  );
};

export default InvestmentEntityDocumentSlideOver;
