import { useMutation } from '@apollo/client';
import { UserIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

const CREATE_EXTERNAL_INVESTOR_MUTATION = gql(`
  mutation CreateDealExternalInvestor(
    $dealId: ID!
    $name: String!
    $description: String
    $amount: Float!
    $isLead: Boolean!
  ) {
    createDealExternalInvestor(
      dealId: $dealId
      name: $name
      description: $description
      amount: $amount
      isLead: $isLead
    ) {
      id
      name
      description
      amount
      isLead
    }
  }
`);

const CreateDealExternalInvestorDialogButton: FC<{
  dealId: string;
}> = ({ dealId }) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [createDealExternalInvestor, { loading, error }] = useMutation(
    CREATE_EXTERNAL_INVESTOR_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;

        cache.modify({
          id: cache.identify({
            __typename: 'DealType',
            id: dealId,
          }),
          fields: {
            externalInvestors(existingExternalInvestors = []) {
              const newExternalInvestorRef = cache.writeFragment({
                data: data.createDealExternalInvestor,
                fragment: gql(`
                fragment NewExternalInvestor on DealExternalInvestorType {
                  id
                  name
                  description
                  amount
                  isLead
                }
              `),
              });

              return [...existingExternalInvestors, newExternalInvestorRef];
            },
          },
        });
      },
    }
  );

  return (
    <>
      <Button leadingIcon={<UserIcon />} variant="secondary" onClick={() => setOpen(true)}>
        Add an External Investor
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel>
          <DialogTitle>Add an external investor</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => setOpen(false)}
            onSubmit={data => {
              createDealExternalInvestor({
                variables: {
                  dealId,
                  name: data.name,
                  description: data.description,
                  amount: data.amount,
                  isLead: data.isLead === 'true',
                },
              }).then(() => setOpen(false));
            }}
            buttonRef={buttonRef}
          >
            <FormInput fullWidth type="text" fieldName="name" label="Name" defaultValue={''} />
            <FormInput
              fullWidth
              type="text"
              fieldName="description"
              label="Description"
              defaultValue={''}
            />
            <FormInput
              fullWidth
              type="currency"
              fieldName="amount"
              label="Amount"
              defaultValue={null}
            />
            <FormInput
              fullWidth
              type="radio-group"
              fieldName="isLead"
              label="Is Lead"
              defaultValue={'false'}
              options={[
                { value: 'true', label: 'Yes' },
                { value: 'false', label: 'No' },
              ]}
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateDealExternalInvestorDialogButton;
