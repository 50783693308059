import { Navigate } from 'react-router-dom';
import Help from 'routes/Help';

import MessagingPanel from 'components/Conversation/MessagingPanel';
import ProtectedRoute from 'components/ProtectedRoute';

import SuspendedSyndicate from './SuspendedSyndicate';
import SyndicateConversations from './SyndicateConversations';
import SyndicateDeals from './SyndicateDeals';
import CreateDeal from './SyndicateDeals/CreateDeal';
import BasicInformation from './SyndicateDeals/CreateDeal/BasicInformation';
import CarryRecipients from './SyndicateDeals/CreateDeal/CarryRecipients';
import CompanyInformation from './SyndicateDeals/CreateDeal/CompanyInformation';
import DealUnderReview from './SyndicateDeals/CreateDeal/DealUnderReview';
import ExternalInvestors from './SyndicateDeals/CreateDeal/ExternalInvestors';
import Memo from './SyndicateDeals/CreateDeal/Memo';
import RisksDisclosures from './SyndicateDeals/CreateDeal/RiskDisclosures';
import SubmitDealForReview from './SyndicateDeals/CreateDeal/SubmitForReview';
import Deal from './SyndicateDeals/Deal';
import DealInformation from './SyndicateDeals/Deal/Information';
import InviteUsersToDeal from './SyndicateDeals/Deal/InviteUsers';
import ManageDeal from './SyndicateDeals/Deal/Manage';
import ActiveInvites from './SyndicateDeals/Deal/Manage/ActiveInvites';
import BlockedUsers from './SyndicateDeals/Deal/Manage/BlockedUsers';
import CancelledInvites from './SyndicateDeals/Deal/Manage/CancelledInvites';
import Commitments from './SyndicateDeals/Deal/Manage/Commitments';
import PassedInvites from './SyndicateDeals/Deal/Manage/PassedInvites';
import ViewedInvites from './SyndicateDeals/Deal/Manage/ViewedInvites';
import DealPosts from './SyndicateDeals/Deal/Posts';
import SyndicateNetwork from './SyndicateNetwork';
import SyndicateApplications from './SyndicateNetwork/Applications';
import PassedApplications from './SyndicateNetwork/Applications/Passed';
import PendingApplications from './SyndicateNetwork/Applications/Pending';
import SyndicateInviteLinks from './SyndicateNetwork/InviteLinks';
import SyndicateInvites from './SyndicateNetwork/Invites';
import SyndicateUsers from './SyndicateNetwork/Users';
import SyndicatePortfolio from './SyndicatePortfolio';
import SyndicatePosts from './SyndicatePosts';
import SyndicateSettings from './SyndicateSettings';
import SyndicateTeam from './SyndicateTeam';

const routes = [
  {
    path: '/syndicate-dashboard/:syndicateId',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <SyndicatePortfolio />
          </ProtectedRoute>
        ),
      },
      {
        path: 'deals',
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <SyndicateDeals />
              </ProtectedRoute>
            ),
          },
          {
            path: 'create',
            element: (
              <ProtectedRoute>
                <CreateDeal />
              </ProtectedRoute>
            ),
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute>
                    <BasicInformation />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':dealId/basic-information',
                element: (
                  <ProtectedRoute>
                    <BasicInformation />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':dealId/external-investors',
                element: (
                  <ProtectedRoute>
                    <ExternalInvestors />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':dealId/company-information',
                element: (
                  <ProtectedRoute>
                    <CompanyInformation />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':dealId/memo',
                element: (
                  <ProtectedRoute>
                    <Memo />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':dealId/risks-and-disclosures',
                element: (
                  <ProtectedRoute>
                    <RisksDisclosures />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':dealId/carry-recipients',
                element: (
                  <ProtectedRoute>
                    <CarryRecipients />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':dealId/e-sign-submit',
                element: (
                  <ProtectedRoute>
                    <SubmitDealForReview />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':dealId/under-review',
                element: (
                  <ProtectedRoute>
                    <DealUnderReview />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: ':dealId',
            element: (
              <ProtectedRoute>
                <Deal />
              </ProtectedRoute>
            ),
            children: [
              {
                index: true,
                element: <Navigate replace to="manage" />,
              },
              {
                path: 'manage',
                element: (
                  <ProtectedRoute>
                    <ManageDeal />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'information',
                element: (
                  <ProtectedRoute>
                    <DealInformation />
                  </ProtectedRoute>
                ),
                children: [
                  {
                    index: true,
                    path: 'basic-information',
                    element: (
                      <ProtectedRoute>
                        <BasicInformation />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'external-investors',
                    element: (
                      <ProtectedRoute>
                        <ExternalInvestors />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'company-information',
                    element: (
                      <ProtectedRoute>
                        <CompanyInformation />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'memo',
                    element: (
                      <ProtectedRoute>
                        <Memo />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'risks-and-disclosures',
                    element: (
                      <ProtectedRoute>
                        <RisksDisclosures />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'carry-recipients',
                    element: (
                      <ProtectedRoute>
                        <CarryRecipients />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                path: 'invites',
                element: (
                  <ProtectedRoute>
                    <InviteUsersToDeal />
                  </ProtectedRoute>
                ),
                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute>
                        <ActiveInvites />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'viewed',
                    element: (
                      <ProtectedRoute>
                        <ViewedInvites />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'passed',
                    element: (
                      <ProtectedRoute>
                        <PassedInvites />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'cancelled',
                    element: (
                      <ProtectedRoute>
                        <CancelledInvites />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'blocked',
                    element: (
                      <ProtectedRoute>
                        <BlockedUsers />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                path: 'commitments',
                element: (
                  <ProtectedRoute>
                    <Commitments />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'posts',
                element: (
                  <ProtectedRoute>
                    <DealPosts />
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'network',
        element: (
          <ProtectedRoute>
            <SyndicateNetwork />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <SyndicateUsers />
              </ProtectedRoute>
            ),
          },
          {
            path: 'applications',
            element: (
              <ProtectedRoute>
                <SyndicateApplications />
              </ProtectedRoute>
            ),
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute>
                    <PendingApplications />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'passed',
                element: (
                  <ProtectedRoute>
                    <PassedApplications />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: 'invites',
            element: (
              <ProtectedRoute>
                <SyndicateInvites />
              </ProtectedRoute>
            ),
          },
          {
            path: 'invite-links',
            element: (
              <ProtectedRoute>
                <SyndicateInviteLinks />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'team',
        element: (
          <ProtectedRoute>
            <SyndicateTeam />
          </ProtectedRoute>
        ),
      },
      {
        path: 'conversations',
        element: (
          <ProtectedRoute>
            <SyndicateConversations />
          </ProtectedRoute>
        ),
        children: [
          {
            path: ':conversationId',
            element: (
              <ProtectedRoute>
                <MessagingPanel />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'settings',
        element: (
          <ProtectedRoute>
            <SyndicateSettings />
          </ProtectedRoute>
        ),
      },
      {
        path: 'posts',
        element: (
          <ProtectedRoute>
            <SyndicatePosts />
          </ProtectedRoute>
        ),
      },
      {
        path: 'help',
        element: <Help />,
      },
      {
        path: 'suspended',
        element: <SuspendedSyndicate />,
      },
    ],
  },
];

export default routes;
