import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import useParentRoute from 'hooks/useParentRoute';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FormInput, FormPanel } from 'components/FormPanel';

import Alert from 'primitives/Alert';

const SUBMIT_INVESTMENT_ENTITY_FOR_REVIEW_MUTATION = gql(`
  mutation SubmitInvestmentEntityForReview($investmentEntityId: ID!) {
    submitInvestmentEntityForReview(id: $investmentEntityId) {
      id
    }
  }
`);

const SubmitInvestmentEntityForReview: FC = () => {
  const navigate = useNavigate();
  const parentRoute = useParentRoute();
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const [submitInvestmentEntityForReview, { loading: signLoading, error: signError }] = useMutation(
    SUBMIT_INVESTMENT_ENTITY_FOR_REVIEW_MUTATION
  );

  return (
    <FormPanel
      loading={signLoading}
      error={signError}
      onSubmit={data => {
        submitInvestmentEntityForReview({
          variables: {
            investmentEntityId,
          },
        }).then(() => {
          navigate(`/invest/investment-entities`);
        });
      }}
      onCancel={() => {
        navigate(`${parentRoute}/investor-application`);
      }}
      submitButtonLabel="Submit"
      cancelButtonLabel="Back"
    >
      <FormInput
        type="switch"
        fieldName="disclaimer"
        label="I confirm that the information provided is accurate to the best of my knowledge"
        defaultValue=""
        validators={{
          required: true,
        }}
      />
      <Alert
        type="info"
        title=""
        message="Please note that SEBI has mandated all AIFs to issue units in dematerialised form only. Accordingly, the investor must hold an active demat account at the time of making investments. Furthermore, the bank account and the demat account must be registered in the same name as that of the investor (i.e., the investing entity)."
      />
    </FormPanel>
  );
};

export default SubmitInvestmentEntityForReview;
