import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DashboardDealInvitesEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

const DASHBOARD_INVITES_QUERY = gql(`
  query GetPassedInvitesOfUser($cursor: ID, $filters: DashboardDealInvitesFilterType) {
    dashboard {
      id
      dealInvites(cursor: $cursor, filters: $filters) {
        nodes {
          id
          deal {
            id
            closingDate
            companyInformation {
              id
              company {
                id
                name
                image
              }
            }
            dealTerms {
              id
              valuationOrCap
              fundingRoundName
            }
            syndicate {
              id
              name
            }
            externalInvestors {
              id
              name
            }
          }
          status
          createdAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const PassedInvitesOfUser: FC = () => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore } = useQuery(DASHBOARD_INVITES_QUERY, {
    variables: {
      filters: {
        type: DashboardDealInvitesEnumType.Passed,
      },
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const invites = data.dashboard.dealInvites.nodes;
  const pageInfo = data.dashboard.dealInvites.pageInfo;

  return (
    <DataTable
      data={invites}
      onClick={invite => navigate(`/invest/deal-invites/${invite.id}`)}
      emptyListTitle="You haven't passed on any invites"
      emptyListDescription="Please check back later for new deal invites."
      columns={[
        {
          label: 'Company',
          fieldName: 'deal.companyInformation.company',
          type: 'COMPANY',
        },
        {
          label: 'Syndicate',
          fieldName: 'deal.syndicate.name',
        },
        {
          label: 'Co-Investors',
          fieldName: 'deal.externalInvestors[0].name',
        },
        {
          label: 'Round',
          fieldName: 'deal.dealTerms.fundingRoundName',
          type: 'FUNDING_ROUND',
        },
        {
          label: 'Valuation/Cap',
          fieldName: 'deal.dealTerms.valuationOrCap',
          type: 'CURRENCY',
        },
        {
          label: 'Invitation Date',
          fieldName: 'createdAt',
          type: 'DATE',
        },
        {
          label: 'Deadline',
          fieldName: 'deal.closingDate',
          type: 'DATE',
        },
      ]}
      hasNextPage={pageInfo.hasNextPage}
      onLoadMore={() =>
        fetchMore({
          variables: {
            cursor: pageInfo.cursor,
          },
        })
      }
    />
  );
};

export default PassedInvitesOfUser;
