import { InvestmentEntityDocumentTypeEnumType } from '__generated__/graphql';

export default function investmentEntityDocumentTypeToReadable(
  type: InvestmentEntityDocumentTypeEnumType
): {
  label: string;
  color: string;
} {
  switch (type) {
    case InvestmentEntityDocumentTypeEnumType.UnitStatement:
      return {
        label: 'Unit Statement',
        color: 'indigo',
      };
    case InvestmentEntityDocumentTypeEnumType.DistributionStatement:
      return {
        label: 'Distribution Statement',
        color: 'green',
      };
    case InvestmentEntityDocumentTypeEnumType.SchemeInformationDocument:
      return {
        label: 'Scheme Information Document',
        color: 'yellow',
      };
    case InvestmentEntityDocumentTypeEnumType.ClosingDocument:
      return {
        label: 'Closing Document',
        color: 'black',
      };
    case InvestmentEntityDocumentTypeEnumType.Other:
      return {
        label: 'Other',
        color: 'gray',
      };
    default:
      return {
        label: type,
        color: 'gray',
      };
  }
}
