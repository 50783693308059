import { useMutation } from '@apollo/client';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { gql } from '__generated__/gql';
import { FC, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import AuthLayout from 'layouts/AuthLayout';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';

import { storeLoginCredentials } from 'utils/auth';

const AUTHENTICATE_OTP_MUTATION = gql(`
  mutation Login($email: String!, $otp: String!) {
    login(email: $email, otp: $otp) {
      token
      user {
        id
        name
        email
        phoneNumber
      }
    }
  }
`);

const AuthenticateOTP: FC = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const buttonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const [login, { error, loading }] = useMutation(AUTHENTICATE_OTP_MUTATION);

  if (!email) {
    return <ErrorMessage title={'Something went wrong'} />;
  }

  return (
    <AuthLayout title="Please check your email for a one-time password">
      <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
        <FormPanel
          loading={loading}
          error={error}
          onSubmit={formData => {
            login({
              variables: {
                email,
                otp: formData.otp,
              },
            }).then(({ data }) => {
              if (data?.login?.token) {
                storeLoginCredentials(data.login.user, `Bearer ${data.login.token}`);
                navigate('/');
              }
            });
          }}
          submitButtonLabel="Login"
          buttonRef={buttonRef}
        >
          <FormInput
            fieldName="otp"
            type="text"
            defaultValue=""
            label="One-Time Password"
            fullWidth
            validators={{
              required: true,
              isInteger: true,
              minLength: 6,
              maxLength: 6,
            }}
          />
        </FormPanel>
        <div className="mt-6">
          <Button
            loading={loading}
            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              // @ts-ignore
              buttonRef.current?.submit();
            }}
          >
            Authenticate
          </Button>
        </div>
      </div>

      <p className="mt-10 text-center text-sm text-gray-500">
        <NavLink
          to="/login"
          className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
        >
          <ArrowLeftIcon className="h-4 w-4 inline-block -mt-1 mr-1" />
          Back to Login
        </NavLink>
      </p>
    </AuthLayout>
  );
};

export default AuthenticateOTP;
