import { FC } from 'react';

import { CreateLayoutContent } from 'layouts/CreateLayout';

const DealUnderReview: FC = () => {
  return (
    <CreateLayoutContent title="Under Review" subTitle="We'll get back to you right away">
      <p className="text-black-500">We review deals within 2 business days.</p>
      <p className="mt-3 mb-3 text-black-500">
        Questions ? Email us at{' '}
        <a className=" text-indigo-500" href="mailto:hi@angellistindia.com">
          hi@angellistindia.com
        </a>
      </p>
    </CreateLayoutContent>
  );
};

export default DealUnderReview;
