import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { FC, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import Button from 'primitives/Button';

const UploadInput: FC<{
  label: string;
  value: any;
  onChange;
  fullWidth?: boolean;
  helperText?: string;
  readOnly?: boolean;
  error?: string;
  isAvatar?: boolean;
  isPdf?: boolean;
  maxFileSize?: number;
}> = ({
  label,
  value,
  onChange,
  fullWidth = false,
  helperText,
  readOnly,
  error,
  isAvatar,
  isPdf,
  maxFileSize,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [internalError, setInternalError] = useState<string | null>(null);
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: isAvatar
      ? { 'image/png': ['.png'], 'image/jpeg': ['.jpeg'], 'image/jpg': ['.jpg'] }
      : isPdf
      ? { 'application/pdf': ['.pdf'] }
      : {
          'image/png': ['.png'],
          'image/jpeg': ['.jpeg'],
          'image/jpg': ['.jpg'],
          'application/pdf': ['.pdf'],
        },
    onDrop: acceptedFiles => {
      if (maxFileSize) {
        const file = acceptedFiles[0];
        if (file.size > maxFileSize * 1024 * 1024) {
          setInternalError(`File size exceeds the maximum limit of ${maxFileSize}MB.`);
          return;
        }
      }
      setInternalError(null);
      onChange(acceptedFiles);
    },
  });

  const errorMessage = internalError || error;

  if (readOnly) {
    if (!value)
      return (
        <>
          <p className="text-sm mb-2">{label}</p>
          <span>-</span>
        </>
      );

    if (typeof value === 'string' && isAvatar)
      return (
        <>
          <p className="text-sm mb-2">{label}</p>
          {helperText && !errorMessage && (
            <p className="mt-1 text-sm text-gray-500">{helperText}</p>
          )}
          <img
            src={value}
            alt="Avatar"
            onClick={() => window.open(value, '_blank')}
            style={{
              objectFit: 'cover',
              border: '1px solid #ddd',
              borderRadius: isAvatar ? '1000px' : '0px',
              width: '100px',
              height: '100px',
              cursor: 'pointer',
            }}
          />
        </>
      );

    return (
      <>
        <p className="text-sm mb-2">{label}</p>
        {helperText && !errorMessage && <p className="mt-1 text-sm text-gray-500">{helperText}</p>}
        <Button
          onClick={() =>
            value.url ? window.open(value.url, '_blank') : window.open(value, '_blank')
          }
        >
          {value.name || 'View'}
        </Button>
      </>
    );
  }

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  function formatName(str: string) {
    if (!str) return null;

    const chunkSize = 25;
    const chunks: any = [];

    for (let i = 0; i < str.length; i += chunkSize) {
      chunks.push(
        <p>{str.slice(i, i + chunkSize) + `${i + chunkSize >= str.length ? '' : '...'}`}</p>
      );
    }

    return chunks;
  }

  function renderContent() {
    if (value && value instanceof File) {
      return (
        <div className="bg-indigo-50 p-1.5 rounded-md">
          <div className="text-gray-800 text-sm">{formatName(value.name)}</div>
          <p className="text-gray-700 text-xs">{formatBytes(value.size)}</p>
          <button
            className="text-blue-400 text-sm hover:underline cursor-pointer mr-1"
            onClick={() => (inputRef && inputRef.current ? inputRef.current.click() : {})}
          >
            Replace
          </button>
          <button
            className="text-blue-400 text-sm hover:underline cursor-pointer"
            onClick={() => onChange('')}
          >
            Clear
          </button>
        </div>
      );
    }

    if (value && (typeof value === 'object' || typeof value === 'string')) {
      return (
        <div>
          <div className="text-gray-800 text-sm">{formatName(value.name) || 'FILE'}</div>
          <div className="bg-indigo-50 p-1.5 rounded-md space-x-4 flex flex-row align-middle w-min mt-2">
            <button
              className="text-blue-400 text-sm hover:underline cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                window.open(value.url || value, '_blank');
              }}
            >
              View
            </button>
            <button
              className="text-blue-400 text-sm hover:underline cursor-pointer"
              onClick={() => (inputRef && inputRef.current ? inputRef.current.click() : {})}
            >
              Replace
            </button>
            <button
              className="text-blue-400 text-sm hover:underline cursor-pointer"
              onClick={() => onChange('')}
            >
              Clear
            </button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <p className="text-gray-800 text-sm">Drag and drop or choose file to upload</p>
        {isPdf ? (
          <p className="text-gray-700 text-xs">{'PDF'}</p>
        ) : (
          <p className="text-gray-700 text-xs">{`JPG, PNG${isAvatar ? '' : ', PDF'} `}</p>
        )}
      </div>
    );
  }

  return (
    <>
      <p className="text-base font-semibold text-gray-900">{label}</p>
      {helperText && !error && <p className="mt-1 text-sm text-gray-500">{helperText}</p>}
      <div
        className={classNames(
          'flex items-center justify-start gap-1 mt-2 rounded-md min-h-16 p-1.5 border border-1 border-dashed shadow-sm border-gray-300',
          {
            'w-full': fullWidth,
            'border-red-500': error,
            'border-gray-600': isDragActive || isDragAccept,
            'border-red-600': isDragReject,
            'cursor-pointer hover:border-gray-400': !value,
          }
        )}
        onClick={() => (!value && inputRef && inputRef.current ? inputRef.current.click() : {})}
        {...getRootProps()}
      >
        <span className="inline-flex items-center pr-1">
          <ArrowUpOnSquareIcon className="h-4 w-4 text-gray-400" />
        </span>
        {renderContent()}
        <input
          type="file"
          key="input-file"
          id="imageFile"
          multiple={true}
          accept="image/*, application/pdf"
          ref={inputRef}
          onChange={e => onChange(e.target.files)}
          className="hidden"
          {...(!value ? getInputProps() : {})}
        />
      </div>
      {errorMessage && (
        <p className="mt-2 text-sm text-red-600" id="error">
          {errorMessage}
        </p>
      )}
    </>
  );
};

export default UploadInput;
