import { useState } from 'react';
import ContactSupport from 'routes/Help/ContactSupport';

import SlideOver from 'primitives/SlideOver';

export default function ReportAccountSuspension() {
  const [showReportIssueSlideOver, toggleReportIssueSlideOver] = useState(false);
  return (
    <>
      <SlideOver
        open={!!showReportIssueSlideOver}
        onClose={() => toggleReportIssueSlideOver(false)}
        title=""
      >
        <ContactSupport onClose={() => toggleReportIssueSlideOver(false)} />
      </SlideOver>

      <button
        onClick={() => toggleReportIssueSlideOver(true)}
        className="text-sm font-semibold text-gray-900"
      >
        Contact support <span aria-hidden="true">&rarr;</span>
      </button>
    </>
  );
}
