import { Popover, Transition } from '@headlessui/react';
import { CheckCircleIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { Squares2X2Icon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Fragment } from 'react';

function ProfileIcon() {
  return (
    <Squares2X2Icon
      className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
      aria-hidden="true"
    />
  );
}

function ProfileTab({
  id,
  name,
  onSelect,
  isActive,
}: {
  id: string;
  name: string;
  onSelect: (id: string) => void;
  isActive: boolean;
}) {
  return (
    <div
      key={name}
      className={classNames(
        'group relative flex items-center gap-x-2 rounded-lg p-2 hover:bg-gray-50'
      )}
    >
      <div className="flex h-9 w-9 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
        <ProfileIcon />
      </div>
      <div className="p-1">
        <Popover.Button
          onClick={() => onSelect(id)}
          key={name}
          className="font-medium mt-0 text-gray-900"
        >
          {name}
          <span className="absolute inset-0" />
        </Popover.Button>
        <p className="text-gray-600 text-xs mt-0">Investment Entity</p>
      </div>
      <span className="flex-1" />
      {isActive && <CheckCircleIcon className="mr-2 h-6 w-6 text-indigo-600" />}
    </div>
  );
}

export default function InvestmentEntityBar({
  currentInvestmentEntityId,
  investmentEntities,
  onSelect,
}: {
  currentInvestmentEntityId: string;
  investmentEntities: { id: string; name: string }[];
  onSelect: (id: string) => void;
}) {
  return (
    <Popover className="relative max-w-sm">
      <Popover.Button className="w-full focus:outline-none">
        <div
          key={'invest'}
          className="group relative flex items-center gap-x-2 rounded-xl p-2 hover:bg-gray-200 bg-gray-100"
        >
          <div className="flex h-9 w-9 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
            <ProfileIcon />
          </div>
          <div className="">
            <p className="text-sm text-left font-medium text-gray-900 w-32 whitespace-nowrap truncate">
              {investmentEntities.find(i => i.id === currentInvestmentEntityId)?.name}
            </p>
            <p className="text-xs text-left text-gray-600">Investment Entity</p>
          </div>
          <span className="flex-1" />
          <ChevronDownIcon className="mr-2 h-5 w-5 text-gray-600" />
        </div>
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-0 z-20 mt-2 flex w-screen max-w-sm">
          <div className="w-screen max-w-sm flex-auto overflow-auto max-h-96 rounded-xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div>
              {investmentEntities.map(investmentEntity => (
                <ProfileTab
                  key={investmentEntity.id}
                  id={investmentEntity.id}
                  name={investmentEntity.name}
                  isActive={investmentEntity.id === currentInvestmentEntityId}
                  onSelect={onSelect}
                />
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
