import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DealStatusEnumType, SyndicateTypeEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateLayoutContent } from 'layouts/CreateLayout';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import LoadingIndicator from 'primitives/LoadingIndicator';

const DISCLOSURES_QUERY = gql(`
  query DealDisclosures($id: ID!) {
    deal(id: $id) {
      id
      status
      dealDisclosures {
        id
        risksNote
        hasLeadInvestedInCompanyBefore
        doesLeadHaveAdvisoryShares
        isLeadEmployeeAtCompany
        doesLeadHaveRelativesInCompany
        hasSameTermsForCoInvestors
        isLeadInvestingOnSameTerms
        isLeadInvestingFromFund
        otherDisclosuresNote
      }
      syndicate {
        id
        type
      }
    }
  }
`);

const UPDATE_DEAL_DISCLOSURES_MUTATION = gql(`
  mutation UpdateDealDisclosures(
    $dealId: ID!
    $risksNote: String
    $hasLeadInvestedInCompanyBefore: Boolean!
    $doesLeadHaveAdvisoryShares: Boolean!
    $isLeadEmployeeAtCompany: Boolean!
    $doesLeadHaveRelativesInCompany: Boolean!
    $hasSameTermsForCoInvestors: Boolean!
    $isLeadInvestingOnSameTerms: Boolean!
    $isLeadInvestingFromFund: Boolean!
    $otherDisclosuresNote: String
  ) {
    updateDealDisclosures(
      dealId: $dealId
      risksNote: $risksNote
      hasLeadInvestedInCompanyBefore: $hasLeadInvestedInCompanyBefore
      doesLeadHaveAdvisoryShares: $doesLeadHaveAdvisoryShares
      isLeadEmployeeAtCompany: $isLeadEmployeeAtCompany
      doesLeadHaveRelativesInCompany: $doesLeadHaveRelativesInCompany
      hasSameTermsForCoInvestors: $hasSameTermsForCoInvestors
      isLeadInvestingOnSameTerms: $isLeadInvestingOnSameTerms
      isLeadInvestingFromFund: $isLeadInvestingFromFund
      otherDisclosuresNote: $otherDisclosuresNote
    ) {
      id
      dealDisclosures {
        id
        risksNote
        hasLeadInvestedInCompanyBefore
        doesLeadHaveAdvisoryShares
        isLeadEmployeeAtCompany
        doesLeadHaveRelativesInCompany
        hasSameTermsForCoInvestors
        isLeadInvestingOnSameTerms
        isLeadInvestingFromFund
        otherDisclosuresNote
      }
    }
  }
`);

const RisksDisclosures: FC = () => {
  const navigate = useNavigate();

  const { syndicateId, dealId } = useParams<{ syndicateId: string; dealId: string }>() as {
    syndicateId: string;
    dealId: string;
  };

  const { loading, error, data, refetch } = useQuery(DISCLOSURES_QUERY, {
    variables: {
      id: dealId,
    },
  });

  const [updateDealDisclosures, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_DEAL_DISCLOSURES_MUTATION
  );

  if (loading) return <LoadingIndicator />;

  if (error || !data || !dealId) return <ErrorMessage error={error} refetch={refetch} />;
  const isRuv = data.deal.syndicate.type === SyndicateTypeEnumType.Ruv;
  const isDealUnderReview = data.deal.status === DealStatusEnumType.UnderOpsReview;
  return (
    <CreateLayoutContent
      title="What should others know?"
      subTitle="Describe the risks and other disclaimers in that"
    >
      <FormPanel
        loading={updateLoading}
        error={updateError}
        onSubmit={data => {
          updateDealDisclosures({
            variables: {
              dealId,
              risksNote: data.risksNote,
              hasLeadInvestedInCompanyBefore: data.hasLeadInvestedInCompanyBefore,
              doesLeadHaveAdvisoryShares: data.doesLeadHaveAdvisoryShares,
              isLeadEmployeeAtCompany: data.isLeadEmployeeAtCompany,
              doesLeadHaveRelativesInCompany: data.doesLeadHaveRelativesInCompany,
              hasSameTermsForCoInvestors: data.hasSameTermsForCoInvestors,
              isLeadInvestingOnSameTerms: data.isLeadInvestingOnSameTerms,
              isLeadInvestingFromFund: data.isLeadInvestingFromFund,
              otherDisclosuresNote: data.otherPotentialConflicts
                ? data.otherDisclosures
                : undefined,
            },
          }).then(() => {
            if (isDealUnderReview) {
              navigate(`/syndicate-dashboard/${syndicateId}/deals/create/${dealId}/under-review`);
              return;
            }
            if (isRuv) {
              navigate(`/syndicate-dashboard/${syndicateId}/deals/create/${dealId}/e-sign-submit`);
              return;
            } else {
              navigate(
                `/syndicate-dashboard/${syndicateId}/deals/create/${dealId}/carry-recipients`
              );
            }
          });
        }}
        onCancel={() => {
          navigate(`/syndicate-dashboard/${syndicateId}/deals/create/${dealId}/memo`);
        }}
        submitButtonLabel="Save and continue"
        cancelButtonLabel="Back"
      >
        <FormInput
          fieldName="risksNote"
          type="textarea"
          fullWidth
          defaultValue={data.deal.dealDisclosures?.risksNote || ''}
          helperText="Disclosing specific risk factors is required for compliance"
          label=""
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="switch"
          fieldName="hasLeadInvestedInCompanyBefore"
          label="I invested in a previous round"
          defaultValue={data.deal.dealDisclosures?.hasLeadInvestedInCompanyBefore || false}
        />
        <FormInput
          type="switch"
          fieldName="doesLeadHaveAdvisoryShares"
          label="I have advisory shares, warrants, or other shares"
          defaultValue={data.deal.dealDisclosures?.doesLeadHaveAdvisoryShares || false}
        />
        <FormInput
          type="switch"
          fieldName="isLeadEmployeeAtCompany"
          label="I am an officer or employee of the company"
          defaultValue={data.deal.dealDisclosures?.isLeadEmployeeAtCompany || false}
        />
        <FormInput
          type="switch"
          fieldName="doesLeadHaveRelativesInCompany"
          label="I have a relative or significant other working at the company"
          defaultValue={data.deal.dealDisclosures?.doesLeadHaveRelativesInCompany || false}
        />
        <FormInput
          type="switch"
          fieldName="hasSameTermsForCoInvestors"
          label="I have the same terms as other co-investors"
          defaultValue={data.deal.dealDisclosures?.hasSameTermsForCoInvestors || false}
        />
        <FormInput
          type="switch"
          fieldName="isLeadInvestingOnSameTerms"
          label="I am investing on the same terms as other investors"
          defaultValue={data.deal.dealDisclosures?.isLeadInvestingOnSameTerms || false}
        />
        <FormInput
          type="switch"
          fieldName="isLeadInvestingFromFund"
          label="I am investing from a fund"
          defaultValue={data.deal.dealDisclosures?.isLeadInvestingFromFund || false}
        />
        <FormInput
          type="switch"
          fieldName="otherPotentialConflicts"
          label="I have other potential conflicts to disclose"
          defaultValue={data.deal.dealDisclosures?.otherDisclosuresNote ? true : false}
        />
        <FormInput
          type="textarea"
          fullWidth
          fieldName="otherDisclosures"
          label="Other Disclosures"
          defaultValue={data.deal.dealDisclosures?.otherDisclosuresNote || ''}
          conditionsToShow={{
            matches: [
              {
                field: 'otherPotentialConflicts',
                condition: '===',
                value: 'true',
              },
            ],
          }}
        />
      </FormPanel>
    </CreateLayoutContent>
  );
};

export default RisksDisclosures;
