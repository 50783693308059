import { useQuery } from '@apollo/client';
import { gql } from '__generated__';

import ErrorMessage from 'components/ErrorMessage';
import PDFViewer from 'components/PDFViewer';

import LoadingIndicator from 'primitives/LoadingIndicator';

const PITCH_DECK_QUERY = gql(`
  query GetPitchDeck($id: ID!) {
    deal(id: $id) {
      companyInformation {
        pitchDeck
      }
    }
  }
`);

const PitchDeck = ({ dealId }) => {
  const { data, loading, error, refetch } = useQuery(PITCH_DECK_QUERY, {
    variables: {
      id: dealId,
    },
  });

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    if (!data.deal.companyInformation.pitchDeck) {
      return <p>No pitch deck found</p>;
    }

    return <PDFViewer url={data.deal.companyInformation.pitchDeck} />;
  }

  return <div>{renderContent()}</div>;
};

export default PitchDeck;
