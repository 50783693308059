import { PencilSquareIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import {
  Children,
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  isValidElement,
  useReducer,
  useState,
} from 'react';

import Button from 'primitives/Button';

import validate from 'utils/text-validators';

import ErrorMessage from '../ErrorMessage';
import FormInput from './FormInput';
import FormPanelContext from './formPanelContext';
import reducer, {
  ActionType,
  ResetFormActionType,
  UpdateErrorsActionType,
} from './formPanelReducer';
import { getFormInputElements, getReactElementClones } from './process-form-panel-children';

function getInitialDataAndValidatorsFromChildren(children: ReactNode) {
  let initialData = {};
  let validators = {};
  let conditionsToShow = {};
  Children.forEach(children, element => {
    if (!isValidElement(element)) return;

    initialData = { ...initialData, [element.props.fieldName]: element.props.defaultValue };
    validators = { ...validators, [element.props.fieldName]: element.props.validators };
    conditionsToShow = {
      ...conditionsToShow,
      [element.props.fieldName]: element.props.conditionsToShow,
    };
  });

  return [initialData, validators, conditionsToShow];
}

const FormPanel: FC<{
  children: ReactElement | ReactElement[];
  onSubmit: (data: any) => void;
  loading: boolean;
  error: any;
  onCancel?: () => void;
  cancelButtonLabel?: string;
  onReset?: () => void;
  submitButtonLabel?: string;
  buttonRef?: any;
}> = ({ ...props }) => {
  const formInputElements = getFormInputElements(props.children);
  const reactElementClones = getReactElementClones(props.children);

  const [initialData, validators, conditionsToShow] =
    getInitialDataAndValidatorsFromChildren(formInputElements);

  const [formState, formDispatch] = useReducer(reducer, { data: initialData });
  const formContext = { formState, formDispatch };
  const updateErrorsDispatch: Dispatch<ActionType & UpdateErrorsActionType> = formDispatch;
  const resetFormDispatch: Dispatch<ActionType & ResetFormActionType> = formDispatch;

  function validateForm() {
    let errors = {};
    Object.keys(formState.data).forEach(f => {
      if (validators[f]) {
        if (!formInputHidden(conditionsToShow[f])) {
          const error = validate(validators[f], formState, formState.data[f]);
          if (error) {
            errors = { ...errors, [f]: error };
          }
        }
      }
    });

    formInputElements.forEach(c => {
      if (
        // @ts-ignore
        c.props.type === 'date-range' &&
        // @ts-ignore
        formState.data[c.props.fieldName][0] > formState.data[c.props.fieldName][1]
      ) {
        // @ts-ignore
        errors = { ...errors, [c.props.fieldName]: "'From Date' must be before 'To Date'" };
      }
    });

    updateErrorsDispatch({
      type: 'UPDATE_ERRORS',
      payload: { errors },
    });

    return errors;
  }
  function formInputHidden(conditionsToShow) {
    if (!conditionsToShow) return false;
    if (!conditionsToShow.matches.length) return false;

    if (conditionsToShow.type === 'some')
      return !conditionsToShow.matches.some(c => {
        // eslint-disable-next-line
        return eval(`"${formState.data[c.field]}" ${c.condition} "${c.value}"`);
      });
    else
      return !conditionsToShow.matches.every(c => {
        // eslint-disable-next-line
        return eval(`"${formState.data[c.field]}" ${c.condition} "${c.value}"`);
      });
  }

  function handleSubmit() {
    const errors = validateForm();
    if (Object.keys(errors).length < 1) {
      props.onSubmit(formState.data);
    }
  }

  function handleCancel() {
    if (props.onCancel) props.onCancel();
  }

  function handleReset() {
    resetFormDispatch({
      type: 'RESET_FORM',
      payload: { initialData },
    });
    if (props.onReset) props.onReset();
  }

  if (props.buttonRef) {
    props.buttonRef.current = {
      submit: handleSubmit,
      cancel: handleCancel,
      reset: handleReset,
    };
  }

  function formActions() {
    if (props.buttonRef) return null;

    return (
      <div className="col-span-full flex gap-2">
        <Button loading={props.loading} onClick={handleSubmit}>
          {/* @ts-ignore */}
          {props.submitButtonLabel ? props.submitButtonLabel : 'Submit'}
        </Button>
        {props.onCancel ? (
          <Button onClick={handleCancel} variant="secondary">
            {props.cancelButtonLabel ? props.cancelButtonLabel : 'Cancel'}
          </Button>
        ) : null}
        {props.onReset ? <Button onClick={handleReset}>Reset</Button> : null}
      </div>
    );
  }

  return (
    <FormPanelContext.Provider value={formContext}>
      <div className="min-w-96 grid grid-cols-1 gap-x-3 gap-y-3 sm:grid-cols-6">
        {reactElementClones}
        {props.error ? <ErrorMessage type="alert" error={props.error} /> : null}
        {formActions()}
      </div>
    </FormPanelContext.Provider>
  );
};

const FormPanelWithReadMode: FC<{
  children: ReactElement | ReactElement[];
  onSubmit: (data: any) => void;
  loading: boolean;
  error: any;
  cancelButtonLabel?: string;
  submitButtonLabel?: string;
  disableEdit?: boolean;
  title?: string;
  subTitle?: string;
  buttonRef?: any;
}> = props => {
  const [readOnlyMode, toggleReadOnlyMode] = useState(true);

  const formInputElements = getFormInputElements(props.children);
  const reactElementClones = getReactElementClones(props.children, readOnlyMode);

  const [initialData, validators] = getInitialDataAndValidatorsFromChildren(formInputElements);

  const [formState, formDispatch] = useReducer(reducer, { data: initialData });
  const formContext = { formState, formDispatch };
  const updateErrorsDispatch: Dispatch<ActionType & UpdateErrorsActionType> = formDispatch;
  const resetFormDispatch: Dispatch<ActionType & ResetFormActionType> = formDispatch;

  function validateForm() {
    let errors = {};
    Object.keys(formState.data).forEach(f => {
      if (validators[f]) {
        const error = validate(validators[f], formState, formState.data[f]);
        if (error) {
          errors = { ...errors, [f]: error };
        }
      }
    });

    formInputElements.forEach(c => {
      if (
        // @ts-ignore
        c.props.type === 'date-range' &&
        // @ts-ignore
        formState.data[c.props.fieldName][0] > formState.data[c.props.fieldName][1]
      ) {
        // @ts-ignore
        errors = { ...errors, [c.props.fieldName]: "'From Date' must be before 'To Date'" };
      }
    });

    updateErrorsDispatch({
      type: 'UPDATE_ERRORS',
      payload: { errors },
    });

    return errors;
  }

  function handleSubmit() {
    const errors = validateForm();
    if (Object.keys(errors).length < 1) {
      props.onSubmit(formState.data);
      toggleReadOnlyMode(true);
    }
  }

  function handleCancel() {
    resetFormDispatch({
      type: 'RESET_FORM',
      payload: { initialData },
    });
    toggleReadOnlyMode(true);
  }

  if (props.buttonRef) {
    props.buttonRef.current = {
      submit: handleSubmit,
      cancel: handleCancel,
    };
  }

  function formActions() {
    if (props.disableEdit) {
      return null;
    }

    return (
      <div
        className={classNames(
          'flex items-center gap-x-6 border-t border-gray-200 px-4 py-4 sm:px-4',
          readOnlyMode ? 'justify-start' : 'justify-end'
        )}
      >
        {readOnlyMode ? (
          <Button
            variant="secondary"
            onClick={() => toggleReadOnlyMode(false)}
            leadingIcon={<PencilSquareIcon />}
          >
            Make Changes
          </Button>
        ) : (
          <>
            <Button loading={props.loading} onClick={handleSubmit}>
              {/* @ts-ignore */}
              {props.submitButtonLabel ? props.submitButtonLabel : 'Submit'}
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              {props.cancelButtonLabel ? props.cancelButtonLabel : 'Cancel'}
            </Button>
          </>
        )}
      </div>
    );
  }

  return (
    <FormPanelContext.Provider value={formContext}>
      <div
        className={classNames(
          'shadow-sm ring-1 ring-gray-200 rounded-md sm:rounded-lg md:col-span-2',
          {
            'ring-2 ring-indigo-500': !readOnlyMode,
          }
        )}
      >
        {props.title && (
          <div className="border-b border-gray-200 px-4 py-4 sm:px-4">
            <h2 className="text-lg font-semibold leading-7 text-gray-900 sm:truncate sm:text-lg sm:tracking-tight">
              {props.title}
            </h2>
            <div className="flex items-center text-sm text-gray-600">{props.subTitle}</div>
          </div>
        )}
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 px-4 py-3 sm:p-4">
          {reactElementClones}
          {props.error ? <ErrorMessage type="alert" error={props.error} /> : null}
        </div>
        {formActions()}
      </div>
    </FormPanelContext.Provider>
  );
};

export { FormPanel, FormPanelWithReadMode, FormInput };
