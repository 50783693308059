import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import { formatCurrency } from 'utils/format-helper';

const PORTFOLIO_PERFORMANCE_QUERY = gql(`
  query getPortfolioPerformance($id: ID!) {
    investmentEntity(id: $id) {
      id
      portfolioPerformance {
        effectiveOn
        realizedValue
        unRealizedValue
        investmentValue
      }
    }
  }
`);

const indigo = '#8884d8';
const green = '#82ca9d';
// const black = '#1f2937';

const getColor = {
  // 'Realised Value': '#334155',
  'Unrealised Value': '#4f46e5',
  'Investment Value': '#16a34a',
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-gray-50 p-2 border border-gray-200 rounded-md opacity-70">
        {payload.map((item, index) => (
          <div key={index} className="grid grid-cols-2 gap-x-2 items-center text-sm">
            <span className="col-span-1 font-semibold" style={{ color: getColor[item.dataKey] }}>
              {item.name}
            </span>
            <span className="col-span-1 text-gray-900">: {formatCurrency(item.value)}</span>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

const CustomLegend = ({ payload }) => {
  if (payload && payload.length) {
    return (
      <div className="">
        {payload.map((item, index) => (
          <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-700">
            <svg className="h-4 w-4" viewBox="0 0 6 6" aria-hidden="true" fill={item.color}>
              <circle cx={3} cy={3} r={3} />
            </svg>
            {item.value}
          </span>
        ))}
      </div>
    );
  }

  return null;
};

const PortfolioChart = ({ investmentEntityId }: { investmentEntityId: string }) => {
  const { loading, error, data, refetch } = useQuery(PORTFOLIO_PERFORMANCE_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const portfolioPerformance = data.investmentEntity.portfolioPerformance?.map(stat => ({
    // 'Realised Value': stat?.realizedValue,
    'Unrealised Value': stat?.unRealizedValue,
    'Investment Value': stat?.investmentValue,
    'Effective On': stat?.effectiveOn,
  }));

  if (portfolioPerformance?.length === 0) {
    return <></>;
  }

  return (
    <div className="rounded-xl p-4 overflow-clip border border-white bg-gray-50">
      <ResponsiveContainer width="100%" height={460}>
        <ComposedChart data={portfolioPerformance}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={indigo} stopOpacity={0.8} />
              <stop offset="95%" stopColor={indigo} stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={green} stopOpacity={0.8} />
              <stop offset="95%" stopColor={green} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis axisLine={false} tickLine={false} dataKey="Effective On" fontSize={10} />
          <YAxis hide={true} domain={[0, 'dataMax + 0.2']} />
          <Legend content={({ payload }) => <CustomLegend payload={payload} />} />
          <CartesianGrid
            stroke="#eef2ff"
            strokeDasharray="3 3"
            verticalCoordinatesGenerator={props =>
              props.width > 0
                ? Array.from({ length: Math.ceil(props.width / 20) }, (_, index) => index * 20)
                : []
            }
            horizontalCoordinatesGenerator={props =>
              props.height > 0
                ? Array.from(
                    { length: Math.ceil((props.height - 40) / 20) },
                    (_, index) => index * 20
                  )
                : []
            }
          />
          <Area
            type="monotone"
            dataKey="Unrealised Value"
            fill="url(#colorUv)"
            stroke={indigo}
            fillOpacity={0.7}
            dot={false}
          />
          <Area
            type="monotone"
            dataKey="Investment Value"
            fillOpacity={1}
            fill="url(#colorPv)"
            stroke={green}
            dot={false}
          />
          {/* <Line
            type="monotone"
            dataKey="Realised Value"
            stroke={black}
            strokeWidth={2}
            dot={false}
          /> */}
          <Tooltip
            position={{ x: 5, y: 5 }}
            cursor={{ stroke: '#616161', strokeWidth: 1, strokeDasharray: '3 3' }}
            content={({ active, payload }) => <CustomTooltip active={active} payload={payload} />}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PortfolioChart;
