const transactionTypeOptions = [
  { label: 'Primary (Securities issued by the portfolio company)', value: 'PRIMARY' },
  { label: 'Secondary (Securities purchased from an existing stakeholder)', value: 'SECONDARY' },
];

const fundingRoundNameOptions = [
  { label: 'Pre-Seed', value: 'PRE_SEED' },
  { label: 'Seed', value: 'SEED' },
  { label: 'Seed+', value: 'SEED_PLUS' },
  { label: 'Series A', value: 'SERIES_A' },
  { label: 'Series A+', value: 'SERIES_A_PLUS' },
  { label: 'Series B', value: 'SERIES_B' },
  { label: 'Series B+', value: 'SERIES_B_PLUS' },
  { label: 'Series C', value: 'SERIES_C' },
  { label: 'Series C+', value: 'SERIES_C_PLUS' },
  { label: 'Other', value: 'OTHER' },
];

const shareClassTypeOptions = [
  { label: 'Preferred', value: 'PREFERRED' },
  { label: 'Common', value: 'COMMON' },
];

const prorataRightsTypeOptions = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
  { label: 'Maybe', value: 'NOT_SURE' },
];

const premiumToLastRoundTypeOptions = [
  { label: 'Premium', value: 'PREMIUM' },
  { label: 'Discount', value: 'DISCOUNT' },
];

const valuationTypeOptions = [
  { label: 'Pre-Money', value: 'PRE_MONEY' },
  { label: 'Post-Money', value: 'POST_MONEY' },
];

const financialInstrumentTypeOptions = [
  { label: 'Equity/ Preference Shares', value: 'EQUITY' },
  { label: 'Compulsorily Convertible Debentures', value: 'DEBT' },
  { label: 'Convertible Note', value: 'CONVERTIBLE_NOTE' },
  { label: 'SAFE', value: 'SAFE' },
];

export {
  transactionTypeOptions,
  fundingRoundNameOptions,
  shareClassTypeOptions,
  prorataRightsTypeOptions,
  premiumToLastRoundTypeOptions,
  valuationTypeOptions,
  financialInstrumentTypeOptions,
};
