import { Disclosure } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { MinusIcon } from '@heroicons/react/24/outline';

const faqs = [
  {
    question: 'What is the review process?',
    answer:
      "Angel Investing in India is governed by SEBI's AIF regulations. We are required to verify the identity of all investors and ensure that they are eligible to invest in startups as angel investors.",
  },
  {
    question: 'How long does this process take?',
    answer:
      'Typically 1-2 business days. We will keep you notified of any updates or delays through email.',
  },
  {
    question: 'How do I make changes to my application?',
    answer:
      'You can always make changes to your profile after the review process is complete. But if you would like to change your submission or inform us of any errors, please contact us at hi@angellistindia.com.',
  },
];

export default function NextSteps() {
  return (
    <div className="mx-auto mt-10 max-w-7xl px-6 lg:px-8 pb-8 ring-1 ring-gray-200 rounded-lg">
      <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
        <dl className="space-y-6 divide-y divide-gray-900/10">
          {faqs.map(faq => (
            <Disclosure key={faq.question} as="div" className="pt-6">
              <dt>
                <Disclosure.Button className="group flex w-full items-start justify-between text-left text-gray-900">
                  <span className="text-base font-semibold leading-7">{faq.question}</span>
                  <span className="ml-6 flex h-7 items-center">
                    <PlusIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                    <MinusIcon
                      aria-hidden="true"
                      className="h-6 w-6 [.group:not([data-open])_&]:hidden"
                    />
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-2 pr-12">
                <p className="text-base leading-7 text-gray-600 text-left">{faq.answer}</p>
              </Disclosure.Panel>
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  );
}
