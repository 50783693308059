import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateLayoutContent } from 'layouts/CreateLayout';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import Badge from 'primitives/Badge';
import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';

import { formatCurrency } from 'utils/format-helper';

import CreateDealExternalInvestorDialogButton from './CreateExternalInvestorDialogButton';
import RemoveExternalInvestorDialogButton from './RemoveExternalInvestorDialogButton';
import UpdateDealExternalInvestorDialog from './UpdateExternalInvestorDialog';

const EXTERNAL_INVESTORS_QUERY = gql(`
  query ExternalInvestorsOfDeal($id: ID!) {
    deal(id: $id) {
      id
      status
      externalInvestors {
        id
        name
        description
        amount
        isLead
      }
      dealTerms {
        id
        hasPreviouslyRaisedMoney
        previouslyRaisedAmount
      }
    }
  }
`);

const UPDATE_DEAL_TERMS_MUTATION = gql(`
  mutation UpdateDealTerms(
    $dealId: ID!
    $hasPreviouslyRaisedMoney: Boolean!
    $previouslyRaisedAmount: Float
  ) {
    updateDealTerms(
      id: $dealId
      hasPreviouslyRaisedMoney: $hasPreviouslyRaisedMoney
      previouslyRaisedAmount: $previouslyRaisedAmount
    ) {
      id
      hasPreviouslyRaisedMoney
      previouslyRaisedAmount
    }
  }
`);

const ExternalInvestors: FC = () => {
  const navigate = useNavigate();
  const [externalInvestorOnDisplay, setExternalInvestorOnDisplay] = useState<string | null>(null);
  const { syndicateId, dealId } = useParams<{ syndicateId: string; dealId: string }>() as {
    syndicateId: string;
    dealId: string;
  };

  const { loading, error, data, refetch } = useQuery(EXTERNAL_INVESTORS_QUERY, {
    variables: {
      id: dealId,
    },
  });

  const [updateDealTerms, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_DEAL_TERMS_MUTATION
  );

  if (loading) return <LoadingIndicator />;

  if (error || !data || !dealId) return <ErrorMessage error={error} refetch={refetch} />;

  const { externalInvestors, dealTerms } = data.deal;

  return (
    <CreateLayoutContent
      title="Who else is participating in this deal"
      subTitle="Let others know the other co-investors and angels in this deal"
    >
      <div className="mb-4 rounded-lg grid grid-cols-1 gap-2">
        <div>
          <h3 className="text-base font-semibold">External Investors</h3>
          {externalInvestors.length === 0 && (
            <p className="text-md text-gray-500">
              List the external investors participating in this deal
            </p>
          )}
        </div>
        {externalInvestors.map(externalInvestor => (
          <div key={externalInvestor.id}>
            <div className="bg-white ring-1 ring-gray-200 rounded-lg px-4 py-2">
              <div className="flex items-start">
                <div className="flex-1">
                  <h3 className="text-md font-medium">{externalInvestor.name}</h3>
                  <p className="text-sm text-gray-500">{externalInvestor.description}</p>
                </div>
                <div className="flex flex-col justify-start">
                  <p className="text-md text-indigo-500">
                    {externalInvestor.amount && `${formatCurrency(externalInvestor.amount)}`}
                  </p>
                  <p className="text-right text-sm text-gray-500">
                    {externalInvestor.isLead ? <Badge label="Lead" color="purple" /> : ''}
                  </p>
                </div>
              </div>
              <div className="mt-4 flex gap-x-2 border-t pt-1">
                <Button
                  variant="text"
                  className="!text-gray-700 hover:!text-gray-500"
                  onClick={() => setExternalInvestorOnDisplay(externalInvestor.id)}
                >
                  Edit
                </Button>
                <RemoveExternalInvestorDialogButton id={externalInvestor.id} />
              </div>
              {externalInvestorOnDisplay === externalInvestor.id && (
                <UpdateDealExternalInvestorDialog
                  key={externalInvestor.id}
                  id={externalInvestor.id}
                  name={externalInvestor.name}
                  description={externalInvestor.description}
                  amount={externalInvestor.amount}
                  isLead={externalInvestor.isLead}
                  onClose={() => setExternalInvestorOnDisplay(null)}
                />
              )}
            </div>
          </div>
        ))}
        <div>
          <CreateDealExternalInvestorDialogButton dealId={dealId} />
        </div>
      </div>

      <FormPanel
        loading={updateLoading}
        error={updateError}
        onSubmit={submitData => {
          updateDealTerms({
            variables: {
              dealId,
              hasPreviouslyRaisedMoney: submitData.hasPreviouslyRaisedMoney === 'true',
              previouslyRaisedAmount:
                submitData.hasPreviouslyRaisedMoney === 'true'
                  ? submitData.previouslyRaisedAmount
                  : 0,
            },
          }).then(() => {
            navigate(
              `/syndicate-dashboard/${syndicateId}/deals/create/${dealId}/company-information`
            );
          });
        }}
        onCancel={() => {
          navigate(`/syndicate-dashboard/${syndicateId}/deals/create/${dealId}/basic-information`);
        }}
        submitButtonLabel="Save and continue"
        cancelButtonLabel="Back"
      >
        <FormInput
          fullWidth
          type="radio-group"
          fieldName="hasPreviouslyRaisedMoney"
          label="Has the company previously raised money?"
          defaultValue={dealTerms?.hasPreviouslyRaisedMoney?.toString() || ''}
          options={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="currency"
          fieldName="previouslyRaisedAmount"
          label="Previously raised amount"
          helperText="The total amount the company has raised in previous rounds"
          defaultValue={dealTerms?.previouslyRaisedAmount || ''}
          conditionsToShow={{
            matches: [
              {
                field: 'hasPreviouslyRaisedMoney',
                condition: '===',
                value: 'true',
              },
            ],
          }}
          validators={{
            required: true,
            minValue: 1,
          }}
        />
      </FormPanel>
    </CreateLayoutContent>
  );
};

export default ExternalInvestors;
