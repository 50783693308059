import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { InvestmentEntityStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';
import Toast from 'primitives/Toast';
import useToast from 'primitives/Toast/useToast';

const PENDING_SYNDICATE_INVITES_QUERY = gql(`
  query PendingSyndicateInvites($investmentEntityFilters: DashboardInvestmentEntitiesFilterType, $cursor: ID, $limit: Int) {
    dashboard {
      id
      syndicateInvites(cursor: $cursor, limit: $limit) {
        nodes {
          id
          type
          syndicate {
            id
            name
            image
            statistics {
              id
              topStage
              topIndustry
              dealFlow
            }
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
      investmentEntities(filters: $investmentEntityFilters) {
        id
        status
      }
    }
  }
`);

const IncomingSyndicateInvites: FC = () => {
  const { loading, error, data, refetch, fetchMore } = useQuery(PENDING_SYNDICATE_INVITES_QUERY, {
    variables: {
      limit: 50,
      investmentEntityFilters: {
        status: InvestmentEntityStatusEnumType.Active,
      },
    },
  });

  const { showToast, handleToastOpen, handleToastClose } = useToast();

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const dashboard = data.dashboard;
  const syndicateInvites = dashboard.syndicateInvites.nodes;
  const investmentEntities = dashboard.investmentEntities;
  const pageInfo = dashboard.syndicateInvites.pageInfo;

  return (
    <>
      <DataTable
        data={syndicateInvites}
        onClick={syndicateInvite =>
          investmentEntities.length === 0
            ? handleToastOpen()
            : window.open(`/syndicate-invites/${syndicateInvite.id}`, '_blank')
        }
        columns={[
          {
            label: '',
            fieldName: 'syndicate.image',
            type: 'IMAGE',
          },
          {
            label: 'Name',
            fieldName: 'syndicate.name',
          },
          {
            label: 'Invite Type',
            fieldName: 'type',
            type: 'SYNDICATE_INVITE_TYPE',
          },
          {
            label: 'Top Stage',
            fieldName: 'syndicate.statistics.topStage',
            type: 'STAGE',
          },
          {
            label: 'Top Industry',
            fieldName: 'syndicate.statistics.topIndustry',
            type: 'INDUSTRY',
          },
          {
            label: 'Deal Flow',
            fieldName: 'syndicate.statistics.dealFlow',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
      <Toast
        title="Activate your account"
        message={'You must have at least one active investment entity to view this invite.'}
        show={showToast}
        handleClose={handleToastClose}
        type="error"
      />
    </>
  );
};

export default IncomingSyndicateInvites;
