import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { SyndicateInviteLinkType } from '__generated__/graphql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import CreateSyndicateInviteLinkDialogButton from './CreateSyndicateInviteLink';
import UpdateSyndicateInviteLinkStatus from './UpdateSyndicateInviteLinkStatus';

const SYNDICATE_INVITE_LINKS_QUERY = gql(`
  query GetSyndicateInviteLinks($syndicateId: ID!) {
    syndicate(id: $syndicateId) {
      id
      inviteLinks {
        id
        status
        numberOfInvestors
        note
        shortUrl
        createdAt
      }
    }
  }
`);

const SyndicateInviteLinks: FC = () => {
  const [inviteLinkOnDisplay, setInviteLinkDisplay] = useState<SyndicateInviteLinkType | null>(
    null
  );
  const { syndicateId } = useParams<{ syndicateId: string }>() as { syndicateId: string };

  const { loading, error, data, refetch } = useQuery(SYNDICATE_INVITE_LINKS_QUERY, {
    variables: {
      syndicateId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const inviteLinks = data.syndicate.inviteLinks;

  return (
    <>
      {inviteLinkOnDisplay && (
        <UpdateSyndicateInviteLinkStatus
          id={inviteLinkOnDisplay.id}
          status={inviteLinkOnDisplay.status}
          onClose={() => setInviteLinkDisplay(null)}
        />
      )}
      <div className="mt-2 sm:flex sm:items-center sm:justify-between">
        <div className="max-w-xl">
          <h3 className="text-lg font-semibold leading-6 text-gray-700">Shareable Invite Links</h3>
          <p className="mt-1 text-md text-gray-500">
            Invite investors to join your syndicate by sharing the invite link.
          </p>
        </div>
        <CreateSyndicateInviteLinkDialogButton syndicateId={syndicateId} />
      </div>
      <div className="mt-4">
        <DataTable
          data={inviteLinks}
          onClick={inviteLink => setInviteLinkDisplay(inviteLink)}
          columns={[
            {
              label: 'Short URL',
              fieldName: 'shortUrl',
              type: 'COPY_STRING',
            },
            {
              label: 'Note',
              fieldName: 'note',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Created At',
              fieldName: 'createdAt',
              type: 'DATETIME',
            },
            {
              label: 'Investor Limit',
              fieldName: 'numberOfInvestors',
              type: 'NUMBER',
            },
          ]}
        />
      </div>
    </>
  );
};

export default SyndicateInviteLinks;
