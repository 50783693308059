import { DealCompanyStageEnumType } from '__generated__/graphql';

import HorizontalBarChart from 'components/HorizontalBarChart';

import stageToReadable from 'utils/enums/stage-to-readable';

const PercentageDealsByStageChart = ({
  data,
}: {
  data: {
    stage: DealCompanyStageEnumType;
    percentage: number;
  }[];
}) => {
  return (
    <div className="mt-4">
      {data.map(item => {
        const { label } = stageToReadable(item.stage);

        return (
          <div className="flex justify-between gap-x-2 my-2 items-end" key={item.stage}>
            <span className="text-xs text-gray-500">{label}</span>
            <div className="inline-flex">
              <span className="text-xs text-indigo-600 mr-2">{item.percentage}%</span>
              <div className="w-36">
                <HorizontalBarChart percentage={item.percentage} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PercentageDealsByStageChart;
