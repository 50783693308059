import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

const INVITE_LEAD_TO_SYNDICATE_MUTATION = gql(`
  mutation InviteLeadToSyndicate($id: ID!, $name: String!, $email: String!, $note: String, $leadRole: SyndicateLeadRoleEnumType) {
    inviteLeadToSyndicate(id: $id, name: $name, email: $email, note: $note, leadRole: $leadRole) {
      id
      name
      email
      status
      leadRole
    }
  }
`);

const InviteLeadToSyndicateDialogButton: FC<{ syndicateId: string }> = ({ syndicateId }) => {
  const [open, setOpen] = useState(false);

  const [inviteLeadToSyndicate, { loading, error }] = useMutation(
    INVITE_LEAD_TO_SYNDICATE_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: cache.identify({
            __typename: 'SyndicateType',
            id: syndicateId,
          }),
          fields: {
            invites(existingInvitesRef) {
              const newInviteNodeRef = cache.writeFragment({
                data: data.inviteLeadToSyndicate,
                fragment: gql(`
                fragment NewInvite on SyndicateInviteType {
                  id
                  name
                  email
                  status
                  leadRole
                }
              `),
              });
              const updatedInvitesRef = {
                nodes: [newInviteNodeRef, ...existingInvitesRef.nodes],
                pageInfo: {
                  ...existingInvitesRef.pageInfo,
                  cursor: data.inviteLeadToSyndicate?.id,
                },
              };
              return updatedInvitesRef;
            },
          },
        });
      },
    }
  );

  return (
    <>
      <Button onClick={() => setOpen(true)}>Invite To Syndicate</Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel>
          <DialogTitle>Invite Lead To Syndicate</DialogTitle>
          <DialogSubTitle>
            Only add people you trust. Owners and Admins have visibility to all areas of the
            syndicate, including your deals and/or funds. Carry Recipients have visibility to the
            carry distribution of a specific deal only.
          </DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => setOpen(false)}
            onSubmit={data => {
              inviteLeadToSyndicate({
                variables: {
                  id: syndicateId,
                  name: data.name,
                  email: data.email,
                  note: data.note,
                  leadRole: data.leadRole,
                },
              }).then(() => setOpen(false));
            }}
          >
            <FormInput
              fullWidth
              type="select"
              fieldName="leadRole"
              label="Role"
              defaultValue="ADMIN"
              options={[
                { label: 'Owner', value: 'OWNER' },
                { label: 'Admin', value: 'ADMIN' },
                { label: 'Carry Recipient', value: 'CARRY_RECIPIENT' },
              ]}
            />
            <FormInput fullWidth type="text" fieldName="name" label="Name" defaultValue="" />
            <FormInput
              fullWidth
              type="text"
              fieldName="email"
              label="Email ID"
              defaultValue=""
              helperText="Email must match the address associated with their AngelList India account"
            />
            <FormInput
              fullWidth
              type="text"
              fieldName="note"
              label="Note"
              defaultValue=""
              helperText='Optional note to include in the invitation email. For example, "Hi, I would like to invite you to join my syndicate."'
            />
          </FormPanel>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default InviteLeadToSyndicateDialogButton;
