const DealFileTypeOptions = [
  {
    label: 'Other',
    value: 'OTHER',
  },
  {
    label: 'Distribution Statements',
    value: 'DISTRIBUTION_STATEMENTS',
  },
  {
    label: 'Unit Statements',
    value: 'UNIT_STATEMENTS',
  },
];

export default DealFileTypeOptions;
