import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import alIndiaLogo from 'media/alIndiaLogo.png';

const CreateStepperLayout: FC<{ children: any; onClose?: () => void }> = props => {
  return (
    <div className="flex min-h-screen flex-1 flex-col py-6 sm:px-6 lg:px-8 bg-gray-50">
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <img className="h-6 w-auto" src={alIndiaLogo} alt="Your Company" />
        </div>
        {props.onClose && (
          <div className="mt-4 flex md:ml-4 md:mt-0">
            <button type="button" className="-m-2.5 p-2.5" onClick={props.onClose}>
              <span className="sr-only">Close sidebar</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        )}
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">{props.children}</div>
    </div>
  );
};

const StepperLayoutContent: FC<{ children: any }> = ({ children }) => {
  return (
    <div className="lg:-mx-4 my-4 p-4 bg-white ring-1 ring-gray-200 rounded-lg">{children}</div>
  );
};

const StepperLayoutTabs = ({
  tabs,
}: {
  tabs: {
    name: string;
    matchingUrls: string[]; // All the URLs that this tab should be active for. Ex: ['/onboarding', '/onboarding/social-profile']
    defaultUrl: string; // The true path to this tab. Ex: /onboarding/social-profile
    title: string;
    subTitle: string;
    conditionForComplete?: boolean;
    index?: boolean;
  }[];
}) => {
  const location = useLocation();

  const currentTab = tabs.find(tab => tab.matchingUrls.some(url => location.pathname === url));

  function isPathActive(paths?: string[]): boolean {
    return paths ? paths.some(path => location.pathname === path) : false;
  }

  function isPathDisabled(paths?: string[]): boolean {
    return !tabs.find(tab => tab.matchingUrls === paths)?.conditionForComplete;
  }

  function isStepComplete(paths?: string[]): boolean {
    return !!tabs.find(tab => tab.matchingUrls === paths)?.conditionForComplete;
  }

  if (!currentTab) {
    return null;
  }

  return (
    <>
      <h2 className="mt-6 text-3xl font-bold leading-9 tracking-tight text-gray-700">
        {currentTab.title}
      </h2>
      <p className="text-md mt-1 text-gray-500">{currentTab.subTitle}</p>
      <nav aria-label="Progress" className="mb-8">
        <ol
          className={classNames(
            'flex md:space-x-2 space-y-0',
            tabs.length > 1 ? 'mt-4 md:mt-6' : 'mt-0'
          )}
        >
          {tabs.length > 1 &&
            tabs.map((tab, i) => (
              <li key={tab.defaultUrl} className="flex-1">
                <NavLink
                  to={tab.defaultUrl}
                  className={classNames('flex flex-col py-2 border-l-0 border-t-4 pb-0 pl-0 pt-4', {
                    'border-indigo-200 hover:border-indigo-300': isStepComplete(tab.matchingUrls),
                    'border-gray-200 hover:border-gray-300': !isStepComplete(tab.matchingUrls),
                    'border-indigo-600': isPathActive(tab.matchingUrls),
                    'pointer-events-none':
                      isPathDisabled(tab.matchingUrls) || isPathActive(tab.matchingUrls),
                  })}
                  aria-current={isPathActive(tab.matchingUrls) ? 'step' : undefined}
                >
                  <span className="text-sm font-medium">
                    {isStepComplete(tab.matchingUrls) ? (
                      <span className="text-indigo-600 group-hover:text-indigo-800">
                        Step {i + 1}
                      </span>
                    ) : (
                      <span className="text-gray-500 group-hover:text-gray-700">Step {i + 1}</span>
                    )}
                  </span>
                  <span className="text-sm font-medium">{tab.name}</span>
                </NavLink>
              </li>
            ))}
        </ol>
      </nav>
    </>
  );
};

export default CreateStepperLayout;

export { StepperLayoutContent, StepperLayoutTabs };
