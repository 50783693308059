export const getEligibilityResponseValue = (response: string | undefined) => {
  if (response === undefined) return null;

  switch (response.replace(/['"]/g, '')) {
    case 'I am an individual investor with net tangible assets of INR 2 crores or more (excluding value of my principal residence)':
      return 'netWorth2Cr';
    case 'I am a body corporate with a net worth of INR 10 crores or more':
      return 'corporateNetWorth10Cr';
    case 'I am a SEBI registered Alternative Investment Fund/ Venture Capital Fund':
      return 'ventureCapitalFund';
    case 'I qualify as an “Accredited Investor” as per Securities and Exchange Board Of India (Alternative Investment Funds) Regulations, 2012':
      return 'accreditedInvestorAsPerSebi';
    default:
      return null;
  }
};

export const getTargetAllocationValue = (target: string | undefined) => {
  if (target === undefined) return null;

  switch (target.replace(/['"]/g, '')) {
    case 'I am looking to invest less than 25 lakhs':
      return 'lessThan25Lakhs';
    case 'I am looking to invest between 25-50 lakhs':
      return '25To50Lakhs';
    case 'I am looking to invest between 50 lakhs - 1 crore':
      return '50LakhsTo1Crore';
    case 'I am looking to invest more than 1 crore':
      return '1CrorePlus';
    default:
      return '';
  }
};

export const getInvestingExperienceValue = (response: string | undefined) => {
  if (response === undefined) return null;

  switch (response.replace(/['"]/g, '')) {
    case 'I have invested in startups or emerging or early-stage ventures directly/ indirectly':
      return 'invested';
    case 'I am a senior management professional with at least 10 years of experience':
      return 'seniorManagement';
    case 'I represent/ have represented a family office':
      return 'familyOffice';
    case 'I have experience as a serial entrepreneur':
      return 'entrepreneur';
    default:
      return '';
  }
};
