import { DealTypeEnumType } from '__generated__/graphql';

export default function dealTypeEnumToReadable(type: DealTypeEnumType): {
  label: string;
  color: string;
} {
  switch (type) {
    case DealTypeEnumType.Syndicated:
      return {
        label: 'Syndicated',
        color: 'indigo',
      };
    case DealTypeEnumType.Ruv:
      return {
        label: 'Roll Up Vehicle',
        color: 'purple',
      };
    default:
      return {
        label: type,
        color: 'gray',
      };
  }
}
