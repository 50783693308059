import { gql, useQuery } from '@apollo/client';
import { BankAccountType } from '__generated__/graphql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import AddBankAccountDialogButton from './AddBankAccountDialogButton';
import UpdateBankAccountDialog from './UpdateBankAccountDialog';

const INVESTMENT_ENTITY_BANK_ACCOUNTS_QUERY = gql(`
  query InvestmentEntityBankAccountsInformation($id: ID!) {
    investmentEntity(id: $id) {
      id
      bankAccounts {
        id
        bankName
        accountHolderName
        accountType
        accountNumber
        ifscCode
        isVerified
      }
      permissions {
        canAddBankAccount
      }
    }
  }
`);

const InvestmentEntityBankAccount: FC = () => {
  const [selectedBankDetails, setSelectedBankDetails] = useState<BankAccountType | null>(null);

  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };
  const { loading, data, error, refetch } = useQuery(INVESTMENT_ENTITY_BANK_ACCOUNTS_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;
  const bankAccounts = data.investmentEntity.bankAccounts;

  return (
    <>
      {selectedBankDetails && (
        <UpdateBankAccountDialog
          investmentEntityId={investmentEntityId}
          bankAccount={selectedBankDetails}
          onClose={() => setSelectedBankDetails(null)}
        />
      )}
      <DataTable
        data={bankAccounts}
        onClick={bankDetails => setSelectedBankDetails(bankDetails)}
        emptyListTitle="Your haven't added any bank accounts yet."
        columns={[
          {
            label: 'Bank Name',
            fieldName: 'bankName',
          },
          {
            label: 'Account Holder Name',
            fieldName: 'accountHolderName',
          },
          {
            label: 'Account Number',
            fieldName: 'accountNumber',
          },
          {
            label: 'IFSC Code',
            fieldName: 'ifscCode',
          },
          {
            label: 'Account Type',
            fieldName: 'accountType',
          },
        ]}
      />

      <div>
        {data.investmentEntity.permissions.canAddBankAccount && (
          <AddBankAccountDialogButton investmentEntityId={data.investmentEntity.id} />
        )}
      </div>
    </>
  );
};

export default InvestmentEntityBankAccount;
