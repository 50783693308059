import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  SyndicateInviteQueryTypeEnumType,
  SyndicateInviteSourceTypeEnumType,
  SyndicateInviteType,
  SyndicateInviteTypeEnumType,
} from '__generated__/graphql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import InviteUsersToSyndicateDialogButton from './InviteUsersToSyndicateDialogButton';
import RescindSyndicateUserInviteDialog from './RescindSyndicateUserInviteDialog';

const SYNDICATE_INVITES_QUERY = gql(`
  query SyndicateInvites($id: ID!, $cursor: ID, $filters: SyndicateInvitesFilterType) {
    syndicate(id: $id) {
      id
      invites(cursor: $cursor, filters: $filters) {
        nodes {
          id
          name
          email
          user {
            id
            name
            email
          }
          status
          createdAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const SyndicateInvites: FC = () => {
  const { syndicateId } = useParams<{ syndicateId: string }>() as { syndicateId: string };
  const [inviteOnDisplay, setInviteOnDisplay] = useState<SyndicateInviteType | null>(null);

  const { loading, error, data, refetch, fetchMore } = useQuery(SYNDICATE_INVITES_QUERY, {
    variables: {
      id: syndicateId,
      filters: {
        type: SyndicateInviteTypeEnumType.FromSyndicateToUser,
        status: SyndicateInviteQueryTypeEnumType.Pending,
        source: SyndicateInviteSourceTypeEnumType.Email,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading || !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const { nodes: invites, pageInfo } = data.syndicate.invites;

  const combinedInvites = invites.map(invite => ({
    ...invite,
    name: invite.user?.name || invite.name,
    email: invite.user?.email || invite.email,
  }));

  return (
    <>
      {inviteOnDisplay && (
        <RescindSyndicateUserInviteDialog
          id={inviteOnDisplay.id}
          onClose={() => setInviteOnDisplay(null)}
        />
      )}
      <div className="mt-2 sm:flex sm:items-center sm:justify-between">
        <div className="max-w-xl">
          <h3 className="text-lg font-semibold leading-6 text-gray-700">Invite by email</h3>
          <p className="mt-1 text-md text-gray-500">
            Build your network by inviting investors to your syndicate.
          </p>
        </div>
        <InviteUsersToSyndicateDialogButton syndicateId={syndicateId} />
      </div>
      <DataTable
        data={combinedInvites}
        onClick={invite => setInviteOnDisplay(invite)}
        columns={[
          {
            label: 'Invited Email',
            fieldName: 'email',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Invited At',
            fieldName: 'createdAt',
            type: 'DATETIME',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        paginationLoading={loading}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </>
  );
};

export default SyndicateInvites;
