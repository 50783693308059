import Help from 'routes/Help';

import MessagingPanel from 'components/Conversation/MessagingPanel';
import ProtectedRoute from 'components/ProtectedRoute';

import Dashboard from './Dashboard';
import DealPage from './Deal';
import DealInvitePage from './DealInvite';
import DealInviteConfirmPage from './DealInviteConfirm';
import DealInvites from './DealInvites';
import ActiveInvitesOfUser from './DealInvites/ActiveInvites';
import CommitmentsOfUser from './DealInvites/Commitments';
import PassedInvitesOfUser from './DealInvites/PassedInvites';
import InvestmentEntities from './InvestmentEntities';
import CreateInvestmentEntity from './InvestmentEntities/CreateInvestmentEntity';
import BasicInformationInvestmentEntity from './InvestmentEntities/CreateInvestmentEntity/BasicInformation';
import InvestorApplication from './InvestmentEntities/CreateInvestmentEntity/InvestorApplication';
import Kyc from './InvestmentEntities/CreateInvestmentEntity/Kyc';
import SubmitInvestmentEntityForReview from './InvestmentEntities/CreateInvestmentEntity/SubmitForReview';
import InvestmentEntity from './InvestmentEntity';
import InvestmentEntityBankAccount from './InvestmentEntity/BankAccount';
import InvestmentEntityDematAccount from './InvestmentEntity/DematAccount';
import InvestmentEntityDocuments from './InvestmentEntity/Documents';
import InvestmentEntityInformation from './InvestmentEntity/Information';
import InvestmentEntityInvestments from './InvestmentEntity/Investments';
import InvestmentEntityKyc from './InvestmentEntity/Kyc';
import UnitStatement from './InvestmentEntity/UnitStatement';
import InvestorConversations from './InvestorConversations';
import Posts from './Posts';
import Settings from './Settings';
import GeneralSettings from './Settings/General';
import InvestmentPreferencesSettingsPage from './Settings/InvestmentPreferences';
import NotificationSettingsPage from './Settings/Notifications';
import SecuritySettingsPage from './Settings/Security';
import Syndicates from './Syndicates';
import InvestorApplications from './Syndicates/Applications';
import IncomingSyndicateInvites from './Syndicates/Applications/Incoming';
import OutgoingSyndicateApplications from './Syndicates/Applications/Outgoing';
import BackedSyndicates from './Syndicates/Backed';

const routes = [
  {
    path: '/invest',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: 'deal/:dealId',
        element: (
          <ProtectedRoute>
            <DealPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'deal-invites/:dealInviteId',
        element: (
          <ProtectedRoute>
            <DealInvitePage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'deal-invites/:dealInviteId/confirm',
        element: (
          <ProtectedRoute>
            <DealInviteConfirmPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'deal-invites',
        element: (
          <ProtectedRoute>
            <DealInvites />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <ActiveInvitesOfUser />
              </ProtectedRoute>
            ),
          },
          {
            path: 'committed',
            element: (
              <ProtectedRoute>
                <CommitmentsOfUser />
              </ProtectedRoute>
            ),
          },
          {
            path: 'passed',
            element: (
              <ProtectedRoute>
                <PassedInvitesOfUser />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'investment-entities',
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <InvestmentEntities />
              </ProtectedRoute>
            ),
          },
          {
            path: ':investmentEntityId',
            element: (
              <ProtectedRoute>
                <InvestmentEntity />
              </ProtectedRoute>
            ),
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute>
                    <InvestmentEntityInformation />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'kyc',
                element: (
                  <ProtectedRoute>
                    <InvestmentEntityKyc />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'demat',
                element: (
                  <ProtectedRoute>
                    <InvestmentEntityDematAccount />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'bankAccounts',
                element: (
                  <ProtectedRoute>
                    <InvestmentEntityBankAccount />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'documents',
                element: (
                  <ProtectedRoute>
                    <InvestmentEntityDocuments />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'investments',
                element: (
                  <ProtectedRoute>
                    <InvestmentEntityInvestments />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: 'create',
            element: (
              <ProtectedRoute>
                <CreateInvestmentEntity />
              </ProtectedRoute>
            ),
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute>
                    <BasicInformationInvestmentEntity />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':investmentEntityId/basic-information',
                element: (
                  <ProtectedRoute>
                    <BasicInformationInvestmentEntity />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':investmentEntityId/kyc',
                element: (
                  <ProtectedRoute>
                    <Kyc />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':investmentEntityId/investor-application',
                element: (
                  <ProtectedRoute>
                    <InvestorApplication />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':investmentEntityId/submit-for-review',
                element: (
                  <ProtectedRoute>
                    <SubmitInvestmentEntityForReview />
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'investment-entities/:investmentEntityId/unit-statement',
        element: (
          <ProtectedRoute>
            <UnitStatement />
          </ProtectedRoute>
        ),
      },
      {
        path: 'syndicates',
        element: (
          <ProtectedRoute>
            <Syndicates />
          </ProtectedRoute>
        ),
        children: [
          // Removing the all syndicates tab for now
          // {
          //   index: true,
          //   element: (
          //     <ProtectedRoute>
          //       <SuggestedSyndicates />
          //     </ProtectedRoute>
          //   ),
          // },
          {
            index: true,
            path: 'backed',
            element: (
              <ProtectedRoute>
                <BackedSyndicates />
              </ProtectedRoute>
            ),
          },
          {
            path: 'pending',
            element: (
              <ProtectedRoute>
                <InvestorApplications />
              </ProtectedRoute>
            ),
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute>
                    <OutgoingSyndicateApplications />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'incoming',
                element: (
                  <ProtectedRoute>
                    <IncomingSyndicateInvites />
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'settings',
        element: (
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <GeneralSettings />
              </ProtectedRoute>
            ),
          },
          {
            path: 'notifications',
            element: (
              <ProtectedRoute>
                <NotificationSettingsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'security',
            element: (
              <ProtectedRoute>
                <SecuritySettingsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'investment-preferences',
            element: (
              <ProtectedRoute>
                <InvestmentPreferencesSettingsPage />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'conversations',
        element: (
          <ProtectedRoute>
            <InvestorConversations />
          </ProtectedRoute>
        ),
        children: [
          {
            path: ':conversationId',
            element: (
              <ProtectedRoute>
                <MessagingPanel />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'posts',
        element: (
          <ProtectedRoute>
            <Posts />
          </ProtectedRoute>
        ),
      },
      {
        path: 'help',
        element: <Help />,
      },
    ],
  },
];

export default routes;
