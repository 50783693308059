import { ProrataRightsEnumType } from '__generated__/graphql';

export default function prorataRightsToReadable(rights: ProrataRightsEnumType): {
  label: string;
  color: string;
} {
  switch (rights) {
    case ProrataRightsEnumType.Yes:
      return {
        label: 'Included',
        color: '#43A047',
      };
    case ProrataRightsEnumType.No:
      return {
        label: 'Not Included',
        color: '#FB8C00',
      };
    case ProrataRightsEnumType.NotSure:
      return {
        label: 'Unsure',
        color: '#FB8C00',
      };
  }
}
