import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_KYC_MUTATION = gql(`
  mutation UpdateKyc(
    $id: ID!
    $pan: String!
    $identityProofDocument: Upload!
    $addressProofDocument: Upload!
    $dateOfBirth: String!
  ) {
    updateKyc(
      id: $id
      pan: $pan
      identityProofDocument: $identityProofDocument
      addressProofDocument: $addressProofDocument
      dateOfBirth: $dateOfBirth
    ) {
      id
      pan
      identityProofDocument {
        url
        name
      }
      addressProofDocument {
        url
        name
      }
      dateOfBirth
    }
  }
`);

const UpdateKyc: FC<{
  id: string;
  pan: string;
  identityProofDocument: {
    url: string;
    name: string;
  };
  addressProofDocument: {
    url: string;
    name: string;
  };
  dateOfBirth: string;
}> = ({ id, pan, identityProofDocument, addressProofDocument, dateOfBirth }) => {
  const [updateKyc, { loading, error }] = useMutation(UPDATE_KYC_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateKyc({
          variables: {
            id,
            pan: data.pan,
            identityProofDocument: data.identityProofDocument,
            addressProofDocument: data.addressProofDocument,
            dateOfBirth: data.dateOfBirth,
          },
        });
      }}
      disableEdit={true}
      title="KYC Details"
    >
      <FormInput type="text" fieldName="pan" label="PAN" defaultValue={pan} />
      <FormInput
        type="upload"
        fieldName="identityProofDocument"
        label="Identity Proof Document"
        defaultValue={identityProofDocument.url}
      />
      <FormInput
        type="upload"
        fieldName="addressProofDocument"
        label="Address Proof Document"
        defaultValue={addressProofDocument.url}
      />
      <FormInput
        type="date"
        fieldName="dateOfBirth"
        label="Date of Birth"
        defaultValue={dateOfBirth}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateKyc;
