import { XMarkIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { FC } from 'react';
import { useLocation, useMatch } from 'react-router-dom';

import alIndiaLogo from 'media/alIndiaLogo.png';

const CreateLayout: FC<{ children: any; onClose?: () => void }> = props => {
  return (
    <div className="flex min-h-screen flex-1 flex-col py-6 sm:px-6 lg:px-8 bg-gray-50">
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <img className="h-6 w-auto" src={alIndiaLogo} alt="Your Company" />
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0">
          <button type="button" className="-m-2.5 p-2.5" onClick={props.onClose}>
            <span className="sr-only">Close sidebar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-4xl grid grid-cols-6 gap-x-8 pt-8">
        {props.children}
      </div>
    </div>
  );
};

const CreateLayoutContent = ({
  title,
  subTitle,
  children,
}: {
  title: string;
  subTitle: string;
  children: any;
}) => {
  return (
    <div>
      <h2 className="text-3xl font-bold leading-9 tracking-tight text-gray-700">{title}</h2>
      <p className="text-md mt-1 text-gray-500">{subTitle}</p>
      <div className="my-4 p-4 bg-white ring-1 ring-gray-200 rounded-lg">{children}</div>
    </div>
  );
};

const CreateLayoutTabs = ({
  tabs,
  baseUrl,
}: {
  tabs: {
    url: string;
    title: string;
    subTitle: string;
    conditionForComplete?: boolean;
    index?: boolean;
    showCheckIcon?: boolean;
  }[];
  baseUrl: string;
}) => {
  const location = useLocation();

  const basePathmatch = useMatch(baseUrl);

  function isPathActive(path: string): boolean {
    // Set Index Route tab as active if there is not identifier in the URL
    const indexRoutePath = tabs.find(tab => tab.index === true)?.url;
    if (basePathmatch && path === indexRoutePath) {
      return true;
    }
    return location.pathname.includes(path);
  }

  function isPathDisabled(path: string): boolean {
    return !tabs.find(tab => tab.url === path)?.conditionForComplete;
  }

  function isStepComplete(path: string): boolean {
    return (
      !!tabs.find(tab => tab.url === path)?.conditionForComplete &&
      !!tabs.find(tab => tab.url === path)?.showCheckIcon
    );
  }

  return (
    <div className="sticky top-8">
      <nav>
        <ol className="overflow-hidden space-y-2">
          {tabs.map(tab => (
            <li key={tab.url} className="relative overflow-hidden rounded-md shadow-sm">
              <div className="overflow-hidden bg-white">
                <a
                  href={tab.url}
                  className={classNames('group', {
                    'group-hover:bg-gray-200': isPathActive(tab.url),
                    'pointer-events-none': isPathDisabled(tab.url) || isPathActive(tab.url),
                  })}
                >
                  <span
                    className={classNames('absolute left-0 top-0 h-full w-1', {
                      'bg-indigo-600 group-hover:bg-indigo-600': isPathActive(tab.url),
                      'group-hover:bg-gray-200 bg-transparent': !isPathActive(tab.url),
                    })}
                  />
                  <span className="flex justify-between items-center px-4 py-2">
                    <span className="flex min-w-0 flex-col">
                      <span
                        className={classNames(
                          'text-md font-medium',
                          isPathActive(tab.url) ? 'text-indigo-600' : 'text-gray-700'
                        )}
                      >
                        {tab.title}
                      </span>
                      <span className="text-sm font-normal text-gray-500">{tab.subTitle}</span>
                    </span>
                    {isStepComplete(tab.url) && (
                      <span className="flex-shrink-0">
                        <span className="flex h-6 w-6 items-center justify-center rounded-full bg-indigo-500">
                          <CheckIcon className="h-4 w-4 text-white" aria-hidden="true" />
                        </span>
                      </span>
                    )}
                  </span>
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default CreateLayout;

export { CreateLayoutTabs, CreateLayoutContent };
