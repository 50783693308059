import { useLazyQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { SyndicateTypeEnumType } from '__generated__/graphql';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { CreateLayoutTabs } from 'layouts/CreateLayout';

const GET_DEAL_QUERY = gql(`
  query GetDealTabs($id: ID!) {
    deal(id: $id) {
      id
      status
      companyInformation {
        id
        stage
        memo
      }
      dealDisclosures {
        id
        risksNote
      }
      dealTerms {
        id
        hasPreviouslyRaisedMoney
      }
      carryRecipients {
        id
      }
      isCarryDistributionVerified
    }
  }
`);

const GET_SYNDICATE_TYPE_QUERY = gql(`
  query GetSyndicateTypeForTabs($id: ID!) {
    syndicate(id: $id) {
      id
      type
    }
  }
`);

const DealTabs: FC = () => {
  const { dealId, syndicateId } = useParams<{ dealId: string; syndicateId: string }>();
  const [getDeal, { data }] = useLazyQuery(GET_DEAL_QUERY);

  const [getSyndicate, { data: syndicateData }] = useLazyQuery(GET_SYNDICATE_TYPE_QUERY);
  useEffect(() => {
    if (dealId && syndicateId) {
      getDeal({ variables: { id: dealId } });
      getSyndicate({ variables: { id: syndicateId } });
    } else if (syndicateId) {
      getSyndicate({ variables: { id: syndicateId } });
    } else {
      return;
    }
  }, [dealId, getDeal, getSyndicate, syndicateId]);

  const isRuv = syndicateData?.syndicate.type === SyndicateTypeEnumType.Ruv;
  const isDealUnderReview = data?.deal.status === 'UNDER_OPS_REVIEW';

  const tabs = [
    {
      title: 'Basic Information',
      url: 'basic-information',
      subTitle: 'Company, Allocation and Terms',
      conditionForComplete: !!data?.deal,
      index: true,
      showCheckIcon: true,
    },
    {
      title: 'External Investors',
      url: 'external-investors',
      subTitle: 'Past Financing Information',
      conditionForComplete:
        data?.deal.dealTerms?.hasPreviouslyRaisedMoney !== null &&
        data?.deal.dealTerms?.hasPreviouslyRaisedMoney !== undefined,
      showCheckIcon: true,
    },
    {
      title: 'Company Information',
      url: 'company-information',
      subTitle: 'Company Details',
      conditionForComplete: !!data?.deal.companyInformation?.stage,
      showCheckIcon: true,
    },
    {
      title: 'Memo',
      url: 'memo',
      subTitle: 'Investment Thesis',
      conditionForComplete: !!data?.deal.companyInformation?.memo,
      showCheckIcon: true,
    },
    {
      title: 'Risks & Disclosures',
      url: 'risks-and-disclosures',
      subTitle: 'Risks your investors should know',
      conditionForComplete: !!data?.deal.dealDisclosures?.risksNote,
      showCheckIcon: true,
    },
    ...(isRuv
      ? []
      : [
          {
            title: 'Carry & GP Commitment',
            url: 'carry-recipients',
            subTitle: 'Carry Recipients',
            conditionForComplete: data?.deal.isCarryDistributionVerified,
            showCheckIcon: true,
          },
        ]),
  ];

  const submitForReviewTab = {
    title: 'E-Sign & Submit',
    url: 'e-sign-submit',
    subTitle: 'Submit for review',
    conditionForComplete: false,
    showCheckIcon: false,
  };

  const underReviewTab = {
    title: 'Under Review',
    url: 'under-review',
    subTitle: 'Your deal is under review',
    conditionForComplete: false,
    showCheckIcon: false,
  };

  if (isDealUnderReview) {
    tabs.push(underReviewTab);
  } else {
    tabs.push(submitForReviewTab);
  }

  const baseUrl = '/syndicate-dashboard/:syndicateId/deals/create';

  return <CreateLayoutTabs tabs={tabs} baseUrl={baseUrl} />;
};

export default DealTabs;
