import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import UpdateFund from './UpdateFund';

const FUND_QUERY = gql(`
  query GetFundSettings($id: ID!) {
    fund(id: $id) {
      id
      name
      image
      description
      closingDate
    }
  }
`);

const FundSettings: FC = () => {
  const { fundId } = useParams<{ fundId: string }>() as { fundId: string };

  const { loading, error, data, refetch } = useQuery(FUND_QUERY, {
    variables: {
      id: fundId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const fund = data.fund;

    return (
      <>
        <UpdateFund
          id={fund.id}
          name={fund.name}
          image={fund.image}
          description={fund.description}
          closingDate={fund.closingDate}
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Fund Settings"
        subTitle="Manage your fund information and configuration"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default FundSettings;
