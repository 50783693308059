import { SyndicateInviteTypeEnumType } from '__generated__/graphql';

export default function syndicateInviteTypeToReadable(type: SyndicateInviteTypeEnumType): {
  label: string;
  color: string;
} {
  switch (type) {
    case SyndicateInviteTypeEnumType.FromSyndicateToLead:
      return {
        label: 'Lead/Carry Invite',
        color: 'purple',
      };
    case SyndicateInviteTypeEnumType.FromSyndicateToUser:
      return {
        label: 'Investor Invite',
        color: 'green',
      };
    case SyndicateInviteTypeEnumType.FromUserToSyndicate:
      return {
        label: 'Syndicate Application',
        color: 'blue',
      };
    default:
      return {
        label: type,
        color: 'gray',
      };
  }
}
