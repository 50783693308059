import alIndiaLogo from 'media/alIndiaLogo.png';

export default function AuthLayout({ title, children }) {
  return (
    <div className="flex h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-10 mb-auto w-auto" src={alIndiaLogo} alt="AngelList India" />
        <h2 className="mt-6 text-center text-3xl font-bold leading-9 tracking-tight text-gray-600">
          {title}
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">{children}</div>
    </div>
  );
}
