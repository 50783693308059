import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateLayoutContent } from 'layouts/CreateLayout';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';
import MultiSelectFormInput from 'components/MultiSelect/MultiSelectFormInput';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

import LoadingIndicator from 'primitives/LoadingIndicator';

import { industryOptions } from './constants';

const COMPANY_MEMO_QUERY = gql(`
  query CompanyMemo($id: ID!) {
    deal(id: $id) {
      id
      status
      companyInformation {
        id
        memo
        industries
        pitchDeck
      }
    }
  }
`);

const UPDATE_COMPANY_MEMO_MUTATION = gql(`
  mutation UpdateCompanyInformationForCreate(
    $dealId: ID!
    $memo: String
    $industries: [IndustryTypeEnumType]
    $pitchDeck: Upload
  ) {
    updateCompanyInformation(
      id: $dealId
      memo: $memo
      industries: $industries
      pitchDeck: $pitchDeck
    ) {
      id
      companyInformation {
        id
        memo
        industries
        pitchDeck
      }
    }
  }
`);

const Memo: FC = () => {
  const navigate = useNavigate();

  const { syndicateId, dealId } = useParams<{ syndicateId: string; dealId: string }>() as {
    syndicateId: string;
    dealId: string;
  };

  const { loading, error, data, refetch } = useQuery(COMPANY_MEMO_QUERY, {
    variables: {
      id: dealId,
    },
  });

  const [updateCompanyMemo, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_COMPANY_MEMO_MUTATION
  );

  if (loading) return <LoadingIndicator />;

  if (error || !data || !dealId) return <ErrorMessage error={error} refetch={refetch} />;

  return (
    <CreateLayoutContent title="Describe the deal" subTitle="Add a memo and pitch deck">
      <FormPanel
        loading={updateLoading}
        error={updateError}
        onSubmit={data => {
          updateCompanyMemo({
            variables: {
              dealId,
              memo: data.dealMemo,
              industries: data.industries,
              pitchDeck: typeof data.pitchDeck === 'string' ? undefined : data.pitchDeck,
            },
          }).then(() => {
            navigate(
              `/syndicate-dashboard/${syndicateId}/deals/create/${dealId}/risks-and-disclosures`
            );
          });
        }}
        onCancel={() => {
          navigate(
            `/syndicate-dashboard/${syndicateId}/deals/create/${dealId}/company-information`
          );
        }}
        submitButtonLabel="Save and continue"
        cancelButtonLabel="Back"
      >
        <FormInput
          fieldName="dealMemo"
          type="custom"
          fullWidth
          defaultValue={data.deal.companyInformation.memo}
          helperText="What’s your investment thesis? Why are you excited about this deal?"
          label="Deal Memo *"
          readOnlyMode={false}
          customInput={EditorFormInput}
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="upload"
          fieldName="pitchDeck"
          label="Pitch Deck"
          helperText="Upto 30MB. Only PDF files are allowed."
          defaultValue={data.deal.companyInformation.pitchDeck || ''}
          maxFileSize={30}
          onlyAcceptsPDF={true}
        />
        <FormInput
          fullWidth
          type="custom"
          fieldName="industries"
          customInput={MultiSelectFormInput}
          label="Select the investment industry of the deal"
          helperText="The business carried out by the company should be such that 100% foreign investment is allowed under the automatic route as per the prevailing foreign exchange laws of India."
          defaultValue={data.deal.companyInformation.industries || []}
          options={industryOptions}
        />
      </FormPanel>
    </CreateLayoutContent>
  );
};

export default Memo;
