import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanel, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_SYNDICATE_USER_NOTE = gql(`
  mutation UpdateSyndicateUserNote($id: ID!, $privateNote: String!) {
    updateSyndicateUserNote(id: $id, privateNote: $privateNote) {
      id
      privateNote
    }
  }
`);

const SyndicateUserNote: FC<{ id: string; privateNote?: string | null }> = ({
  id,
  privateNote,
}) => {
  const [updateNote, { loading, error }] = useMutation(UPDATE_SYNDICATE_USER_NOTE);

  if (!privateNote) {
    return (
      <FormPanel
        loading={loading}
        error={error}
        onSubmit={data => {
          updateNote({
            variables: {
              id,
              privateNote: data.privateNote,
            },
          });
        }}
      >
        <FormInput
          type="textarea"
          fieldName="privateNote"
          label=""
          defaultValue={privateNote || ''}
        />
      </FormPanel>
    );
  }

  return (
    <>
      <FormPanelWithReadMode
        loading={loading}
        error={error}
        onSubmit={data => {
          updateNote({
            variables: {
              id,
              privateNote: data.privateNote,
            },
          });
        }}
      >
        <FormInput type="textarea" fieldName="privateNote" label="" defaultValue={privateNote} />
      </FormPanelWithReadMode>
    </>
  );
};

export default SyndicateUserNote;
