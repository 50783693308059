import { XMarkIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';

import alIndiaLogo from 'media/alIndiaLogo.png';

const FocusLayout: FC<{ title?: string; children: any; onClose?: () => void }> = props => {
  const renderContent = () => {
    return (
      <div className="flex min-h-screen flex-1 flex-col py-6 sm:px-6 lg:px-8 bg-gray-50">
        <div className="md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <img className="h-6 w-auto" src={alIndiaLogo} alt="AngelList India" />
          </div>
          {props.onClose && (
            <div className="mt-4 flex md:ml-4 md:mt-0">
              <button type="button" className="-m-2.5 p-2.5" onClick={props.onClose}>
                <span className="sr-only">Close sidebar</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-3xl">{props.children}</div>
      </div>
    );
  };

  return <>{renderContent()}</>;
};

export default FocusLayout;
