import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';
import classNames from 'classnames';

export default function RadioGroup({
  value,
  onChange,
  options,
  label,
  helperText,
  disabled = false,
  error,
}: {
  value: string;
  onChange?: (value: string) => void;
  options: { label: string; value: string }[];
  label: string;
  helperText?: string;
  disabled?: boolean;
  error?: string;
}) {
  return (
    <HeadlessRadioGroup
      value={options.find(option => option.value === value)}
      onChange={option => onChange!(option.value)}
    >
      <HeadlessRadioGroup.Label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </HeadlessRadioGroup.Label>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="error">
          {error}
        </p>
      )}
      <HeadlessRadioGroup.Description className="mt-2 text-sm text-gray-500">
        {helperText}
      </HeadlessRadioGroup.Description>
      <div className="mt-4 flex flex-col space-y-1">
        {options.map(option => (
          <HeadlessRadioGroup.Option
            key={option.value}
            value={option}
            className={classNames(
              'relative flex cursor-pointer items-start justify-start p-0.5',
              disabled ? 'cursor-not-allowed' : ''
            )}
            disabled={disabled}
          >
            {({ checked, active }) => (
              <>
                <span
                  className={classNames(
                    checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-indigo-600' : '',
                    'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <span className="ml-2 -mt-0.5 block text-sm font-normal leading-6 text-gray-900">
                  {option.label}
                </span>
              </>
            )}
          </HeadlessRadioGroup.Option>
        ))}
      </div>
    </HeadlessRadioGroup>
  );
}
