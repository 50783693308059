import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

const CLOSINGS_QUERY = gql(`
  query getClosingsOfSyndicateUser($id: ID!, $cursor: ID, $limit: Int) {
    syndicateUser(id: $id) {
      id
      closings(cursor: $cursor, limit: $limit) {
        nodes {
          id
          deal {
            id
            companyInformation {
              id
              company {
                id
                name
                image
              }
            }
          }
          amount
          status
          createdAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const InvestmentsOfSyndicateUser: FC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore, networkStatus } = useQuery(CLOSINGS_QUERY, {
    variables: {
      id,
      limit: 20,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const closings = data.syndicateUser.closings.nodes;
  const pageInfo = data.syndicateUser.closings.pageInfo;

  return (
    <DataTable
      data={closings}
      filterLoading={networkStatus === NetworkStatus.setVariables}
      paginationLoading={loading}
      onClick={closing => navigate(`/invest/deal/${closing.deal.id}`)}
      emptyListTitle="The user's investments will appear here"
      columns={[
        {
          label: 'Deal',
          fieldName: 'deal.companyInformation.company',
          type: 'COMPANY',
        },
        {
          label: 'Amount',
          fieldName: 'amount',
          type: 'CURRENCY',
        },
        {
          label: 'Status',
          fieldName: 'status',
          type: 'STATUS',
        },
        {
          label: 'Committed At',
          fieldName: 'createdAt',
          type: 'DATETIME',
        },
      ]}
      hasNextPage={pageInfo.hasNextPage}
      onLoadMore={() =>
        fetchMore({
          variables: {
            cursor: pageInfo.cursor,
          },
        })
      }
    />
  );
};

export default InvestmentsOfSyndicateUser;
