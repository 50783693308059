import htmlTruncate from 'html-truncate';
import { useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

const ReadMoreWithDialog = ({ content }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const previewLength = 600;

  const openDialog = () => setIsDialogOpen(true);

  const closeDialog = () => setIsDialogOpen(false);

  const previewText = htmlTruncate(content, previewLength);
  const isLongText = previewText !== content;

  return (
    <div>
      <div
        className="prose prose-md mt-4 text-gray-500 border-l-2 border-indigo-200 pl-6"
        dangerouslySetInnerHTML={{ __html: previewText }}
      />

      {isLongText && (
        <Button
          variant="text"
          onClick={openDialog}
          className="text-indigo-600 hover:underline mt-2"
        >
          Read More
        </Button>
      )}

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogPanel className="sm:!max-w-3xl">
          <DialogTitle>Full Memo</DialogTitle>
          <div
            className="prose prose-md mt-4 text-gray-500"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <DialogActions className="sm:grid-cols-1">
            <Button onClick={closeDialog}>Close</Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </div>
  );
};

export default ReadMoreWithDialog;
