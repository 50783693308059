import { useMutation } from '@apollo/client';
import { gql } from '__generated__';
import { FC, useMemo, useState } from 'react';
import QuillEditor from 'react-quill';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type EditorProps = {
  prevData?: string;
  readOnly?: boolean;
  onChange?: (any) => void;
};

let quillReact: ReactQuill | null = null;

const UPLOAD_IMAGE_FOR_POST_MUTATION = gql(`
  mutation UploadImageForPost($image: Upload) {
    uploadImageForPost(image: $image)
  }
`);

const Editor: FC<EditorProps> = ({ prevData, readOnly, onChange }) => {
  const [value, setValue] = useState('');

  const [uploadImageForPost] = useMutation(UPLOAD_IMAGE_FOR_POST_MUTATION);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ align: ['right', 'center', 'justify'] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image'],
        ],
        handlers: {
          image: file => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.click();
            input.onchange = async () => {
              const file: File | null = input.files ? input.files[0] : null;
              if (file) {
                uploadImageForPost({
                  variables: {
                    image: file,
                  },
                }).then(response => {
                  const newFileName: string | undefined | null = response.data?.uploadImageForPost;
                  let quill: any | null = quillReact?.getEditor();
                  const range: any | null = quill?.getSelection(true);
                  quill?.insertEmbed(range.index, 'image', newFileName);
                });
              }
            };
          },
        },
      },
    }),
    [uploadImageForPost]
  );

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
  ];

  return (
    <div className="mt-1">
      <QuillEditor
        theme="snow"
        modules={readOnly ? { toolbar: false } : modules}
        formats={formats}
        value={prevData || value}
        readOnly={readOnly}
        style={{
          minHeight: '200px',
          height: '30rem',
          marginBottom: '2.5rem',
        }}
        onChange={value => {
          setValue(value);
          if (onChange) onChange(value);
        }}
        ref={(el: ReactQuill) => {
          quillReact = el;
        }}
      />
    </div>
  );
};

export default Editor;
