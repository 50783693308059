import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateLayoutContent } from 'layouts/CreateLayout';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import LoadingIndicator from 'primitives/LoadingIndicator';

const COMPANY_INFORMATION_QUERY = gql(`
  query CompanyInformation($id: ID!) {
    deal(id: $id) {
      id
      status
      companyInformation {
        id
        stage
        monthsOfRunway
      }
    }
  }
`);

const UPDATE_COMPANY_INFORMATION_MUTATION = gql(`
  mutation UpdateCompanyInformation(
    $dealId: ID!
    $stage: DealCompanyStageEnumType
    $monthsOfRunway: MonthsOfRunwayEnumType
  ) {
    updateCompanyInformation(
      id: $dealId
      stage: $stage
      monthsOfRunway: $monthsOfRunway
    ) {
      id
      companyInformation {
        id
        stage
        monthsOfRunway
      }
    }
  }
`);

const CompanyInformation: FC = () => {
  const navigate = useNavigate();
  const { syndicateId, dealId } = useParams<{ syndicateId: string; dealId: string }>() as {
    syndicateId: string;
    dealId: string;
  };

  const { loading, error, data, refetch } = useQuery(COMPANY_INFORMATION_QUERY, {
    variables: {
      id: dealId,
    },
  });

  const [updateCompanyInformation, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_COMPANY_INFORMATION_MUTATION
  );

  if (loading) return <LoadingIndicator />;

  if (error || !data || !dealId) return <ErrorMessage error={error} refetch={refetch} />;

  return (
    <CreateLayoutContent title="Company Information" subTitle="Tell us more about the company">
      <FormPanel
        loading={updateLoading}
        error={updateError}
        onSubmit={data => {
          updateCompanyInformation({
            variables: {
              dealId,
              stage: data.stage,
              monthsOfRunway: data.monthsOfRunway,
            },
          }).then(() => {
            navigate(`/syndicate-dashboard/${syndicateId}/deals/create/${dealId}/memo`);
          });
        }}
        onCancel={() => {
          navigate(`/syndicate-dashboard/${syndicateId}/deals/create/${dealId}/external-investors`);
        }}
        submitButtonLabel="Save and continue"
        cancelButtonLabel="Back"
      >
        <FormInput
          fullWidth
          type="select"
          fieldName="stage"
          label="Company Stage"
          defaultValue={data.deal.companyInformation.stage || ''}
          options={[
            { label: 'Pre Launch', value: 'PRE_LAUNCH' },
            { label: 'Pre Revenue', value: 'PRE_REVENUE' },
            { label: 'Post Revenue', value: 'POST_REVENUE' },
            { label: 'Profitable', value: 'PROFITABLE' },
          ]}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="monthsOfRunway"
          label="Months of runway after this round"
          defaultValue={data.deal.companyInformation.monthsOfRunway || ''}
          options={[
            { label: 'Fewer than 12 months', value: 'FEWER_THAN_12' },
            { label: 'More than 12 months', value: 'MORE_THAN_12' },
          ]}
          validators={{
            required: true,
          }}
        />
      </FormPanel>
    </CreateLayoutContent>
  );
};

export default CompanyInformation;
