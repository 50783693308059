import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

export interface TabItem {
  key: string;
  label: string;
  description: string;
  route: string;
  icon?: any;
  exact?: boolean;
}

export default function HorizontalTabs({
  tabs,
  renderSecondaryColours,
}: {
  tabs: TabItem[];
  renderSecondaryColours?: boolean;
}) {
  const location = useLocation();

  return (
    <nav className="flex gap-x-1 overflow-x-auto" aria-label="Tabs">
      {tabs.map(tab => {
        function isActive() {
          if (tab?.exact) {
            return location.pathname === tab.route;
          }
          return (
            location.pathname === tab.route ||
            (location.pathname.startsWith(tab.route) && tab.route !== '/')
          );
        }

        return (
          <NavLink
            key={tab.key}
            to={tab.route}
            replace
            className={classNames(
              isActive()
                ? renderSecondaryColours
                  ? 'bg-gray-100 text-gray-700'
                  : 'bg-indigo-100 text-indigo-700'
                : renderSecondaryColours
                ? 'text-indigo-500 hover:text-indigo-700 hover:bg-indigo-50 font-bold'
                : ' text-gray-500 hover:text-gray-700 hover:bg-gray-50',
              'rounded-md px-3 py-2 text-sm font-medium text-nowrap min-w-fit'
            )}
            aria-current={isActive() ? 'page' : undefined}
          >
            <div className="flex items-center gap-1">
              {tab.icon && (
                <img
                  src={tab.icon}
                  alt="icon"
                  className={classNames(
                    isActive()
                      ? renderSecondaryColours
                        ? 'text-gray-100'
                        : 'text-indigo-600'
                      : renderSecondaryColours
                      ? 'text-indigo-400 group-hover:text-gray-600'
                      : 'text-gray-400 group-hover:text-indigo-600',
                    'h-5 w-5 shrink-0'
                  )}
                  aria-hidden="true"
                />
              )}
              {tab.label}
            </div>
          </NavLink>
        );
      })}
    </nav>
  );
}
