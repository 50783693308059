import { useMutation } from '@apollo/client';
import { TrashIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';

const RESCIND_SYNDICATE_INVITE_MUTATION = gql(`
  mutation RescindSyndicateLeadInvite($id: ID!) {
    rescindSyndicateInvite(id: $id) {
      id
      status
    }
  }
`);

const RescindSyndicateLeadInviteDialog: FC<{
  id: string;
  onClose: () => void;
}> = ({ id, onClose }) => {
  const [rescindSyndicateInvite, { loading }] = useMutation(RESCIND_SYNDICATE_INVITE_MUTATION);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogPanel>
        <DialogTitle>Rescind invite</DialogTitle>
        <p className="py-4">Are you sure you want to rescind this invite?</p>
        <div className="flex flex-row min-w-full space-x-2 mt-4">
          <Button
            variant="secondary"
            loading={loading}
            className="w-full"
            onClick={onClose}
            leadingIcon={<XCircleIcon />}
          >
            Close
          </Button>
          <Button
            loading={loading}
            className="w-full"
            onClick={() =>
              rescindSyndicateInvite({
                variables: {
                  id,
                },
              }).then(() => {
                onClose();
              })
            }
            leadingIcon={<TrashIcon />}
          >
            Rescind
          </Button>
        </div>
      </DialogPanel>
    </Dialog>
  );
};

export default RescindSyndicateLeadInviteDialog;
