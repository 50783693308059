import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { SyndicateTypeEnumType } from '__generated__/graphql';
import { FC, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';
import HorizontalTabs from 'components/HorizontalTabs';

import Alert from 'primitives/Alert';
import LoadingIndicator from 'primitives/LoadingIndicator';
import Toast from 'primitives/Toast';
import useToast from 'primitives/Toast/useToast';

import InviteLinks from './InviteLinks';
import InviteUsersEmailPreviewDialogButton from './InviteUsersEmailPreviewDialogButton';

const DEAL_INFORMATION_INVITE_QUERY = gql(`
  query DealInformationForInvite($id: ID!, $cursor: ID, $filters: SyndicateUsersFilterType) {
    deal(id: $id) {
      id
      permissions {
        canInviteUsers
      }
      syndicate {
        id
        type
        syndicateUsers(cursor: $cursor, filters: $filters) {
          nodes {
            id
            user {
              id
              name
              email
            }
            createdAt
            statistics {
              investRate
            }
          }
          pageInfo {
            hasNextPage
            cursor
          }
        }
      }
    }
  }
`);

const INVITE_USERS_TO_DEAL_MUTATION = gql(`
  mutation InviteUsersToDeal(
    $dealId: ID!
    $userIds: [ID]!
    $note: String
    $minimumInvestmentAmount: Int
    $maximumInvestmentAmount: Int
  ) {
    inviteUsersToDeal(
      id: $dealId
      userIds: $userIds
      note: $note
      minimumInvestmentAmount: $minimumInvestmentAmount
      maximumInvestmentAmount: $maximumInvestmentAmount
    )
  }
`);

const InviteUsersToDeal: FC = () => {
  const { syndicateId, dealId } = useParams<{ syndicateId: string; dealId: string }>() as {
    syndicateId: string;
    dealId: string;
  };

  const tabs = [
    {
      key: 'active',
      label: 'Invited',
      description: 'View and manage invited LPs',
      route: `/syndicate-dashboard/${syndicateId}/deals/${dealId}/invites`,
      exact: true,
    },
    {
      key: 'passed',
      label: 'Passed',
      description: 'View and manage passed LPs',
      route: `/syndicate-dashboard/${syndicateId}/deals/${dealId}/invites/passed`,
    },
    {
      key: 'viewed',
      label: 'Viewed',
      description: 'View and manage viewed LPs',
      route: `/syndicate-dashboard/${syndicateId}/deals/${dealId}/invites/viewed`,
    },
    {
      key: 'cancelled',
      label: 'Cancelled',
      description: 'View and manage cancelled LPs',
      route: `/syndicate-dashboard/${syndicateId}/deals/${dealId}/invites/cancelled`,
    },
    {
      key: 'blocked',
      label: 'Blocked',
      description: 'View and manage blocked LPs',
      route: `/syndicate-dashboard/${syndicateId}/deals/${dealId}/invites/blocked`,
    },
  ];

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [openEmailPreview, setOpenEmailPreview] = useState(false);
  const [formData, setFormData] = useState<any>(null);
  const { showToast, handleToastOpen, handleToastClose } = useToast();

  const { loading, error, data, refetch, fetchMore } = useQuery(DEAL_INFORMATION_INVITE_QUERY, {
    variables: {
      id: dealId,
      filters: {
        isEligibleForDealId: dealId,
      },
    },
  });

  const [inviteUsersToDeal, { loading: inviteUsersToDealLoading, error: inviteUsersToDealError }] =
    useMutation(INVITE_USERS_TO_DEAL_MUTATION, {
      refetchQueries: ['GetActiveInvites', 'DealInformationForInvite'],
    });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const { nodes: syndicateUsers, pageInfo } = data.deal.syndicate.syndicateUsers;
  const isRuvSyndicate = data.deal.syndicate.type === SyndicateTypeEnumType.Ruv;

  const onSubmitAfterEmailPreview = async () => {
    inviteUsersToDeal({
      variables: {
        dealId,
        userIds: selectedUserIds,
        note: formData.note,
        minimumInvestmentAmount: formData.minimumInvestmentAmount,
        maximumInvestmentAmount: formData.maximumInvestmentAmount,
      },
    });
  };
  const canInviteUsers = data.deal.permissions.canInviteUsers;
  return (
    <>
      {!canInviteUsers && (
        <Alert
          type="warning"
          title="Invites Disabled"
          message="You can only invite users to a deal that is in the raising state."
        />
      )}

      <div className="mt-2 sm:flex sm:items-center sm:justify-between">
        <div className="max-w-xl">
          <h3 className="text-lg font-semibold leading-6 text-gray-700">Manage your invites</h3>
          <p className="mt-1 text-md text-gray-500">View details about your past invites.</p>
        </div>
      </div>
      <HorizontalTabs tabs={tabs} renderSecondaryColours={true} />
      <Outlet />
      {!isRuvSyndicate && (
        <>
          <div className="mt-8 sm:flex sm:items-center sm:justify-between">
            <div className="max-w-xl">
              <h3 className="text-lg font-semibold leading-6 text-gray-700">Invite your network</h3>
              <p className="mt-1 text-md text-gray-500">
                Send out invites to your network to join this deal.
              </p>
            </div>
          </div>
          <DataTable
            data={syndicateUsers}
            columns={[
              {
                label: 'Select',
                fieldName: 'user.id',
                type: 'SELECT',
              },
              {
                label: 'Name',
                fieldName: 'user.name',
              },
              {
                label: 'Email',
                fieldName: 'user.email',
              },
              {
                label: 'Joined Syndicate',
                fieldName: 'createdAt',
                type: 'DATE',
              },
              {
                label: 'Invest Rate In Your Syndicate',
                fieldName: 'statistics.investRate',
                type: 'PERCENTAGE',
              },
            ]}
            onSelect={(userIds: string[]) => setSelectedUserIds(userIds)}
            hasNextPage={pageInfo.hasNextPage}
            onLoadMore={() =>
              fetchMore({
                variables: {
                  cursor: pageInfo.cursor,
                },
              })
            }
          />
        </>
      )}

      {selectedUserIds && selectedUserIds.length && canInviteUsers ? (
        <>
          <FormPanel
            loading={inviteUsersToDealLoading}
            error={inviteUsersToDealError}
            onSubmit={data => {
              setFormData(data);
              setOpenEmailPreview(true);
            }}
            submitButtonLabel="Preview Invitation"
          >
            <FormInput type="textarea" fieldName="note" label="Note" defaultValue={''} />
            <FormInput
              type="currency"
              fieldName="minimumInvestmentAmount"
              label="Minimum Investment Amount"
              defaultValue={300000}
            />
            <FormInput
              type="currency"
              fieldName="maximumInvestmentAmount"
              label="Maximum Investment Amount"
              defaultValue={1000000}
            />
          </FormPanel>
        </>
      ) : null}

      <InviteLinks />
      <InviteUsersEmailPreviewDialogButton
        open={openEmailPreview}
        setOpen={setOpenEmailPreview}
        handleToastOpen={handleToastOpen}
        onSubmit={onSubmitAfterEmailPreview}
        formData={formData}
        dealId={dealId}
      />
      <Toast
        title="Success"
        message={'Invites sent successfully'}
        show={showToast}
        handleClose={handleToastClose}
      />
    </>
  );
};

export default InviteUsersToDeal;
