import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

const LEAVE_SYNDICATE_MUTATION = gql(`
  mutation LeaveSyndicateAsUser($syndicateId: ID!) {
    leaveSyndicateAsUser(syndicateId: $syndicateId) {
      id
      syndicate {
        id
        permissions {
          canApplyToSyndicate
          canCancelSyndicateInvite
          canLeaveSyndicateAsUser
        }
      }
    }
  }
`);

const LeaveSyndicateAsUserDialogButton: FC<{
  syndicateId: string;
  onClose: () => void;
}> = ({ syndicateId, onClose }) => {
  const [showConfirmDialog, toggleConfirmDialog] = useState(false);

  const [removeUserFromSyndicate, { error, loading }] = useMutation(LEAVE_SYNDICATE_MUTATION, {
    refetchQueries: ['SuggestedSyndicates', 'BackedSyndicates'],
  });

  return (
    <>
      <Button onClick={() => toggleConfirmDialog(true)}>Leave Syndicate</Button>
      <Dialog
        open={showConfirmDialog}
        onClose={() => {
          onClose();
          toggleConfirmDialog(false);
        }}
      >
        <DialogPanel>
          <DialogTitle>Are you sure?</DialogTitle>
          You will have to be invited again or send an application to rejoin
          {error && <ErrorMessage error={error} type="alert" />}
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                onClose();
                toggleConfirmDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() =>
                removeUserFromSyndicate({ variables: { syndicateId } }).then(() => {
                  onClose();
                  toggleConfirmDialog(false);
                })
              }
            >
              Leave Syndicate
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default LeaveSyndicateAsUserDialogButton;
