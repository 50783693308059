import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DealTypeEnumType, SyndicateTypeEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateLayoutContent } from 'layouts/CreateLayout';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import LoadingIndicator from 'primitives/LoadingIndicator';

import SearchCompanies from './SearchCompanies';
import {
  financialInstrumentTypeOptions,
  fundingRoundNameOptions,
  premiumToLastRoundTypeOptions,
  prorataRightsTypeOptions,
  shareClassTypeOptions,
  transactionTypeOptions,
  valuationTypeOptions,
} from './constants';

const GET_SYNDICATE_TYPE_QUERY = gql(`
  query SyndicateType($id: ID!) {
    syndicate(id: $id) {
      id
      type
    }
  }
`);

const GET_DEAL_QUERY = gql(`
  query Deal($id: ID!) {
    deal(id: $id) {
      id
      status
      type
      allocation
      closingDate
      createdAt
      updatedAt
      companyInformation {
        id
        company {
          id
          name
          image
        }
      }
      dealTerms {
        id
        transactionType
        fundingRoundName
        valuationType
        valuationOrCap
        financialInstrument
        shareClass
        prorataRights
        minimumInvestment
        otherTerms
        fundingRoundSize
        expectedSharePrice
        premiumToLastRound
        premiumToLastRoundType
      }
    }
  }
`);

const UPSERT_DEAL_MUTATION = gql(`
  mutation UpsertDeal(
    $syndicateId: ID!
    $dealId: ID
    $companyId: ID!
    $transactionType: DealTransactionTypeEnumType!
    $fundingRoundName: FundingRoundNameEnumType!
    $fundingRoundSize: Float
    $type: DealTypeEnumType!
    $valuationOrCap: Float!
    $valuationType: ValuationTypeEnumType!
    $financialInstrument: FinancialInstrumentEnumType!
    $expectedSharePrice: Float
    $premiumToLastRound: Float
    $premiumToLastRoundType: PremiumToLastRoundEnumType
    $shareClass: ShareClassEnumType!
    $allocation: Float!
    $prorataRights: ProrataRightsEnumType!
    $minimumInvestment: Float!
    $closingDate: String!
    $otherTerms: String
  ) {
    upsertDeal(
      syndicateId: $syndicateId
      dealId: $dealId
      companyId: $companyId
      transactionType: $transactionType
      fundingRoundName: $fundingRoundName
      fundingRoundSize: $fundingRoundSize
      type: $type
      valuationOrCap: $valuationOrCap
      valuationType: $valuationType
      financialInstrument: $financialInstrument
      expectedSharePrice: $expectedSharePrice
      premiumToLastRound: $premiumToLastRound
      premiumToLastRoundType: $premiumToLastRoundType
      shareClass: $shareClass
      allocation: $allocation
      prorataRights: $prorataRights
      minimumInvestment: $minimumInvestment
      closingDate: $closingDate
      otherTerms: $otherTerms
    ) {
      id
      status
      type
      allocation
      closingDate
      createdAt
      updatedAt
      companyInformation {
        id
        company {
          id
          name
          image
        }
      }
      dealTerms {
        id
        transactionType
        fundingRoundName
        valuationType
        valuationOrCap
        financialInstrument
        shareClass
        prorataRights
        minimumInvestment
        otherTerms
        fundingRoundSize
        expectedSharePrice
        premiumToLastRound
        premiumToLastRoundType
      }
    }
  }
`);

const BasicInfo: FC = () => {
  const navigate = useNavigate();
  const { syndicateId, dealId } = useParams<{ syndicateId: string; dealId: string }>() as {
    syndicateId: string;
    dealId: string;
  };

  const {
    data: syndicateData,
    loading: syndicateLoading,
    error: syndicateError,
  } = useQuery(GET_SYNDICATE_TYPE_QUERY, {
    variables: {
      id: syndicateId,
    },
  });

  const [getDeal, { data, loading, error, refetch }] = useLazyQuery(GET_DEAL_QUERY);

  const [upsertDeal, { loading: upsertLoading, error: upsertError }] = useMutation(
    UPSERT_DEAL_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: `SyndicateType:${syndicateId}`,
          fields: {
            deals(existingDealsRef) {
              const newDealNodeRef = cache.writeFragment({
                data: data.upsertDeal,
                fragment: gql(`
                  fragment NewDeal on DealType {
                    id
                    status
                    dealTerms {
                      id
                      fundingRoundSize
                      fundingRoundName
                      valuationOrCap
                      financialInstrument
                    }
                    companyInformation {
                      id
                      company {
                        id
                        name
                        image
                      }
                    }
                    closingDate
                    createdAt
                    updatedAt
                  }
              `),
              });
              const updatedDealsRef = { ...existingDealsRef };
              updatedDealsRef.nodes = [newDealNodeRef, ...updatedDealsRef.nodes];
              return updatedDealsRef;
            },
          },
        });
      },
    }
  );

  useEffect(() => {
    if (!dealId) return;

    getDeal({ variables: { id: dealId } });
  }, [dealId, getDeal]);

  if (loading || syndicateLoading) return <LoadingIndicator />;

  if (error || syndicateError || !syndicateData)
    return <ErrorMessage error={error} refetch={refetch} />;

  if (dealId && !data) return <LoadingIndicator />;

  return (
    <CreateLayoutContent
      title={
        syndicateData.syndicate.type === SyndicateTypeEnumType.Ruv
          ? 'Create a Roll Up Vehicle'
          : 'Create a Deal'
      }
      subTitle="Start getting investments by providing information about an ongoing deal"
    >
      <FormPanel
        loading={upsertLoading}
        error={upsertError}
        onSubmit={data => {
          upsertDeal({
            variables: {
              dealId: dealId ? dealId : '',
              syndicateId,
              companyId: data.company.id,
              transactionType: data.transactionType,
              fundingRoundName: data.fundingRoundName,
              fundingRoundSize: data.fundingRoundSize,
              financialInstrument: data.financialInstrument,
              type:
                syndicateData.syndicate.type === SyndicateTypeEnumType.Ruv
                  ? DealTypeEnumType.Ruv
                  : DealTypeEnumType.Syndicated,
              valuationOrCap: data.valuationOrCap,
              valuationType: data.valuationType,
              expectedSharePrice: data.expectedSharePrice,
              premiumToLastRound: data.premiumToLastRound,
              premiumToLastRoundType: data.premiumToLastRoundType,
              shareClass: data.shareClass,
              allocation: data.allocation,
              prorataRights: data.prorataRights,
              minimumInvestment: data.minimumInvestment,
              closingDate: data.closingDate,
              otherTerms: data.otherTerms,
            },
          }).then(data => {
            navigate(
              `/syndicate-dashboard/${syndicateId}/deals/create/${data.data?.upsertDeal.id}/external-investors`
            );
          });
        }}
        submitButtonLabel="Save and continue"
      >
        <FormInput
          type="custom"
          fieldName="company"
          label="Company"
          defaultValue={data?.deal?.companyInformation.company || ''}
          customInput={SearchCompanies}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="transactionType"
          label="Transaction Type"
          defaultValue={data?.deal?.dealTerms?.transactionType || ''}
          options={transactionTypeOptions}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="fundingRoundName"
          label="Round"
          defaultValue={data?.deal?.dealTerms?.fundingRoundName || ''}
          options={fundingRoundNameOptions}
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="currency"
          fieldName="fundingRoundSize"
          label="Round Size"
          helperText="The total amount the company wants to raise this round"
          defaultValue={data?.deal?.dealTerms?.fundingRoundSize || undefined}
          conditionsToShow={{
            matches: [
              {
                field: 'transactionType',
                condition: '===',
                value: 'PRIMARY',
              },
            ],
          }}
          validators={{
            required: true,
            minValue: 5000000,
          }}
        />
        <FormInput
          type="currency"
          fieldName="expectedSharePrice"
          label="Expected Share Price"
          helperText="The price per share, including any additional costs, at which the SPV expects to purchase the shares"
          defaultValue={data?.deal?.dealTerms?.expectedSharePrice || undefined}
          conditionsToShow={{
            matches: [
              {
                field: 'transactionType',
                condition: '===',
                value: 'SECONDARY',
              },
            ],
          }}
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="number"
          fieldName="premiumToLastRound"
          label="Premium to Last Round"
          helperText="Discount or premium of the share price compared to the company's latest funding round"
          defaultValue={data?.deal?.dealTerms?.premiumToLastRound || undefined}
          conditionsToShow={{
            matches: [
              {
                field: 'transactionType',
                condition: '===',
                value: 'SECONDARY',
              },
            ],
          }}
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="radio-group"
          fieldName="premiumToLastRoundType"
          label="Premium to Last Round Type"
          helperText="Type of Discount or Premium"
          defaultValue={data?.deal?.dealTerms?.premiumToLastRoundType || undefined}
          options={premiumToLastRoundTypeOptions}
          conditionsToShow={{
            matches: [
              {
                field: 'transactionType',
                condition: '===',
                value: 'SECONDARY',
              },
            ],
          }}
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="currency"
          fieldName="valuationOrCap"
          label="Valuation"
          defaultValue={data?.deal?.dealTerms?.valuationOrCap || undefined}
          validators={{
            required: true,
            minValue: 1,
          }}
        />
        <FormInput
          type="radio-group"
          fieldName="valuationType"
          label="Valuation Type"
          defaultValue={data?.deal?.dealTerms?.valuationType || ''}
          options={valuationTypeOptions}
          conditionsToShow={{
            matches: [
              {
                field: 'transactionType',
                condition: '===',
                value: 'PRIMARY',
              },
            ],
          }}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="financialInstrument"
          label="Financial Instrument"
          defaultValue={data?.deal?.dealTerms?.financialInstrument || ''}
          options={financialInstrumentTypeOptions}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="shareClass"
          label="Share Class"
          defaultValue={data?.deal?.dealTerms?.shareClass || ''}
          options={shareClassTypeOptions}
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="currency"
          fieldName="allocation"
          label="Your Allocation"
          defaultValue={data?.deal?.allocation || undefined}
          helperText="₹50L minimum suggested. You can change this anytime prior to closing."
          validators={{
            required: true,
            minValue: 5000000,
          }}
        />
        <FormInput
          type="radio-group"
          fieldName="prorataRights"
          label="Pro-rata Rights"
          defaultValue={data?.deal?.dealTerms?.prorataRights || ''}
          helperText="Will the SPV receive formal legal pro-rata rights?"
          options={prorataRightsTypeOptions}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="textarea"
          fieldName="otherTerms"
          label="Other Terms"
          defaultValue={data?.deal?.dealTerms?.otherTerms || ''}
          helperText="Non-standard liquidation preferences, interest rates, redemption rights, etc."
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="date"
          fieldName="closingDate"
          label="Target Closing Date"
          defaultValue={data?.deal?.closingDate || ''}
          helperText="Date we stop accepting new commitments. You can adjust this as needed."
          minDate={dayjs()}
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="currency"
          fieldName="minimumInvestment"
          label="LP Minimum Investment"
          defaultValue={data?.deal?.dealTerms?.minimumInvestment || undefined}
          helperText="Default minimum LPs are required to invest. Modify later for individual LPs with custom invite links."
          validators={{
            required: true,
            minValue: 300000,
          }}
        />
      </FormPanel>
    </CreateLayoutContent>
  );
};

export default BasicInfo;
