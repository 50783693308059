import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import HorizontalTabs from 'components/HorizontalTabs';

import { useSyndicateOnDisplay } from '../index';

const InvestorApplications: FC = () => {
  const { setSyndicateOnDisplay } = useSyndicateOnDisplay();

  const tabs = [
    {
      key: 'outgoing',
      label: 'Outbound',
      description: 'View and manage outgoing applications to Syndicates',
      route: `/invest/syndicates/pending`,
      exact: true,
    },
    {
      key: 'incoming',
      label: 'Inbound',
      description: 'View and manage incoming invites from Syndicates',
      route: `/invest/syndicates/pending/incoming`,
      exact: true,
    },
  ];

  return (
    <>
      <HorizontalTabs tabs={tabs} />
      <Outlet context={{ setSyndicateOnDisplay }} />
    </>
  );
};

export default InvestorApplications;
