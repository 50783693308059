import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { InvestmentEntityTypeEnumType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_SYNDICATE_MUTATION = gql(`
  mutation UpdateInvestmentEntity($id: ID!, $name: String!, $type: InvestmentEntityTypeEnumType!) {
    updateInvestmentEntity(id: $id, name: $name, type: $type) {
      id
      name
      type
      updatedAt
    }
  }
`);

const UpdateInvestmentEntity: FC<{
  id: string;
  name: string;
  type: InvestmentEntityTypeEnumType;
}> = ({ id, name, type }) => {
  const [updateInvestmentEntity, { loading, error }] = useMutation(UPDATE_SYNDICATE_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateInvestmentEntity({
          variables: {
            id,
            name: data.name,
            type: data.type,
          },
        });
      }}
      disableEdit={true}
      title="Information"
    >
      <FormInput type="text" fieldName="name" label="Name" defaultValue={name} />
      <FormInput
        fullWidth
        type="select"
        fieldName="type"
        label="Type"
        defaultValue={type}
        options={[
          { value: 'INDIVIDUAL', label: 'Individual' },
          { value: 'COMPANY', label: 'Company' },
          { value: 'UNKNOWN', label: 'Unknown' },
        ]}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateInvestmentEntity;
