import { gql, useQuery } from '@apollo/client';
import { SyndicateTypeEnumType } from '__generated__/graphql';
import classNames from 'classnames';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

const GET_SYNDICATE_TYPE_QUERY = gql(`
  query GetSyndicateTypeForTabs($id: ID!) {
    syndicate(id: $id) {
      id
      type
    }
  }
`);

const DealTabsForInformation: FC<{ activeTab: string; onSelect: (tab: string) => void }> = ({
  activeTab,
  onSelect,
}) => {
  const { syndicateId } = useParams<{ syndicateId: string }>();
  const { data } = useQuery(GET_SYNDICATE_TYPE_QUERY, {
    variables: { id: syndicateId },
  });

  const isRuv = data?.syndicate?.type === SyndicateTypeEnumType.Ruv;
  const tabs = [
    {
      title: 'Basic Information',
      url: 'basic-information',
      subTitle: 'Company, Allocation and Terms',
    },
    {
      title: 'External Investors',
      url: 'external-investors',
      subTitle: 'Past Financing Information',
    },
    {
      title: 'Company Information',
      url: 'company-information',
      subTitle: 'Company Details',
    },
    {
      title: 'Memo',
      url: 'memo',
      subTitle: 'Investment Thesis',
    },
    {
      title: 'Risks & Disclosures',
      url: 'risks-and-disclosures',
      subTitle: 'Risks your investors should know',
    },
    ...(isRuv
      ? []
      : [
          {
            title: 'Carry & GP Commitment',
            url: 'carry-recipients',
            subTitle: 'Carry Recipients',
          },
        ]),
  ];

  function isPathActive(path: string) {
    return activeTab === path;
  }

  return (
    <div className="sticky top-20">
      <nav>
        <ol className="overflow-hidden space-y-2">
          {tabs.map(tab => (
            <li key={tab.url} className="relative overflow-hidden rounded-md shadow-sm">
              <div className="overflow-hidden bg-gray-50">
                <button
                  onClick={() => onSelect(tab.url)}
                  className={classNames('group text-left', {
                    'group-hover:bg-gray-200': isPathActive(tab.url),
                    'pointer-events-none': isPathActive(tab.url),
                  })}
                >
                  <span
                    className={classNames('absolute left-0 top-0 h-full w-1', {
                      'bg-indigo-600 group-hover:bg-indigo-600': isPathActive(tab.url),
                      'group-hover:bg-gray-200 bg-transparent': !isPathActive(tab.url),
                    })}
                  />
                  <span className="flex justify-between items-center px-4 py-2">
                    <span className="flex min-w-0 flex-col">
                      <span
                        className={classNames(
                          'text-md font-medium',
                          isPathActive(tab.url) ? 'text-indigo-600' : 'text-gray-700'
                        )}
                      >
                        {tab.title}
                      </span>
                      <span className="text-sm font-normal text-gray-500">{tab.subTitle}</span>
                    </span>
                  </span>
                </button>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default DealTabsForInformation;
