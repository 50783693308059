import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { IncorporationTypeEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';

const CREATE_COMPANY_MUTATION = gql(`
  mutation CreateCompany(
    $name: String!
    $legalName: String!
    $countryOfIncorporation: CountryEnumType!
    $dateOfIncorporation: String
    $incorporationType: IncorporationTypeEnumType!
    $image: Upload
    $website: String
  ) {
    createCompany(
      name: $name
      legalName: $legalName
      countryOfIncorporation: $countryOfIncorporation
      dateOfIncorporation: $dateOfIncorporation
      incorporationType: $incorporationType
      image: $image
      website: $website
    ) {
      id
      name
      image
      legalName
    }
  }
`);

const CreateCompanyDialogButton: FC<{
  onSubmit: (company: {
    id: string;
    name: string;
    legalName?: string | null;
    image?: string | null;
  }) => void;
}> = ({ onSubmit }) => {
  const [open, setOpen] = useState(false);

  const [createCompany, { loading, error }] = useMutation(CREATE_COMPANY_MUTATION);

  return (
    <>
      <Button variant="text" className="mt-1" onClick={() => setOpen(true)}>
        Or create a new one
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel>
          <DialogTitle>Create a Company</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => setOpen(false)}
            onSubmit={data => {
              createCompany({
                variables: {
                  name: data.name,
                  legalName: data.legalName,
                  countryOfIncorporation: data.countryOfIncorporation,
                  dateOfIncorporation: data.dateOfIncorporation,
                  incorporationType: data.incorporationType,
                  image: data.image,
                  website: data.website,
                },
              }).then(data => {
                setOpen(false);
                if (!data.data) return;
                onSubmit({
                  id: data.data.createCompany.id,
                  name: data.data.createCompany.name,
                  legalName: data.data.createCompany.legalName,
                  image: data.data.createCompany.image,
                });
              });
            }}
          >
            <FormInput
              fullWidth
              type="text"
              fieldName="name"
              label="Name *"
              defaultValue=""
              validators={{
                required: true,
                maxLength: 255,
                minLength: 3,
              }}
            />
            <FormInput
              fullWidth
              type="text"
              fieldName="legalName"
              label="Legal Name *"
              defaultValue=""
              validators={{
                required: true,
                maxLength: 255,
                minLength: 3,
              }}
            />
            <FormInput
              fullWidth
              type="select"
              fieldName="countryOfIncorporation"
              label="Country of Incorporation *"
              defaultValue="IN"
              options={[{ value: 'IN', label: 'India' }]}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fullWidth
              type="date"
              fieldName="dateOfIncorporation"
              label="Date of Incorporation"
              defaultValue={null}
              maxDate={dayjs()}
            />
            <FormInput
              fullWidth
              type="select"
              fieldName="incorporationType"
              label="Incorporation Type *"
              options={[
                { value: IncorporationTypeEnumType.PrivateLimited, label: 'Private Limited' },
                { value: IncorporationTypeEnumType.Llc, label: 'LLC' },
                { value: IncorporationTypeEnumType.Other, label: 'Other' },
              ]}
              defaultValue={IncorporationTypeEnumType.PrivateLimited}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fullWidth
              type="upload"
              fieldName="image"
              label="logo"
              defaultValue={null}
              maxFileSize={10}
            />
            <FormInput fullWidth type="text" fieldName="website" label="Website" defaultValue="" />
          </FormPanel>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateCompanyDialogButton;
