import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { MegaphoneIcon, TicketIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const GetStartedWithInvestingBanner: FC = () => {
  const items = [
    {
      name: 'Discover new syndicates',
      description: 'Browse AngelList India syndicates to enhance your dealflow',
      href: `/invest/syndicates`,
      iconColor: 'bg-pink-500',
      icon: TicketIcon,
    },
    {
      name: 'Invest in deals',
      description: 'Get access to exclusive deals and invest in startups',
      href: `/invest/deal-invites`,
      iconColor: 'bg-purple-500',
      icon: MegaphoneIcon,
    },
    {
      name: 'Adjust your investment preferences',
      description: 'Set your investment preferences to receive personalized deal invites',
      href: `/invest/settings/investment-preferences`,
      iconColor: 'bg-yellow-500',
      icon: UserGroupIcon,
    },
  ];

  return (
    <div className="rounded-xl bg-gray-50 z-10 p-4">
      <h2 className="text-xl font-semibold leading-6 text-gray-700">Get started with investing</h2>
      <p className="mt-1 text-sm text-gray-500">
        AngelList India provides all the tools you need to get started with discovering syndicates,
        and investing in startups.
      </p>
      <ul className="mt-6 divide-y divide-gray-200 rounded-lg px-4 bg-white">
        {items.map((item, itemIdx) => (
          <li key={itemIdx}>
            <Link to={item.href}>
              <div className="group relative flex items-start space-x-3 py-4">
                <div className="flex-shrink-0">
                  <span
                    className={classNames(
                      item.iconColor,
                      'inline-flex h-10 w-10 items-center justify-center rounded-lg'
                    )}
                  >
                    <item.icon aria-hidden="true" className="h-6 w-6 text-white" />
                  </span>
                </div>
                <div className="min-w-0 flex-1">
                  <div className="text-sm font-medium text-gray-900">
                    <a href={item.href}>
                      <span aria-hidden="true" className="absolute inset-0" />
                      {item.name}
                    </a>
                  </div>
                  <p className="text-sm text-gray-500">{item.description}</p>
                </div>
                <div className="flex-shrink-0 self-center">
                  <ChevronRightIcon
                    aria-hidden="true"
                    className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      <div className="mt-6 flex">
        <a
          href="https://angellistindia.com"
          className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
        >
          Learn how investing works
          <span aria-hidden="true"> &rarr;</span>
        </a>
      </div>
    </div>
  );
};

export default GetStartedWithInvestingBanner;
